const frameSettings = {
    NoFrame: {
        '300': {
            viewBox: "0 0 300 300",
            x: "0",
            y: "0",
            framePath: ``
        },
        '500': {
            viewBox: "0 0 500 500",
            x: "0",
            y: "0",
            framePath: ``
        },
        '1000': {
            viewBox: "0 0 1000 1000",
            x: "0",
            y: "0",
            framePath: ``
        },
        '2000': {
            viewBox: "0 0 2000 2000",
            x: "0",
            y: "0",
            framePath: ``
        },
        '4000': {
            viewBox: "0 0 4000 4000",
            x: "0",
            y: "0",
            framePath: ``
        }
    },
    ScanMeBottom: {

        '300': {
            viewBox: "0 0 386.5 512.1",
            x: "43.3",
            y: "43.3",
            framePath: `<path class="st0" d="M358.4,0H28.2C12.6,0,0,12.6,0,28.1v313.3v16.9c0,23,0,62.4,0,73.2v15.2v32.6v4.5c0,15.5,12.6,28.2,28.2,28.2
            h330.2c15.5,0,28.2-12.6,28.2-28.2v-4.5v-32.6v-15.2c0-11.4,0-52.2,0-73.2v-12.7V28.1C386.5,12.6,373.9,0,358.4,0z"/>
            <path class="st1" d="M372,358.4c0,7.5-6.1,13.6-13.6,13.6H244.7l-44.1,39.7L156.4,372H28.2c-7.5,0-13.6-6.1-13.6-13.6V28.1
                c0-7.5,6.1-13.6,13.6-13.6h330.2c7.5,0,13.6,6.1,13.6,13.6V358.4z"/>
            <g>
                <path class="st1" d="M55,470.8v4.4c0,4.2,1,6.1,3.8,6.1c2.8,0,3.5-2.9,3.5-5.9c0-6-1.1-7.7-7.6-13.1c-7.1-6-10.8-9.6-10.8-19
                    c0-9.1,3-17.4,15-17.4c12.7,0,14.6,8.7,14.6,15.9v3.6H62v-3.8c0-3.8-0.6-5.7-3-5.7c-2.2,0-3,1.9-3,5.5c0,3.8,0.7,5.7,5.9,9.8
                    c9.3,7.3,12.7,11.4,12.7,21.9c0,9.9-3.4,18.1-16,18.1c-12.1,0-15.5-7.3-15.5-16.7v-3.8H55z"/>
                <path class="st1" d="M120.5,469.6v3.4c0,8-1.5,18.2-16,18.2c-10.7,0-15.4-5.7-15.4-17.4v-31.1c0-11,5.5-16.8,15.6-16.8
                    c13.4,0,15.6,8.8,15.6,17.1v4h-12.3v-5.4c0-3.5-0.7-5.3-3.3-5.3c-2.5,0-3.3,1.7-3.3,5.3v33.5c0,3.4,0.6,5.7,3.3,5.7
                    c2.6,0,3.4-1.9,3.4-6v-5.3H120.5z"/>
                <path class="st1" d="M146.8,475.2l-1.6,15.1h-12.4l9-63.4h18.2l9.2,63.4h-12.7l-1.7-15.1H146.8z M153.6,464.5
                    c-0.9-8.1-2.2-20.4-2.8-26.5h-0.5c-0.2,4.4-1.7,18.7-2.6,26.5H153.6z"/>
                <path class="st1" d="M182.9,490.3V427h13.5c2,7.7,8.4,36.4,8.9,39.2h0.3c-0.7-8.4-1.1-20-1.1-28.8V427h11.3v63.4h-13.6
                    c-1.3-6-8.2-38.8-8.7-40.8h-0.3c0.5,7.5,0.8,20.1,0.8,30v10.8H182.9z"/>
                <path class="st1" d="M287.2,470.1c0-9.8,0.1-23.7,0.3-31.5h-0.3c-1.3,12.7-4.1,38.4-5.9,51.7H271c-1.4-13.9-4.1-40-5.5-51.8h-0.4
                    c0.2,7.4,0.5,21.2,0.5,32.1v19.7h-11.1V427H272c1.7,12,3.5,27.9,4.2,36.5h0.3c1-9.2,3.1-22.5,5-36.5H299v63.4h-11.7V470.1z"/>
                <path class="st1" d="M341.2,462.3h-13.1v17.3h15.3l-1.5,10.7h-26.1V427h26v10.8h-13.8v13.9h13.1V462.3z"/>
            </g>`
        },
        '500': {
            viewBox: "0 0 645.5 855.3",
            x: "72.7",
            y: "72.7",
            framePath: `<path class="st0" d="M598.5,0H47C21.1,0,0,21.1,0,47v523.2v28.3c0,38.4,0,104.3,0,122.3v25.5v54.5v7.5c0,25.9,21.1,47,47,47h551.5
            c25.9,0,47-21.1,47-47v-7.5v-54.5v-25.5c0-19,0-87.2,0-122.3v-21.2V47C645.5,21.1,624.4,0,598.5,0z"/>
            <path class="st1" d="M621.3,598.5c0,12.6-10.2,22.8-22.8,22.8H408.6l-73.7,66.3l-73.7-66.3H47c-12.6,0-22.8-10.2-22.8-22.8V47
                c0-12.6,10.2-22.8,22.8-22.8h551.5c12.6,0,22.8,10.2,22.8,22.8V598.5z"/>
            <g>
                <path class="st1" d="M91.9,786.2v7.3c0,7,1.7,10.2,6.4,10.2c4.7,0,5.8-4.8,5.8-9.8c0-10-1.9-12.9-12.7-21.9
                    c-11.9-10.1-18-16.1-18-31.7c0-15.1,5-29,25.1-29c21.3,0,24.4,14.5,24.4,26.6v6.1h-19.2v-6.3c0-6.4-1.1-9.6-5.1-9.6
                    c-3.8,0-5,3.2-5,9.2c0,6.4,1.2,9.5,9.9,16.3c15.6,12.2,21.1,19.1,21.1,36.5c0,16.6-5.7,30.2-26.7,30.2c-20.2,0-25.9-12.2-25.9-27.9
                    v-6.3H91.9z"/>
                <path class="st1" d="M201.3,784.3v5.7c0,13.4-2.5,30.4-26.7,30.4c-17.9,0-25.7-9.5-25.7-29v-51.9c0-18.4,9.2-28.1,26.1-28.1
                    c22.4,0,26,14.7,26,28.6v6.6h-20.5v-9c0-5.8-1.2-8.8-5.5-8.8c-4.2,0-5.5,2.8-5.5,8.8v56c0,5.6,0.9,9.5,5.5,9.5
                    c4.4,0,5.7-3.2,5.7-10v-8.8H201.3z"/>
                <path class="st1" d="M245.1,793.7l-2.6,25.2h-20.8l15-105.8h30.3l15.3,105.8h-21.2l-2.9-25.2H245.1z M256.6,775.7
                    c-1.5-13.5-3.6-34.1-4.6-44.3h-0.8c-0.4,7.4-2.9,31.2-4.3,44.3H256.6z"/>
                <path class="st1" d="M305.5,818.8V713H328c3.4,12.9,14,60.8,14.8,65.5h0.5c-1.1-14.1-1.9-33.3-1.9-48.1V713h18.9v105.8h-22.7
                    c-2.2-10.1-13.8-64.8-14.4-68.2h-0.5c0.8,12.5,1.4,33.5,1.4,50.1v18.1H305.5z"/>
                <path class="st1" d="M479.7,785.1c0-16.4,0.2-39.6,0.4-52.6h-0.5c-2.2,21.2-6.9,64-9.8,86.3h-17.3c-2.3-23.1-6.9-66.8-9.1-86.6
                    h-0.7c0.3,12.4,0.8,35.4,0.8,53.6v33h-18.5V713h29.2c2.8,20.1,5.9,46.5,7.1,60.9h0.4c1.6-15.3,5.1-37.6,8.4-60.9h29.1v105.8h-19.6
                    V785.1z"/>
                <path class="st1" d="M569.8,772.1H548v28.8h25.6l-2.4,17.9h-43.5V713h43.4v18h-23v23.2h21.8V772.1z"/>
            </g>`
        },
        '1000': {
            viewBox: "0 0 1291 1710.5",
            x: "145.5",
            y: "145.5",
            framePath: `<path class="st0" d="M1196.9,0H94.1C42.2,0,0,42.2,0,94v1046.3v56.6c0,76.8,0,208.5,0,244.7v50.9v109v15c0,51.9,42.2,94.1,94.1,94.1
            H1197c51.8,0,94.1-42.2,94.1-94.1v-15v-109v-50.9c0-38,0-174.4,0-244.7v-42.5V94C1291,42.2,1248.8,0,1196.9,0z"/>
            <path class="st1" d="M1242.5,1196.9c0,25.1-20.4,45.6-45.6,45.6H817.2L669.9,1375l-147.3-132.6H94.1c-25.1,0-45.6-20.4-45.6-45.6V94
                c0-25.1,20.4-45.6,45.6-45.6H1197c25.1,0,45.6,20.4,45.6,45.6V1196.9z"/>
            <g>
                <path class="st1" d="M183.8,1572.4v14.7c0,13.9,3.5,20.4,12.7,20.4c9.4,0,11.6-9.6,11.6-19.6c0-20-3.8-25.8-25.4-43.8
                    c-23.8-20.2-36.1-32.2-36.1-63.3c0-30.2,10-58.1,50.1-58.1c42.6,0,48.9,29,48.9,53.3v12.2h-38.4v-12.7c0-12.8-2.1-19.2-10.2-19.2
                    c-7.5,0-9.9,6.5-9.9,18.4c0,12.7,2.3,19,19.8,32.7c31.1,24.5,42.3,38.2,42.3,73c0,33.1-11.3,60.4-53.4,60.4
                    c-40.4,0-51.9-24.4-51.9-55.7v-12.7H183.8z"/>
                <path class="st1" d="M402.6,1568.6v11.5c0,26.7-4.9,60.8-53.4,60.8c-35.8,0-51.4-19.1-51.4-58v-103.8c0-36.8,18.4-56.2,52.3-56.2
                    c44.7,0,52,29.4,52,57.1v13.3h-41v-18.1c0-11.7-2.5-17.5-11-17.5c-8.4,0-11.1,5.6-11.1,17.5v111.9c0,11.3,1.9,19,11.1,19
                    c8.8,0,11.4-6.5,11.4-20v-17.6H402.6z"/>
                <path class="st1" d="M490.2,1587.3l-5.2,50.4h-41.5l29.9-211.7h60.7l30.6,211.7h-42.3l-5.8-50.4H490.2z M513.1,1551.4
                    c-3-27.1-7.2-68.3-9.2-88.5h-1.6c-0.7,14.8-5.8,62.4-8.6,88.5H513.1z"/>
                <path class="st1" d="M611,1637.7V1426h45c6.7,25.8,27.9,121.7,29.6,131h1c-2.3-28.2-3.7-66.7-3.7-96.2V1426h37.8v211.7h-45.5
                    c-4.4-20.1-27.5-129.7-28.9-136.4h-1.1c1.6,24.9,2.8,67,2.8,100.3v36.1H611z"/>
                <path class="st1" d="M959.4,1570.2c0-32.8,0.3-79.2,0.8-105.2h-1.1c-4.4,42.3-13.8,128.1-19.6,172.6H905
                    c-4.7-46.3-13.8-133.6-18.2-173.2h-1.3c0.5,24.9,1.6,70.7,1.6,107.2v65.9h-36.9V1426h58.4c5.5,40.2,11.7,93.1,14.1,121.7h0.9
                    c3.3-30.6,10.3-75.1,16.8-121.7h58.2v211.7h-39.2V1570.2z"/>
                <path class="st1" d="M1139.6,1544.2h-43.6v57.7h51.2l-4.9,35.8h-87V1426h86.7v36h-46v46.4h43.6V1544.2z"/>
            </g>`
        },
        '2000': {
            viewBox: "0 0 2582 3421",
            x: "291",
            y: "291",
            framePath: `<path class="st0" d="M2393.8,0H188.1C84.4,0,0,84.4,0,188v2092.6v113.2c0,153.5,0,417.1,0,489.3V2985v218v29.9
            C0,3336.7,84.4,3421,188.1,3421h2205.8c103.7,0,188.1-84.4,188.1-188.1V3203v-218v-101.8c0-76,0-348.8,0-489.3v-84.9V188
            C2582,84.4,2497.6,0,2393.8,0z"/>
            <path class="st1" d="M2485,2393.8c0,50.2-40.9,91.1-91.1,91.1h-759.5l-294.7,265.1L1045.1,2485h-857c-50.2,0-91.1-40.9-91.1-91.1
                V188c0-50.2,40.9-91.1,91.1-91.1h2205.8c50.2,0,91.1,40.9,91.1,91.1V2393.8z"/>
            <g>
                <path class="st1" d="M367.5,3144.9v29.3c0,27.8,6.9,40.8,25.5,40.8c18.8,0,23.2-19.2,23.2-39.1c0-40-7.5-51.5-50.7-87.7
                    c-47.7-40.4-72.2-64.3-72.2-126.7c0-60.5,20-116.2,100.2-116.2c85.1,0,97.7,58.1,97.7,106.5v24.4h-76.9v-25.4
                    c0-25.7-4.2-38.4-20.3-38.4c-15,0-19.9,12.9-19.9,36.9c0,25.5,4.6,38.1,39.6,65.4c62.3,48.9,84.6,76.4,84.6,146.1
                    c0,66.3-22.6,120.9-106.7,120.9c-80.8,0-103.8-48.7-103.8-111.4v-25.4H367.5z"/>
                <path class="st1" d="M805.2,3137.2v22.9c0,53.4-9.8,121.6-106.9,121.6c-71.7,0-102.8-38.2-102.8-116v-207.6
                    c0-73.6,36.7-112.4,104.5-112.4c89.4,0,103.9,58.9,103.9,114.3v26.6h-82v-36.1c0-23.3-5-35.1-22-35.1c-16.8,0-22.2,11.1-22.2,35.1
                    v223.9c0,22.6,3.8,38,22.2,38c17.6,0,22.8-13,22.8-39.9v-35.2H805.2z"/>
                <path class="st1" d="M980.4,3174.6L970,3275.4h-83l59.9-423.3h121.4l61.3,423.3h-84.7l-11.7-100.7H980.4z M1026.3,3102.8
                    c-6-54.2-14.5-136.6-18.4-177.1h-3.2c-1.5,29.5-11.5,124.7-17.1,177.1H1026.3z"/>
                <path class="st1" d="M1222,3275.4V2852h90.1c13.4,51.7,55.9,243.4,59.2,262h2c-4.5-56.3-7.4-133.3-7.4-192.4V2852h75.6v423.3h-90.9
                    c-8.8-40.3-55.1-259.3-57.8-272.9h-2.2c3.2,49.8,5.7,134,5.7,200.6v72.3H1222z"/>
                <path class="st1" d="M1918.7,3140.5c0-65.5,0.6-158.4,1.7-210.4h-2.2c-8.8,84.7-27.6,256.2-39.1,345.2H1810
                    c-9.3-92.6-27.6-267.2-36.5-346.3h-2.6c1,49.7,3.2,141.5,3.2,214.4v131.9h-73.9V2852h116.9c11,80.5,23.4,186.2,28.2,243.5h1.7
                    c6.5-61.3,20.6-150.2,33.6-243.5h116.4v423.3h-78.4V3140.5z"/>
                <path class="st1" d="M2279.1,3088.4h-87.3v115.4h102.3l-9.8,71.6h-174.1V2852h173.4v72h-91.9v92.8h87.3V3088.4z"/>
            </g>`
        },
        '4000': {
            viewBox: "0 0 5164 6842.1",
            x: "582",
            y: "582",
            framePath: `<path class="st0" d="M4787.7,0H376.2C168.7,0,0,168.7,0,376.1v4185.3v226.3c0,307,0,834.2,0,978.6v203.6V6406v59.9
            c0,207.5,168.7,376.2,376.2,376.2h4411.6c207.3,0,376.2-168.7,376.2-376.2V6406v-436.1v-203.6c0-151.9,0-697.7,0-978.6v-169.8V376.1
            C5163.9,168.7,4995.2,0,4787.7,0z"/>
            <path class="st1" d="M4970.1,4787.7c0,100.5-81.8,182.2-182.2,182.2h-1519l-589.3,530.3l-589.3-530.3h-1714
                c-100.5,0-182.2-81.8-182.2-182.2V376.1c0-100.5,81.8-182.2,182.2-182.2h4411.6c100.5,0,182.2,81.8,182.2,182.2V4787.7z"/>
            <g>
                <path class="st1" d="M735.1,6289.8v58.6c0,55.7,13.8,81.5,51,81.5c37.5,0,46.3-38.3,46.3-78.3c0-79.9-15-103-101.5-175.4
                    c-95.4-80.8-144.4-128.6-144.4-253.3c0-120.9,40-232.4,200.4-232.4c170.3,0,195.4,116.1,195.4,213v48.7H828.6v-50.7
                    c0-51.4-8.4-76.8-40.6-76.8c-30,0-39.7,25.9-39.7,73.8c0,51,9.3,76.2,79.3,130.8c124.5,97.8,169.2,152.8,169.2,292.2
                    c0,132.6-45.3,241.8-213.5,241.8c-161.5,0-207.6-97.4-207.6-222.9v-50.7H735.1z"/>
                <path class="st1" d="M1610.5,6274.4v45.8c0,106.8-19.7,243.2-213.8,243.2c-143.4,0-205.6-76.4-205.6-232v-415.2
                    c0-147.2,73.4-224.8,209.1-224.8c178.8,0,207.9,117.7,207.9,228.5v53.1H1444v-72.2c0-46.6-9.9-70.2-43.9-70.2
                    c-33.5,0-44.4,22.3-44.4,70.2v447.7c0,45.2,7.5,76,44.3,76c35.2,0,45.6-25.9,45.6-79.8v-70.4H1610.5z"/>
                <path class="st1" d="M1960.9,6349.2l-20.9,201.5h-166l119.7-846.6h242.8l122.6,846.6h-169.4l-23.3-201.5H1960.9z M2052.5,6205.6
                    c-12-108.3-28.9-273.1-36.8-354.2h-6.4c-2.9,59-23,249.5-34.2,354.2H2052.5z"/>
                <path class="st1" d="M2443.9,6550.7v-846.6h180.2c26.9,103.3,111.7,486.8,118.4,523.9h3.9c-9-112.6-14.8-266.7-14.8-384.8v-139.1
                    h151.2v846.6H2701c-17.6-80.5-110.1-518.6-115.6-545.7h-4.4c6.3,99.7,11.3,268,11.3,401.1v144.6H2443.9z"/>
                <path class="st1" d="M3837.5,6281c0-131,1.3-316.8,3.4-420.7h-4.4c-17.7,169.4-55.1,512.4-78.2,690.5h-138.1
                    c-18.7-185.1-55.2-534.3-72.9-692.6h-5.2c2.1,99.4,6.3,283,6.3,428.9v263.7h-147.8v-846.6h233.7c22.1,161,46.8,372.3,56.5,487h3.5
                    c13.1-122.6,41.2-300.4,67.2-487h232.8v846.6h-156.8V6281z"/>
                <path class="st1" d="M4558.2,6176.8h-174.5v230.8h204.6l-19.5,143.2h-348.1v-846.6h346.8v144h-183.8v185.6h174.5V6176.8z"/>
            </g>`
        }
    },

    ScanMeTop: {

        '300': {
            viewBox: "0 0 386.5 512.1",
            x: "43.3",
            y: "168.9",
            framePath: `<path class="st0" d="M358.4,512.1H28.2C12.6,512.1,0,499.5,0,484V170.7v-16.9c0-23,0-62.4,0-73.2V65.3V32.6v-4.5
            C0,12.6,12.6,0,28.2,0h330.2c15.5,0,28.2,12.6,28.2,28.2v4.5v32.6v15.2c0,11.4,0,52.2,0,73.2v12.7V484
            C386.5,499.5,373.9,512.1,358.4,512.1z"/>
            <path class="st1" d="M372,153.8c0-7.5-6.1-13.6-13.6-13.6H244.7l-44.1-39.7l-44.1,39.7H28.2c-7.5,0-13.6,6.1-13.6,13.6V484
                c0,7.5,6.1,13.6,13.6,13.6h330.2c7.5,0,13.6-6.1,13.6-13.6V153.8z"/>
            <g>
                <path class="st1" d="M55,65.7v4.4c0,4.2,1,6.1,3.8,6.1c2.8,0,3.5-2.9,3.5-5.9c0-6-1.1-7.7-7.6-13.1c-7.1-6-10.8-9.6-10.8-19
                    c0-9.1,3-17.4,15-17.4c12.7,0,14.6,8.7,14.6,15.9v3.6H62v-3.8c0-3.8-0.6-5.7-3-5.7c-2.2,0-3,1.9-3,5.5c0,3.8,0.7,5.7,5.9,9.8
                    c9.3,7.3,12.7,11.4,12.7,21.9c0,9.9-3.4,18.1-16,18.1c-12.1,0-15.5-7.3-15.5-16.7v-3.8H55z"/>
                <path class="st1" d="M120.5,64.6V68c0,8-1.5,18.2-16,18.2c-10.7,0-15.4-5.7-15.4-17.4V37.7c0-11,5.5-16.8,15.6-16.8
                    c13.4,0,15.6,8.8,15.6,17.1v4h-12.3v-5.4c0-3.5-0.7-5.3-3.3-5.3c-2.5,0-3.3,1.7-3.3,5.3v33.5c0,3.4,0.6,5.7,3.3,5.7
                    c2.6,0,3.4-1.9,3.4-6v-5.3H120.5z"/>
                <path class="st1" d="M146.8,70.2l-1.6,15.1h-12.4l9-63.4h18.2l9.2,63.4h-12.7l-1.7-15.1H146.8z M153.6,59.4
                    c-0.9-8.1-2.2-20.4-2.8-26.5h-0.5c-0.2,4.4-1.7,18.7-2.6,26.5H153.6z"/>
                <path class="st1" d="M182.9,85.2V21.9h13.5c2,7.7,8.4,36.4,8.9,39.2h0.3c-0.7-8.4-1.1-20-1.1-28.8V21.9h11.3v63.4h-13.6
                    c-1.3-6-8.2-38.8-8.7-40.8h-0.3c0.5,7.5,0.8,20.1,0.8,30v10.8H182.9z"/>
                <path class="st1" d="M287.2,65c0-9.8,0.1-23.7,0.3-31.5h-0.3c-1.3,12.7-4.1,38.4-5.9,51.7H271c-1.4-13.9-4.1-40-5.5-51.8h-0.4
                    c0.2,7.4,0.5,21.2,0.5,32.1v19.7h-11.1V21.9H272c1.7,12,3.5,27.9,4.2,36.5h0.3c1-9.2,3.1-22.5,5-36.5H299v63.4h-11.7V65z"/>
                <path class="st1" d="M341.2,57.3h-13.1v17.3h15.3L342,85.2h-26.1V21.9h26v10.8h-13.8v13.9h13.1V57.3z"/>
            </g>`
        },
        '500': {
            viewBox: "0 0 645.5 855.3",
            x: "72.7",
            y: "282.5",
            framePath: `<path class="st0" d="M598.5,855.3H47c-25.9,0-47-21.1-47-47V285.1v-28.3c0-38.4,0-104.3,0-122.3V109V54.5V47C0,21.1,21.1,0,47,0
            h551.5c25.9,0,47,21.1,47,47v7.5V109v25.5c0,19,0,87.2,0,122.3V278v530.2C645.5,834.2,624.4,855.3,598.5,855.3z"/>
            <path class="st1" d="M621.3,256.8c0-12.6-10.2-22.8-22.8-22.8H408.6l-73.7-66.3L261.3,234H47c-12.6,0-22.8,10.2-22.8,22.8v551.5
                c0,12.6,10.2,22.8,22.8,22.8h551.5c12.6,0,22.8-10.2,22.8-22.8V256.8z"/>
            <g>
                <path class="st1" d="M91.9,109.7v7.3c0,7,1.7,10.2,6.4,10.2c4.7,0,5.8-4.8,5.8-9.8c0-10-1.9-12.9-12.7-21.9
                    c-11.9-10.1-18-16.1-18-31.7c0-15.1,5-29,25.1-29c21.3,0,24.4,14.5,24.4,26.6v6.1h-19.2v-6.3c0-6.4-1.1-9.6-5.1-9.6
                    c-3.8,0-5,3.2-5,9.2c0,6.4,1.2,9.5,9.9,16.3c15.6,12.2,21.1,19.1,21.1,36.5c0,16.6-5.7,30.2-26.7,30.2c-20.2,0-25.9-12.2-25.9-27.9
                    v-6.3H91.9z"/>
                <path class="st1" d="M201.3,107.8v5.7c0,13.4-2.5,30.4-26.7,30.4c-17.9,0-25.7-9.5-25.7-29V63c0-18.4,9.2-28.1,26.1-28.1
                    c22.4,0,26,14.7,26,28.6v6.6h-20.5v-9c0-5.8-1.2-8.8-5.5-8.8c-4.2,0-5.5,2.8-5.5,8.8v56c0,5.6,0.9,9.5,5.5,9.5
                    c4.4,0,5.7-3.2,5.7-10v-8.8H201.3z"/>
                <path class="st1" d="M245.1,117.2l-2.6,25.2h-20.8l15-105.8h30.3l15.3,105.8h-21.2l-2.9-25.2H245.1z M256.6,99.2
                    c-1.5-13.5-3.6-34.1-4.6-44.3h-0.8c-0.4,7.4-2.9,31.2-4.3,44.3H256.6z"/>
                <path class="st1" d="M305.5,142.4V36.5H328c3.4,12.9,14,60.8,14.8,65.5h0.5c-1.1-14.1-1.9-33.3-1.9-48.1V36.5h18.9v105.8h-22.7
                    c-2.2-10.1-13.8-64.8-14.4-68.2h-0.5c0.8,12.5,1.4,33.5,1.4,50.1v18.1H305.5z"/>
                <path class="st1" d="M479.7,108.6c0-16.4,0.2-39.6,0.4-52.6h-0.5c-2.2,21.2-6.9,64-9.8,86.3h-17.3c-2.3-23.1-6.9-66.8-9.1-86.6
                    h-0.7c0.3,12.4,0.8,35.4,0.8,53.6v33h-18.5V36.5h29.2c2.8,20.1,5.9,46.5,7.1,60.9h0.4c1.6-15.3,5.1-37.6,8.4-60.9h29.1v105.8h-19.6
                    V108.6z"/>
                <path class="st1" d="M569.8,95.6H548v28.8h25.6l-2.4,17.9h-43.5V36.5h43.4v18h-23v23.2h21.8V95.6z"/>
            </g>`
        },
        '1000': {
            viewBox: "0 0 1291 1710.5",
            x: "145.5",
            y: "565",
            framePath: `<path class="st0" d="M1196.9,1710.5H94.1c-51.9,0-94.1-42.2-94.1-94V570.2v-56.6C0,436.8,0,305,0,268.9V218V109v-15
            C0,42.2,42.2,0,94.1,0H1197c51.8,0,94.1,42.2,94.1,94.1v15v109v50.9c0,38,0,174.4,0,244.7V556v1060.4
            C1291,1668.3,1248.8,1710.5,1196.9,1710.5z"/>
            <path class="st1" d="M1242.5,513.6c0-25.1-20.4-45.6-45.6-45.6H817.2L669.9,335.5L522.5,468H94.1c-25.1,0-45.6,20.4-45.6,45.6
                v1102.9c0,25.1,20.4,45.6,45.6,45.6H1197c25.1,0,45.6-20.4,45.6-45.6V513.6z"/>
            <g>
                <path class="st1" d="M183.8,219.5v14.7c0,13.9,3.5,20.4,12.7,20.4c9.4,0,11.6-9.6,11.6-19.6c0-20-3.8-25.8-25.4-43.8
                    c-23.8-20.2-36.1-32.2-36.1-63.3c0-30.2,10-58.1,50.1-58.1c42.6,0,48.9,29,48.9,53.3v12.2h-38.4v-12.7c0-12.8-2.1-19.2-10.2-19.2
                    c-7.5,0-9.9,6.5-9.9,18.4c0,12.7,2.3,19,19.8,32.7c31.1,24.5,42.3,38.2,42.3,73c0,33.1-11.3,60.4-53.4,60.4
                    c-40.4,0-51.9-24.4-51.9-55.7v-12.7H183.8z"/>
                <path class="st1" d="M402.6,215.6v11.5c0,26.7-4.9,60.8-53.4,60.8c-35.8,0-51.4-19.1-51.4-58V126.1c0-36.8,18.4-56.2,52.3-56.2
                    c44.7,0,52,29.4,52,57.1v13.3h-41v-18.1c0-11.7-2.5-17.5-11-17.5c-8.4,0-11.1,5.6-11.1,17.5v111.9c0,11.3,1.9,19,11.1,19
                    c8.8,0,11.4-6.5,11.4-20v-17.6H402.6z"/>
                <path class="st1" d="M490.2,234.3l-5.2,50.4h-41.5L473.4,73h60.7l30.6,211.7h-42.3l-5.8-50.4H490.2z M513.1,198.4
                    c-3-27.1-7.2-68.3-9.2-88.5h-1.6c-0.7,14.8-5.8,62.4-8.6,88.5H513.1z"/>
                <path class="st1" d="M611,284.7V73h45c6.7,25.8,27.9,121.7,29.6,131h1c-2.3-28.2-3.7-66.7-3.7-96.2V73h37.8v211.7h-45.5
                    c-4.4-20.1-27.5-129.7-28.9-136.4h-1.1c1.6,24.9,2.8,67,2.8,100.3v36.1H611z"/>
                <path class="st1" d="M959.4,217.3c0-32.8,0.3-79.2,0.8-105.2h-1.1c-4.4,42.3-13.8,128.1-19.6,172.6H905
                    c-4.7-46.3-13.8-133.6-18.2-173.2h-1.3c0.5,24.9,1.6,70.7,1.6,107.2v65.9h-36.9V73h58.4c5.5,40.2,11.7,93.1,14.1,121.7h0.9
                    c3.3-30.6,10.3-75.1,16.8-121.7h58.2v211.7h-39.2V217.3z"/>
                <path class="st1" d="M1139.6,191.2h-43.6v57.7h51.2l-4.9,35.8h-87V73h86.7v36h-46v46.4h43.6V191.2z"/>
            </g>`
        },
        '2000': {
            viewBox: "0 0 2582 3421",
            x: "291",
            y: "1130.1",
            framePath: `<path class="st0" d="M2393.8,3421H188.1C84.4,3421,0,3336.7,0,3233V1140.3v-113.2c0-153.5,0-417.1,0-489.3V436.1V218v-29.9
            C0,84.4,84.4,0,188.1,0h2205.8C2497.6,0,2582,84.4,2582,188.1V218v218v101.8c0,76,0,348.8,0,489.3v84.9V3233
            C2582,3336.7,2497.6,3421,2393.8,3421z"/>
            <path class="st1" d="M2485,1027.2c0-50.2-40.9-91.1-91.1-91.1h-759.5l-294.7-265.1l-294.7,265.1h-857c-50.2,0-91.1,40.9-91.1,91.1
                V3233c0,50.2,40.9,91.1,91.1,91.1h2205.8c50.2,0,91.1-40.9,91.1-91.1V1027.2z"/>
            <g>
                <path class="st1" d="M367.5,438.9v29.3c0,27.8,6.9,40.8,25.5,40.8c18.8,0,23.2-19.2,23.2-39.1c0-40-7.5-51.5-50.7-87.7
                    c-47.7-40.4-72.2-64.3-72.2-126.7c0-60.5,20-116.2,100.2-116.2c85.1,0,97.7,58.1,97.7,106.5v24.4h-76.9v-25.4
                    c0-25.7-4.2-38.4-20.3-38.4c-15,0-19.9,12.9-19.9,36.9c0,25.5,4.6,38.1,39.6,65.4c62.3,48.9,84.6,76.4,84.6,146.1
                    c0,66.3-22.6,120.9-106.7,120.9c-80.8,0-103.8-48.7-103.8-111.4v-25.4H367.5z"/>
                <path class="st1" d="M805.2,431.2v22.9c0,53.4-9.8,121.6-106.9,121.6c-71.7,0-102.8-38.2-102.8-116V252.2
                    c0-73.6,36.7-112.4,104.5-112.4c89.4,0,103.9,58.9,103.9,114.3v26.6h-82v-36.1c0-23.3-5-35.1-22-35.1c-16.8,0-22.2,11.1-22.2,35.1
                    v223.9c0,22.6,3.8,38,22.2,38c17.6,0,22.8-13,22.8-39.9v-35.2H805.2z"/>
                <path class="st1" d="M980.4,468.7L970,569.4h-83l59.9-423.3h121.4l61.3,423.3h-84.7l-11.7-100.7H980.4z M1026.3,396.9
                    c-6-54.2-14.5-136.6-18.4-177.1h-3.2c-1.5,29.5-11.5,124.7-17.1,177.1H1026.3z"/>
                <path class="st1" d="M1222,569.4V146.1h90.1c13.4,51.7,55.9,243.4,59.2,262h2c-4.5-56.3-7.4-133.3-7.4-192.4v-69.6h75.6v423.3
                    h-90.9c-8.8-40.3-55.1-259.3-57.8-272.9h-2.2c3.2,49.8,5.7,134,5.7,200.6v72.3H1222z"/>
                <path class="st1" d="M1918.7,434.5c0-65.5,0.6-158.4,1.7-210.4h-2.2c-8.8,84.7-27.6,256.2-39.1,345.2H1810
                    c-9.3-92.6-27.6-267.2-36.5-346.3h-2.6c1,49.7,3.2,141.5,3.2,214.4v131.9h-73.9V146.1h116.9c11,80.5,23.4,186.2,28.2,243.5h1.7
                    c6.5-61.3,20.6-150.2,33.6-243.5h116.4v423.3h-78.4V434.5z"/>
                <path class="st1" d="M2279.1,382.4h-87.3v115.4h102.3l-9.8,71.6h-174.1V146.1h173.4v72h-91.9v92.8h87.3V382.4z"/>
            </g>`
        },
        '4000': {
            viewBox: "0 0 5164 6842.1",
            x: "582",
            y: "2260.1",
            framePath: `<path class="st0" d="M4787.7,6842.1H376.2C168.7,6842.1,0,6673.3,0,6466V2280.7v-226.3c0-307,0-834.2,0-978.6V872.1V436.1v-59.9
            C0,168.7,168.7,0,376.2,0h4411.6C4995.2,0,5164,168.7,5164,376.2v59.9v436.1v203.6c0,151.9,0,697.7,0,978.6v169.8V6466
            C5163.9,6673.3,4995.2,6842.1,4787.7,6842.1z"/>
            <path class="st1" d="M4970.1,2054.4c0-100.5-81.8-182.2-182.2-182.2h-1519l-589.3-530.3l-589.3,530.3h-1714
                c-100.5,0-182.2,81.8-182.2,182.2V6466c0,100.5,81.8,182.2,182.2,182.2h4411.6c100.5,0,182.2-81.8,182.2-182.2V2054.4z"/>
            <g>
                <path class="st1" d="M735.1,877.9v58.6c0,55.7,13.8,81.5,51,81.5c37.5,0,46.3-38.3,46.3-78.3c0-79.9-15-103-101.5-175.4
                    c-95.4-80.8-144.4-128.6-144.4-253.3c0-120.9,40-232.4,200.4-232.4c170.3,0,195.4,116.1,195.4,213v48.7H828.6v-50.7
                    c0-51.4-8.4-76.8-40.6-76.8c-30,0-39.7,25.9-39.7,73.8c0,51,9.3,76.2,79.3,130.8C952,715.4,996.7,770.3,996.7,909.7
                    c0,132.6-45.3,241.8-213.5,241.8c-161.5,0-207.6-97.4-207.6-222.9v-50.7H735.1z"/>
                <path class="st1" d="M1610.5,862.5v45.8c0,106.8-19.7,243.2-213.8,243.2c-143.4,0-205.6-76.4-205.6-232V504.3
                    c0-147.2,73.4-224.8,209.1-224.8c178.8,0,207.9,117.7,207.9,228.5v53.1H1444v-72.2c0-46.6-9.9-70.2-43.9-70.2
                    c-33.5,0-44.4,22.3-44.4,70.2v447.7c0,45.2,7.5,76,44.3,76c35.2,0,45.6-25.9,45.6-79.8v-70.4H1610.5z"/>
                <path class="st1" d="M1960.9,937.3l-20.9,201.5h-166l119.7-846.6h242.8l122.6,846.6h-169.4l-23.3-201.5H1960.9z M2052.5,793.7
                    c-12-108.3-28.9-273.1-36.8-354.2h-6.4c-2.9,59-23,249.5-34.2,354.2H2052.5z"/>
                <path class="st1" d="M2443.9,1138.8V292.2h180.2c26.9,103.3,111.7,486.8,118.4,523.9h3.9c-9-112.6-14.8-266.7-14.8-384.8V292.2
                    h151.2v846.6H2701c-17.6-80.5-110.1-518.6-115.6-545.7h-4.4c6.3,99.7,11.3,268,11.3,401.1v144.6H2443.9z"/>
                <path class="st1" d="M3837.5,869.1c0-131,1.3-316.8,3.4-420.7h-4.4c-17.7,169.4-55.1,512.4-78.2,690.5h-138.1
                    c-18.7-185.1-55.2-534.3-72.9-692.6h-5.2c2.1,99.4,6.3,283,6.3,428.9v263.7h-147.8V292.2h233.7c22.1,161,46.8,372.3,56.5,487h3.5
                    c13.1-122.6,41.2-300.4,67.2-487h232.8v846.6h-156.8V869.1z"/>
                <path class="st1" d="M4558.2,764.9h-174.5v230.8h204.6l-19.5,143.2h-348.1V292.2h346.8v144h-183.8v185.6h174.5V764.9z"/>
            </g>`
        }
    },

    MenuBottom: {

        '300': {
            viewBox: "0 0 386.5 512.1",
            x: "43.3",
            y: "43.3",
            framePath: `<path class="st0" d="M358.4,0H28.2C12.6,0,0,12.6,0,28.1v313.3v16.9c0,23,0,62.4,0,73.2v15.2v32.6v4.5c0,15.5,12.6,28.2,28.2,28.2
            h330.2c15.5,0,28.2-12.6,28.2-28.2v-4.5v-32.6v-15.2c0-11.4,0-52.2,0-73.2v-12.7V28.1C386.5,12.6,373.9,0,358.4,0z"/>
            <path class="st1" d="M372,358.4c0,7.5-6.1,13.6-13.6,13.6H244.7l-44.1,39.7L156.4,372H28.2c-7.5,0-13.6-6.1-13.6-13.6V28.1
                c0-7.5,6.1-13.6,13.6-13.6h330.2c7.5,0,13.6,6.1,13.6,13.6V358.4z"/>
            <g>
                <path class="st1" d="M133.8,470.3c0-9.8,0.1-23.6,0.3-31.4h-0.3c-1.3,12.6-4.1,38.2-5.8,51.5h-10.3c-1.4-13.8-4.1-39.9-5.4-51.7
                    h-0.4c0.2,7.4,0.5,21.1,0.5,32v19.7h-11v-63.2h17.4c1.6,12,3.5,27.8,4.2,36.3h0.3c1-9.1,3.1-22.4,5-36.3h17.4v63.2h-11.7V470.3z"/>
                <path class="st1" d="M187.6,462.5h-13v17.2h15.3l-1.5,10.7h-26v-63.2h25.9V438h-13.7v13.8h13V462.5z"/>
                <path class="st1" d="M203.6,490.4v-63.2H217c2,7.7,8.3,36.3,8.8,39.1h0.3c-0.7-8.4-1.1-19.9-1.1-28.7v-10.4h11.3v63.2h-13.6
                    c-1.3-6-8.2-38.7-8.6-40.7h-0.3c0.5,7.4,0.8,20,0.8,29.9v10.8H203.6z"/>
                <path class="st1" d="M265.3,427.2v48.1c0,3.7,1.1,5.7,3.9,5.7c2.9,0,3.8-2.2,3.8-5.7v-48.1h12.3v47.3c0,11.2-5.1,16.7-16.3,16.7
                    c-11,0-15.9-5.4-15.9-16.8v-47.2H265.3z"/>
            </g>`
        },
        '500': {
            viewBox: "0 0 645.5 855.3",
            x: "72.7",
            y: "72.7",
            framePath: `<path class="st0" d="M598.5,0H47C21.1,0,0,21.1,0,47v523.2v28.3c0,38.4,0,104.3,0,122.3v25.5v54.5v7.5c0,25.9,21.1,47,47,47h551.5
            c25.9,0,47-21.1,47-47v-7.5v-54.5v-25.5c0-19,0-87.2,0-122.3v-21.2V47C645.5,21.1,624.4,0,598.5,0z"/>
            <path class="st1" d="M621.3,598.5c0,12.6-10.2,22.8-22.8,22.8H408.6l-73.7,66.3l-73.7-66.3H47c-12.6,0-22.8-10.2-22.8-22.8V47
                c0-12.6,10.2-22.8,22.8-22.8h551.5c12.6,0,22.8,10.2,22.8,22.8V598.5z"/>
            <g>
                <path class="st1" d="M223.4,785.3c0-16.3,0.2-39.5,0.4-52.4h-0.5c-2.2,21.1-6.9,63.9-9.7,86h-17.2c-2.3-23.1-6.9-66.6-9.1-86.3
                    h-0.6c0.3,12.4,0.8,35.3,0.8,53.4V819h-18.4V713.4h29.1c2.7,20.1,5.8,46.4,7,60.7h0.4c1.6-15.3,5.1-37.4,8.4-60.7h29V819h-19.5
                    V785.3z"/>
                <path class="st1" d="M313.2,772.4h-21.8v28.8H317l-2.4,17.8h-43.4V713.4h43.2v17.9h-22.9v23.1h21.8V772.4z"/>
                <path class="st1" d="M340,819V713.4h22.5c3.4,12.9,13.9,60.7,14.8,65.3h0.5c-1.1-14-1.8-33.2-1.8-48v-17.3h18.8V819h-22.7
                    c-2.2-10-13.7-64.6-14.4-68h-0.5c0.8,12.4,1.4,33.4,1.4,50v18H340z"/>
                <path class="st1" d="M443.1,713.4v80.3c0,6.2,1.9,9.4,6.5,9.4c4.9,0,6.4-3.7,6.4-9.4v-80.3h20.5v79c0,18.7-8.6,27.9-27.3,27.9
                    c-18.3,0-26.6-8.9-26.6-28.1v-78.9H443.1z"/>
            </g>`
        },
        '1000': {
            viewBox: "0 0 1291 1710.5",
            x: "145.5",
            y: "145.5",
            framePath: `<path class="st0" d="M1196.9,0H94.1C42.2,0,0,42.2,0,94v1046.3v56.6c0,76.8,0,208.5,0,244.7v50.9v109v15c0,51.9,42.2,94.1,94.1,94.1
            H1197c51.8,0,94.1-42.2,94.1-94.1v-15v-109v-50.9c0-38,0-174.4,0-244.7v-42.5V94C1291,42.2,1248.8,0,1196.9,0z"/>
            <path class="st1" d="M1242.5,1196.9c0,25.1-20.4,45.6-45.6,45.6H817.2L669.9,1375l-147.3-132.6H94.1c-25.1,0-45.6-20.4-45.6-45.6V94
                c0-25.1,20.4-45.6,45.6-45.6H1197c25.1,0,45.6,20.4,45.6,45.6V1196.9z"/>
            <g>
                <path class="st1" d="M446.8,1570.7c0-32.7,0.3-79,0.8-104.9h-1.1c-4.4,42.2-13.7,127.7-19.5,172.1h-34.4
                    c-4.7-46.1-13.8-133.2-18.2-172.6h-1.3c0.5,24.8,1.6,70.5,1.6,106.9v65.7h-36.8v-211h58.3c5.5,40.1,11.7,92.8,14.1,121.4h0.9
                    c3.3-30.5,10.3-74.9,16.7-121.4h58v211h-39.1V1570.7z"/>
                <path class="st1" d="M626.4,1544.7h-43.5v57.5h51l-4.9,35.7h-86.8v-211h86.4v35.9h-45.8v46.2h43.5V1544.7z"/>
                <path class="st1" d="M680,1637.9v-211h44.9c6.7,25.8,27.8,121.3,29.5,130.6h1c-2.2-28.1-3.7-66.5-3.7-95.9v-34.7h37.7v211h-45.3
                    c-4.4-20.1-27.4-129.3-28.8-136h-1.1c1.6,24.8,2.8,66.8,2.8,100v36H680z"/>
                <path class="st1" d="M886.2,1426.9v160.7c0,12.3,3.7,18.9,13,18.9c9.8,0,12.8-7.5,12.8-18.9v-160.7h41.1V1585
                    c0,37.4-17.2,55.9-54.5,55.9c-36.7,0-53.2-17.9-53.2-56.2v-157.7H886.2z"/>
            </g>`
        },
        '2000': {
            viewBox: "0 0 2582 3421",
            x: "291",
            y: "291",
            framePath: `<path class="st0" d="M2393.8,0H188.1C84.4,0,0,84.4,0,188v2092.6v113.2c0,153.5,0,417.1,0,489.3V2985v218v29.9
            C0,3336.7,84.4,3421,188.1,3421h2205.8c103.7,0,188.1-84.4,188.1-188.1V3203v-218v-101.8c0-76,0-348.8,0-489.3v-84.9V188
            C2582,84.4,2497.6,0,2393.8,0z"/>
            <path class="st1" d="M2485,2393.8c0,50.2-40.9,91.1-91.1,91.1h-759.5l-294.7,265.1L1045.1,2485h-857c-50.2,0-91.1-40.9-91.1-91.1
                V188c0-50.2,40.9-91.1,91.1-91.1h2205.8c50.2,0,91.1,40.9,91.1,91.1V2393.8z"/>
            <g>
                <path class="st1" d="M893.6,3141.4c0-65.3,0.6-157.9,1.7-209.7H893c-8.8,84.4-27.5,255.4-39,344.2h-68.9
                    c-9.3-92.3-27.5-266.4-36.3-345.3h-2.6c1,49.6,3.2,141.1,3.2,213.8v131.5h-73.7v-422h116.5c11,80.2,23.4,185.6,28.2,242.8h1.7
                    c6.5-61.1,20.5-149.8,33.5-242.8h116v422h-78.1V3141.4z"/>
                <path class="st1" d="M1252.8,3089.4h-87v115h102l-9.7,71.4h-173.5v-422h172.9v71.8h-91.6v92.5h87V3089.4z"/>
                <path class="st1" d="M1360.1,3275.8v-422h89.8c13.4,51.5,55.7,242.6,59,261.2h2c-4.5-56.1-7.4-132.9-7.4-191.8v-69.3h75.4v422
                    h-90.6c-8.8-40.2-54.9-258.5-57.6-272h-2.2c3.2,49.7,5.6,133.6,5.6,199.9v72.1H1360.1z"/>
                <path class="st1" d="M1772.4,2853.8v321.4c0,24.6,7.5,37.8,26,37.8c19.6,0,25.6-15,25.6-37.8v-321.4h82.2v316.1
                    c0,74.8-34.4,111.8-109.1,111.8c-73.3,0-106.5-35.8-106.5-112.5v-315.5H1772.4z"/>
            </g>`
        },
        '4000': {
            viewBox: "0 0 5164 6842.1",
            x: "582",
            y: "582",
            framePath: `<path class="st0" d="M4787.7,0H376.2C168.7,0,0,168.7,0,376.1v4185.3v226.3c0,307,0,834.2,0,978.6v203.6V6406v59.9
            c0,207.5,168.7,376.2,376.2,376.2h4411.6c207.3,0,376.2-168.7,376.2-376.2V6406v-436.1v-203.6c0-151.9,0-697.7,0-978.6v-169.8V376.1
            C5163.9,168.7,4995.2,0,4787.7,0z"/>
            <path class="st1" d="M4970.1,4787.7c0,100.5-81.8,182.2-182.2,182.2h-1519l-589.3,530.3l-589.3-530.3h-1714
                c-100.5,0-182.2-81.8-182.2-182.2V376.1c0-100.5,81.8-182.2,182.2-182.2h4411.6c100.5,0,182.2,81.8,182.2,182.2V4787.7z"/>
            <g>
                <path class="st1" d="M1787.1,6282.7c0-130.6,1.3-315.9,3.3-419.4h-4.4c-17.6,168.9-54.9,510.8-78,688.4h-137.7
                    c-18.6-184.6-55-532.7-72.7-690.5h-5.2c2.1,99.1,6.3,282.1,6.3,427.6v262.9h-147.3v-844.1h233c22,160.5,46.7,371.2,56.3,485.5h3.5
                    c13-122.2,41.1-299.5,67-485.5h232.1v844.1h-156.3V6282.7z"/>
                <path class="st1" d="M2505.7,6178.8h-174v230.1h204l-19.5,142.7h-347.1v-844.1h345.8v143.5h-183.2v185h174V6178.8z"/>
                <path class="st1" d="M2720.2,6551.6v-844.1h179.6c26.8,103,111.4,485.3,118.1,522.3h3.9c-9-112.3-14.8-265.9-14.8-383.7v-138.7
                    h150.7v844.1h-181.3c-17.5-80.3-109.8-517.1-115.2-544.1h-4.4c6.3,99.3,11.3,267.2,11.3,399.9v144.2H2720.2z"/>
                <path class="st1" d="M3544.8,5707.6v642.8c0,49.2,15,75.5,52,75.5c39.2,0,51.3-30,51.3-75.5v-642.8h164.3v632.3
                    c0,149.6-68.7,223.6-218.1,223.6c-146.7,0-212.9-71.5-212.9-224.9v-630.9H3544.8z"/>
            </g>`
        }
    },

    MenuTop: {

        '300': {
            viewBox: "0 0 386.5 512.1",
            x: "43.3",
            y: "168.9",
            framePath: `<path class="st0" d="M358.4,512.1H28.2C12.6,512.1,0,499.5,0,484V170.7v-16.9c0-23,0-62.4,0-73.2V65.3V32.6v-4.5
            C0,12.6,12.6,0,28.2,0h330.2c15.5,0,28.2,12.6,28.2,28.2v4.5v32.6v15.2c0,11.4,0,52.2,0,73.2v12.7V484
            C386.5,499.5,373.9,512.1,358.4,512.1z"/>
            <path class="st1" d="M372,153.8c0-7.5-6.1-13.6-13.6-13.6H244.7l-44.1-39.7l-44.1,39.7H28.2c-7.5,0-13.6,6.1-13.6,13.6V484
                c0,7.5,6.1,13.6,13.6,13.6h330.2c7.5,0,13.6-6.1,13.6-13.6V153.8z"/>
            <g>
                <path class="st1" d="M133.8,63.9c0-9.8,0.1-23.6,0.3-31.4h-0.3c-1.3,12.6-4.1,38.2-5.8,51.5h-10.3c-1.4-13.8-4.1-39.9-5.4-51.7
                    h-0.4c0.2,7.4,0.5,21.1,0.5,32V84h-11V20.9h17.4c1.6,12,3.5,27.8,4.2,36.3h0.3c1-9.1,3.1-22.4,5-36.3h17.4V84h-11.7V63.9z"/>
                <path class="st1" d="M187.6,56.1h-13v17.2h15.3L188.3,84h-26V20.9h25.9v10.7h-13.7v13.8h13V56.1z"/>
                <path class="st1" d="M203.6,84V20.9H217c2,7.7,8.3,36.3,8.8,39.1h0.3c-0.7-8.4-1.1-19.9-1.1-28.7V20.9h11.3V84h-13.6
                    c-1.3-6-8.2-38.7-8.6-40.7h-0.3c0.5,7.4,0.8,20,0.8,29.9V84H203.6z"/>
                <path class="st1" d="M265.3,20.9V69c0,3.7,1.1,5.7,3.9,5.7c2.9,0,3.8-2.2,3.8-5.7V20.9h12.3v47.3c0,11.2-5.1,16.7-16.3,16.7
                    c-11,0-15.9-5.4-15.9-16.8V20.9H265.3z"/>
            </g>`
        },
        '500': {
            viewBox: "0 0 645.5 855.3",
            x: "72.7",
            y: "282.5",
            framePath: `<path class="st0" d="M598.5,855.3H47c-25.9,0-47-21.1-47-47V285.1v-28.3c0-38.4,0-104.3,0-122.3V109V54.5V47C0,21.1,21.1,0,47,0
            h551.5c25.9,0,47,21.1,47,47v7.5V109v25.5c0,19,0,87.2,0,122.3V278v530.2C645.5,834.2,624.4,855.3,598.5,855.3z"/>
            <path class="st1" d="M621.3,256.8c0-12.6-10.2-22.8-22.8-22.8H408.6l-73.7-66.3L261.3,234H47c-12.6,0-22.8,10.2-22.8,22.8v551.5
                c0,12.6,10.2,22.8,22.8,22.8h551.5c12.6,0,22.8-10.2,22.8-22.8V256.8z"/>
            <g>
                <path class="st1" d="M223.4,106.7c0-16.3,0.2-39.5,0.4-52.4h-0.5c-2.2,21.1-6.9,63.9-9.7,86h-17.2c-2.3-23.1-6.9-66.6-9.1-86.3
                    h-0.6c0.3,12.4,0.8,35.3,0.8,53.4v32.9h-18.4V34.8h29.1c2.7,20.1,5.8,46.4,7,60.7h0.4c1.6-15.3,5.1-37.4,8.4-60.7h29v105.5h-19.5
                    V106.7z"/>
                <path class="st1" d="M313.2,93.7h-21.8v28.8H317l-2.4,17.8h-43.4V34.8h43.2v17.9h-22.9v23.1h21.8V93.7z"/>
                <path class="st1" d="M340,140.3V34.8h22.5c3.4,12.9,13.9,60.7,14.8,65.3h0.5c-1.1-14-1.8-33.2-1.8-48V34.8h18.8v105.5h-22.7
                    c-2.2-10-13.7-64.6-14.4-68h-0.5c0.8,12.4,1.4,33.4,1.4,50v18H340z"/>
                <path class="st1" d="M443.1,34.8v80.3c0,6.2,1.9,9.4,6.5,9.4c4.9,0,6.4-3.7,6.4-9.4V34.8h20.5v79c0,18.7-8.6,27.9-27.3,27.9
                    c-18.3,0-26.6-8.9-26.6-28.1V34.8H443.1z"/>
            </g>`
        },
        '1000': {
            viewBox: "0 0 1291 1710.5",
            x: "145.5",
            y: "565",
            framePath: `<path class="st0" d="M1196.9,1710.5H94.1c-51.9,0-94.1-42.2-94.1-94V570.2v-56.6C0,436.8,0,305,0,268.9V218V109v-15
            C0,42.2,42.2,0,94.1,0H1197c51.8,0,94.1,42.2,94.1,94.1v15v109v50.9c0,38,0,174.4,0,244.7V556v1060.4
            C1291,1668.3,1248.8,1710.5,1196.9,1710.5z"/>
            <path class="st1" d="M1242.5,513.6c0-25.1-20.4-45.6-45.6-45.6H817.2L669.9,335.5L522.5,468H94.1c-25.1,0-45.6,20.4-45.6,45.6
                v1102.9c0,25.1,20.4,45.6,45.6,45.6H1197c25.1,0,45.6-20.4,45.6-45.6V513.6z"/>
            <g>
                <path class="st1" d="M446.8,213.5c0-32.7,0.3-79,0.8-104.9h-1.1c-4.4,42.2-13.7,127.7-19.5,172.1h-34.4
                    c-4.7-46.1-13.8-133.2-18.2-172.6h-1.3c0.5,24.8,1.6,70.5,1.6,106.9v65.7h-36.8v-211h58.3c5.5,40.1,11.7,92.8,14.1,121.4h0.9
                    c3.3-30.5,10.3-74.9,16.7-121.4h58v211h-39.1V213.5z"/>
                <path class="st1" d="M626.4,187.5h-43.5V245h51l-4.9,35.7h-86.8v-211h86.4v35.9h-45.8v46.2h43.5V187.5z"/>
                <path class="st1" d="M680,280.7v-211h44.9c6.7,25.8,27.8,121.3,29.5,130.6h1c-2.2-28.1-3.7-66.5-3.7-95.9V69.7h37.7v211h-45.3
                    c-4.4-20.1-27.4-129.3-28.8-136h-1.1c1.6,24.8,2.8,66.8,2.8,100v36H680z"/>
                <path class="st1" d="M886.2,69.7v160.7c0,12.3,3.7,18.9,13,18.9c9.8,0,12.8-7.5,12.8-18.9V69.7h41.1v158.1
                    c0,37.4-17.2,55.9-54.5,55.9c-36.7,0-53.2-17.9-53.2-56.2V69.7H886.2z"/>
            </g>`
        },
        '2000': {
            viewBox: "0 0 2582 3421",
            x: "291",
            y: "1130.1",
            framePath: `<path class="st0" d="M2393.8,3421H188.1C84.4,3421,0,3336.7,0,3233V1140.3v-113.2c0-153.5,0-417.1,0-489.3V436.1V218v-29.9
            C0,84.4,84.4,0,188.1,0h2205.8C2497.6,0,2582,84.4,2582,188.1V218v218v101.8c0,76,0,348.8,0,489.3v84.9V3233
            C2582,3336.7,2497.6,3421,2393.8,3421z"/>
            <path class="st1" d="M2485,1027.2c0-50.2-40.9-91.1-91.1-91.1h-759.5l-294.7-265.1l-294.7,265.1h-857c-50.2,0-91.1,40.9-91.1,91.1
                V3233c0,50.2,40.9,91.1,91.1,91.1h2205.8c50.2,0,91.1-40.9,91.1-91.1V1027.2z"/>
            <g>
                <path class="st1" d="M893.6,426.9c0-65.3,0.6-157.9,1.7-209.7H893c-8.8,84.4-27.5,255.4-39,344.2h-68.9
                    c-9.3-92.3-27.5-266.4-36.3-345.3h-2.6c1,49.6,3.2,141.1,3.2,213.8v131.5h-73.7v-422h116.5c11,80.2,23.4,185.6,28.2,242.8h1.7
                    c6.5-61.1,20.5-149.8,33.5-242.8h116v422h-78.1V426.9z"/>
                <path class="st1" d="M1252.8,375h-87v115h102l-9.7,71.4h-173.5v-422h172.9v71.8h-91.6v92.5h87V375z"/>
                <path class="st1" d="M1360.1,561.4v-422h89.8c13.4,51.5,55.7,242.6,59,261.2h2c-4.5-56.1-7.4-132.9-7.4-191.8v-69.3h75.4v422h-90.6
                    c-8.8-40.2-54.9-258.5-57.6-272h-2.2c3.2,49.7,5.6,133.6,5.6,199.9v72.1H1360.1z"/>
                <path class="st1" d="M1772.4,139.3v321.4c0,24.6,7.5,37.8,26,37.8c19.6,0,25.6-15,25.6-37.8V139.3h82.2v316.1
                    c0,74.8-34.4,111.8-109.1,111.8c-73.3,0-106.5-35.8-106.5-112.5V139.3H1772.4z"/>
            </g>`
        },
        '4000': {
            viewBox: "0 0 5164 6842.1",
            x: "582",
            y: "2260.1",
            framePath: `<path class="st0" d="M4787.7,6842.1H376.2C168.7,6842.1,0,6673.3,0,6466V2280.7v-226.3c0-307,0-834.2,0-978.6V872.1V436.1v-59.9
            C0,168.7,168.7,0,376.2,0h4411.6C4995.2,0,5164,168.7,5164,376.2v59.9v436.1v203.6c0,151.9,0,697.7,0,978.6v169.8V6466
            C5163.9,6673.3,4995.2,6842.1,4787.7,6842.1z"/>
            <path class="st1" d="M4970.1,2054.4c0-100.5-81.8-182.2-182.2-182.2h-1519l-589.3-530.3l-589.3,530.3h-1714
                c-100.5,0-182.2,81.8-182.2,182.2V6466c0,100.5,81.8,182.2,182.2,182.2h4411.6c100.5,0,182.2-81.8,182.2-182.2V2054.4z"/>
            <g>
                <path class="st1" d="M1787.1,853.8c0-130.6,1.3-315.9,3.3-419.4h-4.4c-17.6,168.9-54.9,510.8-78,688.4h-137.7
                    c-18.6-184.6-55-532.7-72.7-690.5h-5.2c2.1,99.1,6.3,282.1,6.3,427.6v262.9h-147.3V278.7h233c22,160.5,46.7,371.2,56.3,485.5h3.5
                    c13-122.2,41.1-299.5,67-485.5h232.1v844.1h-156.3V853.8z"/>
                <path class="st1" d="M2505.7,749.9h-174V980h204l-19.5,142.7h-347.1V278.7h345.8v143.5h-183.2v185h174V749.9z"/>
                <path class="st1" d="M2720.2,1122.7V278.7h179.6c26.8,103,111.4,485.3,118.1,522.3h3.9c-9-112.3-14.8-265.9-14.8-383.7V278.7h150.7
                    v844.1h-181.3c-17.5-80.3-109.8-517-115.2-544.1h-4.4c6.3,99.3,11.3,267.2,11.3,399.9v144.2H2720.2z"/>
                <path class="st1" d="M3544.8,278.7v642.8c0,49.2,15,75.5,52,75.5c39.2,0,51.3-30,51.3-75.5V278.7h164.3v632.3
                    c0,149.6-68.7,223.6-218.1,223.6c-146.7,0-212.9-71.5-212.9-224.9V278.7H3544.8z"/>
            </g>`
        },
    },
    ArrowBottom: {
        '300': {
            viewBox: "0 0 377.3 470.8",
            x: "38.7",
            y: "8.2",
            framePath: `<path class="st0" d="M212.3,404c-0.7-0.2-1.4-0.2-2.1,0.1c-0.6,0.3-1.2,0.9-1.4,1.6c-1.1,3.3-5,12.8-9.6,21.2
            c-2.7,4.8-5.2,8.6-7.6,11.3c-3.2,3.7-5.3,4.2-6.2,4.2c-1.2,0-1.7-0.4-2-0.8c-2.6-3.1-0.7-13.9,0.8-22.7c2-11.3,3.8-22.1-0.4-27.1
            c-1-1.2-3-2.7-6.2-2.7c-5.6,0-10.6,4.9-15.1,14.9c-0.2,0.5-0.5,0.9-0.7,1.4c-0.1,0.2-0.2,0.5-0.2,0.7c-0.2,0.6-0.5,1.2-0.7,1.8
            c-1.6,4.3-3,8.6-4.2,13c-0.4-10,1.2-21.9,3.9-28c1.9-4.2,0.1-8-2.7-9.3c-1.4-0.6-3-0.6-4.4,0.1c-1.3,0.6-2.4,1.8-3.2,3.4
            c-3,6.1-4.6,15.1-5.1,24.1c-2.5,4.8-6.2,11.6-10.1,17.6l-0.1,0.2c-6.6,10.1-10.5,12.3-11.4,12.3c-0.2,0-0.5-0.1-0.6-0.3
            c-0.6-0.8-2.4-4.9,0.9-21.3c2-9.9,5-20,6.2-24.3c0.4-1.4,0.6-1.9,0.6-2.2c0.3-1.4-0.2-2.8-1.2-3.8c-1.6-1.5-4.7-2.1-6.9-1.4
            c-1.2,0.3-2.2,1.2-2.7,2.3c-0.1,0.2-0.2,0.4-0.2,0.6c-0.3,1.1-1.7,4.7-3.9,9.2c-0.4-3.3-1.2-6.5-2.9-8.6c-1.4-1.8-3.3-2.7-5.5-2.7
            c-5.1,0-9.6,4.7-13.2,13.5c-0.7,1.7-1.3,3.6-1.9,5.5c-2.5,4-5.5,8.9-8.9,13.7l-0.1,0.1c-8.2,11.6-16.5,19.2-21.1,19.2
            c-3.6,0-4.9-7.6-4.9-14.1c0-7.4,1.5-16.5,3.8-23.6c2.9-8.8,5.7-10.7,6.4-10.7c0.1,0,0.3,0,0.6,0.5c0.7,1.1,1,3.3,0.2,5.2
            c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
            c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
            c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1
            c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
            c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0
            c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
            c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
            c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0s0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1
            c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
            c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c1.7-3.2,1.6-7.8-0.2-10.9
            c-1.5-2.6-4.1-4.1-7.3-4.1c-6,0-11.4,4.6-15.3,12.9c-0.9,2-1.8,4.2-2.5,6.6c-8.9,3.2-16.6,7.7-22.7,13.6c-0.6,0.5-1.1,1.1-1.7,1.7
            c-2.1-3.6-3.8-7.1-4.3-10.8c-0.8-7.5,2.2-15.9,5.6-19.7c1.2-1.3,2.3-2,3.2-2c2.6,0,4.8,4.6,4.8,7.7c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.1
            c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1
            c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
            c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1
            c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0s0.1,0,0.1,0c0,0,0.1,0,0.1,0
            c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
            c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1
            c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1
            c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
            c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1s0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
            c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0.2-3.9-1.2-7.8-3.8-10.6c-2.2-2.4-5.2-3.7-8.4-3.7
            c-4.1,0-8.1,2.1-11.5,5.9c-5.4,6.1-8.4,15.7-7.3,23.7c1.1,8.8,4.6,15.3,8.2,20.4c-2.2,4.1-3.9,8.6-5,13.2c-1.4,6-0.8,11.3,1.9,14.6
            c1.6,2,4,3.3,6.6,3.3c0.4,0.1,0.9,0.1,1.3,0.1c2.7,0,5.6-1.2,8.1-3.5c4-3.7,6.2-9,5.7-14.4c-0.6-7.8-4.2-14-7.6-19.5
            c0.8-0.9,1.6-1.7,2.5-2.6c4.8-4.6,10.6-8.3,17.3-11.1c-0.7,3.8-1.1,7.6-1.1,11.4c0,6.6,1.2,12.3,3.4,16.5c2.7,5.2,7,7.9,12.3,7.9
            c6.1,0,12.8-4.5,20.5-13.9c2.9-3.5,5.7-7.4,8.2-11.1c0,0.8-0.1,1.5-0.1,2.2c0,9.9,4.1,11.4,6.5,11.4c2.8,0,5.8-2.2,9.4-6.8
            c2.6-3.3,5.5-7.8,8.6-13.4c0.4-0.8,0.9-1.6,1.3-2.4c-0.2,1.1-0.3,2.1-0.4,3c-0.8,6.4-1.2,11.9-1.1,16.3c0.2,5.9,1.2,10,3.1,12.4
            c1.3,1.8,3.4,2.8,5.6,2.8c4.6,0,9.9-4.7,16.7-15.2c0.1-0.2,0.2-0.3,0.3-0.4l0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c1.7-2.7,3.5-5.7,5.4-9.1
            c0.3,5.4,1,10.3,2.1,14.1c0.7,2.2,2.2,3.8,4.2,4.2c0.4,0.1,0.8,0.1,1.2,0.1c1.7,0,3.3-0.8,4.4-2c1-1.1,1.5-2.5,1.6-3.9
            c2.7-12.8,5.6-22.1,8.5-28.6c3.9-7.8,6.2-9.9,7.1-10.5c0.2,0.6,0.3,1.9,0.3,4.5c-0.1,3-0.5,6.8-0.9,10.8
            c-1.4,12.7-2.9,27.1,2.9,33.6c2,2.2,4.7,3.4,7.9,3.4c9,0,17.1-14.4,20.3-20.6c4.7-8.9,8.2-17.7,9-20.3c0.2-0.7,0.2-1.4-0.1-2.1
            C213.5,404.7,213,404.2,212.3,404z M23.2,455.1c-0.9,1.2-2.4,1.9-3.9,1.9c-1,0-2-0.4-2.6-1.2c-1.5-1.9-1.8-5.7-0.8-10
            c0.8-3.2,1.9-6.4,3.3-9.4c2.6,3.5,4.7,6.6,5.4,9.9C25.4,449.9,24.9,453.1,23.2,455.1z M110.5,408.4c-0.1,1.2-0.3,2.3-0.5,3.5
            c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.1,0.2-0.3,0.5-0.4,0.7c-1.3,2.3-2.5,4.3-3.5,6c0,0,0,0.1-0.1,0.1
            c-2.9,4.7-6.4,8.7-8.1,10c-0.3-1.4-0.4-4.6,0.8-10.5c1.2-6.1,3.4-12.6,5.7-17.2c2-4,3.6-5.3,4.2-5.6c0.5,0.6,1.3,2.6,1.7,6.5
            C110.5,404.2,110.6,406.3,110.5,408.4z M369,404.4c-0.6-0.4-1.3-0.6-2-0.5c-0.7,0.1-1.4,0.5-1.8,1.1l-0.6,0.8
            c-3.2,4.2-8.1,10.8-13.5,16.1c-4.2,4-9.4,7.9-12.8,7.9c-1,0-2.2-0.3-3.1-2.1c-0.9-1.7-1.3-4.3-1.3-7.5c0-2.8,0.3-5.5,0.8-8.2
            c5.8-1.6,10.9-5.7,13-10.1l0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.3c1.3-3,1.1-6.5-0.5-8.9
            c-1.4-2.1-3.7-3.3-6.4-3.3c-5.3,0-10.2,3.5-13.7,9.7c-2.7,4.8-4.4,11.1-4.6,17c-1.6,3.5-3.4,7.3-5.5,11c-2.7,4.8-5.2,8.6-7.6,11.3
            c-3.2,3.7-5.2,4.2-6.2,4.2c-1.2,0-1.7-0.4-2-0.8c-2.6-3.1-0.7-13.9,0.8-22.7c2-11.3,3.8-22.1-0.4-27.1c-1-1.2-3-2.7-6.2-2.7
            c-6.6,0-11.6,7.3-15.8,14.9c0.1-0.7,0.1-1.4,0.2-2.1c0.5-5.5,0.8-15.4-2.1-20.3c-1.7-2.9-4.1-3.4-5.4-3.4c-3,0-7.8,2.3-15.2,20.8
            c-0.3,0.8-0.6,1.5-0.7,1.8c-0.3,0.7-0.5,1.3-0.8,2c-1.7,4.7-3.4,9.8-4.8,14.5c0-9.2,1.6-19.1,4-24.4c1.9-4.2,0.1-8-2.7-9.3
            c-1.4-0.6-3-0.6-4.4,0.1c-1.3,0.6-2.4,1.8-3.2,3.4c-6.1,12.5-6.6,37.4-2.9,49.6c0.7,2.2,2.2,3.8,4.2,4.2c0.4,0.1,0.8,0.1,1.2,0.1
            c1.7,0,3.3-0.8,4.4-2c1.5-1.7,2-4,1.4-6.3c0.4-4.3,3.6-16.2,7.7-27.5c1.1-3,2.2-5.8,3.3-8.3c2.1-4.6,4.3-8.3,5.7-10.1
            c0.2,2.6-0.3,7.5-0.7,11c-1,9.6-2,19.6,2.3,23.8c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0.1
            c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0
            c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0s0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
            c0,0,0,0,0,0h0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0h0c0,0,0,0,0.1,0h0c0.1,0,0.1,0,0.2,0
            l0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0
            c2.5-0.8,4.3-4,7.6-10.5c0.6-1.2,1.2-2.3,1.9-3.6c1.4-2.6,3-5.1,4.7-7.4c2.3-3,3.7-4,4.5-4.4c0.1,0.6,0.3,1.9,0.2,4.5
            c-0.1,3-0.6,6.7-1.1,10.7c-1.5,12.6-3.2,27,2.6,33.4c2,2.2,4.7,3.4,7.9,3.4c9,0,17.1-14.4,20.3-20.6c0.3-0.6,0.6-1.2,1-1.8
            c0.5,1.4,1.1,2.7,1.8,3.9c2.5,3.9,6.3,6,10.9,6c12.1,0,24.8-16.8,32.3-26.8l0.6-0.8C370.4,407,370.2,405.3,369,404.4z M337.1,403.2
            c2.5-6.2,4.7-7.5,5.2-7.5c0.4,0,0.5,0.1,0.5,0.1c0.4,0.7,0.3,2.5,0,3.2c0,0.1-0.1,0.2-0.1,0.3c-1.1,2.3-3.5,4.5-6.4,6
            C336.6,404.7,336.8,403.9,337.1,403.2z M324.2,328.1c-0.4-1.7-0.7-3.4,0-5.3c1-0.9,2.1-1.2,3-1.5c8.2,3,16.2,6,24.3,9
            c0.2,0.8-0.8,1.7-1.8,2.8c-5.5-0.7-9.4-3.1-14.7-4.4c2.4,2.9,4.6,5.5,6.9,8.1c0.6,0.6,1,1.1,1.3,1.8c1.3,3,3.7,5.5,5.3,8.2
            c1.2,1.9,2.4,3.5,4.4,6.9c2.5,4,3.6,6.4,5.7,10.3c0,0,10.1,19.3,7.9,25.3c-0.3,0.9-1,1.3-1,1.3c-0.7,0.4-1.6,0.5-2.2,0.3
            c-0.8-0.3-0.7-1.2-0.7-1.4c0-2.9-5.6-19.9-10.8-28.1c-0.3-0.4-3.5-5.8-5.4-8.4c-1.2-1.6-2.1-3.3-3.1-4.9c-2.1-3.4-4.8-6.4-7-9.6
            c-1.9-2.9-3.9-5.6-7.3-8.3c1.2,3.3,1.1,6.3,1.6,9.2c0.3,1.8-0.1,3.8-0.8,5.9c-0.6,1.5-1.7,2.9-4.3,3c-0.7-1.6,0.5-3.4,0.3-5.1
            C325.4,338,325,333,324.2,328.1z"/>`
        },
        '500': {
            viewBox: "0 0 630.1 786.2",
            x: "65.1",
            y: "14.2",
            framePath: `<path class="st0" d="M354.5,674.6c-1.1-0.4-2.4-0.3-3.5,0.2c-1.1,0.5-1.9,1.5-2.3,2.6c-1.9,5.5-8.3,21.3-16,35.3
            c-4.4,8-8.7,14.4-12.6,18.8c-5.4,6.1-8.8,7-10.3,7c-2.1,0-2.9-0.7-3.4-1.3c-4.3-5.1-1.1-23.3,1.4-37.9c3.3-19,6.4-36.9-0.6-45.2
            c-1.7-2.1-4.9-4.5-10.4-4.5c-9.4,0-17.7,8.1-25.2,24.8c-0.4,0.8-0.8,1.6-1.2,2.4c-0.2,0.4-0.3,0.8-0.4,1.2c-0.4,1-0.8,2-1.2,3
            c-2.7,7.1-5.1,14.4-7.1,21.8c-0.7-16.7,2.1-36.6,6.5-46.7c3.1-7,0.2-13.4-4.4-15.5c-2.3-1.1-5-1-7.3,0.1c-2.2,1.1-4,3-5.3,5.7
            c-4.9,10.2-7.7,25.2-8.5,40.3c-4.2,8-10.3,19.4-16.9,29.4l-0.2,0.4c-11.1,16.8-17.5,20.5-19,20.5c-0.4,0-0.8-0.2-1-0.5
            c-1-1.4-4.1-8.1,1.5-35.6c3.3-16.6,8.3-33.4,10.4-40.5c0.7-2.3,0.9-3.1,1-3.6c0.5-2.3-0.3-4.7-2.1-6.3c-2.7-2.5-7.8-3.5-11.6-2.3
            c-2,0.6-3.7,2-4.5,3.9c-0.2,0.3-0.3,0.7-0.4,1c-0.5,1.9-2.9,7.8-6.5,15.4c-0.6-5.6-2.1-10.9-4.9-14.4c-2.4-3-5.6-4.5-9.2-4.5
            c-8.5,0-16.1,7.8-22.1,22.5c-1.2,2.9-2.3,6-3.2,9.3c-4.1,6.6-9.2,14.8-14.8,22.9l-0.2,0.2c-13.7,19.4-27.5,32-35.2,32
            c-6.1,0-8.2-12.7-8.2-23.6c0-12.4,2.4-27.5,6.3-39.4c4.8-14.6,9.5-17.9,10.8-17.9c0.2,0,0.5,0,1,0.8c1.2,1.8,1.6,5.5,0.3,8.7
            c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
            c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
            c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
            c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.2
            c0,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.2
            c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1
            c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
            c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2-0.1
            c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
            c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
            c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.2
            c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c2.9-5.3,2.7-13-0.4-18.3c-2.6-4.4-6.9-6.8-12.2-6.8c-10,0-19.1,7.7-25.6,21.6
            c-1.6,3.4-2.9,7.1-4.1,11c-14.9,5.3-27.6,12.9-37.9,22.7c-1,0.9-1.9,1.9-2.8,2.8c-3.5-5.9-6.4-11.8-7.1-18.1
            c-1.4-12.5,3.7-26.5,9.4-32.9c1.9-2.2,3.9-3.4,5.4-3.4c4.4,0,8,7.8,8,12.8c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
            c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
            c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
            c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.2,0.1
            c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1
            c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
            c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0
            c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1
            c0,0,0.1,0,0.1-0.1c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2
            c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2
            c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2
            c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
            c0,0,0-0.1,0-0.1c0.3-6.5-2-12.9-6.4-17.8c-3.6-4-8.7-6.2-14.1-6.2c-6.8,0-13.4,3.4-19.2,9.9c-9,10.3-14,26.2-12.3,39.6
            c1.9,14.8,7.8,25.5,13.7,34.1c-3.7,6.9-6.5,14.3-8.3,22c-2.4,10-1.3,18.9,3.1,24.4c2.7,3.4,6.7,5.4,11,5.5c0.7,0.1,1.5,0.2,2.2,0.2
            c4.5,0,9.4-2.1,13.6-5.9c6.7-6.1,10.3-15,9.6-24c-1-13.1-7.1-23.4-12.8-32.6c1.3-1.5,2.7-2.9,4.1-4.3c8-7.6,17.7-13.8,28.9-18.5
            c-1.2,6.3-1.9,12.7-1.9,19c0,11,1.9,20.5,5.6,27.5c4.6,8.7,11.7,13.2,20.5,13.2c10.2,0,21.4-7.6,34.3-23.1
            c4.9-5.9,9.5-12.3,13.7-18.6c-0.1,1.3-0.1,2.5-0.1,3.7c0,16.5,6.8,19,10.8,19c4.6,0,9.6-3.6,15.7-11.3c4.3-5.5,9.1-13,14.3-22.3
            c0.7-1.3,1.5-2.6,2.2-4c-0.3,1.8-0.5,3.5-0.7,4.9c-1.4,10.7-2,19.9-1.9,27.2c0.3,9.9,2,16.7,5.2,20.8c2.2,2.9,5.7,4.6,9.4,4.6
            c7.6,0,16.5-7.9,28-25.4c0.2-0.3,0.3-0.5,0.5-0.7l0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3c2.9-4.4,5.9-9.5,9.1-15.1
            c0.5,9,1.7,17.2,3.6,23.5c1.1,3.7,3.7,6.3,7,7.1c0.6,0.1,1.3,0.2,2,0.2c2.8,0,5.5-1.3,7.3-3.4c1.6-1.8,2.6-4.1,2.7-6.5
            c4.5-21.4,9.4-37,14.2-47.7c6.4-13,10.3-16.6,11.9-17.5c0.3,1.1,0.6,3.2,0.4,7.5c-0.2,5-0.9,11.3-1.6,18
            c-2.3,21.2-4.9,45.3,4.8,56.1c3.4,3.7,7.8,5.6,13.2,5.6c15,0,28.6-24,33.8-34.5c7.9-14.8,13.6-29.6,15.1-33.9
            c0.4-1.1,0.3-2.4-0.2-3.5C356.6,675.9,355.7,675,354.5,674.6z M38.8,760c-1.5,2-3.9,3.1-6.4,3.1c-1.7,0.1-3.3-0.7-4.3-2
            c-2.5-3.2-3.1-9.6-1.4-16.6c1.3-5.4,3.1-10.7,5.5-15.7c4.3,5.9,7.9,11,9,16.5C42.4,751.3,41.6,756.7,38.8,760z M184.5,682
            c-0.2,2-0.5,3.9-0.9,5.8c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c-0.2,0.4-0.5,0.8-0.7,1.2c-2.1,3.8-4.1,7.1-5.9,10
            c0,0-0.1,0.1-0.1,0.1c-4.8,7.8-10.6,14.5-13.5,16.7c-0.5-2.3-0.7-7.7,1.3-17.6c2-10.1,5.7-21.1,9.6-28.7c3.4-6.6,6-8.9,7.1-9.4
            c0.8,1.1,2.2,4.3,2.9,10.8C184.6,675,184.7,678.5,184.5,682z M616.2,675.3c-1-0.7-2.2-1-3.4-0.9c-1.2,0.2-2.3,0.8-3,1.8l-1,1.3
            c-5.3,7-13.6,18-22.6,26.9c-7,6.7-15.6,13.3-21.4,13.3c-1.7,0-3.6-0.4-5.2-3.5c-1.5-2.9-2.2-7.2-2.2-12.5c0-4.6,0.5-9.2,1.4-13.7
            c9.7-2.7,18.2-9.5,21.6-16.9l0,0c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0.1-0.2,0.1-0.3,0.2-0.5c2.1-5,1.8-10.8-0.8-14.8
            c-2.3-3.6-6.1-5.5-10.8-5.5c-8.9,0-17,5.8-22.8,16.3c-4.5,8.1-7.3,18.5-7.7,28.4c-2.6,5.8-5.7,12.3-9.1,18.4
            c-4.4,8-8.7,14.4-12.6,18.8c-5.4,6.1-8.8,7-10.3,7c-2.1,0-2.9-0.7-3.4-1.3c-4.3-5.1-1.1-23.3,1.4-37.9c3.3-19,6.4-36.9-0.6-45.2
            c-1.7-2.1-4.9-4.5-10.4-4.5c-11,0-19.4,12.2-26.3,24.9c0.1-1.2,0.2-2.4,0.3-3.5c0.8-9.1,1.3-25.7-3.6-34c-2.9-4.9-6.8-5.6-8.9-5.6
            c-5.1,0-13.1,3.8-25.4,34.7c-0.6,1.4-1,2.4-1.2,3.1c-0.4,1.1-0.9,2.2-1.3,3.4c-2.9,7.8-5.7,16.4-8,24.2c0.1-15.3,2.7-31.9,6.7-40.8
            c3.1-7,0.2-13.4-4.4-15.5c-2.3-1.1-5-1-7.3,0.1c-2.2,1.1-3.9,3-5.3,5.7c-10.1,20.9-11,62.4-4.9,82.8c1.1,3.7,3.7,6.3,7,7.1
            c0.6,0.1,1.3,0.2,1.9,0.2c2.8,0,5.5-1.3,7.3-3.4c2.5-2.8,3.3-6.6,2.3-10.5c0.7-7.1,5.9-27.1,12.9-46c1.8-5,3.7-9.7,5.5-13.9
            c3.5-7.6,7.1-13.9,9.5-16.9c0.3,4.3-0.5,12.5-1.1,18.3c-1.6,16.1-3.3,32.7,3.8,39.7c0,0,0,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1
            c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1
            c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
            c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0h0c0.1,0,0.1,0,0.2,0
            c0,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0h0c0,0,0.1,0,0.1,0h0c0.1,0,0.2,0,0.3,0l0,0c0,0,0.1,0,0.2,0
            c0.1,0,0.2,0,0.2,0l0.1,0c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c4.2-1.3,7.1-6.7,12.7-17.6
            c1-1.9,2-3.9,3.1-5.9c2.3-4.3,5-8.5,7.9-12.4c3.8-5,6.2-6.7,7.4-7.4c0.2,1.1,0.5,3.2,0.3,7.5c-0.2,4.9-1,11.2-1.8,17.9
            c-2.5,21.1-5.4,45,4.3,55.9c3.3,3.7,7.8,5.6,13.2,5.6c15,0,28.6-24,33.8-34.5c0.5-1,1.1-2.1,1.6-3.1c0.8,2.4,1.8,4.6,3,6.5
            c4.1,6.6,10.4,10.1,18.2,10.1c20.2,0,41.3-28.1,54-44.8l1-1.3C618.6,679.7,618.2,676.8,616.2,675.3z M563,673.4
            c4.1-10.4,7.9-12.5,8.7-12.5c0.7,0,0.8,0.1,0.8,0.2c0.7,1.2,0.5,4.1,0,5.4c-0.1,0.2-0.1,0.3-0.2,0.5c-1.8,3.9-5.8,7.6-10.6,10.1
            C562.1,675.8,562.5,674.6,563,673.4z M541.4,547.9c-0.6-2.8-1.2-5.6,0.1-8.8c1.7-1.5,3.5-2,5-2.5c13.7,5,27,10,40.7,15
            c0.3,1.4-1.4,2.9-2.9,4.7c-9.2-1.2-15.7-5.1-24.5-7.4c4,4.8,7.6,9.3,11.5,13.6c1,0.9,1.6,1.8,2.1,3c2.1,5.1,6.1,9.1,8.9,13.7
            c2,3.2,4,5.9,7.4,11.5c4.1,6.7,6,10.7,9.4,17.3c0,0,16.9,32.3,13.2,42.2c-0.5,1.4-1.7,2.1-1.7,2.1c-1.1,0.7-2.7,0.8-3.6,0.4
            c-1.3-0.5-1.2-2-1.2-2.3c0.1-4.8-9.3-33.2-18-47c-0.5-0.7-5.8-9.7-9-14c-2-2.6-3.4-5.5-5.2-8.2c-3.5-5.6-7.9-10.7-11.6-16
            c-3.2-4.8-6.5-9.3-12.2-13.9c2,5.5,1.8,10.6,2.7,15.4c0.4,3.1-0.2,6.4-1.4,9.8c-1,2.5-2.9,4.9-7.2,4.9c-1.1-2.6,0.8-5.7,0.5-8.5
            C543.4,564.5,542.8,556.1,541.4,547.9z"/>`
        },
        '1000': {
            viewBox: "0 0 1260.3 1572.4",
            x: "130.1",
            y: "28.5",
            framePath: `<path class="st0" d="M709,1349.3c-2.3-0.8-4.8-0.6-7,0.4c-2.2,1.1-3.8,2.9-4.6,5.2c-3.8,10.9-16.6,42.6-32.1,70.7
            c-8.9,16.1-17.4,28.7-25.3,37.6c-10.8,12.2-17.6,14.1-20.6,14.1c-4.1,0-5.8-1.4-6.8-2.6c-8.6-10.2-2.3-46.5,2.8-75.7
            c6.6-37.9,12.8-73.7-1.2-90.4c-3.5-4.1-9.9-9.1-20.7-9.1c-18.8,0-35.3,16.3-50.3,49.6c-0.8,1.6-1.6,3.1-2.3,4.8
            c-0.4,0.8-0.6,1.6-0.8,2.4c-0.8,2-1.6,3.9-2.4,6c-5.4,14.3-10.2,28.8-14.2,43.5c-1.4-33.5,4.1-73.2,13.1-93.5
            c6.2-14,0.4-26.8-8.9-31.1c-4.6-2.1-10-2.1-14.6,0.2c-4.4,2.2-7.9,6-10.6,11.5c-9.9,20.3-15.3,50.4-16.9,80.6
            c-8.4,16-20.7,38.7-33.7,58.9l-0.5,0.7c-22.1,33.7-35,41.1-38.1,41.1c-0.8,0.1-1.6-0.3-2-1c-2.1-2.8-8.1-16.3,3-71.2
            c6.7-33.1,16.5-66.7,20.8-81c1.4-4.6,1.8-6.2,2-7.2c1-4.6-0.6-9.4-4.2-12.5c-5.4-5-15.6-7-23.1-4.6c-4,1.1-7.3,4-9,7.8
            c-0.3,0.7-0.6,1.3-0.8,2.1c-0.9,3.8-5.7,15.6-12.9,30.9c-1.2-11.2-4.1-21.8-9.8-28.8c-4.8-5.9-11.1-9-18.3-9
            c-17,0-32.2,15.6-44.2,45.1c-2.4,5.8-4.5,12.1-6.4,18.5c-8.2,13.3-18.3,29.6-29.6,45.7l-0.3,0.4c-27.4,38.9-55,64-70.4,64
            c-12.1,0-16.4-25.4-16.4-47.2c0-24.8,4.9-55.1,12.7-78.9c9.6-29.2,19-35.8,21.5-35.8c0.3,0,1.1,0,2.1,1.5c2.4,3.5,3.3,11,0.6,17.4
            c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1,0,0.2-0.1,0.3c0,0.2-0.1,0.4-0.1,0.5c0,0.1,0,0.3-0.1,0.4c0,0.2,0,0.3-0.1,0.5
            c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0,0.4c0,0.2,0,0.3,0,0.5c0,0.1,0,0.3,0,0.4c0,0.2,0,0.3,0,0.5c0,0.1,0,0.2,0.1,0.4
            c0,0.1,0.1,0.3,0.1,0.5c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.4
            c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.3,0.3,0.4
            c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.4,0.3
            c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.5,0.3c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0,0,0c0.1,0.1,0.3,0.1,0.4,0.2
            c0.1,0.1,0.3,0.1,0.4,0.2c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0,0.2,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0.1
            c0.1,0,0.3,0,0.4,0.1c0.1,0,0.3,0,0.5,0.1c0.1,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0,0.4,0
            c0.2,0,0.3,0,0.5-0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.3-0.1,0.5-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1,0,0.3-0.1,0.4-0.1
            c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.3-0.2,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.2
            c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.2,0.2-0.3
            c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0
            c0-0.1,0.1-0.1,0.1-0.2c5.7-10.7,5.4-26-0.8-36.6c-5.2-8.8-13.8-13.6-24.3-13.6c-20,0-38.2,15.3-51.3,43.1
            c-3.2,6.7-5.9,14.2-8.2,21.9c-29.8,10.6-55.3,25.8-75.7,45.4c-1.9,1.8-3.8,3.7-5.6,5.6c-7.1-11.9-12.8-23.6-14.2-36.2
            c-2.7-25,7.4-53,18.8-65.8c3.9-4.3,7.8-6.8,10.8-6.8c8.9,0,16,15.5,16,25.6c0,0.1,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5c0,0.1,0,0.3,0,0.4
            c0,0.2,0,0.3,0.1,0.5c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.5c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4
            c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.2,0.3,0.3,0.4
            c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3
            c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.4,0.2
            c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1
            c0.1,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.4,0.1c0.2,0,0.3,0,0.5,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.3,0
            c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4-0.1c0.2,0,0.3-0.1,0.5-0.1c0.1,0,0.2-0.1,0.3-0.1
            c0.2,0,0.3-0.1,0.5-0.2c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.3-0.2,0.5-0.3
            c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2
            c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.4
            c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.3,0.1-0.4
            c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.3,0.1-0.5c0-0.1,0-0.3,0.1-0.4c0-0.2,0-0.3,0-0.5c0-0.1,0-0.1,0-0.2
            c0.7-13.1-3.9-25.9-12.8-35.5c-7.2-7.9-17.5-12.4-28.2-12.3c-13.7,0-26.9,6.9-38.3,19.8c-18.1,20.5-27.9,52.4-24.5,79.3
            c3.8,29.5,15.5,50.9,27.3,68.3c-7.5,13.9-13.1,28.6-16.7,44c-4.8,20-2.5,37.8,6.2,48.8c5.3,6.8,13.4,10.9,22.1,11
            c1.5,0.2,3,0.3,4.5,0.3c9.1,0,18.8-4.1,27.2-11.7c13.5-12.2,20.6-30,19.2-48.1c-2-26.1-14.1-46.9-25.5-65.2c2.6-2.9,5.4-5.8,8.3-8.6
            c15.9-15.3,35.3-27.6,57.8-37c-2.4,12.6-3.7,25.5-3.7,37.9c0,22,3.9,41,11.3,54.9c9.2,17.3,23.4,26.5,41.1,26.5
            c20.4,0,42.9-15.1,68.6-46.3c9.7-11.8,18.9-24.6,27.4-37.1c-0.1,2.5-0.2,5-0.2,7.4c0,33,13.6,37.9,21.6,37.9
            c9.3,0,19.3-7.2,31.4-22.6c8.6-11,18.3-26,28.7-44.6c1.5-2.6,2.9-5.3,4.4-7.9c-0.5,3.6-1,6.9-1.4,9.9c-2.8,21.4-4.1,39.8-3.7,54.5
            c0.5,19.7,3.9,33.3,10.5,41.6c4.5,5.9,11.4,9.3,18.8,9.2c15.3,0,33-15.8,55.9-50.9c0.4-0.6,0.7-1,1-1.5l0.5-0.7
            c0.1-0.2,0.3-0.4,0.4-0.6c5.7-8.9,11.7-19,18.1-30.2c1,18,3.4,34.5,7.1,47c2.2,7.5,7.3,12.6,14,14.2c1.3,0.3,2.6,0.4,3.9,0.4
            c5.6-0.1,10.9-2.5,14.6-6.7c3.2-3.6,5.1-8.2,5.4-13c9-42.9,18.8-73.9,28.4-95.4c12.9-26.1,20.6-33.1,23.8-35c0.5,2.1,1.1,6.5,0.8,15
            c-0.3,10-1.7,22.6-3.2,36c-4.6,42.4-9.8,90.5,9.7,112.2c6.7,7.5,15.6,11.3,26.5,11.3c29.9,0,57.2-48.1,67.7-69
            c15.8-29.6,27.3-59.2,30.2-67.7c0.8-2.3,0.6-4.8-0.4-7C713.2,1351.7,711.3,1350.1,709,1349.3z M77.6,1520.1c-3.1,4-7.9,6.3-12.9,6.2
            c-3.4,0.1-6.6-1.4-8.6-4.1c-5.1-6.4-6.1-19.2-2.8-33.3c2.6-10.8,6.2-21.4,10.9-31.5c8.6,11.7,15.8,22.1,18,33
            C84.8,1502.5,83.1,1513.3,77.6,1520.1z M368.9,1364c-0.3,3.9-0.9,7.8-1.7,11.7c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1
            c-0.5,0.8-0.9,1.6-1.4,2.4c-4.3,7.7-8.2,14.3-11.8,20.1c0,0.1-0.1,0.2-0.2,0.3c-9.6,15.7-21.3,29-26.9,33.4
            c-1-4.6-1.4-15.4,2.5-35.1c4.1-20.3,11.4-42.2,19.2-57.4c6.7-13.2,11.9-17.7,14.1-18.9c1.5,2.2,4.3,8.5,5.8,21.7
            C369.2,1350,369.4,1357,368.9,1364z M1232.4,1350.6c-1.9-1.5-4.4-2.1-6.8-1.8c-2.4,0.3-4.6,1.6-6,3.5l-1.9,2.6
            c-10.6,14-27.2,36-45.1,53.8c-13.9,13.4-31.3,26.6-42.8,26.6c-3.4,0-7.2-0.9-10.4-7.1c-2.9-5.7-4.5-14.4-4.5-25.1
            c0.1-9.2,1-18.4,2.8-27.4c19.5-5.3,36.3-19.1,43.3-33.7l0,0c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0.1-0.2
            c0.1-0.3,0.3-0.7,0.5-1.1c4.2-10.1,3.6-21.6-1.5-29.7c-4.6-7.1-12.2-11.1-21.5-11.1c-17.8,0-34,11.5-45.7,32.5
            c-9,16.1-14.6,37-15.3,56.7c-5.2,11.6-11.5,24.5-18.3,36.8c-8.9,16.1-17.4,28.7-25.3,37.6c-10.8,12.2-17.5,14.1-20.6,14.1
            c-4.1,0-5.8-1.4-6.8-2.6c-8.6-10.2-2.3-46.5,2.8-75.7c6.6-37.9,12.8-73.7-1.2-90.4c-3.5-4.1-9.9-9.1-20.7-9.1
            c-21.9,0-38.8,24.5-52.7,49.7c0.2-2.4,0.5-4.7,0.7-7c1.5-18.2,2.6-51.3-7.2-67.9c-5.8-9.8-13.7-11.3-17.9-11.3
            c-10.1,0-26.2,7.5-50.7,69.5c-1.1,2.7-1.9,4.8-2.4,6.2c-0.9,2.2-1.7,4.5-2.6,6.8c-5.8,15.7-11.3,32.7-15.9,48.5
            c0.1-30.6,5.4-63.7,13.3-81.6c6.2-14,0.3-26.8-8.9-31.1c-4.7-2.1-10-2.1-14.6,0.2c-4.3,2.2-7.9,6-10.6,11.5
            c-20.3,41.8-21.9,124.8-9.8,165.7c2.2,7.5,7.3,12.6,14,14.1c1.3,0.3,2.6,0.4,3.9,0.4c5.6-0.1,10.9-2.5,14.6-6.7
            c5-5.5,6.6-13.3,4.7-21c1.3-14.2,11.9-54.2,25.7-92c3.7-10.1,7.4-19.4,11-27.8c6.9-15.3,14.2-27.8,18.9-33.7c0.7,8.6-1,25-2.2,36.7
            c-3.3,32.2-6.7,65.4,7.6,79.3c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0.1
            c0,0,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.2,0.1
            c0.1,0.1,0.2,0.1,0.4,0.2c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.1,0.1,0.2,0.1
            c0,0,0.1,0,0.1,0c0.1,0.1,0.3,0.1,0.5,0.2c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
            c0,0,0,0,0.1,0h0c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0h0.1
            c0.1,0,0.1,0,0.2,0h0c0.2,0,0.4,0,0.6,0l0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.5-0.1l0.1,0c0.1,0,0.2,0,0.3-0.1
            c0.1,0,0.3-0.1,0.4-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2-0.1c8.4-2.7,14.2-13.4,25.5-35.1c2-3.8,4.1-7.8,6.2-11.9
            c4.7-8.6,10-16.9,15.9-24.8c7.6-10,12.5-13.5,14.9-14.7c0.5,2.1,1,6.5,0.7,14.9c-0.4,9.9-1.9,22.4-3.5,35.7
            c-5,42.2-10.7,90,8.5,111.7c6.7,7.5,15.6,11.3,26.5,11.3c29.9,0,57.2-48.1,67.7-69c1.1-2.1,2.2-4.1,3.2-6.2c1.6,4.8,3.6,9.2,6,13
            c8.3,13.2,20.9,20.1,36.5,20.1c40.3,0,82.7-56.1,108-89.6l1.9-2.6C1237.2,1359.4,1236.4,1353.7,1232.4,1350.6z M1126,1346.8
            c8.2-20.8,15.8-25,17.3-25c1.4,0,1.5,0.3,1.7,0.5c1.4,2.3,0.9,8.2,0,10.8c-0.1,0.3-0.2,0.6-0.4,0.9c-3.6,7.7-11.5,15.2-21.3,20.1
            C1124.2,1351.6,1125,1349.1,1126,1346.8z M1082.8,1095.8c-1.2-5.6-2.4-11.3,0.1-17.7c3.4-2.9,7-4.1,10.1-5c27.3,10,54,20,81.3,30
            c0.6,2.8-2.8,5.8-5.9,9.4c-18.3-2.3-31.5-10.2-49-14.8c8.1,9.6,15.3,18.5,23,27.2c1.9,1.9,3.2,3.6,4.2,5.9
            c4.2,10.1,12.3,18.3,17.8,27.4c3.9,6.5,7.9,11.8,14.8,23c8.2,13.4,12.1,21.5,18.9,34.5c0,0,33.7,64.6,26.3,84.4
            c-1.1,2.9-3.4,4.3-3.4,4.3c-2.2,1.3-5.3,1.6-7.2,0.9c-2.6-1.1-2.4-4-2.4-4.7c0.1-9.7-18.6-66.4-36-93.9
            c-0.9-1.5-11.6-19.4-18.1-28.1c-3.9-5.3-6.9-10.9-10.3-16.4c-7-11.3-15.9-21.3-23.3-32.1c-6.3-9.6-13-18.7-24.3-27.8
            c4,11,3.6,21.1,5.3,30.8c0.8,6.2-0.4,12.8-2.8,19.6c-1.9,5-5.8,9.7-14.4,9.9c-2.2-5.2,1.6-11.4,1-16.9
            C1086.8,1129,1085.6,1112.3,1082.8,1095.8z"/>`
        },
        '2000': {
            viewBox: "0 0 2520.6 3144.8",
            x: "260.3",
            y: "56.9",
            framePath: `<path class="st0" d="M1418.1,2698.6c-4.6-1.6-9.6-1.3-14,0.8c-4.3,2.1-7.7,5.9-9.3,10.5c-7.5,21.9-33.1,85.2-64.2,141.3
            c-17.8,32.2-34.8,57.5-50.5,75.3c-21.6,24.5-35.1,28.1-41.2,28.1c-8.2,0-11.6-2.8-13.7-5.2c-17.2-20.5-4.6-93.1,5.6-151.4
            c13.2-75.8,25.6-147.4-2.4-180.8c-7-8.3-19.8-18.1-41.4-18.1c-37.6,0-70.6,32.5-100.7,99.2c-1.5,3.1-3.1,6.3-4.6,9.6
            c-0.7,1.5-1.2,3.1-1.5,4.8c-1.6,3.9-3.3,7.9-4.8,12c-10.9,28.5-20.4,57.6-28.4,87.1c-2.8-67,8.3-146.4,26.2-187
            c12.4-28,0.7-53.6-17.7-62.2c-9.3-4.3-20-4.1-29.1,0.4c-8.7,4.3-15.8,12-21.1,22.9c-19.8,40.6-30.7,100.8-33.9,161.2
            c-16.7,32-41.4,77.4-67.5,117.7l-1,1.5c-44.2,67.3-70,82.1-76.2,82.1c-1.6,0.2-3.2-0.6-4-2.1c-4.2-5.6-16.2-32.5,5.9-142.4
            c13.4-66.3,33.1-133.4,41.5-162.1c2.7-9.2,3.7-12.5,4.1-14.4c2-9.3-1.2-18.9-8.3-25.1c-10.9-9.9-31.3-14-46.3-9.2
            c-8,2.3-14.6,8-18.1,15.6c-0.7,1.3-1.2,2.7-1.6,4.1c-1.8,7.6-11.4,31.1-25.9,61.8c-2.4-22.3-8.3-43.6-19.6-57.6
            c-9.6-11.8-22.2-18.1-36.6-18.1c-33.9,0-64.4,31.2-88.4,90.1c-4.7,11.7-9,24.2-12.8,37c-16.5,26.6-36.7,59.2-59.2,91.4l-0.6,0.9
            c-54.7,77.8-110,128-140.8,128c-24.2,0-32.8-50.9-32.8-94.4c0-49.6,9.7-110.1,25.3-157.8c19.2-58.5,38-71.6,43-71.6
            c0.7,0,2.1,0,4.1,3c4.8,7.1,6.6,22.1,1.3,34.9c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.3-0.3,0.7-0.4,1.1c0,0.2-0.1,0.4-0.1,0.7
            c-0.1,0.4-0.2,0.7-0.2,1.1c0,0.3-0.1,0.5-0.1,0.8c0,0.3-0.1,0.6-0.1,1c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,1
            c0,0.3,0,0.5,0,0.8c0,0.3,0,0.6,0.1,1c0,0.2,0.1,0.5,0.1,0.8c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.1,0.5,0.2,0.8
            c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.5,0.3,0.7c0.1,0.3,0.2,0.6,0.4,0.8c0.1,0.2,0.3,0.5,0.4,0.7
            c0.2,0.3,0.3,0.6,0.5,0.8c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.3,0.3,0.5,0.6,0.8c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.4,0.4,0.6,0.7
            c0.2,0.2,0.5,0.4,0.7,0.7c0.2,0.2,0.3,0.4,0.6,0.5c0.2,0.2,0.5,0.5,0.8,0.7c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0.2,0.6,0.4,1,0.6
            c0.1,0.1,0.3,0.2,0.5,0.3c0,0,0,0,0.1,0c0.3,0.1,0.6,0.3,0.8,0.4c0.3,0.1,0.6,0.3,0.9,0.4c0,0,0,0,0.1,0c0.2,0.1,0.4,0.1,0.6,0.2
            c0.3,0.1,0.7,0.3,1,0.4c0.2,0.1,0.5,0.1,0.7,0.2c0.3,0.1,0.7,0.2,1,0.3c0.3,0,0.5,0.1,0.8,0.1c0.3,0,0.6,0.1,0.9,0.1
            c0.3,0,0.6,0,0.9,0c0.2,0,0.5,0,0.8,0c0.3,0,0.7,0,1,0c0.2,0,0.5,0,0.7,0c0.3,0,0.7-0.1,1-0.1c0.2,0,0.5-0.1,0.7-0.1
            c0.3-0.1,0.7-0.2,1-0.2c0.2,0,0.5-0.1,0.7-0.2c0.3-0.1,0.6-0.2,0.9-0.3c0.2-0.1,0.5-0.2,0.8-0.3c0.2-0.1,0.5-0.2,0.8-0.4
            c0.2-0.1,0.5-0.2,0.8-0.4c0.2-0.1,0.5-0.3,0.7-0.4c0.2-0.1,0.5-0.3,0.8-0.5c0.2-0.2,0.4-0.3,0.7-0.5c0.2-0.2,0.5-0.4,0.7-0.6
            c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.4-0.4,0.7-0.6c0.2-0.2,0.4-0.5,0.7-0.7c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.3,0.5-0.6,0.7-0.9
            c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.3,0.4-0.7,0.6-1c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0-0.1,0-0.1c0.1-0.1,0.1-0.3,0.2-0.4
            c11.5-21.4,10.9-52.1-1.6-73.1c-10.4-17.5-27.6-27.2-48.6-27.2c-40.1,0-76.5,30.6-102.5,86.3c-6.3,13.5-11.8,28.3-16.5,43.8
            c-59.7,21.1-110.6,51.5-151.4,90.8c-3.8,3.7-7.6,7.4-11.2,11.2c-14.2-23.8-25.7-47.3-28.4-72.5c-5.5-49.9,14.8-106.1,37.6-131.5
            c7.7-8.6,15.6-13.5,21.6-13.5c17.7,0,32,31,32,51.2c0,0.1,0,0.3,0,0.4c0,0.3,0,0.7,0,1c0,0.3,0,0.6,0.1,0.9c0,0.3,0.1,0.6,0.1,1
            c0,0.3,0.1,0.6,0.1,0.8c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.6,0.3,0.8
            c0.1,0.3,0.2,0.5,0.4,0.8c0.1,0.3,0.2,0.6,0.4,0.8c0.1,0.3,0.2,0.5,0.4,0.7c0.1,0.2,0.3,0.5,0.5,0.8c0.1,0.2,0.3,0.5,0.5,0.7
            c0.1,0.2,0.3,0.5,0.5,0.7c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.4,0.6,0.5
            c0.2,0.2,0.5,0.4,0.7,0.5c0.2,0.2,0.4,0.3,0.7,0.5c0.2,0.2,0.5,0.3,0.8,0.5c0.2,0.1,0.5,0.3,0.7,0.4c0.2,0.1,0.5,0.2,0.8,0.4
            c0.2,0.1,0.5,0.2,0.8,0.4c0.3,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.6,0.2,0.9,0.3c0.3,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.6,0.1,0.9,0.2
            c0.3,0,0.5,0.1,0.8,0.1c0.3,0,0.6,0.1,1,0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4,0
            c0.3,0,0.6,0,0.9,0c0.3,0,0.6,0,1-0.1c0.2,0,0.5-0.1,0.8-0.1c0.3,0,0.7-0.1,1-0.2c0.2,0,0.4-0.1,0.7-0.2c0.3-0.1,0.7-0.2,1-0.3
            c0.2-0.1,0.4-0.1,0.6-0.2c0.3-0.1,0.7-0.3,1-0.4c0.2-0.1,0.4-0.2,0.5-0.3c0.3-0.2,0.7-0.3,1-0.5c0.1-0.1,0.3-0.2,0.5-0.3
            c0.3-0.2,0.6-0.4,0.9-0.6c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.2,0.5-0.4,0.8-0.7c0.2-0.2,0.4-0.3,0.5-0.5c0.2-0.2,0.5-0.5,0.7-0.7
            c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.4-0.5,0.6-0.7c0.2-0.2,0.3-0.4,0.5-0.7c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.2,0.3-0.5,0.5-0.8
            c0.1-0.2,0.2-0.5,0.4-0.8c0.1-0.3,0.2-0.5,0.4-0.8c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.6,0.3-0.9
            c0.1-0.3,0.1-0.6,0.2-0.8c0.1-0.3,0.1-0.6,0.2-0.9c0-0.3,0.1-0.6,0.1-0.9c0-0.3,0.1-0.6,0.1-1c0-0.1,0-0.3,0-0.4
            c1.4-26.2-7.9-51.8-25.7-71c-14.4-15.8-35-24.8-56.4-24.7c-27.3,0-53.8,13.7-76.7,39.7c-36.2,41-55.9,104.8-49.1,158.6
            c7.5,59.1,31,101.9,54.7,136.5c-14.9,27.7-26.1,57.3-33.4,87.9c-9.6,39.9-5.1,75.6,12.4,97.7c10.6,13.7,26.9,21.8,44.2,22.1
            c3,0.4,6,0.7,8.9,0.7c18.2,0,37.5-8.2,54.4-23.4c27-24.4,41.1-59.9,38.4-96.1c-4.1-52.3-28.2-93.8-51.1-130.4
            c5.3-5.9,10.7-11.6,16.5-17.1c31.8-30.5,70.6-55.3,115.6-74c-4.7,25.1-7.4,51-7.4,75.8c0,44.1,7.8,82.1,22.5,109.9
            c18.3,34.7,46.8,53,82.2,53c40.9,0,85.7-30.3,137.2-92.6c19.5-23.5,37.9-49.2,54.7-74.3c-0.3,5-0.5,10-0.5,14.7
            c0,66,27.1,75.9,43.3,75.9c18.5,0,38.5-14.4,62.8-45.3c17.3-21.9,36.6-52,57.4-89.2c3-5.2,5.9-10.5,8.7-15.9
            c-1.1,7.2-2,13.8-2.8,19.7c-5.7,42.9-8.2,79.5-7.4,108.9c1,39.5,7.8,66.7,20.9,83.1c8.9,11.7,22.8,18.6,37.6,18.5
            c30.5,0,65.9-31.6,111.8-101.8c0.7-1.1,1.4-2.1,1.9-2.9l0.9-1.5c0.3-0.4,0.5-0.8,0.7-1.2c11.4-17.7,23.5-37.9,36.3-60.5
            c2,36,6.8,69,14.3,94c4.4,15,14.7,25.3,28.1,28.3c2.6,0.6,5.2,0.9,7.8,0.9c11.2-0.1,21.8-5.1,29.2-13.5c6.5-7.2,10.3-16.4,10.8-26.1
            c18.1-85.7,37.7-147.8,56.7-190.8c25.8-52.2,41.2-66.2,47.5-70c1,4.3,2.2,13,1.7,30c-0.7,19.9-3.4,45.2-6.3,72
            c-9.2,84.9-19.6,181,19.4,224.5c13.4,14.9,31.2,22.5,53,22.5c59.8,0,114.5-96.2,135.4-137.9c31.6-59.2,54.6-118.4,60.4-135.5
            c1.6-4.6,1.3-9.6-0.8-14C1426.4,2703.5,1422.7,2700.2,1418.1,2698.6z M155.2,3040.2c-6.2,8-15.7,12.6-25.8,12.5
            c-6.8,0.3-13.2-2.8-17.3-8.2c-10.2-12.9-12.3-38.4-5.5-66.6c5.1-21.7,12.5-42.8,21.9-63c17.1,23.4,31.5,44.1,36,65.9
            C169.7,3005,166.2,3026.6,155.2,3040.2z M737.9,2728c-0.7,7.8-1.9,15.6-3.5,23.3c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.1,0.7
            c0,0.1,0,0.2,0,0.3c-0.9,1.6-1.8,3.3-2.7,4.9c-8.5,15.3-16.4,28.6-23.6,40.2c-0.1,0.2-0.2,0.3-0.3,0.5
            c-19.3,31.4-42.5,58.1-53.8,66.9c-1.9-9.2-2.9-30.8,5.1-70.3c8.1-40.5,22.8-84.5,38.3-114.8c13.5-26.4,23.9-35.4,28.3-37.7
            c3,4.3,8.6,17.1,11.5,43.4C738.4,2699.9,738.8,2714,737.9,2728z M2464.8,2701.3c-3.8-2.9-8.7-4.2-13.5-3.5c-4.8,0.7-9.1,3.2-12,7.1
            l-3.9,5.2c-21.2,28.1-54.4,72.1-90.3,107.5c-27.8,26.8-62.6,53.1-85.5,53.1c-6.7,0-14.4-1.7-20.9-14.2c-5.9-11.4-9-28.7-9-50.1
            c0.1-18.4,2.1-36.8,5.7-54.9c39-10.6,72.6-38.1,86.6-67.5l0,0c0,0,0-0.1,0-0.1c0-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.3,0.2-0.4
            c0.3-0.7,0.6-1.4,0.9-2.2c8.4-20.2,7.3-43.2-3.1-59.4c-9.1-14.3-24.4-22.1-43-22.1c-35.5,0-68,23.1-91.4,65
            c-18,32.2-29.1,74-30.7,113.5c-10.4,23.2-23,49-36.5,73.5c-17.8,32.2-34.8,57.5-50.5,75.3c-21.6,24.5-35.1,28.1-41.1,28.1
            c-8.3,0-11.6-2.8-13.7-5.2c-17.2-20.5-4.6-93.1,5.6-151.4c13.2-75.8,25.6-147.4-2.4-180.8c-7-8.3-19.8-18.1-41.5-18.1
            c-43.8,0-77.6,49-105.4,99.5c0.5-4.8,0.9-9.5,1.3-14c3-36.5,5.1-102.6-14.3-135.9c-11.5-19.6-27.3-22.6-35.7-22.6
            c-20.3,0-52.4,15-101.4,138.9c-2.2,5.4-3.8,9.7-4.8,12.3c-1.7,4.4-3.4,9-5.2,13.6c-11.6,31.4-22.7,65.5-31.9,97
            c0.3-61.1,10.8-127.4,26.6-163.3c12.4-28,0.7-53.6-17.7-62.2c-9.3-4.3-20-4.1-29.2,0.5c-8.7,4.3-15.8,12-21.1,22.9
            c-40.6,83.5-43.9,249.6-19.6,331.3c4.4,15,14.6,25.3,28.1,28.3c2.6,0.6,5.2,0.9,7.8,0.9c11.2-0.1,21.9-5,29.3-13.5
            c9.9-11.1,13.3-26.5,9.3-41.9c2.7-28.4,23.7-108.3,51.5-184c7.3-20.1,14.8-38.8,22.1-55.5c13.9-30.6,28.4-55.7,37.8-67.5
            c1.3,17.2-2,50-4.4,73.3c-6.6,64.4-13.4,130.9,15.3,158.7c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.2,0.2,0.3,0.3
            c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.5,0.4c0.1,0.1,0.3,0.3,0.5,0.4c0.1,0.1,0.2,0.2,0.4,0.3
            c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.5,0.3,0.7,0.4c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.1,0.1c0.1,0,0.2,0.1,0.4,0.2
            c0.2,0.1,0.5,0.3,0.8,0.4c0.1,0,0.3,0.1,0.4,0.2c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0.1,0.3,0.1,0.5,0.2
            c0,0,0.1,0,0.2,0.1c0.3,0.1,0.6,0.2,0.9,0.2c0.2,0,0.4,0.1,0.6,0.2c0,0,0.1,0,0.1,0h0c0.3,0,0.5,0.1,0.8,0.1c0.2,0,0.4,0.1,0.7,0.1
            c0,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0c0.3,0,0.5,0,0.8,0h0.2c0.1,0,0.3,0,0.4,0h0c0.4,0,0.7,0,1.1,0l0.2,0c0.2,0,0.4,0,0.6-0.1
            c0.3,0,0.6-0.1,0.9-0.1l0.2,0c0.2,0,0.4-0.1,0.7-0.1c0.3-0.1,0.5-0.1,0.8-0.2c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.3-0.1,0.4-0.1
            c16.7-5.4,28.4-26.7,51-70.2c4-7.7,8.1-15.7,12.4-23.7c9.4-17.3,20-33.8,31.7-49.6c15.2-20,24.9-27,29.7-29.4
            c1,4.2,2.1,12.9,1.3,29.9c-0.9,19.8-3.8,44.9-7.1,71.4c-10.1,84.4-21.4,180.1,17.1,223.4c13.3,15,31.1,22.6,52.9,22.6
            c59.8,0,114.5-96.2,135.4-137.9c2.2-4.1,4.3-8.2,6.4-12.3c3.2,9.6,7.2,18.3,12.1,26.1c16.6,26.3,41.8,40.2,72.9,40.2
            c80.6,0,165.4-112.2,216-179.2l3.8-5.2C2474.4,2718.8,2472.8,2707.4,2464.8,2701.3z M2252,2693.5c16.5-41.5,31.5-50,34.6-50
            c2.7,0,3.1,0.5,3.3,0.9c2.9,4.6,1.8,16.4,0,21.6c-0.2,0.6-0.5,1.2-0.8,1.8c-7.1,15.4-23.1,30.4-42.5,40.2
            C2248.3,2703.1,2250.1,2698.2,2252,2693.5z M2165.5,2191.6c-2.4-11.3-4.8-22.5,0.2-35.4c6.8-5.9,13.9-8.1,20.1-9.9
            c54.6,20.1,108,39.9,162.6,60c1.2,5.6-5.6,11.5-11.8,18.7c-36.6-4.6-62.9-20.5-97.9-29.6c16.2,19.3,30.5,37.1,45.9,54.4
            c3.9,3.7,6.5,7.2,8.4,11.8c8.5,20.2,24.5,36.5,35.6,54.9c7.9,13,15.9,23.6,29.6,45.9c16.4,26.7,24.1,42.9,37.8,69
            c0,0,67.5,129.2,52.6,168.7c-2.2,5.8-6.8,8.5-6.8,8.5c-4.5,2.6-10.7,3.3-14.4,1.7c-5.1-2.1-4.8-7.9-4.8-9.3
            c0.2-19.4-37.1-132.7-71.9-187.9c-1.9-3-23.2-38.8-36.2-56.2c-7.9-10.5-13.8-21.8-20.6-32.8c-14.1-22.5-31.8-42.7-46.5-64.2
            c-12.7-19.2-26-37.4-48.7-55.6c8,22,7.3,42.2,10.7,61.5c1.7,12.3-0.8,25.6-5.6,39.1c-3.9,10.1-11.5,19.4-28.7,19.7
            c-4.4-10.5,3.2-22.8,2-33.8C2173.7,2258.1,2171.2,2224.6,2165.5,2191.6z"/>`
        },
        '4000': {
            viewBox: "0 0 5041.1 6289.6",
            x: "520.6",
            y: "113.9",
            framePath: `<path class="st0" d="M2836.2,5397.2c-9.2-3.2-19.2-2.6-27.9,1.7c-8.7,4.2-15.4,11.8-18.5,21c-15.1,43.7-66.3,170.3-128.4,282.7
            c-35.6,64.3-69.6,115-101.1,150.6c-43.3,48.9-70.2,56.3-82.3,56.3c-16.5,0-23.3-5.5-27.3-10.3c-34.4-40.9-9.2-186.1,11.1-302.8
            c26.4-151.6,51.3-294.8-4.8-361.6c-13.9-16.5-39.5-36.2-82.8-36.2c-75.1,0-141.2,65.1-201.4,198.5c-3.1,6.2-6.2,12.5-9.3,19.1
            c-1.5,3-2.5,6.2-3.1,9.5c-3.3,7.8-6.5,15.8-9.7,24c-21.8,57.1-40.7,115.2-56.7,174.2c-5.5-133.9,16.6-292.9,52.4-374
            c24.8-56,1.5-107.2-35.5-124.3c-18.5-8.6-40-8.2-58.3,0.9c-17.5,8.6-31.7,24.1-42.2,45.9c-39.5,81.3-61.3,201.5-67.8,322.5
            c-33.5,64-82.7,154.8-135,235.5l-2,3c-88.5,134.7-140,164.2-152.3,164.2c-3.3,0.3-6.4-1.3-8-4.1c-8.4-11.2-32.4-65.1,11.8-284.7
            c26.7-132.6,66.2-266.8,83-324.2c5.4-18.4,7.4-25,8.2-28.8c4-18.5-2.4-37.8-16.7-50.1c-21.8-19.9-62.5-28-92.6-18.4
            c-16.1,4.5-29.3,15.9-36.2,31.2c-1.4,2.6-2.4,5.4-3.2,8.3c-3.6,15.2-22.9,62.3-51.8,123.5c-4.8-44.6-16.6-87.1-39.2-115.3
            c-19.1-23.6-44.5-36.1-73.3-36.1c-67.8,0-128.9,62.3-176.7,180.2c-9.5,23.4-18,48.4-25.6,74c-32.9,53.1-73.4,118.3-118.4,182.9
            l-1.3,1.8c-109.5,155.5-220,256-281.5,256c-48.4,0-65.7-101.8-65.7-188.9c0-99.3,19.4-220.2,50.7-315.6
            c38.4-116.9,75.9-143.3,86.1-143.3c1.4,0,4.2,0,8.3,6.1c9.6,14.1,13.1,44.1,2.6,69.8c-0.1,0.4-0.2,0.7-0.3,1.1
            c-0.3,0.7-0.6,1.5-0.9,2.2c-0.1,0.4-0.2,0.9-0.3,1.3c-0.2,0.7-0.4,1.5-0.5,2.2c-0.1,0.5-0.2,1-0.3,1.6c-0.1,0.6-0.2,1.3-0.3,2
            c0,0.6,0,1.2,0,1.8c-0.1,0.6-0.1,1.1-0.1,1.7c0,0.6,0,1.3,0.1,1.9c0,0.5,0,1.1,0.1,1.6c0,0.6,0.1,1.3,0.2,2c0.1,0.5,0.2,1,0.3,1.5
            c0.1,0.6,0.3,1.2,0.4,1.8c0.2,0.5,0.3,1,0.4,1.6c0.2,0.5,0.4,1.1,0.6,1.7c0.2,0.5,0.4,1.1,0.6,1.7c0.3,0.5,0.5,1,0.7,1.5
            c0.3,0.5,0.5,1.1,0.8,1.7c0.3,0.5,0.6,0.9,0.8,1.4c0.4,0.5,0.7,1.1,1.1,1.7c0.2,0.4,0.6,0.8,0.9,1.3c0.3,0.5,0.7,1,1.2,1.6
            c0.3,0.4,0.7,0.8,1.1,1.3c0.4,0.4,0.8,0.9,1.2,1.3c0.4,0.4,1,0.9,1.5,1.3c0.4,0.4,0.7,0.7,1.2,1.1c0.5,0.5,1.1,0.9,1.7,1.4
            c0.4,0.3,0.8,0.6,1.2,0.8c0.6,0.4,1.3,0.8,2,1.2c0.3,0.2,0.7,0.4,1,0.6c0.1,0,0.1,0,0.2,0c0.6,0.3,1.2,0.6,1.7,0.8
            c0.6,0.3,1.2,0.6,1.8,0.8c0.1,0,0.1,0,0.2,0c0.4,0.2,0.8,0.3,1.2,0.4c0.7,0.2,1.4,0.5,2.1,0.7c0.5,0.1,1,0.2,1.5,0.3
            c0.7,0.2,1.4,0.4,2.1,0.5c0.6,0.1,1.1,0.1,1.7,0.2c0.6,0.1,1.2,0.2,1.9,0.2c0.6,0,1.2,0.1,1.9,0.1c0.5,0,1.1,0,1.6,0
            c0.7,0,1.4,0,2.1,0c0.5,0,1,0,1.5-0.1c0.7,0,1.4-0.2,2.1-0.3c0.5,0,1-0.1,1.4-0.2c0.7-0.1,1.4-0.3,2-0.5c0.5-0.1,1-0.2,1.5-0.3
            c0.6-0.2,1.2-0.4,1.8-0.6c0.5-0.1,1.1-0.3,1.6-0.5c0.5-0.2,1.1-0.5,1.6-0.7c0.5-0.2,1.1-0.5,1.6-0.8c0.5-0.2,1-0.5,1.5-0.8
            c0.5-0.3,1.1-0.6,1.6-1c0.5-0.3,0.9-0.6,1.4-0.9c0.4-0.3,1-0.7,1.5-1.1c0.5-0.3,0.9-0.7,1.3-1.1c0.5-0.4,0.9-0.8,1.4-1.2
            c0.5-0.4,0.9-0.9,1.4-1.5c0.3-0.3,0.7-0.7,1-1.1c0.5-0.6,1-1.2,1.4-1.8c0.3-0.3,0.6-0.7,0.8-1.1c0.5-0.6,0.9-1.3,1.3-2
            c0.2-0.3,0.4-0.7,0.6-1c0.1,0,0.1-0.1,0.1-0.2c0.2-0.3,0.3-0.5,0.5-0.8c23-42.8,21.8-104.1-3.2-146.3
            c-20.7-35.1-55.2-54.4-97.2-54.4c-80.2,0-152.9,61.3-205,172.6c-12.6,27-23.6,56.6-33,87.7c-119.3,42.2-221.2,103.1-302.9,181.5
            c-7.7,7.3-15.2,14.8-22.4,22.5c-28.4-47.6-51.4-94.5-56.9-145c-10.9-99.9,29.6-212.2,75.1-263c15.5-17.2,31.2-27.1,43.3-27.1
            c35.4,0,64.1,62,64.1,102.5c0,0.3,0,0.5,0,0.8c0,0.7,0.1,1.3,0.1,2c0.1,0.6,0.1,1.1,0.2,1.7c0.1,0.6,0.2,1.3,0.3,1.9
            c0.1,0.5,0.2,1.1,0.3,1.7c0.2,0.5,0.3,1.2,0.5,1.8c0.2,0.6,0.3,1.1,0.5,1.7c0.2,0.5,0.4,1.1,0.6,1.6c0.3,0.6,0.5,1.1,0.7,1.7
            c0.3,0.5,0.5,1,0.8,1.5c0.2,0.5,0.5,1.1,0.9,1.6c0.3,0.5,0.5,1,0.8,1.4c0.3,0.5,0.7,1,1.1,1.5c0.3,0.5,0.6,0.9,1,1.4
            c0.3,0.4,0.7,0.9,1.1,1.4c0.4,0.4,0.8,0.9,1.2,1.3c0.4,0.4,0.8,0.8,1.3,1.2c0.4,0.4,0.8,0.8,1.3,1.2c0.4,0.4,0.9,0.7,1.3,1.1
            c0.5,0.3,1,0.7,1.5,1.1c0.5,0.4,0.9,0.6,1.4,0.9c0.5,0.3,1.1,0.7,1.6,1c0.5,0.3,1,0.5,1.5,0.8c0.5,0.2,1.1,0.5,1.7,0.7
            c0.5,0.2,1,0.5,1.6,0.7c0.6,0.2,1.1,0.4,1.7,0.6c0.6,0.2,1.2,0.4,1.8,0.5c0.6,0.2,1.1,0.3,1.7,0.4c0.6,0.1,1.2,0.3,1.9,0.4
            c0.6,0.1,1.1,0.2,1.7,0.3c0.7,0,1.3,0.1,2,0.2c0.3,0,0.5,0,0.8,0.1c0.3,0,0.7,0,1,0c0.4,0,0.7,0,1.1,0c0.3,0,0.6,0,0.9,0
            c0.6,0,1.2,0,1.8-0.1c0.7,0,1.3-0.1,2-0.2c0.5,0,1.1-0.1,1.6-0.2c0.7-0.1,1.4-0.2,2.1-0.4c0.4-0.1,0.9-0.2,1.4-0.3
            c0.7-0.2,1.4-0.4,2.1-0.6c0.4-0.1,0.9-0.3,1.3-0.5c0.7-0.2,1.4-0.5,2.1-0.8c0.4-0.2,0.8-0.3,1.1-0.5c0.7-0.3,1.4-0.7,2.1-1.1
            c0.3-0.2,0.7-0.4,1.1-0.6c0.6-0.4,1.2-0.8,1.8-1.2c0.4-0.2,0.8-0.5,1.2-0.8c0.5-0.4,1.1-0.9,1.6-1.3c0.4-0.3,0.8-0.6,1.1-1
            c0.5-0.4,1-0.9,1.5-1.4c0.3-0.4,0.7-0.8,1.1-1.2c0.4-0.5,0.8-0.9,1.2-1.4c0.4-0.5,0.7-0.9,1-1.4c0.3-0.4,0.7-0.9,1-1.4
            c0.4-0.5,0.7-1,1-1.5c0.3-0.5,0.5-1,0.8-1.5c0.3-0.5,0.5-1.1,0.8-1.6c0.3-0.5,0.5-1.1,0.7-1.6c0.2-0.5,0.4-1.1,0.6-1.6
            c0.2-0.6,0.4-1.2,0.6-1.8c0.2-0.6,0.3-1.1,0.4-1.7c0.2-0.6,0.3-1.2,0.4-1.9c0.1-0.6,0.2-1.1,0.3-1.7c0.1-0.6,0.2-1.3,0.2-1.9
            c0-0.3,0.1-0.5,0.1-0.8c2.8-52.4-15.8-103.6-51.4-142.1c-28.9-31.6-69.9-49.5-112.7-49.3c-54.6,0-107.6,27.4-153.3,79.4
            c-72.4,82.1-111.7,209.6-98.1,317.2c15.1,118.1,62,203.8,109.4,273.1c-29.9,55.4-52.3,114.5-66.8,175.8
            c-19.2,79.9-10.2,151.1,24.8,195.3c21.3,27.4,53.7,43.5,88.4,44.1c5.9,0.9,11.9,1.4,17.8,1.4c36.4,0,75-16.4,108.8-46.8
            c53.9-48.8,82.2-119.8,76.7-192.3c-8.2-104.5-56.4-187.6-102.2-260.8c10.6-11.7,21.5-23.2,33-34.3
            c63.6-61.1,141.2-110.6,231.2-147.9c-9.5,50.3-14.9,101.9-14.9,151.7c0,88.1,15.6,164.1,45.1,219.8c36.7,69.3,93.6,106,164.4,106
            c81.7,0,171.5-60.5,274.4-185.1c38.9-47.1,75.7-98.4,109.4-148.6c-0.6,10.1-1,20-1,29.5c0,132,54.2,151.7,86.6,151.7
            c37.1,0,77-28.8,125.6-90.6c34.5-43.9,73.2-103.9,114.8-178.5c5.9-10.5,11.7-21,17.5-31.7c-2.2,14.4-4,27.7-5.6,39.5
            c-11.3,85.7-16.4,159.1-14.9,217.9c2.1,79,15.7,133.3,41.8,166.3c17.8,23.5,45.7,37.2,75.1,36.9c61,0,131.8-63.2,223.6-203.5
            c1.5-2.3,2.8-4.2,3.8-5.8l1.9-2.9c0.6-0.8,1.1-1.6,1.5-2.4c22.8-35.5,47-75.8,72.5-120.9c4,72.1,13.7,137.9,28.5,188
            c8.9,29.9,29.4,50.6,56.1,56.6c5.1,1.1,10.4,1.7,15.7,1.7c22.4-0.3,43.7-10.1,58.5-27c12.9-14.4,20.5-32.8,21.7-52.1
            c36.2-171.5,75.3-295.6,113.5-381.5c51.6-104.3,82.3-132.5,95.1-140.1c2.1,8.5,4.4,25.9,3.4,60.1c-1.4,39.9-6.8,90.5-12.6,144
            c-18.3,169.8-39.1,362.1,38.8,449c26.8,29.9,62.4,45,105.9,45c119.6,0,229-192.4,270.8-275.8c63.1-118.5,109.2-236.9,120.9-270.9
            c3.2-9.2,2.6-19.2-1.7-28C2852.8,5407,2845.3,5400.3,2836.2,5397.2z M310.5,6080.4c-12.3,15.9-31.5,25.1-51.6,25
            c-13.5,0.6-26.4-5.5-34.5-16.4c-20.3-25.8-24.6-76.8-11-133.1c10.3-43.3,24.9-85.6,43.8-126c34.2,46.8,63,88.2,72.1,131.9
            C339.4,6010,332.5,6053.3,310.5,6080.4z M1475.7,5456.1c-1.4,15.7-3.7,31.3-6.9,46.7c-0.1,0.3-0.1,0.7-0.2,1.1
            c-0.1,0.4-0.2,0.9-0.2,1.3c-0.1,0.2-0.1,0.4-0.1,0.6c-1.9,3.3-3.6,6.5-5.4,9.8c-17.1,30.6-32.8,57.2-47.1,80.3
            c-0.2,0.3-0.5,0.7-0.7,1c-38.6,62.8-85,116.2-107.7,133.8c-3.8-18.4-5.7-61.5,10.2-140.5c16.3-81,45.6-168.9,76.6-229.5
            c26.9-52.9,47.7-70.9,56.5-75.4c6,8.7,17.3,34.1,23.1,86.8C1476.8,5399.9,1477.5,5428,1475.7,5456.1z M4929.5,5402.6
            c-7.7-5.8-17.5-8.4-27-7.1c-9.6,1.3-18.2,6.4-24.1,14.2l-7.8,10.4c-42.4,56.1-108.9,144.1-180.6,215.1
            c-55.6,53.6-125.1,106.2-171.1,106.2c-13.4,0-28.8-3.4-41.7-28.4c-11.7-22.8-17.9-57.5-17.9-100.3c0.3-36.9,4.1-73.6,11.3-109.8
            c78-21.2,145.2-76.2,173.2-135l0.1,0c0-0.1,0-0.2,0.1-0.2c0.1-0.4,0.3-0.7,0.5-1.1c0.1-0.2,0.2-0.5,0.4-0.8c0.6-1.3,1.2-2.8,1.9-4.3
            c16.8-40.3,14.6-86.3-6.1-118.7c-18.2-28.5-48.8-44.3-86.1-44.3c-71.1,0-136,46.2-182.7,130.1c-36,64.5-58.3,148-61.4,227
            c-20.9,46.4-45.9,98.1-73,147.1c-35.6,64.3-69.6,115-101.1,150.6c-43.3,48.9-70.1,56.3-82.2,56.3c-16.6,0-23.3-5.5-27.3-10.3
            c-34.4-40.9-9.2-186.1,11.1-302.8c26.3-151.6,51.3-294.8-4.8-361.6c-13.9-16.5-39.5-36.2-82.9-36.2c-87.7,0-155.2,98-210.7,199
            c1-9.6,1.9-19,2.7-28c6-72.9,10.3-205.3-28.7-271.8c-23.1-39.3-54.6-45.2-71.5-45.2c-40.5,0-104.8,30.1-202.8,277.9
            c-4.4,10.8-7.7,19.4-9.6,24.7c-3.5,8.8-6.8,17.9-10.4,27.3c-23.2,62.7-45.4,130.9-63.7,193.9c0.6-122.3,21.6-254.9,53.2-326.5
            c24.8-56.1,1.4-107.2-35.5-124.4c-18.6-8.6-40-8.2-58.4,0.9c-17.4,8.6-31.6,24.1-42.2,45.9c-81.2,167.1-87.8,499.2-39.1,662.6
            c8.9,29.9,29.3,50.5,56.1,56.6c5.1,1.2,10.4,1.7,15.6,1.7c22.5-0.2,43.8-10.1,58.6-26.9c19.8-22.2,26.5-53,18.6-83.8
            c5.3-56.8,47.4-216.7,102.9-368.1c14.7-40.3,29.6-77.6,44.2-111.1c27.7-61.1,56.9-111.3,75.6-135c2.7,34.3-4,100-8.9,146.7
            c-13.1,128.7-26.7,261.8,30.6,317.3c0.2,0.2,0.4,0.3,0.6,0.5c0.3,0.3,0.7,0.6,1,0.9c0.2,0.2,0.4,0.4,0.6,0.6
            c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.5,0.4,0.8,0.7c0.3,0.2,0.7,0.5,1,0.8c0.3,0.2,0.7,0.5,1.1,0.8c0.3,0.2,0.5,0.4,0.8,0.5
            c0.2,0.2,0.6,0.4,0.9,0.6c0.5,0.3,1,0.6,1.5,0.9c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0,0.2,0.1,0.3,0.1c0.3,0.1,0.5,0.2,0.8,0.3
            c0.5,0.3,1.1,0.6,1.7,0.9c0.3,0.1,0.6,0.2,0.8,0.3c0.2,0.1,0.4,0.1,0.6,0.2c0.6,0.2,1.3,0.5,1.9,0.7c0.3,0.1,0.6,0.2,1,0.3
            c0.1,0,0.3,0.1,0.5,0.1c0.6,0.1,1.2,0.3,1.8,0.4c0.4,0.1,0.8,0.2,1.2,0.3c0.1,0,0.2,0,0.3,0h0.1c0.6,0.1,1.1,0.1,1.7,0.2
            c0.4,0,0.9,0.1,1.4,0.2c0.1,0,0.3,0,0.4,0c0.5,0,1,0,1.5,0.1c0.6,0,1.1,0.1,1.7,0.1h0.4c0.3,0,0.6,0,0.8,0h0.1c0.8,0,1.5,0,2.3-0.1
            l0.4,0c0.4,0,0.8-0.1,1.3-0.1c0.6-0.1,1.3-0.1,1.9-0.3l0.5-0.1c0.4,0,0.9-0.2,1.4-0.3c0.6-0.1,1.1-0.3,1.7-0.4c0.1,0,0.2,0,0.3,0
            c0.1,0,0.1-0.1,0.2-0.1c0.3-0.1,0.6-0.2,0.8-0.2c33.4-10.8,56.9-53.5,102-140.5c8-15.4,16.3-31.4,24.8-47.4
            c18.7-34.6,39.9-67.7,63.4-99.2c30.5-40,49.9-54,59.5-58.9c2,8.5,4.1,25.8,2.7,59.7c-1.8,39.6-7.7,89.7-14.1,142.8
            c-20.1,168.8-42.9,360.1,34.1,446.8c26.6,30,62.2,45.2,105.8,45.2c119.6,0,229-192.4,270.8-275.8c4.4-8.2,8.7-16.4,12.9-24.6
            c6.4,19.2,14.4,36.7,24.1,52.2c33.1,52.7,83.5,80.5,145.8,80.5c161.2,0,330.7-224.4,432-358.5l7.7-10.3
            C4948.8,5437.6,4945.6,5414.7,4929.5,5402.6z M4503.9,5387.1c32.9-83,63-100,69.2-100c5.4,0,6.1,1.1,6.6,1.9
            c5.7,9.2,3.6,32.9,0.1,43.2c-0.5,1.2-1,2.4-1.6,3.6c-14.3,30.8-46.1,60.7-85,80.4C4496.6,5406.3,4500.2,5396.5,4503.9,5387.1z
             M4331,4383.3c-4.7-22.5-9.6-45.1,0.5-70.8c13.6-11.8,27.9-16.3,40.3-19.9c109.2,40.2,216,79.9,325.3,120
            c2.5,11.3-11.1,23.1-23.6,37.5c-73.3-9.2-125.8-40.9-195.8-59.1c32.3,38.6,61.1,74.1,91.8,108.9c7.7,7.5,13,14.5,16.8,23.7
            c16.9,40.4,49,73.1,71.3,109.7c15.8,26,31.8,47.1,59.2,91.8c32.8,53.4,48.2,85.8,75.5,138.1c0,0,135,258.4,105.2,337.4
            c-4.3,11.5-13.7,17-13.7,17c-8.9,5.2-21.4,6.5-28.9,3.4c-10.2-4.2-9.7-15.9-9.6-18.7c0.4-38.7-74.3-265.4-143.9-375.8
            c-3.7-5.9-46.5-77.7-72.4-112.4c-15.7-21-27.5-43.7-41.3-65.5c-28.1-45-63.5-85.4-93-128.3c-25.4-38.5-52.1-74.8-97.3-111.1
            c16.1,44,14.6,84.5,21.4,123c3.4,24.7-1.7,51.3-11.2,78.3c-7.8,20.1-23.1,38.8-57.5,39.4c-8.8-20.9,6.3-45.6,4-67.7
            C4347.3,4516.1,4342.4,4449.1,4331,4383.3z"/>`
        }
    },
    ArrowTop: {
        '300': {
            viewBox: "0 0 377.3 470.8",
            x: "42.3",
            y: "162.6",
            framePath: `<path class="st0" d="M215.9,34c-0.7-0.2-1.4-0.2-2.1,0.1s-1.2,0.9-1.4,1.6c-1.1,3.3-5,12.8-9.6,21.2c-2.7,4.8-5.2,8.6-7.6,11.3
            c-3.2,3.7-5.3,4.2-6.2,4.2c-1.2,0-1.7-0.4-2-0.8c-2.6-3.1-0.7-13.9,0.8-22.7c2-11.3,3.8-22.1-0.4-27.1c-1-1.2-3-2.7-6.2-2.7
            c-5.6,0-10.6,4.9-15.1,14.9c-0.2,0.5-0.5,0.9-0.7,1.4c-0.1,0.2-0.2,0.5-0.2,0.7c-0.2,0.6-0.5,1.2-0.7,1.8c-1.6,4.3-3,8.6-4.2,13
            c-0.4-10,1.2-21.9,3.9-28c1.9-4.2,0.1-8-2.7-9.3c-1.4-0.6-3-0.6-4.4,0.1c-1.3,0.6-2.4,1.8-3.2,3.4c-3,6.1-4.6,15.1-5.1,24.1
            c-2.5,4.8-6.2,11.6-10.1,17.6l-0.1,0.2c-6.6,10.1-10.5,12.3-11.4,12.3c-0.2,0-0.5-0.1-0.6-0.3c-0.6-0.8-2.4-4.9,0.9-21.3
            c2-9.9,5-20,6.2-24.3c0.4-1.4,0.6-1.9,0.6-2.2c0.3-1.4-0.2-2.8-1.2-3.8c-1.6-1.5-4.7-2.1-6.9-1.4c-1.2,0.3-2.2,1.2-2.7,2.3
            c-0.1,0.2-0.2,0.4-0.2,0.6c-0.3,1.1-1.7,4.7-3.9,9.2c-0.4-3.3-1.2-6.5-2.9-8.6c-1.4-1.8-3.3-2.7-5.5-2.7c-5.1,0-9.6,4.7-13.2,13.5
            c-0.7,1.7-1.3,3.6-1.9,5.5c-2.5,4-5.5,8.9-8.9,13.7L87,52c-8.2,11.6-16.5,19.2-21.1,19.2c-3.6,0-4.9-7.6-4.9-14.1
            c0-7.4,1.5-16.5,3.8-23.6c2.9-8.8,5.7-10.7,6.4-10.7c0.1,0,0.3,0,0.6,0.5c0.7,1.1,1,3.3,0.2,5.2c0,0,0,0.1,0,0.1
            c0,0.1,0,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
            c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
            c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1
            c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
            c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0
            c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
            c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
            c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1
            c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
            c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c1.7-3.2,1.6-7.8-0.2-10.9
            c-1.5-2.6-4.1-4.1-7.3-4.1c-6,0-11.4,4.6-15.3,12.9c-0.9,2-1.8,4.2-2.5,6.6c-8.9,3.2-16.6,7.7-22.7,13.6c-0.6,0.5-1.1,1.1-1.7,1.7
            c-2.1-3.6-3.8-7.1-4.3-10.8c-0.8-7.5,2.2-15.9,5.6-19.7c1.2-1.3,2.3-2,3.2-2c2.6,0,4.8,4.6,4.8,7.7c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.1
            c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1
            c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
            s0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1
            c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
            c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
            c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1
            c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1
            c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1s0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
            c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1s0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
            c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0.2-3.9-1.2-7.8-3.8-10.6c-2.2-2.4-5.2-3.7-8.4-3.7
            c-4.1,0-8.1,2.1-11.5,5.9C13,23.4,10.1,32.9,11.1,41c1.1,8.8,4.6,15.3,8.2,20.4c-2.2,4.1-3.9,8.6-5,13.2c-1.4,6-0.8,11.3,1.9,14.6
            c1.6,2,4,3.3,6.6,3.3c0.4,0.1,0.9,0.1,1.3,0.1c2.7,0,5.6-1.2,8.1-3.5c4-3.7,6.2-9,5.7-14.4c-0.6-7.8-4.2-14-7.6-19.5
            c0.8-0.9,1.6-1.7,2.5-2.6c4.8-4.6,10.6-8.3,17.3-11.1c-0.7,3.8-1.1,7.6-1.1,11.4c0,6.6,1.2,12.3,3.4,16.5c2.7,5.2,7,7.9,12.3,7.9
            c6.1,0,12.8-4.5,20.5-13.9c2.9-3.5,5.7-7.4,8.2-11.1c0,0.8-0.1,1.5-0.1,2.2c0,9.9,4.1,11.4,6.5,11.4c2.8,0,5.8-2.2,9.4-6.8
            c2.6-3.3,5.5-7.8,8.6-13.4c0.4-0.8,0.9-1.6,1.3-2.4c-0.2,1.1-0.3,2.1-0.4,3c-0.8,6.4-1.2,11.9-1.1,16.3c0.2,5.9,1.2,10,3.1,12.4
            c1.3,1.8,3.4,2.8,5.6,2.8c4.6,0,9.9-4.7,16.7-15.2c0.1-0.2,0.2-0.3,0.3-0.4l0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c1.7-2.7,3.5-5.7,5.4-9.1
            c0.3,5.4,1,10.3,2.1,14.1c0.7,2.2,2.2,3.8,4.2,4.2c0.4,0.1,0.8,0.1,1.2,0.1c1.7,0,3.3-0.8,4.4-2c1-1.1,1.5-2.5,1.6-3.9
            c2.7-12.8,5.6-22.1,8.5-28.6c3.9-7.8,6.2-9.9,7.1-10.5c0.2,0.6,0.3,1.9,0.3,4.5c-0.1,3-0.5,6.8-0.9,10.8
            c-1.4,12.7-2.9,27.1,2.9,33.6c2,2.2,4.7,3.4,7.9,3.4c9,0,17.1-14.4,20.3-20.6c4.7-8.9,8.2-17.7,9-20.3c0.2-0.7,0.2-1.4-0.1-2.1
            C217.2,34.8,216.6,34.3,215.9,34z M26.9,85.2C26,86.4,24.5,87,23,87c-1,0-2-0.4-2.6-1.2c-1.5-1.9-1.8-5.7-0.8-10
            c0.8-3.2,1.9-6.4,3.3-9.4c2.6,3.5,4.7,6.6,5.4,9.9C29.1,79.9,28.5,83.1,26.9,85.2z M114.1,38.4c-0.1,1.2-0.3,2.3-0.5,3.5
            c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.1,0.2-0.3,0.5-0.4,0.7c-1.3,2.3-2.5,4.3-3.5,6c0,0,0,0.1-0.1,0.1
            c-2.9,4.7-6.4,8.7-8.1,10c-0.3-1.4-0.4-4.6,0.8-10.5c1.2-6.1,3.4-12.6,5.7-17.2c2-4,3.6-5.3,4.2-5.6c0.5,0.6,1.3,2.6,1.7,6.5
            C114.2,34.2,114.2,36.3,114.1,38.4z M372.6,34.4c-0.6-0.4-1.3-0.6-2-0.5c-0.7,0.1-1.4,0.5-1.8,1.1l-0.6,0.8
            c-3.2,4.2-8.1,10.8-13.5,16.1c-4.2,4-9.4,7.9-12.8,7.9c-1,0-2.2-0.3-3.1-2.1c-0.9-1.7-1.3-4.3-1.3-7.5c0-2.8,0.3-5.5,0.8-8.2
            c5.8-1.6,10.9-5.7,13-10.1l0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.3c1.3-3,1.1-6.5-0.5-8.9
            c-1.4-2.1-3.7-3.3-6.4-3.3c-5.3,0-10.2,3.5-13.7,9.7c-2.7,4.8-4.4,11.1-4.6,17c-1.6,3.5-3.4,7.3-5.5,11c-2.7,4.8-5.2,8.6-7.6,11.3
            c-3.2,3.7-5.2,4.2-6.2,4.2c-1.2,0-1.7-0.4-2-0.8c-2.6-3.1-0.7-13.9,0.8-22.7c2-11.3,3.8-22.1-0.4-27.1c-1-1.2-3-2.7-6.2-2.7
            c-6.6,0-11.6,7.3-15.8,14.9c0.1-0.7,0.1-1.4,0.2-2.1c0.5-5.5,0.8-15.4-2.1-20.3c-1.7-2.9-4.1-3.4-5.4-3.4c-3,0-7.8,2.3-15.2,20.8
            c-0.3,0.8-0.6,1.5-0.7,1.8c-0.3,0.7-0.5,1.3-0.8,2c-1.7,4.7-3.4,9.8-4.8,14.5c0-9.2,1.6-19.1,4-24.4c1.9-4.2,0.1-8-2.7-9.3
            c-1.4-0.6-3-0.6-4.4,0.1c-1.3,0.6-2.4,1.8-3.2,3.4c-6.1,12.5-6.6,37.4-2.9,49.6c0.7,2.2,2.2,3.8,4.2,4.2c0.4,0.1,0.8,0.1,1.2,0.1
            c1.7,0,3.3-0.8,4.4-2c1.5-1.7,2-4,1.4-6.3c0.4-4.3,3.6-16.2,7.7-27.5c1.1-3,2.2-5.8,3.3-8.3c2.1-4.6,4.3-8.3,5.7-10.1
            c0.2,2.6-0.3,7.5-0.7,11c-1,9.6-2,19.6,2.3,23.8c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0.1
            c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0
            c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0s0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
            c0,0,0,0,0,0h0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0h0c0,0,0,0,0.1,0h0c0.1,0,0.1,0,0.2,0
            l0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0
            c2.5-0.8,4.3-4,7.6-10.5c0.6-1.2,1.2-2.3,1.9-3.6c1.4-2.6,3-5.1,4.7-7.4c2.3-3,3.7-4,4.5-4.4c0.1,0.6,0.3,1.9,0.2,4.5
            c-0.1,3-0.6,6.7-1.1,10.7c-1.5,12.6-3.2,27,2.6,33.4c2,2.2,4.7,3.4,7.9,3.4c9,0,17.1-14.4,20.3-20.6c0.3-0.6,0.6-1.2,1-1.8
            c0.5,1.4,1.1,2.7,1.8,3.9c2.5,3.9,6.3,6,10.9,6c12.1,0,24.8-16.8,32.3-26.8l0.6-0.8C374.1,37.1,373.8,35.3,372.6,34.4z M340.8,33.3
            c2.5-6.2,4.7-7.5,5.2-7.5c0.4,0,0.5,0.1,0.5,0.1c0.4,0.7,0.3,2.5,0,3.2c0,0.1-0.1,0.2-0.1,0.3c-1.1,2.3-3.5,4.5-6.4,6
            C340.2,34.7,340.5,34,340.8,33.3z M370.2,70.2c2.2,5.9-7.9,25.3-7.9,25.3c-2,3.9-3.2,6.3-5.7,10.3c-2.1,3.3-3.3,4.9-4.4,6.9
            c-1.7,2.7-4.1,5.2-5.3,8.2c-0.3,0.7-0.7,1.2-1.3,1.8c-2.3,2.6-4.5,5.3-6.9,8.1c5.2-1.4,9.2-3.7,14.7-4.4c0.9,1.1,1.9,2,1.8,2.8
            c-8.2,3-16.2,6-24.3,9c-0.9-0.3-2-0.6-3-1.5c-0.8-1.9-0.4-3.6,0-5.3c0.8-4.9,1.2-9.9,1.7-14.9c0.2-1.6-1-3.5-0.3-5.1
            c2.6,0,3.7,1.4,4.3,3c0.7,2,1.1,4,0.8,5.9c-0.5,2.9-0.4,5.9-1.6,9.2c3.4-2.7,5.4-5.4,7.3-8.3c2.2-3.2,4.9-6.2,7-9.6
            c1-1.6,1.9-3.3,3.1-4.9c1.9-2.6,5.1-8,5.4-8.4c5.2-8.3,10.8-25.2,10.8-28.1c0-0.2,0-1.1,0.7-1.4c0.6-0.2,1.5-0.1,2.2,0.3
            C369.2,68.9,369.9,69.3,370.2,70.2z"/>`
        },
        '500': {
            viewBox: "0 0 630.1 786.2",
            x: "71.2",
            y: "272",
            framePath: `<path class="st0" d="M360.6,56.8c-1.1-0.4-2.4-0.3-3.5,0.2c-1.1,0.5-1.9,1.5-2.3,2.6c-1.9,5.5-8.3,21.3-16,35.3
            c-4.4,8-8.7,14.4-12.6,18.8c-5.4,6.1-8.8,7-10.3,7c-2.1,0-2.9-0.7-3.4-1.3c-4.3-5.1-1.1-23.3,1.4-37.9c3.3-19,6.4-36.9-0.6-45.2
            c-1.7-2.1-4.9-4.5-10.4-4.5c-9.4,0-17.7,8.1-25.2,24.8c-0.4,0.8-0.8,1.6-1.2,2.4c-0.2,0.4-0.3,0.8-0.4,1.2c-0.4,1-0.8,2-1.2,3
            c-2.7,7.1-5.1,14.4-7.1,21.8c-0.7-16.7,2.1-36.6,6.5-46.7c3.1-7,0.2-13.4-4.4-15.5c-2.3-1.1-5-1-7.3,0.1c-2.2,1.1-4,3-5.3,5.7
            c-4.9,10.2-7.7,25.2-8.5,40.3c-4.2,8-10.3,19.4-16.9,29.4l-0.2,0.4c-11.1,16.8-17.5,20.5-19,20.5c-0.4,0-0.8-0.2-1-0.5
            c-1-1.4-4.1-8.1,1.5-35.6c3.3-16.6,8.3-33.4,10.4-40.5c0.7-2.3,0.9-3.1,1-3.6c0.5-2.3-0.3-4.7-2.1-6.3c-2.7-2.5-7.8-3.5-11.6-2.3
            c-2,0.6-3.7,2-4.5,3.9c-0.2,0.3-0.3,0.7-0.4,1c-0.5,1.9-2.9,7.8-6.5,15.4c-0.6-5.6-2.1-10.9-4.9-14.4c-2.4-3-5.6-4.5-9.2-4.5
            c-8.5,0-16.1,7.8-22.1,22.5c-1.2,2.9-2.3,6-3.2,9.3c-4.1,6.6-9.2,14.8-14.8,22.9l-0.2,0.2c-13.7,19.4-27.5,32-35.2,32
            c-6.1,0-8.2-12.7-8.2-23.6c0-12.4,2.4-27.5,6.3-39.4c4.8-14.6,9.5-17.9,10.8-17.9c0.2,0,0.5,0,1,0.8c1.2,1.8,1.6,5.5,0.3,8.7
            c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
            c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
            c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
            c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.2
            c0,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.2
            c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1
            c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
            c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2-0.1
            c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
            c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
            c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.2
            c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c2.9-5.3,2.7-13-0.4-18.3c-2.6-4.4-6.9-6.8-12.2-6.8c-10,0-19.1,7.7-25.6,21.6
            c-1.6,3.4-2.9,7.1-4.1,11c-14.9,5.3-27.6,12.9-37.9,22.7c-1,0.9-1.9,1.9-2.8,2.8c-3.5-5.9-6.4-11.8-7.1-18.1
            c-1.4-12.5,3.7-26.5,9.4-32.9c1.9-2.2,3.9-3.4,5.4-3.4c4.4,0,8,7.8,8,12.8c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
            c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
            c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
            c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.2,0.1
            c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1
            c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
            c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0
            c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1
            c0,0,0.1,0,0.1-0.1c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2
            c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2
            c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2
            c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
            c0,0,0-0.1,0-0.1c0.3-6.5-2-12.9-6.4-17.8c-3.6-4-8.7-6.2-14.1-6.2c-6.8,0-13.4,3.4-19.2,9.9c-9,10.3-14,26.2-12.3,39.6
            c1.9,14.8,7.8,25.5,13.7,34.1c-3.7,6.9-6.5,14.3-8.3,22c-2.4,10-1.3,18.9,3.1,24.4c2.7,3.4,6.7,5.4,11,5.5c0.7,0.1,1.5,0.2,2.2,0.2
            c4.5,0,9.4-2.1,13.6-5.9c6.7-6.1,10.3-15,9.6-24c-1-13.1-7.1-23.4-12.8-32.6c1.3-1.5,2.7-2.9,4.1-4.3c8-7.6,17.7-13.8,28.9-18.5
            c-1.2,6.3-1.9,12.7-1.9,19c0,11,1.9,20.5,5.6,27.5c4.6,8.7,11.7,13.2,20.5,13.2c10.2,0,21.4-7.6,34.3-23.1
            c4.9-5.9,9.5-12.3,13.7-18.6c-0.1,1.3-0.1,2.5-0.1,3.7c0,16.5,6.8,19,10.8,19c4.6,0,9.6-3.6,15.7-11.3c4.3-5.5,9.1-13,14.3-22.3
            c0.7-1.3,1.5-2.6,2.2-4c-0.3,1.8-0.5,3.5-0.7,4.9c-1.4,10.7-2,19.9-1.9,27.2c0.3,9.9,2,16.7,5.2,20.8c2.2,2.9,5.7,4.6,9.4,4.6
            c7.6,0,16.5-7.9,28-25.4c0.2-0.3,0.3-0.5,0.5-0.7l0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3c2.9-4.4,5.9-9.5,9.1-15.1
            c0.5,9,1.7,17.2,3.6,23.5c1.1,3.7,3.7,6.3,7,7.1c0.6,0.1,1.3,0.2,2,0.2c2.8,0,5.5-1.3,7.3-3.4c1.6-1.8,2.6-4.1,2.7-6.5
            c4.5-21.4,9.4-37,14.2-47.7c6.4-13,10.3-16.6,11.9-17.5c0.3,1.1,0.6,3.2,0.4,7.5c-0.2,5-0.9,11.3-1.6,18
            c-2.3,21.2-4.9,45.3,4.8,56.1c3.4,3.7,7.8,5.6,13.2,5.6c15,0,28.6-24,33.8-34.5c7.9-14.8,13.6-29.6,15.1-33.9
            c0.4-1.1,0.3-2.4-0.2-3.5C362.7,58.1,361.8,57.2,360.6,56.8z M44.9,142.2c-1.5,2-3.9,3.1-6.4,3.1c-1.7,0.1-3.3-0.7-4.3-2
            c-2.5-3.2-3.1-9.6-1.4-16.6c1.3-5.4,3.1-10.7,5.5-15.7c4.3,5.9,7.9,11,9,16.5C48.5,133.4,47.7,138.8,44.9,142.2z M190.6,64.2
            c-0.2,2-0.5,3.9-0.9,5.8c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c-0.2,0.4-0.5,0.8-0.7,1.2c-2.1,3.8-4.1,7.1-5.9,10
            c0,0-0.1,0.1-0.1,0.1c-4.8,7.8-10.6,14.5-13.5,16.7c-0.5-2.3-0.7-7.7,1.3-17.6c2-10.1,5.7-21.1,9.6-28.7c3.4-6.6,6-8.9,7.1-9.4
            c0.8,1.1,2.2,4.3,2.9,10.8C190.7,57.2,190.8,60.7,190.6,64.2z M622.3,57.5c-1-0.7-2.2-1-3.4-0.9c-1.2,0.2-2.3,0.8-3,1.8l-1,1.3
            c-5.3,7-13.6,18-22.6,26.9c-7,6.7-15.6,13.3-21.4,13.3c-1.7,0-3.6-0.4-5.2-3.5c-1.5-2.9-2.2-7.2-2.2-12.5c0-4.6,0.5-9.2,1.4-13.7
            c9.8-2.7,18.2-9.5,21.6-16.9l0,0c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0.1-0.2,0.1-0.3,0.2-0.5c2.1-5,1.8-10.8-0.8-14.8
            c-2.3-3.6-6.1-5.5-10.8-5.5c-8.9,0-17,5.8-22.8,16.3c-4.5,8.1-7.3,18.5-7.7,28.4c-2.6,5.8-5.7,12.3-9.1,18.4
            c-4.4,8-8.7,14.4-12.6,18.8c-5.4,6.1-8.8,7-10.3,7c-2.1,0-2.9-0.7-3.4-1.3c-4.3-5.1-1.1-23.3,1.4-37.9c3.3-19,6.4-36.9-0.6-45.2
            c-1.7-2.1-4.9-4.5-10.4-4.5c-11,0-19.4,12.2-26.3,24.9c0.1-1.2,0.2-2.4,0.3-3.5c0.8-9.1,1.3-25.7-3.6-34c-2.9-4.9-6.8-5.6-8.9-5.6
            c-5.1,0-13.1,3.8-25.4,34.7c-0.6,1.4-1,2.4-1.2,3.1c-0.4,1.1-0.9,2.2-1.3,3.4c-2.9,7.8-5.7,16.4-8,24.2c0.1-15.3,2.7-31.9,6.7-40.8
            c3.1-7,0.2-13.4-4.4-15.5c-2.3-1.1-5-1-7.3,0.1c-2.2,1.1-3.9,3-5.3,5.7c-10.1,20.9-11,62.4-4.9,82.8c1.1,3.7,3.7,6.3,7,7.1
            c0.6,0.1,1.3,0.2,1.9,0.2c2.8,0,5.5-1.3,7.3-3.4c2.5-2.8,3.3-6.6,2.3-10.5c0.7-7.1,5.9-27.1,12.9-46c1.8-5,3.7-9.7,5.5-13.9
            c3.5-7.6,7.1-13.9,9.5-16.9c0.3,4.3-0.5,12.5-1.1,18.3c-1.6,16.1-3.3,32.7,3.8,39.7c0,0,0,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1
            c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1
            c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
            c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0h0c0.1,0,0.1,0,0.2,0
            c0,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0h0c0,0,0.1,0,0.1,0h0c0.1,0,0.2,0,0.3,0l0,0c0,0,0.1,0,0.2,0
            c0.1,0,0.2,0,0.2,0l0.1,0c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c4.2-1.3,7.1-6.7,12.7-17.6
            c1-1.9,2-3.9,3.1-5.9c2.3-4.3,5-8.5,7.9-12.4c3.8-5,6.2-6.7,7.4-7.4c0.2,1.1,0.5,3.2,0.3,7.5c-0.2,4.9-1,11.2-1.8,17.9
            c-2.5,21.1-5.4,45,4.3,55.9c3.3,3.7,7.8,5.6,13.2,5.6c15,0,28.6-24,33.8-34.5c0.5-1,1.1-2.1,1.6-3.1c0.8,2.4,1.8,4.6,3,6.5
            c4.1,6.6,10.4,10.1,18.2,10.1c20.2,0,41.3-28.1,54-44.8l1-1.3C624.7,61.9,624.3,59,622.3,57.5z M569.1,55.6
            c4.1-10.4,7.9-12.5,8.7-12.5c0.7,0,0.8,0.1,0.8,0.2c0.7,1.2,0.5,4.1,0,5.4c-0.1,0.2-0.1,0.3-0.2,0.5c-1.8,3.9-5.8,7.6-10.6,10.1
            C568.2,58,568.6,56.7,569.1,55.6z M618.3,117.2c3.7,9.9-13.2,42.2-13.2,42.2c-3.4,6.5-5.3,10.6-9.4,17.3c-3.4,5.6-5.4,8.2-7.4,11.5
            c-2.8,4.6-6.8,8.7-8.9,13.7c-0.5,1.1-1.1,2-2.1,3c-3.8,4.3-7.4,8.8-11.5,13.6c8.7-2.3,15.3-6.2,24.5-7.4c1.6,1.8,3.3,3.3,2.9,4.7
            c-13.7,5-27,10-40.7,15c-1.6-0.4-3.3-1-5-2.5c-1.3-3.2-0.6-6-0.1-8.8c1.4-8.2,2-16.6,2.9-24.9c0.3-2.8-1.6-5.8-0.5-8.5
            c4.3,0.1,6.2,2.4,7.2,4.9c1.2,3.4,1.8,6.7,1.4,9.8c-0.9,4.8-0.7,9.9-2.7,15.4c5.7-4.5,9-9.1,12.2-13.9c3.7-5.4,8.1-10.4,11.6-16
            c1.7-2.7,3.2-5.6,5.2-8.2c3.2-4.3,8.6-13.3,9-14c8.7-13.8,18-42.1,18-47c0-0.4-0.1-1.8,1.2-2.3c0.9-0.4,2.5-0.2,3.6,0.4
            C616.6,115,617.7,115.7,618.3,117.2z"/>`
        },
        '1000': {
            viewBox: "0 0 1260.3 1572.4",
            x: "142.3",
            y: "543.9",
            framePath: `<path class="st0" d="M721.2,113.7c-2.3-0.8-4.8-0.6-7,0.4c-2.2,1.1-3.8,2.9-4.6,5.2c-3.8,10.9-16.6,42.6-32.1,70.7
            c-8.9,16.1-17.4,28.7-25.3,37.6c-10.8,12.2-17.6,14.1-20.6,14.1c-4.1,0-5.8-1.4-6.8-2.6c-8.6-10.2-2.3-46.5,2.8-75.7
            c6.6-37.9,12.8-73.7-1.2-90.4c-3.5-4.1-9.9-9.1-20.7-9.1c-18.8,0-35.3,16.3-50.3,49.6c-0.8,1.6-1.6,3.1-2.3,4.8
            c-0.4,0.8-0.6,1.6-0.8,2.4c-0.8,2-1.6,3.9-2.4,6c-5.4,14.3-10.2,28.8-14.2,43.5c-1.4-33.5,4.1-73.2,13.1-93.5
            c6.2-14,0.4-26.8-8.9-31.1c-4.6-2.1-10-2.1-14.6,0.2c-4.4,2.2-7.9,6-10.6,11.5c-9.9,20.3-15.3,50.4-16.9,80.6
            c-8.4,16-20.7,38.7-33.7,58.9l-0.5,0.7c-22.1,33.7-35,41.1-38.1,41.1c-0.8,0.1-1.6-0.3-2-1c-2.1-2.8-8.1-16.3,3-71.2
            c6.7-33.1,16.5-66.7,20.8-81c1.4-4.6,1.8-6.2,2-7.2c1-4.6-0.6-9.4-4.2-12.5c-5.4-5-15.6-7-23.1-4.6c-4,1.1-7.3,4-9,7.8
            c-0.3,0.7-0.6,1.3-0.8,2.1c-0.9,3.8-5.7,15.6-12.9,30.9C398,90.6,395.1,80,389.4,73c-4.8-5.9-11.1-9-18.3-9
            c-17,0-32.2,15.6-44.2,45.1c-2.4,5.8-4.5,12.1-6.4,18.5c-8.2,13.3-18.3,29.6-29.6,45.7l-0.3,0.4c-27.4,38.9-55,64-70.4,64
            c-12.1,0-16.4-25.4-16.4-47.2c0-24.8,4.9-55.1,12.7-78.9c9.6-29.2,19-35.8,21.5-35.8c0.3,0,1.1,0,2.1,1.5c2.4,3.5,3.3,11,0.6,17.4
            c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1,0,0.2-0.1,0.3c0,0.2-0.1,0.4-0.1,0.5c0,0.1,0,0.3-0.1,0.4c0,0.2,0,0.3-0.1,0.5
            c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0,0.4c0,0.2,0,0.3,0,0.5c0,0.1,0,0.3,0,0.4c0,0.2,0,0.3,0,0.5c0,0.1,0,0.2,0.1,0.4
            c0,0.1,0.1,0.3,0.1,0.5c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.4
            c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.3,0.3,0.4
            c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.4,0.3
            c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.5,0.3c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0,0,0c0.1,0.1,0.3,0.1,0.4,0.2
            c0.1,0.1,0.3,0.1,0.4,0.2c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0,0.2,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0.1
            c0.1,0,0.3,0,0.4,0.1c0.1,0,0.3,0,0.5,0.1c0.1,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0,0.4,0
            c0.2,0,0.3,0,0.5-0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.3-0.1,0.5-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1,0,0.3-0.1,0.4-0.1
            c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.3-0.2,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.2
            c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.2,0.2-0.3
            c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0
            c0-0.1,0.1-0.1,0.1-0.2c5.7-10.7,5.4-26-0.8-36.6c-5.2-8.8-13.8-13.6-24.3-13.6c-20,0-38.2,15.3-51.3,43.1
            c-3.2,6.7-5.9,14.2-8.2,21.9c-29.8,10.6-55.3,25.8-75.7,45.4c-1.9,1.8-3.8,3.7-5.6,5.6c-7.1-11.9-12.8-23.6-14.2-36.2
            c-2.7-25,7.4-53,18.8-65.8c3.9-4.3,7.8-6.8,10.8-6.8c8.9,0,16,15.5,16,25.6c0,0.1,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5c0,0.1,0,0.3,0,0.4
            c0,0.2,0,0.3,0.1,0.5c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.5c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4
            c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.2,0.3,0.3,0.4
            c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3
            c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.4,0.2
            c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1
            c0.1,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.4,0.1c0.2,0,0.3,0,0.5,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.3,0
            c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4-0.1c0.2,0,0.3-0.1,0.5-0.1c0.1,0,0.2-0.1,0.3-0.1
            c0.2,0,0.3-0.1,0.5-0.2c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.3-0.2,0.5-0.3
            c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2
            c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.4
            c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.3,0.1-0.4
            c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.3,0.1-0.5c0-0.1,0-0.3,0.1-0.4c0-0.2,0-0.3,0-0.5c0-0.1,0-0.1,0-0.2
            c0.7-13.1-3.9-25.9-12.8-35.5c-7.2-7.9-17.5-12.4-28.2-12.3c-13.7,0-26.9,6.9-38.3,19.8C43.6,78.1,33.7,110,37.1,136.9
            c3.8,29.5,15.5,50.9,27.3,68.3c-7.5,13.9-13.1,28.6-16.7,44c-4.8,20-2.5,37.8,6.2,48.8c5.3,6.8,13.4,10.9,22.1,11
            c1.5,0.2,3,0.3,4.5,0.3c9.1,0,18.8-4.1,27.2-11.7c13.5-12.2,20.6-30,19.2-48.1c-2-26.1-14.1-46.9-25.5-65.2c2.6-2.9,5.4-5.8,8.3-8.6
            c15.9-15.3,35.3-27.6,57.8-37c-2.4,12.6-3.7,25.5-3.7,37.9c0,22,3.9,41,11.3,54.9c9.2,17.3,23.4,26.5,41.1,26.5
            c20.4,0,42.9-15.1,68.6-46.3c9.7-11.8,18.9-24.6,27.4-37.1c-0.1,2.5-0.2,5-0.2,7.4c0,33,13.6,37.9,21.6,37.9
            c9.3,0,19.3-7.2,31.4-22.6c8.6-11,18.3-26,28.7-44.6c1.5-2.6,2.9-5.3,4.4-7.9c-0.5,3.6-1,6.9-1.4,9.9c-2.8,21.4-4.1,39.8-3.7,54.5
            c0.5,19.7,3.9,33.3,10.5,41.6c4.5,5.9,11.4,9.3,18.8,9.2c15.3,0,33-15.8,55.9-50.9c0.4-0.6,0.7-1,1-1.5l0.5-0.7
            c0.1-0.2,0.3-0.4,0.4-0.6c5.7-8.9,11.7-19,18.1-30.2c1,18,3.4,34.5,7.1,47c2.2,7.5,7.3,12.6,14,14.2c1.3,0.3,2.6,0.4,3.9,0.4
            c5.6-0.1,10.9-2.5,14.6-6.7c3.2-3.6,5.1-8.2,5.4-13c9-42.9,18.8-73.9,28.4-95.4c12.9-26.1,20.6-33.1,23.8-35c0.5,2.1,1.1,6.5,0.8,15
            c-0.3,10-1.7,22.6-3.2,36c-4.6,42.4-9.8,90.5,9.7,112.2c6.7,7.5,15.6,11.3,26.5,11.3c29.9,0,57.2-48.1,67.7-69
            c15.8-29.6,27.3-59.2,30.2-67.7c0.8-2.3,0.6-4.8-0.4-7C725.4,116.1,723.5,114.4,721.2,113.7z M89.8,284.5c-3.1,4-7.9,6.3-12.9,6.2
            c-3.4,0.1-6.6-1.4-8.6-4.1c-5.1-6.4-6.1-19.2-2.8-33.3c2.6-10.8,6.2-21.4,10.9-31.5c8.6,11.7,15.8,22.1,18,33
            C97,266.9,95.3,277.7,89.8,284.5z M381.1,128.4c-0.3,3.9-0.9,7.8-1.7,11.7c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1
            c-0.5,0.8-0.9,1.6-1.4,2.4c-4.3,7.7-8.2,14.3-11.8,20.1c0,0.1-0.1,0.2-0.2,0.3c-9.6,15.7-21.3,29-26.9,33.4
            c-1-4.6-1.4-15.4,2.5-35.1c4.1-20.3,11.4-42.2,19.2-57.4c6.7-13.2,11.9-17.7,14.1-18.9c1.5,2.2,4.3,8.5,5.8,21.7
            C381.4,114.3,381.6,121.4,381.1,128.4z M1244.6,115c-1.9-1.5-4.4-2.1-6.8-1.8c-2.4,0.3-4.6,1.6-6,3.5l-1.9,2.6
            c-10.6,14-27.2,36-45.1,53.8c-13.9,13.4-31.3,26.6-42.8,26.6c-3.4,0-7.2-0.9-10.4-7.1c-2.9-5.7-4.5-14.4-4.5-25.1
            c0.1-9.2,1-18.4,2.8-27.4c19.5-5.3,36.3-19.1,43.3-33.7l0,0c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0.1-0.2
            c0.1-0.3,0.3-0.7,0.5-1.1c4.2-10.1,3.6-21.6-1.5-29.7c-4.6-7.1-12.2-11.1-21.5-11.1c-17.8,0-34,11.5-45.7,32.5
            c-9,16.1-14.6,37-15.3,56.7c-5.2,11.6-11.5,24.5-18.3,36.8c-8.9,16.1-17.4,28.7-25.3,37.6c-10.8,12.2-17.5,14.1-20.6,14.1
            c-4.1,0-5.8-1.4-6.8-2.6c-8.6-10.2-2.3-46.5,2.8-75.7c6.6-37.9,12.8-73.7-1.2-90.4c-3.5-4.1-9.9-9.1-20.7-9.1
            c-21.9,0-38.8,24.5-52.7,49.7c0.2-2.4,0.5-4.7,0.7-7c1.5-18.2,2.6-51.3-7.2-67.9c-5.8-9.8-13.7-11.3-17.9-11.3
            c-10.1,0-26.2,7.5-50.7,69.5c-1.1,2.7-1.9,4.8-2.4,6.2c-0.9,2.2-1.7,4.5-2.6,6.8c-5.8,15.7-11.3,32.7-15.9,48.5
            c0.1-30.6,5.4-63.7,13.3-81.6c6.2-14,0.3-26.8-8.9-31.1c-4.7-2.1-10-2.1-14.6,0.2c-4.3,2.2-7.9,6-10.6,11.5
            c-20.3,41.8-21.9,124.8-9.8,165.7c2.2,7.5,7.3,12.6,14,14.1c1.3,0.3,2.6,0.4,3.9,0.4c5.6-0.1,10.9-2.5,14.6-6.7
            c5-5.5,6.6-13.3,4.7-21c1.3-14.2,11.9-54.2,25.7-92c3.7-10.1,7.4-19.4,11-27.8c6.9-15.3,14.2-27.8,18.9-33.7c0.7,8.6-1,25-2.2,36.7
            c-3.3,32.2-6.7,65.4,7.6,79.3c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0.1
            c0,0,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.2,0.1
            c0.1,0.1,0.2,0.1,0.4,0.2c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.1,0.1,0.2,0.1
            c0,0,0.1,0,0.1,0c0.1,0.1,0.3,0.1,0.5,0.2c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
            c0,0,0,0,0.1,0h0c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0h0.1
            c0.1,0,0.1,0,0.2,0h0c0.2,0,0.4,0,0.6,0l0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.5-0.1l0.1,0c0.1,0,0.2,0,0.3-0.1
            c0.1,0,0.3-0.1,0.4-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2-0.1c8.4-2.7,14.2-13.4,25.5-35.1c2-3.8,4.1-7.8,6.2-11.9
            c4.7-8.6,10-16.9,15.9-24.8c7.6-10,12.5-13.5,14.9-14.7c0.5,2.1,1,6.5,0.7,14.9c-0.4,9.9-1.9,22.4-3.5,35.7
            c-5,42.2-10.7,90,8.5,111.7c6.7,7.5,15.6,11.3,26.5,11.3c29.9,0,57.2-48.1,67.7-69c1.1-2.1,2.2-4.1,3.2-6.2c1.6,4.8,3.6,9.2,6,13
            c8.3,13.2,20.9,20.1,36.5,20.1c40.3,0,82.7-56.1,108-89.6l1.9-2.6C1249.4,123.8,1248.6,118,1244.6,115z M1138.2,111.1
            c8.2-20.8,15.8-25,17.3-25c1.4,0,1.5,0.3,1.7,0.5c1.4,2.3,0.9,8.2,0,10.8c-0.1,0.3-0.2,0.6-0.4,0.9c-3.6,7.7-11.5,15.2-21.2,20.1
            C1136.3,115.9,1137.2,113.5,1138.2,111.1z M1236.6,234.3c7.4,19.8-26.3,84.4-26.3,84.4c-6.8,13.1-10.7,21.2-18.9,34.5
            c-6.9,11.2-10.9,16.5-14.8,23c-5.6,9.2-13.6,17.3-17.8,27.4c-1,2.3-2.3,4-4.2,5.9c-7.7,8.7-14.9,17.6-23,27.2
            c17.5-4.5,30.6-12.5,49-14.8c3.1,3.6,6.5,6.5,5.9,9.4c-27.3,10-54,20-81.3,30c-3.1-0.9-6.7-2-10.1-5c-2.5-6.4-1.3-12.1-0.1-17.7
            c2.8-16.5,4.1-33.2,5.8-49.7c0.6-5.5-3.2-11.7-1-16.9c8.6,0.1,12.4,4.8,14.4,9.9c2.4,6.7,3.6,13.4,2.8,19.6
            c-1.7,9.6-1.3,19.8-5.3,30.8c11.3-9.1,18-18.2,24.3-27.8c7.4-10.7,16.2-20.8,23.3-32.1c3.4-5.5,6.4-11.1,10.3-16.4
            c6.5-8.7,17.2-26.6,18.1-28.1c17.4-27.6,36.1-84.3,36-93.9c0-0.7-0.1-3.6,2.4-4.7c1.9-0.8,5-0.5,7.2,0.9
            C1233.1,230.1,1235.5,231.4,1236.6,234.3z"/>`
        },
        '2000': {
            viewBox: "0 0 2520.6 3144.8",
            x: "284.7",
            y: "1087.9",
            framePath: `<path class="st0" d="M1442.5,227.3c-4.6-1.6-9.6-1.3-14,0.8c-4.3,2.1-7.7,5.9-9.3,10.5c-7.5,21.9-33.1,85.2-64.2,141.3
            c-17.8,32.2-34.8,57.5-50.5,75.3c-21.6,24.5-35.1,28.1-41.2,28.1c-8.2,0-11.6-2.8-13.7-5.2c-17.2-20.5-4.6-93.1,5.6-151.4
            c13.2-75.8,25.6-147.4-2.4-180.8c-7-8.3-19.8-18.1-41.4-18.1c-37.6,0-70.6,32.5-100.7,99.2c-1.5,3.1-3.1,6.3-4.6,9.6
            c-0.7,1.5-1.2,3.1-1.5,4.8c-1.6,3.9-3.3,7.9-4.8,12c-10.9,28.5-20.4,57.6-28.3,87.1c-2.8-67,8.3-146.4,26.2-187
            c12.4-28,0.7-53.6-17.7-62.2c-9.3-4.3-20-4.1-29.1,0.4c-8.7,4.3-15.8,12-21.1,22.9c-19.8,40.6-30.7,100.8-33.9,161.2
            c-16.7,32-41.4,77.4-67.5,117.7l-1,1.5c-44.2,67.3-70,82.1-76.2,82.1c-1.6,0.2-3.2-0.6-4-2.1c-4.2-5.6-16.2-32.5,5.9-142.4
            c13.4-66.3,33.1-133.4,41.5-162.1c2.7-9.2,3.7-12.5,4.1-14.4c2-9.3-1.2-18.9-8.3-25.1c-10.9-9.9-31.3-14-46.3-9.2
            c-8,2.3-14.6,8-18.1,15.6c-0.7,1.3-1.2,2.7-1.6,4.1c-1.8,7.6-11.4,31.1-25.9,61.8c-2.4-22.3-8.3-43.6-19.6-57.6
            c-9.6-11.8-22.2-18.1-36.6-18.1c-33.9,0-64.4,31.2-88.4,90.1c-4.7,11.7-9,24.2-12.8,37c-16.5,26.6-36.7,59.2-59.2,91.4l-0.6,0.9
            c-54.7,77.8-110,128-140.8,128c-24.2,0-32.8-50.9-32.8-94.4c0-49.6,9.7-110.1,25.3-157.8c19.2-58.5,38-71.6,43-71.6
            c0.7,0,2.1,0,4.1,3c4.8,7.1,6.6,22.1,1.3,34.9c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.3-0.3,0.7-0.4,1.1c0,0.2-0.1,0.4-0.1,0.7
            c-0.1,0.4-0.2,0.7-0.2,1.1c0,0.3-0.1,0.5-0.1,0.8c0,0.3-0.1,0.6-0.1,1c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,1
            c0,0.3,0,0.5,0,0.8c0,0.3,0,0.6,0.1,1c0,0.2,0.1,0.5,0.1,0.8c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.1,0.5,0.2,0.8
            c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.5,0.3,0.7c0.1,0.3,0.2,0.6,0.4,0.8c0.1,0.2,0.3,0.5,0.4,0.7
            c0.2,0.3,0.3,0.6,0.5,0.8c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.3,0.3,0.5,0.6,0.8c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.4,0.4,0.6,0.7
            c0.2,0.2,0.5,0.4,0.7,0.7c0.2,0.2,0.3,0.4,0.6,0.5c0.2,0.2,0.5,0.5,0.8,0.7c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0.2,0.6,0.4,1,0.6
            c0.1,0.1,0.3,0.2,0.5,0.3c0,0,0,0,0.1,0c0.3,0.1,0.6,0.3,0.8,0.4c0.3,0.1,0.6,0.3,0.9,0.4c0,0,0,0,0.1,0c0.2,0.1,0.4,0.1,0.6,0.2
            c0.3,0.1,0.7,0.3,1,0.4c0.2,0.1,0.5,0.1,0.7,0.2c0.3,0.1,0.7,0.2,1,0.3c0.3,0,0.5,0.1,0.8,0.1c0.3,0,0.6,0.1,0.9,0.1
            c0.3,0,0.6,0,0.9,0c0.2,0,0.5,0,0.8,0c0.3,0,0.7,0,1,0c0.2,0,0.5,0,0.7,0c0.3,0,0.7-0.1,1-0.1c0.2,0,0.5-0.1,0.7-0.1
            c0.3-0.1,0.7-0.2,1-0.2c0.2,0,0.5-0.1,0.7-0.2c0.3-0.1,0.6-0.2,0.9-0.3c0.2-0.1,0.5-0.2,0.8-0.3c0.2-0.1,0.5-0.2,0.8-0.4
            c0.2-0.1,0.5-0.2,0.8-0.4c0.2-0.1,0.5-0.3,0.7-0.4c0.2-0.1,0.5-0.3,0.8-0.5c0.2-0.2,0.4-0.3,0.7-0.5c0.2-0.2,0.5-0.4,0.7-0.6
            c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.4-0.4,0.7-0.6c0.2-0.2,0.4-0.5,0.7-0.7c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.3,0.5-0.6,0.7-0.9
            c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.3,0.4-0.7,0.6-1c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0-0.1,0-0.1c0.1-0.1,0.1-0.3,0.2-0.4
            c11.5-21.4,10.9-52.1-1.6-73.1c-10.4-17.5-27.6-27.2-48.6-27.2c-40.1,0-76.5,30.6-102.5,86.3c-6.3,13.5-11.8,28.3-16.5,43.8
            c-59.7,21.1-110.6,51.5-151.4,90.8c-3.8,3.7-7.6,7.4-11.2,11.2c-14.2-23.8-25.7-47.3-28.4-72.5c-5.5-49.9,14.8-106.1,37.6-131.5
            c7.7-8.6,15.6-13.5,21.6-13.5c17.7,0,32,31,32,51.2c0,0.1,0,0.3,0,0.4c0,0.3,0,0.7,0,1c0,0.3,0,0.6,0.1,0.9c0,0.3,0.1,0.6,0.1,1
            c0,0.3,0.1,0.6,0.1,0.8c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.6,0.3,0.8
            c0.1,0.3,0.2,0.5,0.4,0.8c0.1,0.3,0.2,0.6,0.4,0.8c0.1,0.3,0.2,0.5,0.4,0.7c0.1,0.2,0.3,0.5,0.5,0.8c0.1,0.2,0.3,0.5,0.5,0.7
            c0.1,0.2,0.3,0.5,0.5,0.7c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.4,0.6,0.5
            c0.2,0.2,0.5,0.4,0.7,0.5c0.2,0.2,0.4,0.3,0.7,0.5c0.2,0.2,0.5,0.3,0.8,0.5c0.2,0.1,0.5,0.3,0.7,0.4c0.2,0.1,0.5,0.2,0.8,0.4
            c0.2,0.1,0.5,0.2,0.8,0.4c0.3,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.6,0.2,0.9,0.3c0.3,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.6,0.1,0.9,0.2
            c0.3,0,0.5,0.1,0.8,0.1c0.3,0,0.6,0.1,1,0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4,0
            c0.3,0,0.6,0,0.9,0c0.3,0,0.6,0,1-0.1c0.2,0,0.5-0.1,0.8-0.1c0.3,0,0.7-0.1,1-0.2c0.2,0,0.4-0.1,0.7-0.2c0.3-0.1,0.7-0.2,1-0.3
            c0.2-0.1,0.4-0.1,0.6-0.2c0.3-0.1,0.7-0.3,1-0.4c0.2-0.1,0.4-0.2,0.5-0.3c0.3-0.2,0.7-0.3,1-0.5c0.1-0.1,0.3-0.2,0.5-0.3
            c0.3-0.2,0.6-0.4,0.9-0.6c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.2,0.5-0.4,0.8-0.7c0.2-0.2,0.4-0.3,0.5-0.5c0.2-0.2,0.5-0.5,0.7-0.7
            c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.4-0.5,0.6-0.7c0.2-0.2,0.3-0.4,0.5-0.7c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.2,0.3-0.5,0.5-0.8
            c0.1-0.2,0.2-0.5,0.4-0.8c0.1-0.3,0.2-0.5,0.4-0.8c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.6,0.3-0.9
            c0.1-0.3,0.1-0.6,0.2-0.8c0.1-0.3,0.1-0.6,0.2-0.9c0-0.3,0.1-0.6,0.1-0.9c0-0.3,0.1-0.6,0.1-1c0-0.1,0-0.3,0-0.4
            c1.4-26.2-7.9-51.8-25.7-71c-14.4-15.8-35-24.8-56.4-24.7c-27.3,0-53.8,13.7-76.7,39.7c-36.2,41-55.9,104.8-49.1,158.6
            c7.5,59.1,31,101.9,54.7,136.5c-14.9,27.7-26.1,57.3-33.4,87.9c-9.6,39.9-5.1,75.6,12.4,97.7c10.6,13.7,26.9,21.8,44.2,22.1
            c3,0.4,6,0.7,8.9,0.7c18.2,0,37.5-8.2,54.4-23.4c27-24.4,41.1-59.9,38.4-96.1c-4.1-52.3-28.2-93.8-51.1-130.4
            c5.3-5.9,10.7-11.6,16.5-17.1c31.8-30.5,70.6-55.3,115.6-74c-4.7,25.1-7.4,51-7.4,75.8c0,44.1,7.8,82.1,22.5,109.9
            c18.3,34.7,46.8,53,82.2,53c40.9,0,85.7-30.3,137.2-92.6c19.5-23.5,37.9-49.2,54.7-74.3c-0.3,5-0.5,10-0.5,14.7
            c0,66,27.1,75.9,43.3,75.9c18.5,0,38.5-14.4,62.8-45.3c17.3-21.9,36.6-52,57.4-89.2c3-5.2,5.9-10.5,8.7-15.9
            c-1.1,7.2-2,13.8-2.8,19.7c-5.7,42.9-8.2,79.5-7.4,108.9c1,39.5,7.8,66.7,20.9,83.1c8.9,11.7,22.8,18.6,37.6,18.5
            c30.5,0,65.9-31.6,111.8-101.8c0.7-1.1,1.4-2.1,1.9-2.9l0.9-1.5c0.3-0.4,0.5-0.8,0.7-1.2c11.4-17.7,23.5-37.9,36.3-60.5
            c2,36,6.8,69,14.3,94c4.4,15,14.7,25.3,28.1,28.3c2.6,0.6,5.2,0.9,7.8,0.9c11.2-0.1,21.8-5.1,29.2-13.5c6.5-7.2,10.3-16.4,10.8-26.1
            c18.1-85.7,37.7-147.8,56.7-190.8c25.8-52.2,41.2-66.2,47.5-70c1,4.3,2.2,13,1.7,30c-0.7,19.9-3.4,45.2-6.3,72
            c-9.2,84.9-19.6,181,19.4,224.5c13.4,14.9,31.2,22.5,53,22.5c59.8,0,114.5-96.2,135.4-137.9c31.6-59.2,54.6-118.4,60.4-135.5
            c1.6-4.6,1.3-9.6-0.8-14C1450.8,232.2,1447,228.9,1442.5,227.3z M179.6,568.9c-6.2,8-15.7,12.6-25.8,12.5
            c-6.8,0.3-13.2-2.8-17.3-8.2c-10.2-12.9-12.3-38.4-5.5-66.6c5.1-21.7,12.5-42.8,21.9-63c17.1,23.4,31.5,44.1,36,65.9
            C194.1,533.7,190.6,555.4,179.6,568.9z M762.3,256.7c-0.7,7.8-1.9,15.6-3.5,23.3c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.1,0.7
            c0,0.1,0,0.2,0,0.3c-0.9,1.6-1.8,3.3-2.7,4.9c-8.5,15.3-16.4,28.6-23.6,40.2c-0.1,0.2-0.2,0.3-0.3,0.5
            c-19.3,31.4-42.5,58.1-53.8,66.9c-1.9-9.2-2.9-30.8,5.1-70.3c8.1-40.5,22.8-84.5,38.3-114.8c13.5-26.4,23.9-35.4,28.3-37.7
            c3,4.3,8.6,17.1,11.5,43.4C762.8,228.7,763.1,242.7,762.3,256.7z M2489.1,230c-3.8-2.9-8.7-4.2-13.5-3.5c-4.8,0.7-9.1,3.2-12,7.1
            l-3.9,5.2c-21.2,28.1-54.4,72.1-90.3,107.5c-27.8,26.8-62.6,53.1-85.5,53.1c-6.7,0-14.4-1.7-20.9-14.2c-5.9-11.4-9-28.7-9-50.1
            c0.1-18.4,2.1-36.8,5.7-54.9c39-10.6,72.6-38.1,86.6-67.5l0,0c0,0,0-0.1,0-0.1c0-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.3,0.2-0.4
            c0.3-0.7,0.6-1.4,0.9-2.2c8.4-20.2,7.3-43.2-3.1-59.4c-9.1-14.3-24.4-22.1-43-22.1c-35.5,0-68,23.1-91.4,65
            c-18,32.2-29.1,74-30.7,113.5c-10.4,23.2-23,49-36.5,73.5c-17.8,32.2-34.8,57.5-50.5,75.3c-21.6,24.5-35.1,28.1-41.1,28.1
            c-8.3,0-11.6-2.8-13.7-5.2c-17.2-20.5-4.6-93.1,5.6-151.4c13.2-75.8,25.6-147.4-2.4-180.8c-7-8.3-19.8-18.1-41.5-18.1
            c-43.8,0-77.6,49-105.4,99.5c0.5-4.8,0.9-9.5,1.3-14c3-36.5,5.1-102.6-14.3-135.9c-11.5-19.6-27.3-22.6-35.7-22.6
            c-20.3,0-52.4,15-101.4,138.9c-2.2,5.4-3.8,9.7-4.8,12.3c-1.7,4.4-3.4,9-5.2,13.6c-11.6,31.4-22.7,65.5-31.9,97
            c0.3-61.1,10.8-127.4,26.6-163.3c12.4-28,0.7-53.6-17.7-62.2c-9.3-4.3-20-4.1-29.2,0.5c-8.7,4.3-15.8,12-21.1,22.9
            c-40.6,83.5-43.9,249.6-19.6,331.3c4.4,15,14.6,25.3,28.1,28.3c2.6,0.6,5.2,0.9,7.8,0.9c11.2-0.1,21.9-5,29.3-13.5
            c9.9-11.1,13.3-26.5,9.3-41.9c2.7-28.4,23.7-108.3,51.5-184c7.3-20.1,14.8-38.8,22.1-55.5c13.9-30.6,28.4-55.7,37.8-67.5
            c1.3,17.2-2,50-4.4,73.3c-6.6,64.4-13.4,130.9,15.3,158.7c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.2,0.2,0.3,0.3
            c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.5,0.4c0.1,0.1,0.3,0.3,0.5,0.4c0.1,0.1,0.2,0.2,0.4,0.3
            c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.5,0.3,0.7,0.4c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.1,0.1c0.1,0,0.2,0.1,0.4,0.2
            c0.2,0.1,0.5,0.3,0.8,0.4c0.1,0,0.3,0.1,0.4,0.2c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0.1,0.3,0.1,0.5,0.2
            c0,0,0.1,0,0.2,0.1c0.3,0.1,0.6,0.2,0.9,0.2c0.2,0,0.4,0.1,0.6,0.2c0,0,0.1,0,0.1,0h0c0.3,0,0.5,0.1,0.8,0.1c0.2,0,0.4,0.1,0.7,0.1
            c0,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0c0.3,0,0.5,0,0.8,0h0.2c0.1,0,0.3,0,0.4,0h0c0.4,0,0.7,0,1.1,0l0.2,0c0.2,0,0.4,0,0.6-0.1
            c0.3,0,0.6-0.1,0.9-0.1l0.2,0c0.2,0,0.4-0.1,0.7-0.1c0.3-0.1,0.5-0.1,0.8-0.2c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.3-0.1,0.4-0.1
            c16.7-5.4,28.4-26.7,51-70.2c4-7.7,8.1-15.7,12.4-23.7c9.4-17.3,20-33.8,31.7-49.6c15.2-20,24.9-27,29.7-29.4
            c1,4.2,2.1,12.9,1.3,29.9c-0.9,19.8-3.8,44.9-7.1,71.4c-10.1,84.4-21.4,180.1,17.1,223.4c13.3,15,31.1,22.6,52.9,22.6
            c59.8,0,114.5-96.2,135.4-137.9c2.2-4.1,4.3-8.2,6.4-12.3c3.2,9.6,7.2,18.3,12.1,26.1c16.6,26.3,41.8,40.2,72.9,40.2
            c80.6,0,165.4-112.2,216-179.2l3.8-5.2C2498.8,247.5,2497.2,236.1,2489.1,230z M2276.3,222.3c16.5-41.5,31.5-50,34.6-50
            c2.7,0,3.1,0.5,3.3,0.9c2.9,4.6,1.8,16.4,0,21.6c-0.2,0.6-0.5,1.2-0.8,1.8c-7.1,15.4-23.1,30.4-42.5,40.2
            C2272.7,231.8,2274.5,227,2276.3,222.3z M2473.1,468.6c14.9,39.5-52.6,168.7-52.6,168.7c-13.6,26.1-21.4,42.3-37.8,69
            c-13.7,22.3-21.7,32.9-29.6,45.9c-11.1,18.3-27.2,34.7-35.6,54.9c-1.9,4.6-4.6,8.1-8.4,11.8c-15.4,17.4-29.7,35.1-45.9,54.4
            c35-9.1,61.3-24.9,97.9-29.6c6.2,7.2,13,13.1,11.8,18.7c-54.6,20.1-108,39.9-162.6,60c-6.2-1.8-13.3-4-20.1-9.9
            c-5-12.8-2.6-24.1-0.2-35.4c5.7-32.9,8.2-66.4,11.6-99.5c1.1-11-6.4-23.4-2-33.8c17.2,0.3,24.9,9.7,28.7,19.7
            c4.8,13.5,7.3,26.8,5.6,39.1c-3.4,19.3-2.7,39.5-10.7,61.5c22.6-18.2,36-36.3,48.7-55.6c14.7-21.5,32.5-41.7,46.5-64.2
            c6.9-10.9,12.8-22.2,20.6-32.8c13-17.3,34.3-53.2,36.2-56.2c34.8-55.2,72.1-168.5,71.9-187.9c0-1.4-0.3-7.2,4.8-9.3
            c3.8-1.5,10-0.9,14.4,1.7C2466.3,460.1,2471,462.9,2473.1,468.6z"/>`
        },
        '4000': {
            viewBox: "0 0 5041.1 6289.6",
            x: "569.3",
            y: "2175.7",
            framePath: `<path class="st0" d="M2884.9,454.6c-9.2-3.2-19.2-2.6-27.9,1.7c-8.7,4.2-15.4,11.8-18.5,21c-15.1,43.7-66.3,170.3-128.4,282.7
            c-35.6,64.3-69.6,115-101.1,150.6c-43.3,48.9-70.2,56.3-82.3,56.3c-16.5,0-23.3-5.5-27.3-10.3c-34.4-40.9-9.2-186.1,11.1-302.8
            c26.4-151.6,51.3-294.8-4.8-361.6c-13.9-16.5-39.5-36.2-82.8-36.2c-75.1,0-141.2,65.1-201.4,198.5c-3.1,6.2-6.2,12.5-9.3,19.1
            c-1.5,3-2.5,6.2-3.1,9.5c-3.3,7.8-6.5,15.8-9.7,24c-21.8,57.1-40.7,115.2-56.7,174.2c-5.5-133.9,16.6-292.9,52.4-374
            c24.8-56,1.5-107.2-35.5-124.3c-18.5-8.6-40-8.2-58.3,0.9c-17.5,8.6-31.7,24.1-42.2,45.9c-39.5,81.3-61.3,201.5-67.8,322.5
            c-33.5,64-82.7,154.8-135,235.5l-2,3c-88.5,134.7-140,164.2-152.4,164.2c-3.3,0.3-6.4-1.3-8-4.1c-8.4-11.2-32.4-65.1,11.8-284.7
            c26.7-132.6,66.2-266.8,83-324.2c5.4-18.4,7.4-25,8.2-28.8c4-18.5-2.4-37.8-16.7-50.1c-21.8-19.9-62.5-28-92.6-18.4
            c-16.1,4.5-29.3,15.9-36.2,31.2c-1.4,2.6-2.4,5.4-3.2,8.3c-3.6,15.2-22.9,62.3-51.8,123.5c-4.8-44.6-16.6-87.1-39.2-115.3
            c-19.1-23.6-44.5-36.1-73.3-36.1c-67.8,0-128.9,62.3-176.7,180.2c-9.5,23.4-18,48.4-25.6,74c-32.9,53.1-73.4,118.3-118.4,182.9
            l-1.3,1.8c-109.5,155.5-220,256-281.5,256c-48.4,0-65.7-101.8-65.7-188.9c0-99.3,19.4-220.2,50.7-315.6
            c38.4-116.9,75.9-143.3,86.1-143.3c1.4,0,4.2,0,8.3,6.1c9.6,14.2,13.1,44.1,2.6,69.8c-0.1,0.4-0.2,0.7-0.3,1.1
            c-0.3,0.7-0.6,1.5-0.9,2.2c-0.1,0.4-0.2,0.9-0.3,1.3c-0.2,0.7-0.4,1.5-0.5,2.2c-0.1,0.5-0.2,1-0.3,1.6c-0.1,0.6-0.2,1.3-0.3,2
            c0,0.6,0,1.2,0,1.8c-0.1,0.6-0.1,1.1-0.1,1.7c0,0.6,0,1.3,0.1,1.9c0,0.5,0,1.1,0.1,1.6c0,0.6,0.1,1.3,0.2,2c0.1,0.5,0.2,1,0.3,1.5
            c0.1,0.6,0.3,1.2,0.4,1.8c0.2,0.5,0.3,1,0.4,1.6c0.2,0.5,0.4,1.1,0.6,1.7c0.2,0.5,0.4,1.1,0.6,1.7c0.3,0.5,0.5,1,0.7,1.5
            c0.3,0.5,0.5,1.1,0.8,1.7c0.3,0.5,0.6,0.9,0.8,1.4c0.4,0.5,0.7,1.1,1.1,1.7c0.2,0.4,0.6,0.8,0.9,1.3c0.3,0.5,0.7,1,1.2,1.6
            c0.3,0.4,0.7,0.8,1.1,1.3c0.4,0.4,0.8,0.9,1.2,1.3c0.4,0.4,1,0.9,1.5,1.3c0.4,0.4,0.7,0.7,1.2,1.1c0.5,0.5,1.1,0.9,1.7,1.4
            c0.4,0.3,0.8,0.6,1.2,0.8c0.6,0.4,1.3,0.8,2,1.2c0.3,0.2,0.7,0.4,1,0.6c0.1,0,0.1,0,0.2,0c0.6,0.3,1.2,0.6,1.7,0.8
            c0.6,0.3,1.2,0.6,1.8,0.8c0.1,0,0.1,0,0.2,0c0.4,0.2,0.8,0.3,1.2,0.4c0.7,0.2,1.4,0.5,2.1,0.7c0.5,0.1,1,0.2,1.5,0.3
            c0.7,0.2,1.4,0.4,2.1,0.5c0.6,0.1,1.1,0.1,1.7,0.2c0.6,0.1,1.2,0.2,1.9,0.2c0.6,0,1.2,0.1,1.9,0.1c0.5,0,1.1,0,1.6,0
            c0.7,0,1.4,0,2.1,0c0.5,0,1,0,1.5-0.1c0.7,0,1.4-0.2,2.1-0.3c0.5,0,1-0.1,1.4-0.2c0.7-0.1,1.4-0.3,2-0.5c0.5-0.1,1-0.2,1.5-0.3
            c0.6-0.2,1.2-0.4,1.8-0.6c0.5-0.1,1.1-0.3,1.6-0.5c0.5-0.2,1.1-0.5,1.6-0.7c0.5-0.2,1.1-0.5,1.6-0.8c0.5-0.2,1-0.5,1.5-0.8
            c0.5-0.3,1.1-0.6,1.6-1c0.5-0.3,0.9-0.6,1.4-0.9c0.4-0.3,1-0.7,1.5-1.1c0.5-0.3,0.9-0.7,1.3-1.1c0.5-0.4,0.9-0.8,1.4-1.2
            c0.5-0.4,0.9-0.9,1.4-1.5c0.3-0.3,0.7-0.7,1-1.1c0.5-0.6,1-1.2,1.4-1.8c0.3-0.3,0.6-0.7,0.8-1.1c0.5-0.6,0.9-1.3,1.3-2
            c0.2-0.3,0.4-0.7,0.6-1c0.1,0,0.1-0.1,0.1-0.2c0.2-0.3,0.3-0.5,0.5-0.8c23-42.8,21.8-104.1-3.2-146.3
            c-20.7-35.1-55.2-54.4-97.2-54.4c-80.2,0-152.9,61.3-205,172.6c-12.6,27-23.6,56.6-33,87.7C571.4,511,469.5,571.9,387.9,650.3
            c-7.7,7.3-15.2,14.8-22.4,22.5c-28.4-47.6-51.4-94.5-56.9-145c-10.9-99.9,29.6-212.2,75.1-263c15.5-17.2,31.2-27.1,43.3-27.1
            c35.4,0,64.1,62,64.1,102.5c0,0.3,0,0.5,0,0.8c0,0.7,0.1,1.3,0.1,2c0.1,0.6,0.1,1.1,0.2,1.7c0.1,0.6,0.2,1.3,0.3,1.9
            c0.1,0.5,0.2,1.1,0.3,1.7c0.2,0.5,0.3,1.2,0.5,1.8c0.2,0.6,0.3,1.1,0.5,1.7c0.2,0.5,0.4,1.1,0.6,1.6c0.3,0.6,0.5,1.1,0.7,1.7
            c0.3,0.5,0.5,1,0.8,1.5c0.2,0.5,0.5,1.1,0.9,1.6c0.3,0.5,0.5,1,0.8,1.4c0.3,0.5,0.7,1,1.1,1.5c0.3,0.5,0.6,0.9,1,1.4
            c0.3,0.4,0.7,0.9,1.1,1.4c0.4,0.4,0.8,0.9,1.2,1.3c0.4,0.4,0.8,0.8,1.3,1.2c0.4,0.4,0.8,0.8,1.3,1.2c0.4,0.4,0.9,0.7,1.3,1.1
            c0.5,0.3,1,0.7,1.5,1.1c0.5,0.4,0.9,0.6,1.4,0.9c0.5,0.3,1.1,0.7,1.6,1c0.5,0.3,1,0.5,1.5,0.8c0.5,0.2,1.1,0.5,1.7,0.7
            c0.5,0.2,1,0.5,1.6,0.7c0.6,0.2,1.1,0.4,1.7,0.6c0.6,0.2,1.2,0.4,1.8,0.5c0.6,0.2,1.1,0.3,1.7,0.4c0.6,0.1,1.2,0.3,1.9,0.4
            c0.6,0.1,1.1,0.2,1.7,0.3c0.7,0,1.3,0.1,2,0.2c0.3,0,0.5,0,0.8,0.1c0.3,0,0.7,0,1,0c0.4,0,0.7,0,1.1,0c0.3,0,0.6,0,0.9,0
            c0.6,0,1.2,0,1.8-0.1c0.7,0,1.3-0.1,2-0.2c0.5,0,1.1-0.1,1.6-0.2c0.7-0.1,1.4-0.2,2.1-0.4c0.4-0.1,0.9-0.2,1.4-0.3
            c0.7-0.2,1.4-0.4,2.1-0.6c0.4-0.1,0.9-0.3,1.3-0.5c0.7-0.2,1.4-0.5,2.1-0.8c0.4-0.2,0.8-0.3,1.1-0.5c0.7-0.3,1.4-0.7,2.1-1.1
            c0.3-0.2,0.7-0.4,1.1-0.6c0.6-0.4,1.2-0.8,1.8-1.2c0.4-0.2,0.8-0.5,1.2-0.8c0.5-0.4,1.1-0.9,1.6-1.3c0.4-0.3,0.8-0.6,1.1-1
            c0.5-0.4,1-0.9,1.5-1.4c0.3-0.4,0.7-0.8,1.1-1.2c0.4-0.5,0.8-0.9,1.2-1.4c0.4-0.5,0.7-0.9,1-1.4c0.3-0.4,0.7-0.9,1-1.4
            c0.4-0.5,0.7-1,1-1.5c0.3-0.5,0.5-1,0.8-1.5c0.3-0.5,0.5-1.1,0.8-1.6c0.3-0.5,0.5-1.1,0.7-1.6c0.2-0.5,0.4-1.1,0.6-1.6
            c0.2-0.6,0.4-1.2,0.6-1.8c0.2-0.6,0.3-1.1,0.4-1.7c0.2-0.6,0.3-1.2,0.4-1.9c0.1-0.6,0.2-1.1,0.3-1.7c0.1-0.6,0.2-1.3,0.2-1.9
            c0-0.3,0.1-0.5,0.1-0.8c2.8-52.4-15.8-103.6-51.4-142.1c-28.9-31.6-69.9-49.5-112.7-49.3c-54.6,0-107.6,27.4-153.3,79.4
            c-72.4,82.1-111.7,209.6-98.1,317.2c15.1,118.1,62,203.8,109.4,273.1C228,875.9,205.6,935,191.1,996.3
            c-19.2,79.9-10.2,151.1,24.8,195.3c21.3,27.4,53.7,43.5,88.4,44.1c5.9,0.9,11.9,1.4,17.8,1.4c36.4,0,75-16.4,108.8-46.8
            c53.9-48.8,82.2-119.8,76.7-192.3c-8.2-104.5-56.4-187.6-102.2-260.8c10.6-11.7,21.5-23.2,33-34.3
            c63.6-61.1,141.2-110.6,231.2-147.9c-9.5,50.3-14.9,101.9-14.9,151.7c0,88.1,15.6,164.1,45.1,219.8c36.7,69.3,93.6,106,164.4,106
            c81.7,0,171.5-60.5,274.4-185.1c38.9-47.1,75.7-98.4,109.4-148.6c-0.6,10.1-1,20-1,29.5c0,132,54.2,151.7,86.6,151.7
            c37.1,0,77-28.8,125.6-90.6c34.5-43.9,73.2-103.9,114.8-178.5c5.9-10.5,11.7-21,17.5-31.7c-2.2,14.4-4,27.7-5.6,39.5
            c-11.3,85.7-16.4,159.1-14.9,217.9c2.1,79,15.7,133.3,41.8,166.3c17.8,23.5,45.7,37.2,75.1,36.9c61,0,131.8-63.2,223.6-203.5
            c1.5-2.3,2.8-4.2,3.8-5.8l1.9-2.9c0.6-0.8,1.1-1.6,1.5-2.4c22.8-35.5,47-75.8,72.5-120.9c4,72.1,13.7,137.9,28.5,188
            c8.9,29.9,29.4,50.6,56.1,56.6c5.1,1.1,10.4,1.7,15.7,1.7c22.4-0.3,43.7-10.1,58.5-27c12.9-14.4,20.5-32.8,21.7-52.1
            c36.2-171.5,75.3-295.6,113.5-381.5c51.6-104.3,82.3-132.5,95.1-140.1c2.1,8.5,4.4,25.9,3.4,60.1c-1.4,39.9-6.8,90.5-12.6,144
            c-18.3,169.8-39.1,362.1,38.8,449c26.8,29.9,62.4,45,105.9,45c119.6,0,229-192.4,270.8-275.8c63.1-118.5,109.2-236.9,120.9-270.9
            c3.2-9.2,2.6-19.2-1.7-28C2901.6,464.4,2894.1,457.8,2884.9,454.6z M359.3,1137.8c-12.3,15.9-31.5,25.1-51.6,25
            c-13.5,0.6-26.4-5.5-34.5-16.4c-20.3-25.8-24.6-76.8-11-133.1c10.3-43.3,24.9-85.6,43.8-126c34.2,46.8,63,88.2,72.1,131.9
            C388.1,1067.5,381.2,1110.7,359.3,1137.8z M1524.5,513.5c-1.4,15.7-3.7,31.3-6.9,46.7c-0.1,0.3-0.1,0.7-0.2,1.1
            c-0.1,0.4-0.2,0.9-0.2,1.3c-0.1,0.2-0.1,0.4-0.1,0.6c-1.9,3.3-3.6,6.5-5.4,9.8c-17.1,30.6-32.8,57.2-47.1,80.3
            c-0.2,0.3-0.5,0.7-0.7,1c-38.6,62.8-85,116.2-107.7,133.8c-3.8-18.4-5.7-61.5,10.2-140.5c16.3-81,45.6-168.9,76.6-229.5
            c26.9-52.9,47.7-70.9,56.5-75.4c6,8.7,17.3,34.1,23.1,86.8C1525.6,457.3,1526.3,485.5,1524.5,513.5z M4978.3,460
            c-7.7-5.8-17.5-8.4-27-7.1c-9.6,1.3-18.2,6.4-24.1,14.2l-7.8,10.4c-42.4,56.1-108.9,144.1-180.6,215.1
            c-55.6,53.6-125.1,106.2-171.1,106.2c-13.4,0-28.8-3.4-41.7-28.4c-11.7-22.8-17.9-57.5-17.9-100.3c0.3-36.9,4.1-73.6,11.3-109.8
            c78-21.3,145.3-76.2,173.2-135l0.1,0c0-0.1,0-0.2,0.1-0.2c0.1-0.4,0.3-0.7,0.5-1.1c0.1-0.2,0.2-0.5,0.4-0.8c0.6-1.3,1.2-2.8,1.9-4.3
            c16.8-40.3,14.6-86.3-6.1-118.7c-18.2-28.5-48.8-44.3-86.1-44.3c-71.1,0-136,46.2-182.7,130.1c-36,64.5-58.3,148-61.4,227
            c-20.9,46.4-45.9,98.1-73,147.1c-35.6,64.3-69.6,115-101.1,150.6c-43.3,48.9-70.1,56.3-82.2,56.3c-16.6,0-23.3-5.5-27.3-10.3
            c-34.4-40.9-9.2-186.1,11.1-302.8c26.3-151.6,51.3-294.8-4.8-361.6c-13.9-16.5-39.5-36.2-82.9-36.2c-87.7,0-155.2,98-210.7,199
            c1-9.6,1.9-19,2.7-28c6-72.9,10.3-205.3-28.7-271.8c-23.1-39.3-54.6-45.2-71.5-45.2c-40.5,0-104.8,30.1-202.8,277.9
            c-4.4,10.8-7.7,19.4-9.6,24.7c-3.5,8.8-6.8,17.9-10.4,27.3c-23.2,62.7-45.4,130.9-63.7,193.9c0.6-122.3,21.6-254.9,53.2-326.5
            c24.8-56.1,1.4-107.2-35.5-124.4c-18.6-8.6-40-8.2-58.4,0.9c-17.4,8.6-31.6,24.1-42.2,45.9c-81.2,167.1-87.8,499.2-39.1,662.6
            c8.9,29.9,29.3,50.5,56.1,56.6c5.1,1.2,10.4,1.7,15.6,1.7c22.5-0.2,43.8-10.1,58.6-26.9c19.8-22.2,26.5-53,18.6-83.8
            c5.3-56.8,47.4-216.7,102.9-368.1c14.7-40.3,29.6-77.6,44.2-111.1c27.7-61.1,56.9-111.3,75.6-135c2.7,34.3-4,100-8.9,146.7
            c-13.1,128.7-26.7,261.8,30.6,317.3c0.2,0.2,0.4,0.3,0.6,0.5c0.3,0.3,0.7,0.6,1,0.9c0.2,0.2,0.4,0.4,0.6,0.6
            c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.5,0.4,0.8,0.7c0.3,0.2,0.7,0.5,1,0.8c0.3,0.2,0.7,0.5,1.1,0.8c0.3,0.2,0.5,0.4,0.8,0.5
            c0.2,0.2,0.6,0.4,0.9,0.6c0.5,0.3,1,0.6,1.5,0.9c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0,0.2,0.1,0.3,0.1c0.3,0.1,0.5,0.2,0.8,0.3
            c0.5,0.3,1.1,0.6,1.7,0.9c0.3,0.1,0.6,0.2,0.8,0.3c0.2,0.1,0.4,0.1,0.6,0.2c0.6,0.2,1.3,0.5,1.9,0.7c0.3,0.1,0.6,0.2,1,0.3
            c0.1,0,0.3,0.1,0.5,0.1c0.6,0.1,1.2,0.3,1.8,0.4c0.4,0.1,0.8,0.2,1.2,0.3c0.1,0,0.2,0,0.3,0h0.1c0.6,0.1,1.1,0.1,1.7,0.2
            c0.4,0,0.9,0.1,1.4,0.2c0.1,0,0.3,0,0.4,0c0.5,0,1,0,1.5,0.1c0.6,0,1.1,0.1,1.7,0.1h0.4c0.3,0,0.6,0,0.8,0h0.1c0.8,0,1.5,0,2.3-0.1
            l0.4,0c0.4,0,0.8-0.1,1.3-0.1c0.6-0.1,1.3-0.1,1.9-0.3l0.5-0.1c0.4,0,0.9-0.2,1.4-0.3c0.6-0.1,1.1-0.3,1.7-0.4c0.1,0,0.2,0,0.3,0
            c0.1,0,0.1-0.1,0.2-0.1c0.3-0.1,0.6-0.2,0.8-0.2c33.4-10.8,56.9-53.5,102-140.5c8-15.4,16.3-31.4,24.8-47.4
            c18.7-34.6,39.9-67.7,63.4-99.2c30.5-40,49.9-54,59.5-58.9c2,8.5,4.1,25.8,2.7,59.7c-1.8,39.6-7.7,89.7-14.1,142.8
            c-20.1,168.8-42.9,360.1,34.1,446.8c26.6,30,62.2,45.2,105.8,45.2c119.6,0,229-192.4,270.8-275.8c4.4-8.2,8.7-16.4,12.9-24.6
            c6.4,19.2,14.4,36.7,24.1,52.2c33.1,52.7,83.5,80.5,145.8,80.5c161.2,0,330.7-224.4,432-358.5l7.7-10.3
            C4997.6,495,4994.4,472.2,4978.3,460z M4552.7,444.5c32.9-83,63-100,69.2-100c5.4,0,6.1,1.1,6.6,1.9c5.7,9.2,3.6,32.9,0.1,43.2
            c-0.5,1.2-1,2.4-1.6,3.6c-14.3,30.8-46.1,60.7-85,80.4C4545.4,463.7,4548.9,453.9,4552.7,444.5z M4946.3,937.3
            c29.7,79-105.2,337.4-105.2,337.4c-27.3,52.2-42.7,84.7-75.5,138.1c-27.4,44.7-43.4,65.8-59.2,91.8c-22.3,36.7-54.4,69.3-71.3,109.7
            c-3.8,9.2-9.1,16.2-16.8,23.7c-30.7,34.7-59.5,70.3-91.8,108.9c70-18.2,122.6-49.9,195.8-59.1c12.4,14.4,26,26.2,23.6,37.5
            c-109.2,40.2-216,79.9-325.3,120c-12.4-3.6-26.7-8.1-40.3-19.9c-10-25.7-5.2-48.3-0.5-70.8c11.3-65.8,16.3-132.8,23.1-199
            c2.3-22-12.8-46.7-4-67.7c34.4,0.6,49.7,19.3,57.5,39.4c9.5,27,14.6,53.6,11.2,78.3c-6.8,38.5-5.3,79-21.4,123
            c45.3-36.4,72-72.7,97.3-111.1c29.5-43,64.9-83.3,93-128.3c13.7-21.8,25.5-44.5,41.3-65.5c25.9-34.7,68.7-106.4,72.4-112.4
            c69.6-110.4,144.3-337.1,143.9-375.8c0-2.8-0.6-14.5,9.6-18.7c7.5-3.1,20-1.8,28.9,3.4C4932.6,920.2,4941.9,925.7,4946.3,937.3z"/>`
        }
    },
    // FrameName: {
    //     '300': {
    //         viewBox: "",
    //         x: "",
    //         y: "",
    //         framePath: ``
    //     },
    //     '500': {
    //         viewBox: "",
    //         x: "",
    //         y: "",
    //         framePath: ``
    //     },
    //     '1000': {
    //         viewBox: "",
    //         x: "",
    //         y: "",
    //         framePath: ``
    //     },
    //     '2000': {
    //         viewBox: "",
    //         x: "",
    //         y: "",
    //         framePath: ``
    //     },
    //     '4000': {
    //         viewBox: "",
    //         x: "",
    //         y: "",
    //         framePath: ``
    //     }
    // },
};

const frameBuilder = (viewBox, primaryColor, secondaryColor, framePath, x, y, qrSvg) => {
    return `
    <?xml version="1.0" encoding="utf-8"?>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="${viewBox}">
    <style type="text/css">
        .st0{fill:${primaryColor};}
        .st1{fill:${secondaryColor};}
    </style>
    ${framePath}
    <g transform="translate(${x}, ${y})">
    ${qrSvg}
    </g>
    </svg>
        `.trim();
};

export const generateFrameSVG = (qrSvg, frameDesign = 'ScanMeBottom', size = '300', primaryColor = '#000000', secondaryColor = '#FFFFFF') => {
    const frameObj = frameSettings[frameDesign]?.[size];

    const { viewBox, x, y, framePath } = frameObj;

    return frameBuilder(viewBox, primaryColor, secondaryColor, framePath, x, y, qrSvg);
};

export const frameWidthHeight = (frameDesign = 'ScanMeBottom', size = '500') => {

    const viewBox = frameSettings[frameDesign]?.[size]?.viewBox;
    const parts = viewBox.split(' ');
    const width = parts[2];
    const height = parts[3];

    return { width, height };
};