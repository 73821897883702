const qrOptions = (action, state) => {
    const { type, options } = action;
    switch (type) {
        case 'edit':
            return options;
        case 'liquid':
            const liquidOptions = {
                shape: 'square',
                width: 300,
                height: 300,
                margin: 0,
                qrOptions: {
                    typeNumber:"0",
                    mode:"Byte",
                    errorCorrectionLevel:"M"
                },
                data: `https://lqr.ai/${options.randKey}`,
                imageOptions: {
                    hideBackgroundDots:true,
                    imageSize:0.4,
                    margin:0,
                    crossOrigin:"anonymous"
                },
                dotsOptions: {
                    type: 'square',
                    color: '#000000',
                    gradient: null
                },
                backgroundOptions: {
                    color: '#ffffff',
                    gradient: null
                },
                image:null,
                cornersSquareOptions: {
                    type: 'square',
                    color: '#000000',
                    gradient: null
                },
                cornersDotOptions: {
                    type: 'square',
                    color: '#000000',
                    gradient: null
                }
            };
            return liquidOptions;
        case 'reset':
            const basicOptions = {
                shape: 'square',
                width: 300,
                height: 300,
                margin:0,
                qrOptions: {
                    typeNumber:"0",
                    mode:"Byte",
                    errorCorrectionLevel:"M"
                },
                data: '',
                imageOptions: {
                    hideBackgroundDots:true,
                    imageSize:0.4,
                    margin:0,
                    crossOrigin:"anonymous"
                },
                dotsOptions: {
                    type: 'square',
                    color: '#000000',
                    gradient: null
                },
                backgroundOptions: {
                    color: '#ffffff',
                    gradient: null
                },
                image:null,
                cornersSquareOptions: {
                    type: 'square',
                    color: '#000000',
                    gradient: null
                },
                cornersDotOptions: {
                    type: 'square',
                    color: '#000000',
                    gradient: null
                },
                type:'svg'
            };

            const tempObj = {};

            for (const key in basicOptions) {
                if (key === 'data') {
                    tempObj[key] = '';
                } else {
                    tempObj[key] = basicOptions[key];
                };
            };
            
            return tempObj;
        case 'shape':
            return {
                ...state,
                shape: options,
            };
        case 'eye':
            const cornerEyeOptions = {
                ...state.cornersDotOptions,
                [Object.keys(options)[0]]: Object.values(options)[0]
            };
            return {...state, cornersDotOptions: cornerEyeOptions};
        case 'frame':
            const cornerFrameOptions = {
                ...state.cornersSquareOptions,
                [Object.keys(options)[0]]: Object.values(options)[0]
            };
            return {...state, cornersSquareOptions: cornerFrameOptions};
        case 'background':
            const backgroundOptions = {
                ...state.backgroundOptions,
                [Object.keys(options)[0]]: Object.values(options)[0]
            };
            return {...state, backgroundOptions: backgroundOptions};
        case 'dots':
            const dotsOptions = {
                ...state.dotsOptions,
                [Object.keys(options)[0]]: Object.values(options)[0]
            };
            return {...state, dotsOptions: dotsOptions};
        case 'logo':
            return {
                ...state, 
                image: options,
                qrOptions: {
                    ...state.qrOptions,
                    errorCorrectionLevel: "H"
                }
            };
        case 'remove-logo':
            return {
                ...state, 
                image: null,
                qrOptions: {
                    ...state?.qrOptions,
                    errorCorrectionLevel: options
                }
            };
        case 'redirect':
            return {...state, data: options};
        case 'randkey':
            return {...state, data: `https://lqr.ai/${options.randKey}`};
        case 'resize':
            return { ...state, height: options, width: options }
        default:
            return action;
    };
};

export default qrOptions;