import React, { useState, useEffect, Fragment } from 'react';
import AddResponse from '../AddResponse';
import API from '../../../API/index.js';
import SignatureCanvas from 'react-signature-canvas';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styles from './Table.module.css';

const Response = (props) => {
    const { selectedResponse, setSelectedResponse, selectedSignature, questions, editResponse, setEditResponse, id, authenticatedUser } = props;

    const [ sigCanvas, setSigCanvas ] = useState(null);
    const [ changeLog, setChangeLog ] = useState(selectedResponse?.edits ?? {});
    const [ showChanges, setShowChanges ] = useState(false);
    
    useEffect(() => {
        sigCanvas && sigCanvas?.clear();
        if (sigCanvas && selectedSignature) {
            sigCanvas?.clear();
            sigCanvas?.fromData(selectedSignature);
            sigCanvas?.off();
        };
    },[sigCanvas, selectedSignature]);

    useEffect(() => {
        if (selectedResponse?.edits) setChangeLog(() => selectedResponse?.edits);
    },[selectedResponse, setChangeLog]);

    if (selectedResponse && !editResponse) {
        return (
            <div className={styles.selectedLogContainer}>
                {selectedResponse?.deleted ? <div className={styles.deleted}><p>Deleted Response</p></div> : <></>}
                {questions?.map((question, index) => {
                    switch(question?.type) {
                        case 'signature':
                            return (
                                <div className={styles.selectedLog} key={question?.type + index}>
                                    <div title={question?.name}>{question?.name}</div>
                                    {selectedResponse[question?.id] ? <SignatureCanvas ref={(ref) => {setSigCanvas(ref)}} penColor='black' canvasProps={{ width: 350, height: 100, className:`${styles.trackerCanvas}` }} /> : <p>No Signature</p>}
                                </div>
                            );
                        case 'date':
                            return (
                                <div className={styles.selectedLog} key={question?.type + index}>
                                    <div title={"Date"}>Date</div>
                                    <p>{new Date(selectedResponse[question?.id]).toLocaleString()}</p>
                                </div>
                            );
                        case 'checkbox': 
                            return (
                                <div className={styles.selectedLog} key={question?.type + index}>
                                    <div title={question?.name}>{question?.name}</div>
                                    <p>{selectedResponse[question?.id]?.join(', ')}</p>
                                </div>
                            );
                        case 'text-area':
                            return (
                                <div className={styles.selectedLog} key={question?.type + index}>
                                    <div title={question?.name}>{question?.name}</div>
                                    {(selectedResponse[question?.id] && selectedResponse[question?.id].split('\n').length > 0) ? selectedResponse[question?.id].split('\n').map((text, index) => <p key={index}>{text}</p>) : <></>}
                                </div>
                            );
                        case 'star-rating':
                        case 'number-rating':
                            return (
                                <div className={styles.selectedLog} key={question?.type + index}>
                                    <div title={question?.name}>{question?.name}</div>
                                    <p>{selectedResponse[question?.id]}</p>
                                </div>
                            );
                        default:
                            return (
                                <div className={styles.selectedLog} key={question?.type + index}>
                                    <div title={question?.name}>{question?.name}</div>
                                    <p>{selectedResponse[question?.id]}</p>
                                </div>
                            );
                    };
                })}
                {(changeLog && Object.keys(changeLog).length > 0) ?  
                    <div className={styles.changeLogWrapper}>
                        {showChanges ?
                            <>
                            <div className={styles.changeLogContainer} >
                                <h4>Edits:</h4>
                                {Object.keys(changeLog).sort((a, b) => Number(b) - Number(a)).map((date) => 
                                    <div className={styles.changeLogEntry} key={date}>
                                        <p className={styles.changeLogDate}>{new Date(Number(changeLog[date].date_edited))?.toLocaleString()}</p>
                                        {Object.keys(changeLog[date]).map((key, index) => {
                                            if (key === 'date_active' || key === 'date_edited') {
                                                return <Fragment key={key} ></Fragment>
                                            };

                                            return (
                                                <div className={styles.changeLogColumn} key={key + index}>
                                                    <p className={styles.changeLogColumnName}>{questions.find(q => q.id === key)?.name}:</p>
                                                    <p className={styles.changeLogValue}>{changeLog[date][key] ? ((questions.find(q => q.id === key)?.type === 'checkbox' && changeLog[date][key]?.length > 0) ? changeLog[date][key]?.join(', ') : changeLog[date][key]) :  'No Value'}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                            <div className={styles.btnContainer}>
                                <span className={styles.changeLog} onClick={() => setShowChanges(false)} >Hide Edits</span>
                            </div>
                            </>
                        :
                            <div className={styles.btnContainer}>
                                <span className={styles.changeLog} onClick={() => setShowChanges(true)} >Show Edits</span>
                            </div>
                        }
                    </div>
                :
                    <></>
                }
            </div>
        );
    } else if (selectedResponse && editResponse) {
        return <AddResponse id={id} questions={questions} editResponse={selectedResponse} setEditResponse={setSelectedResponse} authenticatedUser={authenticatedUser} />;
    } else {
        return (<></>);
    };
};

const Table = (props) => {
    const { id, questions, responses, editResponse, setEditResponse, selectedResponse, setSelectedResponse, authenticatedUser, deletedToggle, setDeletedToggle } = props;
    const { getTrackerSignature } = API;

    const [ error, setError ] = useState('');
    const [ selectedSignature, setSelectedSignature ] = useState(null);
    const [ sortAsc, setSortAsc ] = useState(false);

    const handleGetSignature = async (response, show) => {
        setError('');
        
        const signatureId = questions?.find(q => q?.type === 'signature')?.id ?? null;
        
        if (selectedSignature && selectedSignature?.length > 0) {
            setSelectedSignature(() => null);
        };

        if (show && response[signatureId]) {
            await getTrackerSignature(id, response.date).then((res) => {
                if (res) {
                    setSelectedResponse(response);
                    setSelectedSignature(res);
                } else {
                    setError('No Signature Found');
                    setSelectedSignature(null);
                };
            }).catch((err) => {
                console.log(err);
                setError('Something went wrong, please try again');
            });
        } else if (show && !response[signatureId]) {
            setSelectedResponse(response);
        } else {
            setSelectedResponse('');
        };
    };

    const handleSort = (responses) => {
        if (deletedToggle) {
            return responses.filter(r => r?.deleted).sort((a, b) => !sortAsc ? b.date - a.date : a.date - b.date)
        };

        return responses.filter(r => !r?.deleted).sort((a, b) => !sortAsc ? b.date - a.date : a.date - b.date)
    };

    if (error) return (<div className={styles.error}>{error}</div>);

    if (!responses) return (<></>);
    
    if (selectedResponse) {
        return (<Response selectedResponse={selectedResponse} setSelectedResponse={setSelectedResponse} selectedSignature={selectedSignature} questions={questions} editResponse={editResponse} setEditResponse={setEditResponse} id={id} authenticatedUser={authenticatedUser} />)
    };

    return (
        <div className={styles.logContainer} >
            <div className={styles.logSection} >
                <div className={styles.table}>
                    <div className={styles.logHeaders}>
                        {questions?.map((question, index) => {
                            if (question?.type ==='date') return (
                                <div className={`${styles.logType} ${styles.dateHeader}`} style={{width: `calc(100% / ${questions?.length - 1})`}} key={index} onClick={() => setSortAsc(prev => !prev)} >
                                    Date
                                    <i className={sortAsc ? `far fa-caret-down ms-2 ${styles.ascend}` : `far fa-caret-down ms-2`} />
                                </div>
                            );

                            return (
                                <OverlayTrigger
                                    placement='top'
                                    delay={{show: 250, hide: 400}}
                                    trigger={['click','hover']}
                                    overlay={<Tooltip>{question?.name}</Tooltip>}
                                    key={index}
                                >
                                    <div className={styles.logType} key={index} style={{width: `calc(100% / ${questions?.length - 1})`}}>
                                        <span>{question?.name}</span>
                                    </div>
                                </OverlayTrigger>
                            );
                        })}
                    </div>
                    {responses?.length > 0 ?
                        handleSort(responses)?.map((response, index) => {
                            return (
                                <div className={deletedToggle ? `${styles.logRow} ${styles.deletedLog}` : `${styles.logRow}`} key={index} onClick={() => handleGetSignature(response, selectedResponse?.date === response?.date ? false : true)}>
                                    {questions?.map((question, index) => {
                                        switch (question?.type) {
                                            case 'checkbox':
                                                return (
                                                    <OverlayTrigger
                                                        placement='top'
                                                        delay={{show: 250, hide: 400}}
                                                        trigger={['click','hover']}
                                                        key={index}
                                                        overlay={<Tooltip>{response[question?.id]?.length > 0 ? response[question?.id]?.join(', ') : ''}</Tooltip>}
                                                    >
                                                        <div className={styles.logType} key={index} style={{width: `calc(100% / ${questions?.length - 1})`}}>
                                                            <span>{(response?.hasOwnProperty('edits') && Object?.keys(response?.edits)?.length > 0 && index === 0) ? '*' : ''}{response[question?.id]?.length > 0 ? response[question?.id]?.join(', ') : ''}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                );
                                            case 'date':
                                                return (
                                                    <div className={styles.logType} key={index} style={{width: `calc(100% / ${questions?.length - 1})`}}>
                                                        <span>{(response?.hasOwnProperty('edits') && Object?.keys(response?.edits)?.length > 0 && index === 0) ? '*' : ''}{new Date(response[question?.id]).toLocaleString()}</span>
                                                    </div>
                                                );
                                            case 'signature':
                                                return (
                                                    <div className={styles.logType} key={index} style={{width: `calc(100% / ${questions?.length - 1})`}}>
                                                        <span>{(response?.hasOwnProperty('edits') && Object?.keys(response?.edits)?.length > 0 && index === 0) ? '*' : ''}{response[question?.id] ? 'Signed' : 'No Signature'}</span>
                                                    </div>
                                                );
                                            default:
                                                return (
                                                    <OverlayTrigger
                                                        placement='top'
                                                        delay={{show: 250, hide: 400}}
                                                        trigger={['click','hover']}
                                                        key={index}
                                                        overlay={<Tooltip>{response[question.id]}</Tooltip>}
                                                    >
                                                        <div className={styles.logType} key={index} style={{width: `calc(100% / ${questions?.length - 1})`}}>
                                                            <span>{(response?.hasOwnProperty('edits') && Object?.keys(response?.edits)?.length > 0 && index === 0) ? '*' : ''}{response[question.id]}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                );
                                        };
                                    }
                                    )}
                                </div>
                            );
                        })
                    :
                        <div className={styles.none}>No responses found</div>
                    }
                </div>
                <div className={styles.key}>
                    <span>* indicates response has been edited</span>
                    {(authenticatedUser && responses?.filter(r => r?.deleted)?.length) ? <span className={styles.deletedToggle} onClick={() => setDeletedToggle(prev => !prev) } >{deletedToggle ? 'Hide Deleted' : 'Show Deleted'}</span> : <></>}    
                </div>
            </div>
        </div>
    );
};

export default Table;