import React, { useEffect, Fragment, useState } from 'react';
import QRCard from '../QRCard';
import { useLocation } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';
import QRDisplayMenu from '../QRDisplayMenu';
import QRDragNDrop from '../QRDragNDrop';
import styles from './QRReturnByCategory.module.css';

export default function QRReturnByCategory(props) {
    const { 
        searchReturnArr,
        layout,
        setQRtoEdit,
        setDisplay,
        optionsDispatch,
        dataDispatch,
        analytics,
        plan,
        filters,
        setFilters,
        categories,
        uid,
        orgId,
        searchValue,
        linkedQRs
    } = props;

    const [ categoryStates, setCategoryStates ] = useState([ ...categories, { id: 'Uncategorized', name: 'Uncategorized' }].reduce((acc, cur) => {
        acc[cur.id] = true;
        return acc;
    },{}));

    const { state } = useLocation();
    // ------ Scroll Edit -> Back ------
    useEffect(() => {
        if (state?.qrID && searchReturnArr?.find(qr => qr.id === state?.qrID)) {
            const qr = document.getElementById(state?.qrID);
            const position = qr.offsetTop;
            if (position > 0) {
                window.scrollTo({top: position, behavior: 'smooth'});
                state.qrID = null;
            };
        } else return;
    },[state?.qrID, searchReturnArr]);
    // ---------- End Scroll -----------

    // ------ CATEGORY ORDER --------
    const handleCategoryOrder = () => {
        let initialOrder;
        let alphabeticalCategories = categories.sort((a,b) => a.name.localeCompare(b.name));
        try {
            const saved = localStorage.getItem(`${uid}_order`);
            if (saved) {
                initialOrder = JSON.parse(saved).map(savedCat => {
                    if (savedCat.id === 'Uncategorized') {
                        return { id: 'Uncategorized', name: 'Uncategorized' };
                    };
                    const cat = categories.find(c => c?.id === savedCat?.id);
                    return { id: cat?.id, name: cat?.name }
                });
            } else {
                initialOrder = null;
            };

        } catch (e) {
            initialOrder = null;
        };
        if (Array.isArray(alphabeticalCategories) && alphabeticalCategories.length > 0) {
            if (!alphabeticalCategories.find(cat => cat.id === 'Uncategorized')) {
                alphabeticalCategories.push({ id:'Uncategorized', name: 'Uncategorized' });
            };

            if (initialOrder !== null) {
                initialOrder = initialOrder.filter(cat => alphabeticalCategories.find(c => c?.id === cat?.id));

                alphabeticalCategories.forEach(cat => {
                    if (!initialOrder.find(c => c?.id === cat?.id)) {
                        initialOrder.unshift(cat);
                    };
                });
            };
            
            return (initialOrder !== null && Array.isArray(initialOrder)) ? initialOrder : alphabeticalCategories;
        } else {
            return [{ id: 'Uncategorized', name: 'Uncategorized' }];
        };
    };

    const [ editOrder, setEditOrder ] = useState(false);
    const [ categoryOrder, setCategoryOrder ] = useState(handleCategoryOrder());
    // ---- END CATEGORY ORDER --------

    useEffect(() => {
        localStorage.setItem(`${uid}_order`, JSON.stringify(categoryOrder));
    }, [categoryOrder, uid]);

    useEffect(() => {
        const qrs = document.querySelector(`.${styles.qrs}`);
        const reorder = document.querySelector(`.${styles.qrsReorder}`);

        const handleShowHide = setTimeout(() => {
            if (editOrder) {
                qrs.style.display = 'none';
                reorder.style.display = 'flex';
            } else {
                reorder.style.display = 'none';
                qrs.style.display = 'flex';
            }
        }, 500);
    
        return () => {
            clearTimeout(handleShowHide);
        };
    }, [editOrder]);

    useEffect(() => {
        if (categories?.length > 0) {
            setCategoryOrder(prev => {
                categories.forEach(cat => {
                    if (!prev.find(c => c.id === cat.id)) {
                        prev.unshift(cat);
                    };
                });

                return prev;
            });

            categories.forEach(el => {
                setCategoryStates(prev => ({
                    ...prev,
                    [el.id]: true
                }))
            });
        };
    },[categories, setCategoryOrder, setCategoryStates]);

    const handleCollapse = (e) => {
        const id = e.target.id;
        setCategoryStates(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    return (
        <>
        <div className={editOrder ? `${styles.qrs} ${styles.hide}` : styles.qrs}>
            {(searchValue && searchValue.length > 0) ?
                <>
                <div className={styles.resultsTitle}>Results for "<span>{searchValue}</span>"...</div>
                <div className={`${styles.qrsSection} ${styles.open}`}>
                    {searchReturnArr.map((qr, index) => (
                        <Fragment key={index}>
                            <QRCard QR={qr} setQRtoEdit={setQRtoEdit} setDisplay={setDisplay} optionsDispatch={optionsDispatch} dataDispatch={dataDispatch} analytics={analytics} plan={plan} filters={filters} layout={layout} orgId={orgId} childQRs={linkedQRs?.filter(child => child?.data?.linked_qr === qr?.id)} />    
                        </Fragment>
                    ))}
                </div> 
                </>
            : 
                categoryOrder?.map((cat,i) => (
                    <Fragment key={i}>
                        <button className={categoryStates[cat.id] ? styles.sectionHeader : `${styles.sectionHeader} ${styles.close}`} id={cat.id} onClick={handleCollapse}>
                            <i className='far fa-caret-up me-2' />
                            <span>{cat.name}</span>
                            <i className='far fa-caret-up ms-2' />
                        </button>
                        <Collapse in={categoryStates[cat.id]}>
                            <div style={{width: '100%'}}>
                                <div className={styles.qrsSection}>
                                    {searchReturnArr.filter(qr => qr.data.category === cat.id).length > 0 ?
                                        <>
                                        {searchReturnArr.filter(qr => qr.data.category === cat.id).map((qr, index) => (
                                            <Fragment key={index}>
                                                <QRCard QR={qr} setQRtoEdit={setQRtoEdit} setDisplay={setDisplay} optionsDispatch={optionsDispatch} dataDispatch={dataDispatch} analytics={analytics} plan={plan} filters={filters} layout={layout} orgId={orgId} childQRs={linkedQRs?.filter(child => child?.data?.linked_qr === qr?.id)} />    
                                            </Fragment>
                                        ))}
                                        </>
                                    : (cat.id === 'Uncategorized' && searchReturnArr.filter(qr => qr.data.category === null || qr.data.category === undefined || !categoryOrder.find(c => c.id === qr.data.category)).length > 0) ? 
                                        <>
                                        {searchReturnArr.filter(qr => qr.data.category === null || qr.data.category === undefined || !categoryOrder.find(c => c.id === qr.data.category)).map((qr, index) => (
                                            <Fragment key={index}>
                                                <QRCard QR={qr} setQRtoEdit={setQRtoEdit} setDisplay={setDisplay} optionsDispatch={optionsDispatch} dataDispatch={dataDispatch} analytics={analytics} plan={plan} filters={filters} layout={layout} orgId={orgId} childQRs={linkedQRs?.filter(child => child?.data?.linked_qr === qr?.id)} />    
                                            </Fragment>
                                        ))}
                                        </>
                                        : <div className={styles.noQRs}>0 QRs in this Category</div>
                                    }
                                </div>
                            </div>
                        </Collapse>
                    </Fragment>
                ))
            }
        </div>
        <div className={!editOrder ? `${styles.qrsReorder} ${styles.hide}` : styles.qrsReorder}>
            <QRDragNDrop categoryOrder={categoryOrder} setCategoryOrder={setCategoryOrder} />
        </div>
        {Object.keys(categoryStates).length > 0 ? <QRDisplayMenu categoryOrder={categories.sort((a,b) => a.name.localeCompare(b.name))} editOrder={editOrder} setEditOrder={setEditOrder} categoryStates={categoryStates} setCategoryStates={setCategoryStates} setFilters={setFilters} /> : <></>}
        </>
    );
};