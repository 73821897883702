import React from 'react';
import styles from './LoadingSpinner.module.css';

export default function LoadingSpinner() {

    return (
        <>
        {window.location.pathname === '/upgrade' ?
            <div className={styles.spinnerUpgrade}>
                <i className='fa-duotone fa-spinner fa-spin' />
            </div>
        :
            <div className={styles.container}>
                <i className='fa-duotone fa-spinner fa-spin' />
            </div>
        }
        </>
    )
}