import React, { useRef, useState, useEffect } from 'react';
import QRCodeStyling from 'pp-qr-code';
import Modal from 'react-bootstrap/Modal';
import ColorPicker from '../../Style/ColorPicker';
import { generateFrameSVG, frameWidthHeight } from '../../../../utils/FrameHelper';
import cloneDeep from 'lodash.clonedeep';
import styles from './CustomDownload.module.css';

export default function CustomDownload(props) {
    // ----- STATE INITIALIZATION -----
    const { QR, showDownloadModal, setShowDownloadModal, nickname } = props;
    const qrRef = useRef(null);

    const [options] = useState(cloneDeep(QR?.data?.qr_style));
    const [code] = useState(new QRCodeStyling({ ...options }));

    const [ size, setSize ] = useState("300");
    const [ correction, setCorrection ] = useState(QR.data.qr_style.qrOptions.errorCorrectionLevel);
    const [ primaryColor, setPrimaryColor ] = useState("#000000");
    const [ secondaryColor, setSecondaryColor ] = useState("#FFFFFF");
    const [ fileName, setFileName ] = useState(nickname?.length > 0 ? nickname : '');
    const [ fileType, setFileType ] = useState('png');
    const [ frame, setFrame ] = useState('NoFrame');
    const [ updatedSvgContent, setUpdatedSvgContent ] = useState('');
    const [ displayColorPicker, setDisplayColorPicker ] = useState(null);

    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    useEffect(() => {
        if (options) {
            code.update(options);
        };

        return () => {
            code.update(null);
        };
    }, [options, code]);

    useEffect(() => {
        if (code && options && frame) {

            if (size) {
                options.width = size;
                options.height = size;
            };
            if (correction) {
                options.qrOptions.errorCorrectionLevel = correction;
            };

            code.update(options);

                code.getRawData('svg').then(blob => {
                    blob.text().then(qrSvgText => {
                        // Parse the QR SVG text to insert... REVIEW LATER NOT SURE I'M CONVINCED THIS IS THE BEST WAY TO PARSE
                        const cleanedSvgText = qrSvgText.replace(/^<\?xml\s+version="1\.0"\s+(?:encoding="utf-8"\s+)?standalone="no"\s*\?>\s*/, '');

                        const finishedQR = generateFrameSVG(cleanedSvgText, frame, size, primaryColor, secondaryColor);
                        setUpdatedSvgContent(finishedQR);

                        if (qrRef.current) {
                            qrRef.current.innerHTML = finishedQR;
                        }
                        
                    });

                }).catch(error => {
                    console.error("Error processing QR SVG data:", error);
                });
            
        }
    }, [code, options, qrRef, frame, size, correction, primaryColor, secondaryColor]);

    const downloadQR = async (frame, svg, fileName, fileType) => {

        if (!svg) {
            return;
        }

        if (frame === "NoFrame") {

            await code.download({ name: fileName, extension: fileType });

        } else {

            if (fileType.toLowerCase() === "svg") {
                const url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);
                downloadURL(url, `${fileName}.svg`);
            } else {
                const svgSize = frameWidthHeight(frame, size);
                const img = new Image();
                img.crossOrigin = "anonymous";
                const svgBlob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
                const url = URL.createObjectURL(svgBlob);
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    
                    canvas.width = svgSize.width;
                    canvas.height = svgSize.height;
    
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    canvas.toBlob((blob) => {
                        const newUrl = URL.createObjectURL(blob);
                        downloadURL(newUrl, `${fileName}.${fileType}`);
                        URL.revokeObjectURL(newUrl);
                    }, `image/${fileType === 'jpg' ? 'jpeg' : fileType}`);
                    URL.revokeObjectURL(url);
                };
                img.src = url;
            };
            
        }

    };

    const downloadURL = (url, fileName) => {
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleColorChange = (e, name) => {
        if (name === 'primary-color') {
            setPrimaryColor(e);
        } else if (name === 'secondary-color') {
            setSecondaryColor(e);
        };
    };

    return (
        <Modal
            show={showDownloadModal}
            onHide={() => setShowDownloadModal(false)}
            className={styles.modal}
        >
            <Modal.Header className={styles.header} closeButton><Modal.Title>Custom QR Download</Modal.Title></Modal.Header>
            <Modal.Body className={styles.body}>
                <div className={styles.top}>
                    <div className={styles.topLeft}>

                        <div className={styles.inputGroup} style={{ marginBottom: '1rem' }}>
                            <input
                                id="download-name-label"
                                className={styles.input}
                                value={fileName}
                                onChange={e => setFileName(e.target.value)}
                            />
                            <label htmlFor='download-name-label' className={styles.label}>File Name</label>
                        </div>
                        <div className={`${styles.inputGroup} ${styles.inputGroupSelect}`} style={{ marginBottom: '1rem' }}>
                            <select
                                id='download-size-label'
                                className={styles.input}
                                style={{ appearance: 'none' }}
                                value={size}
                                required
                                onChange={e => setSize(e.target.value)}
                            >
                                <option value='300'>300</option>
                                <option value='500'>500</option>
                                <option value='1000'>1000</option>
                                <option value='2000'>2000</option>
                                <option value='4000'>4000</option>
                            </select>
                            <label htmlFor='download-file-type-label' className={styles.label}>QR Size</label>
                        </div>
                        <div className={`${styles.inputGroup} ${styles.inputGroupSelect}`} style={{ marginBottom: '1rem' }}>
                            <select
                                id='download-file-type-label'
                                className={styles.input}
                                style={{ appearance: 'none' }}
                                value={fileType}
                                onChange={e => { setFileType(e.target.value) }}
                            >
                                <option value='png'>PNG</option>
                                <option value='jpg'>JPG</option>
                                <option value='svg'>SVG</option>
                                <option value='webp'>WEBP</option>
                            </select>
                            <label htmlFor='download-file-type-label' className={styles.label}>File Type</label>
                        </div>
                        <div className={`${styles.inputGroup} ${styles.inputGroupSelect}`} style={{marginBottom: '1rem'}}>
                            <select 
                                id='download-correction-label'
                                className={styles.input} 
                                style={{appearance: 'none'}}
                                value={correction} 
                                onChange={e => setCorrection(e.target.value)}
                            >
                                <option value="L">L</option>
                                <option value="M">M</option>
                                <option value="Q">Q</option>
                                <option value="H">H</option>
                            </select>
                            <label htmlFor='download-correction-label' className={styles.label}>Error Correction</label>
                        </div>
                        <div className={`${styles.inputGroup} ${styles.inputGroupSelect}`} style={{ marginBottom: '1rem' }}>
                            <select
                                id='frame-design-label'
                                className={styles.input}
                                style={{ appearance: 'none' }}
                                value={frame}
                                onChange={e => setFrame(e.target.value)}
                            >
                                <option value="NoFrame">No Frame</option>
                                <option value="ScanMeBottom">Scan Me - Bottom</option>
                                <option value="ScanMeTop">Scan Me - Top</option>
                                <option value="MenuBottom">Menu - Bottom</option>
                                <option value="MenuTop">Menu - Top</option>
                                <option value="ArrowBottom">Arrow - Bottom</option>
                                <option value="ArrowTop">Arrow - Top</option>
                            </select>
                            <label htmlFor='frame-design-label' className={styles.label}>Frame Design</label>
                        </div>
                        <div className={styles.inputGroup} style={{ marginBottom: '1rem' }}>
                            <div className={styles.swatch} onClick={() => setDisplayColorPicker('primary-color')}>
                                <div className={styles.color} style={{background: primaryColor}} />
                            </div>
                            <label className='ms-2'>Primary Color</label>
                            { displayColorPicker === 'primary-color' ? 
                                <div className={styles.popover}>
                                    <div className={styles.cover} onClick={() => setDisplayColorPicker(null)} />
                                    <ColorPicker
                                        name='primary-color'
                                        color={ primaryColor }
                                        onChange={handleColorChange}
                                    />
                                </div> : null }
                        </div>
                        <div className={styles.inputGroup} style={{ marginBottom: '1rem' }}>
                            <div className={styles.swatch} onClick={() => setDisplayColorPicker('secondary-color')}>
                                <div className={styles.color} style={{background: secondaryColor}} />
                            </div>
                            <label className='ms-2'>Secondary Color</label>
                            { displayColorPicker === 'secondary-color' ? 
                                <div className={styles.popover}>
                                    <div className={styles.cover} onClick={() => setDisplayColorPicker(null)} />
                                    <ColorPicker
                                        name='secondary-color'
                                        color={ secondaryColor }
                                        onChange={handleColorChange}
                                    />
                                </div> : null }
                        </div>
                    </div>
                    <div className={styles.topRight}>
                        <div className={styles.codeWrap}>
                            <div id='qrsvg' ref={qrRef}></div>
                        </div>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <div className={styles.words}>
                        <p style={{ marginBottom: '0' }}>File Name: <span style={{ fontWeight: 'bold' }}>{`${fileName ? fileName.replace(/\s+/g, '') : 'QRCode'}.${fileType ? fileType.toLowerCase() : 'png'}`}</span></p>
                    </div>
                    <button
                        onClick={() => {
                            const fileTypeFormat = fileType === 'svg' ? 'svg' : fileType === 'jpg' ? 'jpeg' : fileType;
                            
                            if (updatedSvgContent) {
                                downloadQR(frame, updatedSvgContent, fileName ? fileName : 'QRCode', fileTypeFormat);
                            } else {
                                console.error('No SVG content available for download.');
                            }
                        }}
                        type="button"
                        className={styles.btn}
                        disabled={!updatedSvgContent} // Disable button if SVG content is not available
                    >
                        Download
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};