import { collection, doc, setDoc, getDoc, updateDoc, onSnapshot, runTransaction } from 'firebase/firestore';
import { firestore, auth } from '../firebase';
import axios from 'axios';

// ----- AUTH TOKEN FUNCTION -----
const authToken = () => auth.currentUser.getIdToken(true);
// ----- END AUTH TOKEN FUNCTION -----
// ----------------------------------------------

// ----- GET ROUTES -----
const getQR = async (qr) => {
    const snapshot = await getDoc(doc(firestore, 'codes', qr));
    if (snapshot.exists()) {
        return snapshot.data();
    } else {
        return null;
    };
};

const getQRListener = (qr, setQrObj) => {
    const qrRef = doc(firestore, 'codes', qr);
    const qrListener = onSnapshot(qrRef, snapshot => {
        if (snapshot.exists()) {
            setQrObj(snapshot.data());
        };
    });

    return qrListener;
};

const getTrackerSignature = async (qr, date) => {
    const docRef = doc(firestore, 'codes', qr, 'logs', `${date}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return JSON.parse(docSnap.data().signature);
    } else {
        return null;
    };
};

const getSurveySignature = async (qr, date) => {
    const docRef = doc(firestore, 'codes', qr, 'responses', `${date}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return JSON.parse(docSnap.data().signature);
    } else {
        return null;
    };
};

const getGeneratorQr = async (qrId) => {
    const genQrSnap = await getDoc(doc(firestore, 'generator', qrId));

    if (genQrSnap.exists()) return genQrSnap.data();
    throw('Does not exist');
};
// ----- END GET ROUTES -----
// ----------------------------------------------

// ----- PUT ROUTES -----

const addUIDtoDB = async (data) => {
    return await authToken().then(token => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };

        return axios.put(`/api/users/new`, data, config);
    });
};

const updateUserObject = async (id, data) => {
    const userRef = doc(firestore, 'users', id);

    await setDoc(userRef, data, {merge: true});
};

const updateDocument = async (id, data) => {
    const codesRef = collection(firestore, "codes");

    await setDoc(doc(codesRef, id), data);
};

const createRetailCodes = async (num) => {
    return await authToken().then(token => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };

        return axios.put(`/api/admin/codeGen/${num}`, {}, config);
    });
};

const createLiquidQR = async (randKey, data) => {
    return await authToken().then(token => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };

        return axios.put(`/api/qr/user/liquid/new/${randKey}`, data, config);
    });
};

const updateQR = async (id, data) => {
    return await authToken().then(token => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };
        
        return axios.put(`/api/qr/user/liquid/${id}`, data, config);
    });
};

const updateSonar = async (id, data) => {
    return await authToken().then(token => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };

        return axios.put(`/api/qr/sonar/${id}`, data, config);
    });
};

const linkQR = async (id, data) => {
    return await authToken().then(token => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };

        return axios.put(`/api/qr/user/liquid/link/${id}`, data, config);
    });
};

const createNewCategory = async (data) => {
    return await authToken().then(token => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };

        return axios.put(`/api/users/category`, data, config);
    });
};

const storeFiles = async (id, data) => {
    return await authToken().then(async token => {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
        };

        return await axios.put(`/api/qr/user/fileUpload/${id}`, data, config);
    });
};

const storeSnapshot = async(id, data) => {
    return await authToken().then(async token => {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": 'multipart/form-data'
            }
        };

        return axios.put(`/api/qr/user/snapUpload/${id}`, data, config);
    });
};

const storeLogo = async (id, data) => {
    return await authToken().then(token => {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
        };

        return axios.put(`/api/qr/user/logoUpload/${id}`, data, config);
    });
};

const deactivateQR = async (id) => {
    return await authToken().then(token => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };

        return axios.put(`/api/qr/delete/${id}`,{}, config);
    });
};

const resetPassword = async (email) => {
    return axios.put(`/api/admin/reset`, {email: email});
};

const updateTrackerLog = async (qr, data, sigArr) => {
    try {
        return await runTransaction(firestore, async (transaction) => {
            const qrRef = doc(firestore, 'codes', qr);
            const sigRef = doc(firestore, 'codes', qr, 'logs', `${data?.date}`);
            const qrDoc = await transaction.get(qrRef);
            
            if (!qrDoc.exists()) {
                throw "Document does not exist!";
            };

            if (sigArr?.length > 0) {
                await setDoc(sigRef, { signature: JSON.stringify(sigArr) }, { merge: true }).catch(err => console.log(err));
            };
            
            const qrData = qrDoc.data();
        
            if (qrData?.tracker_logs?.length > 0) {
                qrData.tracker_logs.unshift(data);
                return transaction.update(qrRef, { tracker_logs: qrData.tracker_logs });
            } else {
                return transaction.update(qrRef, { tracker_logs: [data] });
            };
        });
    } catch (err) {
        console.log(err);
        return err;
    };
};

const updateSurveyResponse = async (qr, data, sigArr) => {
    try {
        return await runTransaction(firestore, async (transaction) => {
            const qrRef = doc(firestore, 'codes', qr);
            const sigRef = doc(firestore, 'codes', qr, 'responses', `${data?.date}`);
            const qrDoc = await transaction.get(qrRef);

            if (!qrDoc.exists()) {
                throw 'Document does not exist!';
            };

            if (sigArr?.length > 0) {
                await setDoc(sigRef, { signature: JSON.stringify(sigArr) }, { merge: true }).catch(err => console.log(err));
            };

            const qrData = qrDoc.data();

            if (qrData?.survey_responses?.length > 0) {
                qrData.survey_responses.unshift(data);
                return transaction.update(qrRef, { survey_responses: qrData.survey_responses });
            } else {
                return transaction.update(qrRef, { survey_responses: [data] });
            };
        });
    } catch (err) {
        console.log(err);
        return err;
    };
};

const updateTrackerDescription = async(qr, data) => {
    const { description } = data;
    const qrRef = doc(firestore, 'codes', qr);
    
    return await updateDoc(qrRef, { "tracker.description": description})
        .then(() => true)
        .catch(err => {
            console.log(err);
            return false;
        });
};

const updateQRSchedule = async (id, data) => {
    return await authToken().then(token => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };

        return axios.put(`/api/qr/user/schedule/${id}`, data, config);
    });
};

const updateCategories = async (data) => {
    return await authToken().then(token => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };

        return axios.put(`/api/users/category/edit`, data, config);
    });
};

const editTrackerLog = async (qr, data) => {
    try {
        return await runTransaction(firestore, async (transaction) => {
            const qrRef = doc(firestore, 'codes', qr);
            const snapshot = await transaction.get(qrRef);

            if (!snapshot.exists()) {
                return "Document does not exist!";
            };

            const qrData = snapshot.data();
            let updatedLog;
            const updatedLogs = qrData.tracker_logs.map(log => {
                if (log.date === data.date) {
                    const editedLog = {};
                    for (const key in data) {
                        if (key !== 'edits' && key !== 'deleted' && key !== 'date_edited' && log[key] !== data[key]) {
                            if (Array.isArray(log[key]) && Array.isArray(data[key])) {
                                if (!log[key].every(val => data[key].some(v => v === val)) || data[key].length !== log[key].length) {
                                    if (log?.hasOwnProperty(key) && data[key]) editedLog[key] = log[key] ?? '';
                                    log[key] = data[key];
                                } else continue;
                            } else {
                                if (log?.hasOwnProperty(key) && data[key]) editedLog[key] = log[key] ?? '';
                                log[key] = data[key];
                            };
                        };
                    };

                    if (Object?.keys(editedLog)?.length < 1) return;

                    updatedLog = {
                        ...log,
                        date_edited: data.date_edited,
                        edits: log?.edits ? { ...log.edits, [log.date_edited]: { ...editedLog, date_active: log.date_edited, date_edited: data.date_edited }} : { [log.date]: { ...editedLog, date_active: log.date, date_edited: data.date_edited }}
                    };

                    return updatedLog;
                } else {
                    return log;
                };
            });
            
            if (updatedLog?.hasOwnProperty('date_edited') && updatedLog.date_edited === data.date_edited) {
                transaction.update(qrRef, { tracker_logs: updatedLogs});
                return updatedLog;
            } else {
                return data;
            };
        });
    } catch (err) {
        console.log(err);
        return err;
    };
};

const editSurveyResponse = async (qr, data) => {
    try {
        return await runTransaction(firestore, async (transaction) => {
            const qrRef = doc(firestore, 'codes', qr);
            const snapshot = await transaction.get(qrRef);

            if (!snapshot.exists()) {
                return 'Document does not exist!';
            };

            const qrData = snapshot.data();
            let updatedResponse;
            const updatedResponses = qrData.survey_responses.map(response => {
                if (response.date === data.date) {
                    const editedResponse = {};
                    for (const key in data) {
                        if (key !== 'edits' && key !== 'deleted' && key !== 'date_edited' && response[key] !== data[key]) {
                            if (Array.isArray(response[key]) && Array.isArray(data[key])) {
                                if (!response[key].every(val => data[key].some(v => v === val)) || data[key].length !== response[key].length) {
                                    if (response?.hasOwnProperty(key) && data[key]) editedResponse[key] = response[key] ?? '';
                                    response[key] = data[key];
                                } else continue;
                            } else {
                                if (response?.hasOwnProperty(key) && data[key]) editedResponse[key] = response[key] ?? '';
                                response[key] = data[key];
                            };
                        };
                    };

                    if (Object?.keys(editedResponse)?.length < 1) return;

                    updatedResponse = {
                        ...response,
                        date_edited: data.date_edited,
                        edits: response?.edits ? { ...response.edits, [response.date_edited]: { ...editedResponse, date_active: response.date_edited, date_edited: data.date_edited }} : { [response.date]: { ...editedResponse, date_active: response.date, date_edited: data.date_edited }}
                    };

                    return updatedResponse;
                } else {
                    return response;
                };
            });

            if (updatedResponse?.hasOwnProperty('date_edited') && updatedResponse.date_edited === data.date_edited) {
                transaction.update(qrRef, { survey_responses: updatedResponses });
                return updatedResponse;
            } else {
                return data;
            };
        });
    } catch (err) {
        console.log(err);
        return err;
    }
}

const updateTrackerLogToDeleted = async (id, date) => {
    try {
        return await runTransaction(firestore, async (transaction) => {
            const qrRef = doc(firestore, 'codes', id);
            const snapshot = await transaction.get(qrRef);
            
            if (!snapshot.exists()) {
                return false;
            };

            const data = snapshot.data();
            const updatedLogs = data.tracker_logs.map(log => {
                return (log.date === date) ? { ...log, deleted: true } : log;
            });

            transaction.update(qrRef, { tracker_logs: updatedLogs });
            return true;
        });
    } catch (err) {
        console.log(err);
        return err;
    };
};

const updateSurveyResponseToDeleted = async (id, date) => {
    try {
        return await runTransaction(firestore, async (transaction) => {
            const qrRef = doc(firestore, 'codes', id);
            const snapshot = await transaction.get(qrRef);

            if (!snapshot.exists()) {
                return false;
            };

            const data = snapshot.data();
            const updatedResponses = data.survey_responses.map(response => {
                return (response.date === date) ? { ...response, deleted: true } : response;
            });

            transaction.update(qrRef, { survey_responses: updatedResponses });
            return true;
        });
    } catch (err) {
        console.log(err);
        return err;
    };
};

const addQrFromGenerator = async (data) => {
    return await authToken().then(token => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };

        return axios.put(`/api/users/qr/add`, data, config);
    });
};
// ----- END PUT ROUTES -----
// ----------------------------------------------

// ----- DELETE ROUTES -----
const deleteLogo = async (id) => {
    return await authToken().then(token => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };

        return axios.delete(`/api/qr/delete/logoDelete/${id}`, config);
    });
};

const deleteSnapshot = async (id) => {
    return await authToken().then(token => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };

        return axios.delete(`/api/qr/delete/snapDelete/${id}`, config);
    });
};

const deleteFile = async (filePath) => {
    return await authToken().then(async token => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };

        return axios.delete(`/api/qr/delete/file/${filePath}`, config);
    });
};
// ----- END DELETE ROUTES -----
// ----------------------------------------------

// ----- STRIPE ROUTES -----
const createStripeCustomerAPI = async (data) => {
    return await axios({
        method: 'post',
        url: `${process.env.REACT_APP_STRIPE_URL}/create`,
        data: data
    });
};

const createStripeCheckout = async (data) => {
    return await axios({
        method: 'post',
        url: `${process.env.REACT_APP_STRIPE_URL}/checkout`,
        data: data
    });
};

const upgradeStripeSubscription = async (data) => {
    return await axios({
        method: 'post',
        url: `${process.env.REACT_APP_STRIPE_URL}/subscription`,
        data: data
    });
};
// ----- END STRIPE ROUTES -----
// ----------------------------------------------

const API = {
    getQR,
    getQRListener,
    getTrackerSignature,
    getSurveySignature,
    getGeneratorQr,
    addUIDtoDB,
    updateUserObject,
    updateDocument,
    createRetailCodes,
    createLiquidQR,
    updateQR,
    updateSonar,
    linkQR,
    createNewCategory,
    storeFiles,
    storeSnapshot,
    storeLogo,
    deactivateQR,
    resetPassword,
    updateTrackerLog,
    updateSurveyResponse,
    updateTrackerDescription,
    updateQRSchedule,
    updateTrackerLogToDeleted,
    updateSurveyResponseToDeleted,
    deleteLogo,
    deleteFile,
    deleteSnapshot,
    addQrFromGenerator,
    createStripeCustomerAPI,
    createStripeCheckout,
    upgradeStripeSubscription,
    updateCategories,
    editTrackerLog,
    editSurveyResponse
};

export default API;