import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styles from './PDFOptions.module.css';

const PDFOptions = (props) => {
    const { qr_data, dataDispatch, id, qrFiles, setQrFiles, setDeleteFiles } = props;

    const [ file, setFile ] = useState('');
    const [ fileArr, setFileArr ] = useState([]);
    const [ name, setName ] = useState('');
    const [ nameArr, setNameArr ] = useState(qr_data?.pdf?.length > 0 ? qr_data.pdf.map(obj => obj.name) : []);

    useEffect(() => {
        if (qr_data?.pdf?.length > 0) {
            setNameArr(qr_data.pdf.map(obj => obj.name));
        };
    },[qr_data]);

    const handleRemovePdf = (i, name) => {
        if (qrFiles?.find(file => file?.name === name)) {
            setQrFiles(prev => prev.filter(file => file?.name !== name));
            setNameArr(prev => prev.filter(menu => menu !== name));
        } else if (qr_data.pdf[i]?.redirect?.includes(id)) {
            qr_data.pdf[i]?.redirect?.includes(id) && setDeleteFiles(prev => prev.concat(qr_data.pdf[i].redirect));
            setFileArr(prev => prev.splice(i, 1));
        };

        dataDispatch({ type: 'pdf-reset', data: i });
    };

    const handlePdfReset = () => {
        setFile('');
        setName('');
    };

    const handleAddPdf = () => {
        if (file && file?.type === 'application/pdf' && file?.size <= 5000000) {
            // Sets Default PDF to first uploaded PDF or if QR type was switched to PDF
            if (qr_data?.redirect_url === '' || !qr_data?.redirect_url?.includes(id)) {
                dataDispatch({type: 'redirect', data: `${id}/${name}.pdf`});
            };
            
            setNameArr(prev => prev.concat(name));
            setFileArr(prev => prev.concat({file: file, name: name}));
            setQrFiles(prev => prev.concat({data: file, name: name}));
            dataDispatch({type: 'pdf', data: {name: name, redirect: `${id}/${name}.pdf`}});
            handlePdfReset();
        } else {
            setFile('');
        };
    };

    return (
        <div className={styles.container}>
            <OverlayTrigger 
                placement='top'
                delay={{show: 250, hide: 400}}
                trigger={['click', 'hover']}
                overlay={<Tooltip><p>Give your PDF a meaningful name. Scanning your QR will redirect the user to this PDF's file.</p></Tooltip>}
            >
                <h5 className='mb-2'>Attach File(s) <i className='fa-regular fa-circle-info' /></h5>
            </OverlayTrigger>
            <div className={styles.inputGroup}>
                <input type='text' id='pdf-label' maxLength={15} className={styles.input} value={name} onChange={e => setName(e.target.value.replaceAll(' ', '_'))}></input>
                <label htmlFor='pdf-label' className={styles.label} >PDF Name</label>
                {nameArr?.includes(name) ? <p className={styles.dupName}>PDF name cannot match an existing PDF name.</p> : <></>}
            </div>
            <div className={styles.uploadWrap} >
                <input type='file' id='pdf-file-label' className={styles.fileInput} accept='.pdf' onChange={(e) => setFile(e.target.files[0])} ></input>
                <label htmlFor='pdf-file-label' className={styles.fileLabel}>Upload PDF</label>
            </div>
            {(file && file?.type === 'application/pdf' && file?.size <= 5000000) ?
                <div className={styles.warning} style={{lineHeight: '1.5rem', whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>File Selected: <span style={{fontWeight: 'bold'}}>{file.name}</span></div>
            : (file && (file?.type !== 'application/pdf' || file?.size > 5000000)) ? 
                <div className={styles.warning} style={{color: 'red', marginBottom: '0'}} >{file?.type !== 'application/pdf' ? 'File must be type PDF.' : 'File size exceeds 5MB. File must be under 5MB.'}</div>
            : <></>
            }
            <div className={styles.btnContainer}>
                <button className={styles.resetBtn} onClick={handlePdfReset}>Reset</button>
                <button className={styles.addBtn} onClick={handleAddPdf} disabled={!file || file?.type !== 'application/pdf' || file?.size > 5000000 || !name || nameArr?.includes(name)}>Add PDF</button>
            </div>
            <h5 className='mt-4 mb-0'>Current PDF(s)</h5>
            <div className={styles.remaining}>File Slot(s) Remaining: <span style={{fontWeight: 'bold'}}>{5 - Number(qr_data?.pdf?.length ?? 0)}</span></div>
            <div className={styles.displayWrap}>
                {qr_data?.pdf?.length > 0 ?
                    qr_data?.pdf.map((obj, index) => (
                        <div className={styles.displayContainer} key={index}>
                            <div className={styles.nameDisplay} title={obj.name}>{obj.name}</div>
                            <i className={`fa-solid fa-file-pdf ${styles.displayIcon}`} />
                            <button className={styles.removeBtn} onClick={() => handleRemovePdf(index, obj.name)}><i className='fa-solid fa-xmark' /></button>
                        </div>
                    ))
                :
                    <div style={{fontWeight: '600', opacity: '.6'}}>Upload a PDF to see it here.</div>
                }
                {/* {fileArr?.length < 5 ? 
                    <div className={styles.displayContainerIconAdd} onClick={() => setShow(true)} >
                        <div className={styles.nameDisplay}>New PDF</div>
                        <i className={`fa-solid fa-plus ${styles.displayIcon}`} />
                    </div>
                :
                    <></>
                } */}
            </div>
        </div>
    );
};

export default PDFOptions;