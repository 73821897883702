import React, { useState, useEffect } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import styles from './QRDisplayMenu.module.css';

export default function QRDisplayMenu(props) {

    const { categoryOrder, editOrder, setEditOrder, categoryStates, setCategoryStates, setFilters } = props;
    
    const [ show, setShow ] = useState(false);
    const [ allCollapsed, setAllCollapsed ] = useState(Object.values(categoryStates).every(item => item === false));

    const handleTop = (e) => {
        e.preventDefault();
        window.scroll({top: 0, behavior: 'smooth'});
    };

    const handleSelect = (e) => {
        const btnType = e.target.id;
        const header = document.getElementById(btnType);
        const position = header.offsetTop;
        window.scrollTo({ top: position, behavior: 'smooth' });
        setShow(false);
    };

    const handleCollapse = (bool) => {
        setCategoryStates(prev => {
            const next = {};
            Object.keys(prev).forEach(key => {
                next[key] = bool
            });
            return next;
        });
    };

    useEffect(() => {
        setAllCollapsed(Object.values(categoryStates).every(item => item === false));
    },[categoryStates]);

    useEffect(() => {
        const select = document.querySelector(`.${styles.select}`);
        const showMenu = document.querySelector('#show-menu');
        const listener = (e) => {
            if ((e.target !== select && e.target !== showMenu) && !select?.contains(e.target) && e.target.id !== 'select-qr-close') {
                setShow(false);
            };
        };
        window.addEventListener('click', listener);
        return () => window.removeEventListener('click', listener);
    },[]);

    return (
        <div className={styles.container}>
            {categoryOrder?.length > 0 ?
                <>
                <div className={styles.selectBtnWrap} title={show ? 'Close Menu' : 'Open Menu'} style={editOrder ? {outline: 'none'} : null}>
                    <button id='hide-menu' className={show ? `${styles.closeBtn} ${styles.show}` : styles.closeBtn} onClick={() => setShow(false)} disabled={editOrder} style={editOrder ? {display: 'none'} : {display: 'flex'}}><i className='far fa-xmark' style={{pointerEvents: 'none'}} /></button>
                    <button id='show-menu' className={show ? `${styles.openBtn} ${styles.hide}` : styles.openBtn} onClick={() => {setShow(true); setFilters(prev => {return {...prev, searchValue: ''}})}} disabled={editOrder}><i className='fak fa-sidemenu' style={{fontSize: '1.25rem', pointerEvents: 'none'}} /></button>
                </div>
                <div className={styles.collapsedBtnWrap} title={allCollapsed ? 'Expand Sections' : 'Collapse Sections'} style={editOrder ? {outline: 'none'} : null}>
                    <button id='collapse-open' className={allCollapsed ? `${styles.closeBtn} ${styles.show}` : styles.closeBtn} style={(!allCollapsed && editOrder) ? {display: 'none'} : {display: 'flex'}} onClick={() => {handleCollapse(true); setFilters(prev => {return {...prev, searchValue: ''}})}} disabled={editOrder}><i className='far fa-arrows-from-line' style={{pointerEvents: 'none'}} /></button>
                    <button id='collapse-close' className={allCollapsed ? `${styles.openBtn} ${styles.hide}` : styles.openBtn} style={(allCollapsed && editOrder) ? {display: 'none'} : {display: 'flex'}} onClick={() => {handleCollapse(false); setFilters(prev => {return {...prev, searchValue: ''}})}} disabled={editOrder}><i className='far fa-arrows-to-line' style={{pointerEvents: 'none'}} /></button>
                </div>
                <div className={styles.reorderBtnWrap} title={editOrder ? 'Stop Reorder' : 'Start Reorder'} style={editOrder ? {boxShadow: 'none'} : null}>
                    <button id='reorder-close' className={editOrder ? `${styles.closeBtn} ${styles.show}` : styles.closeBtn} onClick={() => {setEditOrder(false); setFilters(prev => {return {...prev, searchValue: ''}})}} style={editOrder ? {boxShadow: 'inset 1px 1px 5px #999'} : null}><i className='far fa-xmark' style={{pointerEvents: 'none'}} /></button>
                    <button id='reorder-open' className={editOrder ? `${styles.openBtn} ${styles.hide}` : styles.openBtn} onClick={() => setEditOrder(true)}><i className='fak fa-reorder' style={{pointerEvents: 'none'}} /></button>
                </div>
                <Collapse in={show} dimension={window.innerWidth > 768 ? 'width' : 'height'}>
                    <div className={styles.selectWrap}>
                        <div className={styles.select} id='cat-select'>
                            <div className={styles.selectHeader}><span>Select Category</span></div>
                            <div className={styles.selectInner}>
                                {categoryOrder.map((cat, i) => (
                                    <div id={cat.id} key={i} className={styles.selectOption} onClick={handleSelect}>{cat.name}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Collapse>
                </>
            : <></>}
            <button className={styles.btn} id='to-top' title='Top of Page' onClick={handleTop}><i className='far fa-arrow-up' /></button>
        </div>
    );
};