import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PasswordResetForm from '../../components/PasswordResetForm';

const Action = (props) => {
    // ----- STATE INITIALIZATION -----
    
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const mode = searchParams.get('mode');
    const actionCode = searchParams.get('oobCode');
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- RETURN -----
    switch (mode) {
        case 'resetPassword':
            return <PasswordResetForm actionCode={actionCode} />;
        default:
            navigate('/');
            return(<></>);
    };
    // ----- END RETURN -----
    // ----------------------------------------------
};

export default Action;