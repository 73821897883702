import React, { useState, useEffect, useReducer } from 'react';
import { Navigate, useSearchParams, useParams } from 'react-router-dom';
import qrOptions from '../../utils/QRFunctions/qrOptions';
import qrData from '../../utils/QRFunctions/qrData';
import EditQR from '../../components/QRSwitch/EditQR';
import QRDisplay from '../../components/QRSwitch/QRDisplay';
import { useAuth } from '../../utils/Auth';
import keyGen from '../../utils/keygen';
import dataURLtoFile from '../../utils/QRFunctions/handleLogo';
import styles from './QRCodes.module.css';

export default function QRCodes() {
    // ------ STATE INITIALIZATION ------
    const { orgObj, liquidQRs, currentUser, analytics } = useAuth();
    const [ searchParams ] = useSearchParams();
    const { path } = useParams();
    
    const [ display, setDisplay ] = useState('qrs');
    const [ filters, setFilters ] = useState({
        searchValue: '',
        categoryValue: '',
        searchType: 'name',
        sortBy: '',
    });

    const [ searchReturnArr, setSearchReturnArr ] = useState(null);
    const [ QRtoEdit, setQRtoEdit ] = useState(null);
    const [ formData, setFormData ] = useState(null);
    const [ logo, setLogo ] = useState(null);
    const [ id, setId ] = useState(null);
    const [ step, setStep ] = useState(0);
    const [ qrID, setQrID ] = useState(searchParams.get('qrid'));
    const [ reference ] = useState(searchParams.get('ref'));
    const [ categories, setCategories ] = useState([]);

    const [qr_options, optionsDispatch] = useReducer((state, action) => {
        state = qrOptions(action, state);
        return state;
    }, null);

    const [qr_data, dataDispatch] = useReducer((state, action) => {
        if (action.type === 'set') {
            state = action.data;
        } else {
            state = qrData(action, state);
        };

        return state;
    }, { redirect_url: '', category: '', tags: [], nickname: '', menu: '', microsite: '', pdf: '', asset: '', tracker: { description:'', columns: [{ archived: false, id: 'date', name: 'date', required: true, options: [], type: 'date' }] }, survey: { description:'', post_survey_options: {title:'', message:'', url:''}, questions: [{ archived: false, id: 'date', name: 'date', required: true, options: [], type: 'date' }] }, alternate_redirect_url: [] });

    useEffect(() => {
        const handleKeyGen = async () => {
            const key = await keyGen();
            setId(key);
        };
        
        if (path === 'new') {
            dataDispatch({type: 'reset', data:{}});
            optionsDispatch({type: 'reset', options: {}});
            handleKeyGen()
        } else {
            setId(null);
        };

        return () => id && setId(null);
    },[keyGen, path]);
    // ------ END STATE INITIALIZATION ------
    // ----------------------------------------------
    
    // ------ QR LOGO FUNCTIONS ------
    // Handles creating form data to save logo to storage
    useEffect(() => {
        if (logo) {
            optionsDispatch({type: 'logo', options: logo});
            setFormData(dataURLtoFile(logo, 'logo.png'));
        } else {
            setFormData(null);
            optionsDispatch({type: 'remove-logo', options: "M"});
        };
    },[logo]);

    useEffect(() => {
        if (orgObj?.hasOwnProperty('categories') && categories.length < orgObj?.categories?.length) {
            setCategories(orgObj?.categories);
        };
    },[orgObj, orgObj?.categories, categories]);

    // Handles QR to edit logo
    useEffect(() => {
        if (QRtoEdit?.data?.qr_style?.image?.includes('data:image') && formData === null) {
            setFormData(dataURLtoFile(QRtoEdit?.data?.qr_style?.image, 'logo.png'));
        };
    },[QRtoEdit?.data?.qr_style?.image, formData]);
    // ------ END QR LOGO FUNCTIONS ------
    // ----------------------------------------------

    // ------ RESET STATES ------
    const handleReset = () => {
        dataDispatch({type: 'reset', data: {}});
        optionsDispatch({type: 'reset', options: {}});
        setLogo(null);
        setFormData(null);
        setDisplay('qrs');
        setQRtoEdit(null);
    };
    // ------ END RESET STATES ------
    // ----------------------------------------------

    // ------ RETURN CONDITIONALS ------
    const displaySwitch = () => {
        switch(path) {
            case 'new':
            case 'edit':
                if (path === 'edit' && (!QRtoEdit || !Object?.keys(QRtoEdit).length)) return (<Navigate to={'/collection'} />);
                return (<EditQR
                    path={path}
                    formData={formData}
                    handleReset={handleReset}
                    id={path === 'new' ? id : QRtoEdit?.id}
                    searchReturnArr={searchReturnArr}
                    setSearchReturnArr={setSearchReturnArr}
                    org_id={orgObj.org_id}
                    plan={orgObj.plan}
                    plan_id={orgObj.plan.plan_id}
                    QRtoEdit={QRtoEdit}
                    reference={reference}
                    categories={categories}
                    setCategories={setCategories}
                    logo={logo}
                    setLogo={setLogo}
                    step={step}
                    setStep={setStep}
                    qr_data={qr_data}
                    dataDispatch={dataDispatch}
                    qr_options={qr_options}
                    optionsDispatch={optionsDispatch}
                />);
            default:
                return (<QRDisplay
                    activeQRs={liquidQRs}
                    linkedQRs={liquidQRs?.filter(qr => qr?.data?.linked_qr)}
                    analytics={analytics}
                    categories={categories}
                    dataDispatch={dataDispatch}
                    edit={searchParams.get('edit')}
                    liquidQRLength={liquidQRs.filter(obj => !obj.id.startsWith('-')).length}
                    optionsDispatch={optionsDispatch}
                    orgId={orgObj.org_id}
                    plan={orgObj.plan}
                    qr_options={qr_options}
                    qr_data={qr_data}
                    uid={currentUser.uid}
                    display={display}
                    setDisplay={setDisplay}
                    qrID={qrID}
                    setQrID={setQrID}
                    searchReturnArr={searchReturnArr} 
                    setSearchReturnArr={setSearchReturnArr}
                    filters={filters}
                    setFilters={setFilters}
                    setQRtoEdit={setQRtoEdit}
                />);
        };
    };

    if (orgObj && liquidQRs && analytics) {
        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    {displaySwitch()}
                </div>
            </div>
        );
    } else if (currentUser && !orgObj) {
        return (<></>);
    } else {
        return (<Navigate to='/login' />);
    };
    // ------ END RETURN CONDITIONALS ------
    // ----------------------------------------------
};