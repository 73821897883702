import React, { useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import styles from './ColumnTypes.module.css';

const TextBox = ({ state, setState, column }) => {
    const { id, name, required } = column;
    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <label htmlFor={id} className={styles.label}>{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                <input id={id} type='text' className={styles.input} maxLength='100' value={state[id]} placeholder='Your answer' onChange={e => setState(prev => ({ ...prev, [id]: e.target.value}))} ></input>
            </div>
        </div>
    );
};

const TextArea = ({ state, setState, column }) => {
    const { id, name, required } = column;
    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <label htmlFor={id} className={styles.label} >{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                <textarea id={id} value={state[id]} className={styles.textarea} placeholder='Your answer' maxLength={256} onChange={e => setState(prev => ({ ...prev, [id]: e.target.value}))} ></textarea>
            </div>
        </div>
    );
};

const Number = ({ state, setState, column }) => {
    const { id, name, required } = column;
    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <label htmlFor={id} className={styles.label} >{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                <input id={id} type='number' placeholder={0} className={styles.input} value={state[id]} onFocus={(e) => e.target.addEventListener("wheel", (e) => { e.preventDefault() }, { passive: false })} onChange={e => setState(prev => ({ ...prev, [id]: e.target.value}))}></input>
            </div>
        </div>
    );
};

const Dropdown = ({ state, setState, column }) => {
    const { id, options, name, required } = column;
    return (
        <div className={styles.section}>
            <div className={styles.inputGroupSelect}>
                <label htmlFor={id} className={styles.label} >{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                <select id={id} value={state[id] ?? ''} className={styles.dropdown} onChange={e => setState(prev => ({ ...prev, [id]: e.target.value}))} >
                    <option value='' disabled>Select an option</option>
                    {options?.map((name, index) => (<option key={name + index} className={styles.option} value={name}>{name}</option>))}
                </select>
            </div>
        </div>
    );
};

const Signature = ({ column, sigCanvas, setSigCanvas, setState, edit }) => {
    const { id, name, required } = column;

    useEffect(() => {
        if (edit && sigCanvas) {
            sigCanvas.off();
        };
    },[edit, sigCanvas]);

    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <div className={styles.signatureLabelContainer}>
                    <label className={styles.label}>{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                    {edit ? <p style={{paddingLeft: '.5rem', marginBottom: '0.25rem', fontSize: '.85rem'}}>Signatures cannot be edited.</p> : <button className={styles.btn} onClick={() => { sigCanvas.clear(); setState(prev => ({ ...prev, [id]: false } )) }} disabled={edit}>Clear</button>}
                </div>
                <SignatureCanvas ref={(ref) => {setSigCanvas(ref)}} onEnd={() => setState(prev => ({...prev, [id]: sigCanvas?.isEmpty() ? true : false}))} penColor='black' canvasProps={{ width: 350, height: 100, className:`${styles.trackerCanvas}` }} />
            </div>
        </div>
    );
};

const Radio = ({ state, setState, column }) => {
    const { id, name, options, required } = column;
    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <label className={styles.label} >{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                {options.map((opt, index) => (
                    <div key={opt + index} className={styles.checkGroup} >
                        <input type='radio' name={`${column}_options`} id={id+index} className={styles.checkbox} value={opt} checked={state[id] === opt} onChange={(e) => setState(prev => ({ ...prev, [id]: e.target.value}))} ></input>
                        <label htmlFor={id+index} className={styles.checkboxOption} >{opt}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

const Checkbox = ({ state, setState, column }) => {
    const { id, name, options, required } = column;
    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <label className={styles.label} >{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                {options.map((opt, index) => (
                    <div key={opt + index} className={styles.checkGroup}>
                        <input type='checkbox' id={id+index} className={styles.checkbox} defaultChecked={state[id]?.includes(opt) ?? false} onChange={e => setState(prev => e.target.checked ? ({ ...prev, [id]: prev[id].concat(opt) }) : ({...prev, [id]: prev[id].filter(x => x !== opt) }))} ></input>
                        <label htmlFor={id+index} className={styles.checkboxOption}>{opt}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

const ColumnTypes = ({ column, state, setState, sigCanvas, setSigCanvas, edit }) => {
    switch (column?.type) {
        case 'text-box':
            return <TextBox state={state} setState={setState} column={column} />;
        case 'text-area':
            return <TextArea state={state} setState={setState} column={column} />;
        case 'number':
            return <Number state={state} setState={setState} column={column} />;
        case 'dropdown':
            return <Dropdown state={state} setState={setState} column={column} />;
        case 'signature':
            return <Signature column={column} sigCanvas={sigCanvas} setSigCanvas={setSigCanvas} setState={setState} edit={edit} />;
        case 'radio':
            return <Radio state={state} setState={setState} column={column} />;
        case 'checkbox':
            return <Checkbox state={state} setState={setState} column={column} />;
        default:
            return <></>;
    };
};

export default ColumnTypes;