const getTotalScansWeek = (analytics) => {
  const today = Date.now()
  const todayDate = new Date(today)
  const todayDay = todayDate.getDay()

  let dayNumPattern = [todayDay]

  for (let i = 1; i < 7; i++) {
    todayDay - i >= 0 ? dayNumPattern.unshift(todayDay - i) : dayNumPattern.unshift(todayDay - i + 7)
  }

  // console.log(dayNumPattern)

  const weekPast = new Date(today - 518400000)
  
  weekPast.setHours(0)
  weekPast.setMinutes(0)
  weekPast.setSeconds(0)
  weekPast.setMilliseconds(0)

  const weekPastMidnight = weekPast.getTime()

  let weekAnalytics = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: []
  }

  Object.keys(analytics).forEach(key => {
    const qrArr = analytics[key].filter(qr => qr.date > weekPastMidnight)

    qrArr.forEach(qr => {
      let date = qr.date
      let dateStr = new Date(date)
      let dayNum = dateStr.getDay()

      weekAnalytics[dayNum].push(qr)
    })
  })

  let chartData = []

  dayNumPattern.forEach(day => {
    chartData.push(weekAnalytics[day].length)
  })

  return chartData
}

export default getTotalScansWeek