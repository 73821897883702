import React, { useState, useEffect } from 'react';
import ColumnTypes from '../ColumnTypes';
import API from '../../../API';
import styles from './AddLog.module.css';

const AddLog = (props) => {
    const { id, columns, setShowAddLog, editLog, setEditLog } = props;
    const { updateTrackerLog, editTrackerLog, getTrackerSignature } = API;
    
    const [ error, setError ] = useState('');
    const [ disabled, setDisabled ] = useState(false);
    const [ sigCanvas, setSigCanvas ] = useState(null);
    const [ newLog, setNewLog ] = useState(columns.reduce((acc, column) => {
        if (column?.archived) return acc;
        if (editLog && editLog?.hasOwnProperty(column.id)) {
            acc[column.id] = editLog[column.id];
            return acc;
        };

        acc[column.id] = column?.type === 'checkbox' ? [] : '';
        return acc;
    }, {}));

    useEffect(() => {
        if (editLog && columns?.find(col => col?.type === 'signature') && sigCanvas) {
            const handleLogSig = async () => {
                await getTrackerSignature(id, editLog.date).then(res => {
                    if (res) {
                        sigCanvas.fromData(res);
                    };
                }).catch(err => console.log(err));
            };

            handleLogSig();
        };
    },[editLog, columns, sigCanvas, id]);

    const handleAddLog = async () => {
        console.log(newLog)
        const logValid = columns?.reduce((acc, cur) => {
            if (!acc) return acc;

            if (cur?.required) {
                switch (cur?.type) {
                    case 'date':
                        break;
                    case 'signature':
                        if (editLog && editLog?.hasOwnProperty(cur.id)) {
                            break;
                        };

                        acc = (!sigCanvas?.isEmpty() ? true : false);
                        break;
                    case 'checkbox':
                    default:
                        acc = (newLog[cur.id]?.length > 0 ? true : false);
                        break;
                };
            };

            return acc;
        }, true);

        if (!logValid) {
            setError('Please fill out all required fields');
            return;
        };

        const newDate = Date.now();
        newLog.date = (editLog && editLog?.hasOwnProperty('date')) ? editLog.date : newDate;
        newLog.date_edited = newDate;
        let sigArr = [];

        if (columns?.find(col => col?.type === 'signature')) {
            const col = columns?.find(col => col?.type === 'signature');
            if (col?.required && sigCanvas?.isEmpty() && !editLog) {
                setError('Please sign before submitting');
                return;
            } else if (sigCanvas?.isEmpty()) {
                sigArr = [];
                newLog[col.id] = false;
            } else {
                sigArr = sigCanvas.toData();
                newLog[col.id] = true;
            };
        };

        (editLog && Object?.keys(editLog)?.length > 0) ? await editTrackerLog(id, newLog).then(res => setEditLog(res)).catch(err => console.log(err)) : await updateTrackerLog(id, newLog, sigArr).catch(err => console.log(err));
        handleReset();
    };

    const handleReset = () => {
        (sigCanvas && !editLog) && sigCanvas.clear();
        setNewLog(columns.reduce((acc, column) => {
            if (column?.archived) return acc;
            
            acc[column.id] = column?.type === 'checkbox' ? [] : '';
            return acc;
        }, {}));
        setDisabled(false);
        setShowAddLog(false);
    };

    return (
        <div className={styles.addLogContainer}>
            <div className={styles.typesContainer}>
                {columns?.filter(column => !column?.archived)?.map((column, index) => (
                    <ColumnTypes
                        key={index}
                        column={column}
                        state={newLog}
                        setState={setNewLog}
                        sigCanvas={sigCanvas}
                        setSigCanvas={setSigCanvas}
                        edit={(editLog && Object?.keys(editLog)?.length) ? true : false}
                    />
                ))}
            </div>
            <div className={styles.btnContainer}>
                <div style={{display: 'flex', justifyContent: 'space-evenly',  alignItems: 'center'}}>
                    <button className={`${styles.btn} ${styles.cancel} me-2`} onClick={handleReset}>Cancel</button>
                    <button
                        className={`${styles.save} ${styles.btn} ms-2`}
                        onClick={() => { setDisabled(() => true); handleAddLog(); }}
                        disabled={!newLog || disabled || columns?.reduce((acc, cur) => {
                            if (acc) return acc;

                            if (cur?.required) {
                                switch (cur?.type) {
                                    case 'date':
                                        break;
                                    case 'signature':
                                        if (editLog && editLog?.hasOwnProperty(cur.id)) {
                                            break;
                                        };
                                        acc = (sigCanvas?.isEmpty() ? true : false);
                                        break;
                                    case 'checkbox':
                                    default:
                                        acc = (newLog[cur.id]?.length > 0 ? false : true);
                                        break;
                                };
                            };

                            return acc;
                        }, false)}
                    >{disabled ? <i className='fa-duotone fa-spinner fa-spin' /> : 'Save'}</button>
                </div>
                <div style={{color: 'red'}}><i className='far fa-asterisk me-1' style={{fontSize: '.9rem'}} />- Required</div>
                {error ? <p className={styles.error}>{error}</p> : <></>}
            </div>
        </div>
    );
};

export default AddLog;