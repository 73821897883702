import React from 'react';
// import handleLabels from '../../../../../utils/QRFunctions/handleLabels';
import styles from './RedirectLinkOptions.module.css';

const RedirectLinkOptions = (props) => {
    const { qr_data, dataDispatch } = props;

    return (
        <div className={styles.container}>
            <h5 className='mb-2'>URL</h5>
            <div className={styles.inputGroup}>
                <input
                    type='url'
                    id='redirect_url-label'
                    className={styles.input}
                    onChange={e => {
                        dataDispatch({type: 'redirect', data: e.target.value});
                        dataDispatch({type: 'alt-redirect', data: e.target.value, index: 0 });
                    }}
                    value={qr_data.redirect_url}
                />
                <label htmlFor='redirect_url-label' className={styles.label} style={{marginRight:'0.5rem'}}>Redirect (URL)</label>
            </div>
            {/* {qr_data?.alternate_redirect_url?.map((url,i) => i !== 0 ? (
                <div key={i} className={styles.inputGroup}>
                    <input type='url' id={`alt-redirect_url-${i}-label`} className={styles.input} onChange={e => dataDispatch({type: 'alt-redirect', data: e.target.value, index: i, newBool: false })} value={url} onBlur={handleLabels} />
                    <label htmlFor={`alt-redirect_url-${i}-label`} className={styles.label} style={{marginRight:'0.5rem'}}>Alternate Redirect (URL)</label>
                </div>
            ) : <Fragment key={i} />)} */}
            {/* <button className={styles.altRedirect} onClick={() => dataDispatch({ type: 'alt-redirect', data: '', index: qr_data.alternate_redirect_url?.length, newBool: true }) }>Add <i className='far fa-plus' /></button> */}
        </div>
    );
};

export default RedirectLinkOptions;