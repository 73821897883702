import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import QRSortMenu from './QRSortMenu';
import AddBtn from './AddBtn';
import Search from './Search';
import QRReturnByCategory from './QRReturnByCategory';
import styles from './QRDisplay.module.css';

const QRDisplay = (props) => {
    // ----- STATE INITIALIZATION -----
    const {
        searchReturnArr,
        setSearchReturnArr,
        setDisplay,
        optionsDispatch,
        dataDispatch,
        activeQRs,
        linkedQRs,
        categories,
        setQRtoEdit,
        analytics,
        plan,
        liquidQRLength,
        qrID,
        setQrID,
        filters,
        setFilters,
        uid,
        orgId,
        edit
    } = props;

    const navigate = useNavigate();

    
    useEffect(() => {
        if (edit === 'true' && qrID && searchReturnArr?.length) {
            const QR = searchReturnArr?.find(obj => obj.id === qrID);
            setQRtoEdit(QR);
            setDisplay('edit-qr');
            optionsDispatch({type: 'edit', options: { ...QR.data.qr_style, type:'svg' }});
            dataDispatch({type: 'edit', data: {
                redirect_url: QR.data.redirect_url ?? QR.data.url,
                category: QR.data?.category ?? '',
                tags: QR.data?.tags ?? [],
                nickname: QR.data.nickname,
                menu: QR.data?.menu ?? '',
                microsite: QR.data?.microsite ?? '',
                pdf: QR.data?.pdf ?? '',
                location: QR.data?.location ?? '',
                asset: QR.data?.asset ?? '',
                tracker: QR.data?.tracker ?? { description: '', columns: [] },
                survey: QR.data?.survey ?? { description: '', questions: []},
                schedule: QR.data?.schedule ?? '',
                alternate_redirect_url: QR.data?.alternate_redirect_url ?? [],
            }});
            setQrID('');
            navigate('/collection/edit');
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchReturnArr, qrID, edit]);

    const [ layout, setLayout ] = useState(() => {
        if (window.innerWidth >= 768) {
            const saved = localStorage.getItem(`${uid}_layout`);
            const initialValue = JSON.parse(saved);
            return initialValue !== null ? initialValue : 'grid';
        } else return 'grid'
    });

    const { categoryValue, searchType, searchValue, sortBy } = filters;
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------    

    // INITIAL QR DISPLAY LAYOUT (SAVED IN LOCAL STORAGE)
    useEffect(() => {
        localStorage.setItem(`${uid}_layout`, JSON.stringify(layout));
    },[layout, uid]);

    const handleShowText = (id) => {
        const gridText = document.querySelector(`#grid-text`);
        const gridIcon = document.querySelector(`#grid-icon`);
        const listText = document.querySelector(`#list-text`);
        const listIcon = document.querySelector(`#list-icon`);

        const gridTimeout = () => {
            setTimeout(() => {
                gridText.classList.add(styles.hide);
                gridIcon.classList.remove(styles.hide);
            }, 1000)
        };
        const listTimeout = () => {
            setTimeout(() => {
                listText.classList.add(styles.hide);
                listIcon.classList.remove(styles.hide);
            }, 1000);
        };

        if (id === 'grid') {
            gridText.classList.remove(styles.hide);
            gridIcon.classList.add(styles.hide);
            gridTimeout();
        } else if (id === 'list') {
            listText.classList.remove(styles.hide);
            listIcon.classList.add(styles.hide);
            listTimeout();
        };
    };
    
    // ------ END LAYOUT FUNCTIONS ------
    // ----------------------------------------------

    // ------ SEARCH FUNCTIONS ------

    const handleSearchType = (type) => {
        setFilters(prev => {
            return {
                ...prev,
                searchValue: '',
                searchType: type
            };
        });
    };

    const handleSearchValue = (e) => {
        e.preventDefault();

        setFilters(prev => {
            return {
                ...prev,
                searchValue: e.target.value
            };
        });
    };

    useEffect(() => {

        const handleSonarFilter = (arr) => {
            if (qrID && !edit) {
                setFilters({
                    sortBy: '',
                    searchType: 'sonar',
                    searchValue: '',
                    categoryValue: ''
                });
                return handleSearchFilter(arr.filter(obj => obj.id.includes(qrID)));
            };

            return handleSortByFilter(arr);
        };

        const handleSortByFilter = (arr) => {
            // Sort helper for newest/oldest
            // check out this StackOverflow for explanation of Space Ship Operator 
            // https://stackoverflow.com/a/9175783
            const sortHelper = (a,b) => (a < b) - (a > b);
            let result;
            switch(sortBy) {
                case 'newest':
                    result = arr.sort((a,b) => sortHelper((a.data?.created_at ?? '2024-01-01T00:00:00.000Z'), (b.data?.created_at ?? '2024-01-01T00:00:00.000Z')) || sortHelper(b?.data?.nickname?.toLowerCase(), a?.data?.nickname?.toLowerCase()));
                    break;
                case 'oldest':
                    result = arr.sort((a,b) => sortHelper((b.data?.created_at ?? '2024-01-01T00:00:00.000Z'), (a.data?.created_at ?? '2024-01-01T00:00:00.000Z')) || sortHelper(a?.data?.nickname?.toLowerCase(), b?.data?.nickname?.toLowerCase()));
                    break;
                case 'alpha-asc':
                    result = arr.sort((a,b) => a?.data?.nickname?.toLowerCase() > b?.data?.nickname?.toLowerCase() ? 1 : -1);
                    break;
                case 'alpha-des':
                    result = arr.sort((a,b) => a?.data?.nickname?.toLowerCase() < b?.data?.nickname?.toLowerCase() ? 1 : -1);
                    break;
                default:
                    if (!sortBy) {
                        result = arr.sort((a,b) => a?.data?.nickname?.toLowerCase() > b?.data?.nickname?.toLowerCase() ? 1 : -1);
                    } else {
                        result = arr.filter(qr => qr?.data?.qr_type === sortBy).sort((a,b) => b?.data?.nickname?.toLowerCase() > a?.data?.nickname?.toLowerCase() ? 1 : -1);
                    };
                    break;
            };
            return handleCategoryFilter(result);
        };

        const handleCategoryFilter = (arr) => {
            if (categoryValue) {
                return handleSearchFilter(arr.filter(obj => obj.data.category && obj.data.category === categoryValue));
            } else {
                return handleSearchFilter(arr);
            };
        };

        const handleSearchFilter = (arr) => {
            if (searchValue?.length > 0) {
                if (searchType === 'name' && searchValue) {
                    return arr.filter(obj => obj.data.nickname.toLowerCase().includes(searchValue.toLowerCase()));
                } else if (searchType === 'tag' && searchValue) {
                    return arr.filter(obj => {
                        if (obj.data.tags?.length > 0) {
                            const tempArr = obj.data.tags.filter(tag => tag.toLowerCase().includes(searchValue.toLowerCase()));
                            if (tempArr.length > 0) {
                                return obj;
                            } else return null;
                        } else {
                            return null;
                        }
                    });
                };
            } else {
                return arr;
            };
        };

        setSearchReturnArr(handleSonarFilter([...activeQRs?.filter(qr => !qr?.data?.linked_qr)]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchValue, categoryValue, searchType, activeQRs, sortBy, qrID]);

    // ------ END SEARCH FUNCTIONS ------
    // ----------------------------------------------

    // ------ SONAR SEARCH -------
    const handleRemoveSonar = () => {
        setFilters({
            sortBy: '',
            searchType: 'name',
            searchValue: '',
            categoryValue: ''
        });
        setQrID('');
        navigate('/collection');
    };

    // ----- RETURN CONDITIONALS -----

    const displayReturn = () => {
        if (searchReturnArr?.length > 0 || activeQRs?.length > 0) {
            return (
                <>
                <div className={styles.tagWrap} >
                    {(searchType === 'name' || searchType === 'tag') ?
                        <div className={styles.inputGroupTop}>
                            <input type='text' id='nickname-label' className={styles.input} value={searchValue} onChange={handleSearchValue} ></input>
                            <label htmlFor='nickname-label' className={styles.label}>{searchType === 'name' ? 'Name' : 'Tag'}</label>
                            <Search searchType={searchType} handleSearchType={handleSearchType} />
                        </div>
                    : searchType === 'sonar' ?
                        <button className={styles.sonar} onClick={handleRemoveSonar}>Return to all QRs</button>
                    : 
                        <></>
                    }
                    <QRSortMenu filters={filters} setFilters={setFilters} />
                </div>
                {searchReturnArr?.length > 0 ?
                    <QRReturnByCategory uid={uid} orgId={orgId} searchReturnArr={searchReturnArr} layout={layout} setQRtoEdit={setQRtoEdit} setDisplay={setDisplay} optionsDispatch={optionsDispatch} dataDispatch={dataDispatch} analytics={analytics} plan={plan} filters={filters} setFilters={setFilters} categories={categories} searchValue={searchValue} linkedQRs={linkedQRs} />
                :
                    <div className={styles.noReturnValue}>{searchValue?.includes('category value') ? searchValue : `No QRs found.`}</div>
                }
                </>
            );
        } else {
            return (
                <>
                    <div className={styles.noQRs}>
                        <p>You don't have any QRs yet.</p>
                        <p>Click the plus sign (top left) to create a QR!</p>
                    </div>
                    <div className={styles.shineWrap}>
                        <i className='fa-thin fa-certificate fa-spin' />
                    </div>
                </>
            );
        };
    };

    return (
        <div className={styles.container}>
            {displayReturn()}
            <AddBtn activeQRs={activeQRs} liquidQRLength={liquidQRLength} plan={plan} />
            {searchReturnArr?.length > 0 ?
                <div className={styles.layout}>
                    <button className={layout === 'grid' ? `${styles.gridBtn} ${styles.active}` : styles.gridBtn} id='grid' onMouseEnter={e => handleShowText(e.target.id)} onClick={e => setLayout(e.target.id)}>
                        <p id='grid-text' className={`${styles.gridText} ${styles.hide}`} style={{pointerEvents: 'none'}}>Grid</p>
                        <i id='grid-icon' className={`fas fa-grid ${styles.gridIcon}`} style={{pointerEvents: 'none'}} />
                    </button>
                    <button className={layout === 'list' ? `${styles.listBtn} ${styles.active}` : styles.listBtn} id='list' onMouseEnter={e => handleShowText(e.target.id)} onClick={e => setLayout(e.target.id)}>
                        <p id='list-text' className={`${styles.listText} ${styles.hide}`} style={{pointerEvents: 'none'}}>List</p>
                        <i id='list-icon' className={`fas fa-list ${styles.listIcon}`} style={{pointerEvents: 'none'}} />
                    </button>
                </div>
            : <></>}
        </div>
    );
};

export default QRDisplay;