import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import styles from './PasswordResetForm.module.css';

export default function PasswordResetForm(props) {
    // ----- STATE INITIALIZATION -----
    const { actionCode } = props;

    const [ password, setPassword ] = useState('');
    const [ success, setSuccess ] = useState(false);
    const [ validCode, setValidCode ] = useState(false);
    const [ verifiedCode, setVerifiedCode ] = useState(false);
    const [ error, setError ] = useState('');

    const navigate = useNavigate();
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------


    // ----- AUTH FUNCTIONS -----
    useEffect(() => {
        verifyPasswordResetCode(auth, actionCode)
            .then(() => {
                setValidCode(true);
                setVerifiedCode(true);
            }, err => {
                setError(err.message);
                setValidCode(false);
                setVerifiedCode(false);
            })
    },[actionCode]);

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                navigate('/login');
                clearTimeout();
            }, 5000);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[success]);

    const handlePasswordReset = () => {
        confirmPasswordReset(auth, actionCode, password)
            .then(() => {
                setSuccess(true);
            }, err => {
                setError(err.message);
            });
    };
    // ----- END AUTH FUNCTIONS -----
    // ----------------------------------------------

    // ----- COMPONENT RETURN -----
    const handleComponent = () => {
        if (!verifiedCode) {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <h2 style={{textAlign: 'center', marginBottom: '1rem'}}>Password Reset</h2>
                        <div>Gathering bytes, please hold.</div>
                    </div>
                </div>
            );
        } else if (success) {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <h2 style={{textAlign: 'center', marginBottom: '1rem'}}>Password Reset</h2>
                        <div>Successfully reset password! You will be redirected to login with your new password.</div>
                    </div>
                </div>
            );
        } else if (verifiedCode && validCode) {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <h2 style={{textAlign: 'center', marginBottom: '1rem'}} >Password Reset</h2>
                        <div className={styles.inputGroup} >
                            <input 
                                type='password'
                                id='pw-reset-form-password-label'
                                className={styles.input}
                                style={{marginBottom: '1rem'}}
                                value={password}
                                onChange={(e) => {setPassword(e.target.value)}}
                                required
                            ></input>
                            <label htmlFor='pw-reset-form-password-label' className={styles.label}>New Password</label>
                        </div>
                        <button className={styles.btn} onClick={handlePasswordReset} disabled={!password} >Submit</button>
                    </div>
                </div>
            );
        } else if (verifiedCode && !validCode) {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <h2 style={{textAlign: 'center', marginBottom: '1rem'}} >Password Reset</h2>
                        <div>Error reseting your password. Please request another <a href={`${window.location.href.split('/action')[0]}/reset`}>password reset</a>.</div>
                        <div>{error}</div>
                    </div>
                </div>
            );
        };
    };
    // ----- COMPONENT RETURN -----
    // ----------------------------------------------

    switch (success) {
        case false:
            return handleComponent();
        default: 
            navigate('/login');
            return <></>;
    };
};