import React, { useState } from 'react';
import Dots from '../../Style/Dots';
import Background from '../../Style/Background';
import CornerFrame from '../../Style/CornerFrame';
import CornerEye from '../../Style/CornerEye';
import Logo from '../../Style/Logo';
import Shape from '../../Style/Shape';
import QRRef from '../../QRRef';
import Nickname from '../../Nickname';
import styles from './Style.module.css';

export default function Style(props) {
    const { qr_options, optionsDispatch, qr_data, dataDispatch, setLogo, setParent, handleLink, loading } = props;

    const [ display, setDisplay ] = useState('nickname');

    return (
        <>
        <button className={styles.backBtn} onClick={() => setParent({obj: {}, selected: false})}><i className='far fa-arrow-left me-1' />Back</button>
        <button className={styles.createBtn} onClick={handleLink} disabled={!qr_data?.nickname}>{loading ? <i className='fas fa-spinner fa-spin me-1' /> : 'Finish'}</button>
        <div className={styles.containerInner}>
            <div className={styles.containerInner1}>
                <h5 className='w-100 text-center'>Customization</h5>
                <div className={styles.radioContainer}>
                    <input type='radio' className={styles.radio} onChange={() => setDisplay('nickname')} id='nickname' checked={display === 'nickname'} />
                    <label htmlFor='nickname' className={styles.label}>
                        <span className={styles.title}>Nickname</span>
                        <span className={styles.logo}>{qr_data?.nickname ? <i className='far fa-square-check' /> : <i className='far fa-square' />}</span>
                    </label>
                    <input type='radio' className={styles.radio} onChange={() => setDisplay('shape')} id='shape' checked={display === 'shape'} />
                    <label htmlFor='shape' className={styles.label}>
                        <span className={styles.title}>Shape</span>
                        <span className={qr_options?.shape === 'square' ? styles.square : `${styles.square} ${styles.circle}`} />
                    </label>
                    <input type='radio' className={styles.radio} onChange={() => setDisplay('dots')}  id='dots' checked={display === 'dots'} />
                    <label htmlFor="dots" className={styles.label}>
                        <span className={styles.title}>Dots</span>
                        <span className={styles.color} style={qr_options?.dotsOptions?.gradient ? {background: `linear-gradient(to right, ${qr_options.dotsOptions.gradient.colorStops[0].color}, ${qr_options.dotsOptions.gradient.colorStops[1].color})`} : qr_options?.dotsOptions?.gradient === null ? {backgroundColor: qr_options.dotsOptions.color} : {backgroundColor: '#fff'}} />
                    </label>
                    <input type='radio' className={styles.radio} onChange={() => setDisplay('background')} id='background' checked={display === 'background'} />
                    <label htmlFor='background' className={styles.label}>
                        <span className={styles.title}>Background</span>
                        <span className={styles.color} style={qr_options?.backgroundOptions?.gradient ? {background: `linear-gradient(to right, ${qr_options.backgroundOptions.gradient.colorStops[0].color}, ${qr_options.backgroundOptions.gradient.colorStops[1].color})`} : qr_options?.backgroundOptions?.gradient === null ? {backgroundColor: qr_options.backgroundOptions.color} : {backgroundColor: '#fff'}} />
                    </label>
                    <input type='radio' className={styles.radio} onChange={() => setDisplay('corner-frame')} id='corner-frame' checked={display === 'corner-frame'} />
                    <label htmlFor="corner-frame" className={styles.label}>
                        <span className={styles.title}>Corner Frame</span>
                        <span className={styles.color} style={qr_options?.cornersSquareOptions?.gradient ? {background: `linear-gradient(to right, ${qr_options.cornersSquareOptions.gradient.colorStops[0].color}, ${qr_options.cornersSquareOptions.gradient.colorStops[1].color})`} : qr_options?.cornersSquareOptions?.gradient === null ? {backgroundColor: qr_options.cornersSquareOptions.color} : {backgroundColor: '#fff'}} />
                    </label>
                    <input type='radio' className={styles.radio} onChange={() => setDisplay('corner-eye')} id='corner-eye' checked={display === 'corner-eye'} />
                    <label htmlFor='corner-eye' className={styles.label}>
                        <span className={styles.title}>Corner Eye</span>
                        <span className={styles.color} style={qr_options?.cornersDotOptions?.gradient ? {background: `linear-gradient(to right, ${qr_options.cornersDotOptions.gradient.colorStops[0].color}, ${qr_options.cornersDotOptions.gradient.colorStops[1].color})`} : qr_options?.cornersDotOptions?.gradient === null ? {backgroundColor: qr_options.cornersDotOptions.color} : {backgroundColor: '#fff'}} />
                    </label>
                    <input type='radio' className={styles.radio} onChange={() => setDisplay('logo')} id='logo' checked={display === 'logo'} />
                    <label htmlFor="logo" className={styles.label}>
                        <span className={styles.title}>Logo</span>
                        <span className={styles.logo}>{qr_options?.image ? <i className='far fa-image' /> : <i className='far fa-image-slash' />}</span>
                    </label>
                </div>
                {display === 'logo' ?
                    <Logo setLogo={setLogo} />
                    : display === 'dots' ?
                    <Dots qr_options={qr_options} optionsDispatch={optionsDispatch} />
                    : display === 'background' ?
                    <Background qr_options={qr_options} optionsDispatch={optionsDispatch} />
                    : display === 'corner-frame' ?
                    <CornerFrame qr_options={qr_options} optionsDispatch={optionsDispatch} />
                    : display === 'corner-eye' ?
                    <CornerEye qr_options={qr_options} optionsDispatch={optionsDispatch} />
                    : display === 'shape' ? 
                    <Shape qr_options={qr_options} optionsDispatch={optionsDispatch} />
                    : display === 'nickname' &&
                    <Nickname qr_data={qr_data} dataDispatch={dataDispatch} setUp />
                }
            </div>
            <div className={styles.containerInner2}>
                <QRRef qr_options={qr_options} />
            </div>
        </div>
        </>
    );
};