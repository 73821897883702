import React, { useState } from 'react';
import styles from './Logo.module.css';

export default function Logo(props) {
    // ----- STATE INITIALIZATION -----
    const { setLogo } = props;
    const [ fileName, setFileName ] = useState(null);
    const [ error, setError ] = useState(false);
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- CONVERT FILE -----
    const toBase64 = async file => await new Promise(async (resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    // ----- END CONVERT FILE -----
    // ----------------------------------------------

    // ----- HANDLE LOGO -----
    const handleLogo = async (e) => {
        const file = e.target.files[0];
        const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];

        if (validFileTypes.includes(file.type) && file.size <= 5000000) {
            setFileName(file.name);
            setLogo(await toBase64(file));
        } else {
            setFileName(null);
            setLogo(false);

            if (!validFileTypes.includes(file.type)) {
                setError('File type not accepted.');
            } else {
                setError('File size limit exceeded. File must be less than 5MB.');
            };
        };
    };

    const handleRemoveLogo = async () => {
        setLogo(false);
        setFileName(null);
        const input = document.getElementById('logoUpload');
        input.value = '';
    };
    // ----- END HANDLE LOGO -----
    // ----------------------------------------------

    return (
        <div className={styles.container}>
            <div className={styles.inputGroup}>
                <input id='logoUpload' type='file' className={styles.fileInput} onChange={handleLogo} accept='image/png, image/jpeg' />
                <label htmlFor='logoUpload' className={styles.label}><i className='far fa-upload me-1' />Upload</label>
            <button className={styles.removeBtn} onClick={handleRemoveLogo} >Remove</button>
            </div>
            {fileName ?
                <div className={styles.chosen}>File Chosen: {fileName}</div>
            : error ? 
                <div className={styles.error}>{error}</div>
            :   
                <></>
            }
        </div>
    );
};