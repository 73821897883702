import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import SonarSwitch from '../../SonarSwitch';
import CustomDownload from '../../CustomDownload';
import QRCodeStyling from 'pp-qr-code';
import { handleQRReset, handleTempObj } from '../../../../../utils/QRFunctions/downloads';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import API from '../../../../../API';
import LinkedQRDisplay from '../LinkedQRDisplay';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import styles from './List.module.css';

export default function List(props) {

    const { QR, orgId, analytics, setQRtoEdit, setDisplay, dataDispatch, optionsDispatch, childQRs } = props;
    const { deactivateQR, deleteFile } = API;

    const qrRef = useRef(null);
    const deleteRef = useRef(null);
    const navigate = useNavigate();
    
    const { ref, inView } = useInView({
        root: null,
        rootMargin: '0px 0px -25px',
        threshold: .25
    });

    const [ showDownloadModal, setShowDownloadModal ] = useState(false);
    const [ scanCount, setScanCount ] = useState(0);
    const [ showDelete, setShowDelete ] = useState(false);
    const [ showConfirm, setShowConfirm ] = useState(false);
    const [ showChildren, setShowChildren ] = useState(false);
    const [ icon ] = useState(() => {
        if (QR?.data?.qr_type) {
            switch(QR?.data.qr_type) {
                case 'asset':
                    return 'fas fa-shelves';
                case 'microsite':
                    return 'fas fa-square-list';
                case 'location':
                    return 'fas fa-map-location';
                case 'link':
                    return 'fas fa-link-simple';
                case 'pdf':
                    return 'fas fa-file-pdf';
                case 'menu':
                    return 'fas fa-fork-knife';
                case 'appointment':
                    return 'fas fa-calendar-days';
                case 'tracker':
                    return 'fas fa-clipboard-list-check';
                case 'survey':
                    return 'fa-regular fa-square-poll-vertical';
                default:
                    return '';
            };
        };
    });

    const options = QR?.data?.qr_style;
    const [code, setCode] = useState(inView ? new QRCodeStyling(options) : null);

    const onVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            if (code && inView && options) {
                code.update(options);
                code.append(qrRef.current);
            };
        };
    };

    useLayoutEffect(() => {
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => document.removeEventListener("visibilitychange", onVisibilityChange);
    }, []);

    useEffect(() => {
        if (inView && !code && options) {
            setCode(() => new QRCodeStyling(options));
        } else if (inView && code && options) {
            code.update(options);
            code.append(qrRef.current);
        };
    },[inView, code, options, setCode]);
    
    useEffect(() => {
        if (analytics) {
            if (analytics[QR?.id]) {
                setScanCount(analytics[QR?.id].length);
            } else {
                setScanCount(0);
            };
        } else {
            setScanCount(0);
        };
    },[QR, analytics]);
    // end onload and update

    // ----- DOWNLOAD QR -----
    const handleDownload = async (type, size, fileName, correction) => {
        const downloadCode = new QRCodeStyling(options);
        const tempObj = handleTempObj(options);
        options.imageOptions = {
            ...options.imageOptions,
            crossOrigin: 'anonymous'
        };
        if (size) {
            options.width = size;
            options.height = size;
        };
        if (!fileName) {
            fileName = 'QRcode';
        };
        if (!type) {
            type = 'png';
        };
        if (correction) {
            options.qrOptions.errorCorrectionLevel = correction;
        };

        downloadCode.update(options);
        await downloadCode.download({extension: type, name: fileName});
        downloadCode.update(handleQRReset(options, tempObj));
    };

    const handleCustomDownloadClick = () => {
        setShowDownloadModal(true);
    };

    // ----- END DOWNLOAD QR -----
    // ----------------------------------------------

    const handleEditQR = () => {
        setQRtoEdit(QR);
        setDisplay('edit-qr');
        optionsDispatch({type: 'edit', options: QR.data.qr_style});
        dataDispatch({type: 'edit', data: {
            redirect_url: QR.data.redirect_url ?? QR.data.url,
            category: QR.data?.category ?? '',
            tags: QR.data?.tags ?? [],
            nickname: QR.data.nickname,
            menu: QR.data?.menu ?? '',
            microsite: QR.data?.microsite ?? '',
            pdf: QR.data?.pdf ?? '',
            location: QR.data?.location ?? '',
            asset: QR.data?.asset ?? '',
            tracker: QR.data?.tracker ?? { description: '', columns: [] },
            survey: QR.data?.survey ?? { descriptino: '', questions: [] },
            schedule: QR.data?.schedule ?? '',
            alternate_redirect_url: QR.data?.alternate_redirect_url ?? []
        }});
        navigate('/collection/edit');
    };

    const handleShowDeleteConfirm = () => {
        setShowConfirm(true);
        let btn = document.getElementById(`qr-delete-${QR.id}`);
        btn?.classList.add(styles.confirm);

        setTimeout(() => {
            let words = document.querySelector(`.${styles.deleteContent2}.qr-${QR.id}`);
            words?.classList.add(styles.confirm);
        },250);
    };

    const handleShowDelete = () => {
        setShowDelete(true);
    };

    const handleHideDelete = () => {
        setShowDelete(false);
        setShowConfirm(false);
    };

    const cancelDelete = () => {
        setShowConfirm(false);
        let btn = document.getElementById(`qr-delete-${QR.id}`);
        btn?.classList.remove(styles.confirm);
    };

    const handleDelete = async () => {
        if (QR.data.qr_type === 'pdf') {
            QR.data.pdf.forEach(pdf => deleteFile(pdf.redirect));
        } else if (QR.data.qr_type === 'menu') {
            QR.data.menu.forEach(obj => {if (obj.redirect.includes(QR.id)) deleteFile(obj.redirect)});
        } else if (QR.data.qr_type === 'asset' && QR.data.asset?.route?.length > 0) {
            QR.data.asset.route.forEach(fileURL => {
                deleteFile(fileURL);
            });
        };

        await deactivateQR(QR.id)
            .catch(err => console.log('Failed to delete QR.'));
    };

    useEffect(() => {
        if (window.innerWidth <= 768) {
            handleShowDelete();
        };
    },[]);

    useEffect(() => {
        const watcher = (e) => {
            if (!deleteRef.current || (deleteRef.current.contains(e.target) && (e.target.id !== `cancel-x-${QR?.id}` && e.target.id !== `delete-check-${QR?.id}`))) {
                return;
            } else {
                let btn = document.getElementById(`qr-delete-${QR.id}`);
                btn?.classList.remove(styles.confirm);
                setShowConfirm(false);
            };
        };
        window.addEventListener('click', watcher);
        return () => window.removeEventListener('click', watcher);
    },[QR?.id]);

    return (
        <>
        <div className={styles.listWrap} ref={ref} id={QR?.id}>
            <div className={styles.listCodeWrap}>
                <div className={styles.listCode} ref={qrRef} onClick={() => window.open(`https://lqr.ai/${QR.id}?orgId=${orgId}`, '_blank')} >{!code ? <i className='fa-duotone fa-spinner fa-spin' /> : <></>}</div>
                <div className={styles.listCodeWrapInner}>
                    <div className={styles.scanCount}><i className='fas fa-eye me-1' />{scanCount}</div>
                    {childQRs?.length > 0 ?
                        <OverlayTrigger
                            placement='top'
                            delay={{show: 250, hide: 400}}
                            trigger={['click','hover']}
                            overlay={<Tooltip>View Linked QRs</Tooltip>}
                        >
                            <button className={styles.childBtn} onClick={() => setShowChildren(true)}><i className='fa-regular fa-object-exclude me-1' />{childQRs.length}</button>
                        </OverlayTrigger>
                        : <></>
                    }
                </div>
            </div>
            <div className={styles.listCard} onMouseEnter={window.innerWidth > 768 ? handleShowDelete : null} onMouseLeave={window.innerWidth > 768 ? handleHideDelete : null}>
                <div className={styles.listDetails}>
                    <OverlayTrigger
                        placement='top'
                        delay={{show: 250, hide: 400}}
                        trigger={['click','hover']}
                        overlay={<Tooltip>{QR.data.nickname}</Tooltip>}
                    >
                        <h5 className={styles.listHeader}>{QR.data.nickname}</h5>
                    </OverlayTrigger>
                    <div className={styles.detail}>Type: <span>{QR?.data?.qr_type?.charAt(0)?.toUpperCase() + QR.data?.qr_type?.slice(1)}</span><i className={`${icon} ms-2`} /></div>
                    {QR.data?.tags?.length > 0 ? 
                        <OverlayTrigger
                            placement='top'
                            delay={{show: 250, hide: 400}}
                            trigger={['click','hover']}
                            overlay={
                                <Tooltip>
                                    {QR.data.tags.map((tag, i) => (
                                        <div key={i} className={styles.tag}>{tag}<span>,</span></div>
                                    ))}
                                </Tooltip>
                            }
                        >
                            <div className={styles.overlayTrigger}>View Tags</div>
                        </OverlayTrigger> 
                        :
                        <></>
                    }
                </div>
                <div className={styles.listDivider} />
                <div className={styles.listSonarWrap}>
                    <SonarSwitch QR={QR} scanCount={scanCount} layout={'list'} />
                </div>
                <div className={styles.listDivider} />
                <div className={styles.listBtnWrap} >
                    <button className={styles.edit} style={{marginBottom: '0'}} onClick={handleEditQR}>Edit</button>
                    <Dropdown>
                        <Dropdown.Toggle id='qr-download' className={styles.download}><i className='fas fa-download me-3' /></Dropdown.Toggle>
                        <Dropdown.Menu className={styles.dropdownMenu}>
                            <Dropdown.Item onClick={() => handleDownload('png', null, QR.data?.nickname.replace(/\s+/g, ''))}>PNG</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDownload('svg', null, QR.data?.nickname.replace(/\s+/g, ''))}>SVG</Dropdown.Item>
                            <Dropdown.Item onClick={handleCustomDownloadClick}>Add a Frame!</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {showDelete &&
                    <button className={styles.delete} id={`qr-delete-${QR?.id}`} ref={deleteRef} onClick={handleShowDeleteConfirm}>
                        {showConfirm ? 
                            <div className={`${styles.deleteContent2} qr-${QR.id}`}>
                                <span className='me-4'>Are you sure?</span>
                                <div id={`delete-check-${QR?.id}`} className={styles.deleteCheck} style={{marginRight: '.5rem'}} onClick={handleDelete}><i className='fas fa-check' style={{pointerEvents: 'none'}}></i></div>
                                <span style={{width: '.5rem', borderRight: '1px solid #ccc', marginRight: '.5rem'}} />
                                <div id={`cancel-x-${QR?.id}`} className={styles.cancelX} onClick={cancelDelete}><i className='fas fa-xmark' style={{pointerEvents: 'none'}}></i></div>
                            </div>
                            :
                            <i className='fas fa-xmark' style={{pointerEvents: 'none'}}></i>
                        }  
                    </button>
                }
                <div className={styles.extraSpace}></div>
            </div>
        </div>
        {showDownloadModal ? 
            <CustomDownload QR={QR} showDownloadModal={showDownloadModal} setShowDownloadModal={setShowDownloadModal} nickname={QR.data?.nickname} />
            :<></>
        }
        <Modal className={styles.linkedModal} show={showChildren} onHide={() => setShowChildren(false)}>
            <Modal.Header closeButton><span>Linked QR(s)</span></Modal.Header>
            <Modal.Body>
                <Swiper
                    key={Date.now()}
                    effect={'coverflow'}
                    grabCursor={true}
                    slidesPerView={'auto'}
                    centeredSlides={true}
                    watchOverflow={true}
                    loop={childQRs?.length > 3 ? true : false}
                    observer={true}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: false,
                    }}
                    pagination={true}
                    modules={[EffectCoverflow, Pagination]}
                    className={styles.qrSwiper}
                >
                    {childQRs.map((qr,index) => (
                        <SwiperSlide key={qr.id} className={styles.qrSwiperSlide}>
                            <LinkedQRDisplay 
                                QR={qr} 
                                setQRtoEdit={setQRtoEdit} 
                                setDisplay={setDisplay} 
                                optionsDispatch={optionsDispatch}
                                dataDispatch={dataDispatch}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Modal.Body>
        </Modal>
        </>
    );
};