import React, { useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

export const options = {
  responsive: true,
  plugins: {
    legend: false,
    title: {
      display: true,
      text: 'LiquidQR Scans',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export const data = {
  labels: ["a", "b", "c", "d", "e", "f", "g"],
  datasets: [
    {
      label: 'LiquidQR Scans',
      data: [0, 0, 0, 0, 0, 0, 0],
      borderColor: '#607aa4',
      backgroundColor: '#607aa4',
    }
  ],
};

export const updateChart = (chart, chartLabels, chartData) => {
  chart.data.datasets.forEach((dataset) => {
    dataset.data = chartData
  })

  chart.data.labels = chartLabels

  chart.update()
}

export function CombinedChart(props) {

  const chartRef = useRef(null);

  const { chartLabels, chartData } = props

  useEffect(() => {

    if(chartLabels && chartData){

        const chart = chartRef.current; 

        updateChart(chart, chartLabels, chartData);

    }

  }, [chartLabels, chartData])

  return <Line options={options} data={data} ref={chartRef} />;
}