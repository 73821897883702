import React from 'react';
import styles from './NotFound.module.css';

const NotFound = () => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <h1>404</h1>
                <h2>Page Not Found</h2>
            </div>
        </div>
    );
};

export default NotFound;