import React, { useState } from 'react';
import ColorPicker from '../ColorPicker';
import styles from './CornerFrame.module.css';

export default function CornerFrame(props) {
    // ----- STATE INITIALIZATION -----
    const { qr_options, optionsDispatch } = props;

    const [ colorType, setColorType ] = useState(qr_options?.cornersSquareOptions?.gradient ? 'gradient' : 'single');
    const [ gradientType, setGradientType ] = useState(qr_options?.cornersSquareOptions?.gradient?.type ? qr_options.cornersSquareOptions.gradient.type : 'linear');
    const [ displayColorPicker, setDisplayColorPicker ] = useState(null);
    const [ firstColor, setFirstColor ] = useState(qr_options?.cornersSquareOptions?.color ? qr_options.cornersSquareOptions.color : qr_options?.cornersSquareOptions?.gradient?.colorStops ? qr_options.cornersSquareOptions.gradient.colorStops[0].color : '#000000');
    const [ secondColor, setSecondColor ] = useState(qr_options?.cornersSquareOptions?.gradient?.colorStops ? qr_options?.cornersSquareOptions?.gradient?.colorStops[1].color : '#000000');
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- HANDLE FRAME TYPE -----
    const handleCornerFrameStyle = (e) => {
        optionsDispatch({type: 'frame', options: {type: e.target.value}});
    };
    // ----- END HANDLE FRAME TYPE -----
    // ----------------------------------------------

    // ----- HANDLE COLOR FUNCTIONS -----
    const handleColorType = (e) => {
        setColorType(e.target.id);
        if(e.target.id === 'single') {
            setGradientType('linear');
            optionsDispatch({type: 'frame', options: {color: firstColor}});
            optionsDispatch({type: 'frame', options: {gradient: null}});
        } else if (e.target.id === 'gradient') {
            optionsDispatch({type: 'frame', options: {gradient: {...qr_options?.cornersSquareOptions.gradient, colorStops: [{offset: 0, color: firstColor}, {offset: 1, color: secondColor}]}}});
        };
    };

    const handleGradientType = (e) => {
        setGradientType(e.target.id);
        optionsDispatch({type: 'frame', options: {gradient: {...qr_options?.cornersSquareOptions.gradient, type: e.target.id}}});
    };

    const handleColorChange = (e, name) => {
        if (colorType === 'single') {
            setFirstColor(e);
            optionsDispatch({type: 'frame', options: {color: e}});
        } else if (colorType === 'gradient') {
            if (name === 'gradient-color1') {
                setFirstColor(e);
                optionsDispatch({type: 'frame', options: {gradient: {...qr_options?.cornersSquareOptions.gradient, colorStops: [{offset: 0, color: e}, {offset: 1, color: secondColor}]}}});
            } else if (name === 'gradient-color2') {
                setSecondColor(e);
                optionsDispatch({type: 'frame', options:{gradient:{...qr_options?.cornersSquareOptions.gradient, colorStops: [{offset: 0, color: firstColor}, {offset: 1, color: e}]}}});
            };
        };
    };
    // ----- HANDLE COLOR FUNCTIONS -----
    // ----------------------------------------------

    // ----- HANDLE ROTATION -----
    const handleRotation = (e) => {
        optionsDispatch({type: 'frame', options: {gradient: {...qr_options?.cornersSquareOptions.gradient, rotation: e.target.value}}});
    };
    // ----- END HANDLE ROTATION -----
    // ----------------------------------------------

    return (
        <div className={styles.container}>
            <div className={styles.inputGroupSelect}>
                <label htmlFor='corner-frame-type' className={styles.label}>Corner Frame Style</label>
                <select className={styles.select} id='corner-frame-type' onChange={handleCornerFrameStyle} defaultValue={qr_options?.cornersSquareOptions?.type}>
                    <option value=''>None</option>
                    <option value='square'>Square</option>
                    <option value='dot'>Dot</option>
                    <option value='extra-rounded'>Extra Rounded</option>
                </select>
            </div>
            <div className={styles.inputGroup}>
                <label className={styles.label}>Color Type</label>
                <input type='radio' className={styles.radio} onChange={handleColorType} id='single' checked={colorType === 'single'} />
                <label htmlFor='single' className={styles.radioBtn}>Single Color</label>
                <input type='radio' className={styles.radio} onChange={handleColorType} id='gradient' checked={colorType === 'gradient'} />
                <label htmlFor='gradient' className={styles.radioBtn}>Color Gradient</label>
            </div>
            {colorType === 'single' ?
                <div className={styles.inputGroup}>
                    <label className={styles.label}>Corner Frame Color</label>
                    <div className={styles.swatch} onClick={() => setDisplayColorPicker('corner-frame-color')}>
                        <div className={styles.color} style={{background: firstColor}} />
                    </div>
                    { displayColorPicker === 'corner-frame-color' ? 
                        <div className={styles.popover}>
                            <div className={styles.cover} onClick={() => setDisplayColorPicker(null)} />
                            <ColorPicker
                                name='single-color'
                                color={ firstColor }
                                onChange={handleColorChange}
                            />
                        </div> : null }
                </div>
                :
                <>
                <div className={styles.inputGroup}>
                    <label className={styles.label}>Gradient Type</label>
                    <input type='radio' className={styles.radio} onChange={handleGradientType} id='linear' checked={gradientType === 'linear'} />
                    <label htmlFor='linear' className={styles.radioBtn}>Linear</label>
                    <input type='radio' className={styles.radio} onChange={handleGradientType} id='radial' checked={gradientType === 'radial'} />
                    <label htmlFor='radial' className={styles.radioBtn}>Radial</label>
                </div>
                <div className={styles.inputGroup}>
                    <label className={styles.label}>Corner Square Gradient</label>
                    <div className='me-2'>
                        <div className={styles.swatch} onClick={() => setDisplayColorPicker('corner-frame-color1')}>
                            <div className={styles.color} style={{background: firstColor}} />
                        </div>
                        { displayColorPicker === 'corner-frame-color1' ? 
                            <div className={styles.popover}>
                                <div className={styles.cover} onClick={() => setDisplayColorPicker(null)} />
                                <ColorPicker
                                    name='gradient-color1'
                                    color={ firstColor }
                                    onChange={handleColorChange}
                                />
                            </div> : null }
                    </div>
                    <div>
                        <div className={styles.swatch} onClick={() => setDisplayColorPicker('corner-frame-color2')}>
                            <div className={styles.color} style={{background: secondColor}} />
                        </div>
                        { displayColorPicker === 'corner-frame-color2' ? 
                            <div className={styles.popover}>
                                <div className={styles.cover} onClick={() => setDisplayColorPicker(null)} />
                                <ColorPicker
                                    name='gradient-color2'
                                    color={ secondColor }
                                    onChange={handleColorChange}
                                />
                            </div> : null }
                    </div>
                </div>
                <div className={styles.inputGroup} style={{marginBottom: '0'}}>
                    <label htmlFor='rotation' className={styles.label}>Rotation</label>
                    <input id='rotation' type='number' min='0' max='7' className={styles.rotation} value={qr_options?.cornersSquareOptions?.gradient?.rotation ? qr_options.cornersSquareOptions.gradient.rotation : '0'} onChange={handleRotation} />
                </div>
                </>
            }
        </div>
    );
};