import React, { useState, useEffect } from 'react';
import styles from './Scheduling.module.css';

export default function Scheduling() {

    const [ time, setTime ] = useState('');
    const [ day, setDay ] = useState('');

    useEffect(() => {

        if (time && day) {
            const date = new Date();
            date.setDay(+day)
            date.setHours(time.split(':')[0]);
            date.setMinutes(time.split(':')[1]);
        };
    },[time, day]);


    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <input type='time' value={time} onChange={e => setTime(e.target.value)}></input>
                <select defaultValue={day} onChange={e => setDay(e.target.value)}>
                    <option value=''>Select a day</option>
                    <option value='0'>Sunday</option>
                    <option value='1'>Monday</option>
                    <option value='2'>Tuesday</option>
                    <option value='Wednesday'>Wednesday</option>
                    <option value='Thursday'>Thursday</option>
                    <option value='Friday'>Friday</option>
                    <option value='Saturday'>Saturday</option>
                </select>
            </div>
        </div>
    );
};