import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AddBtn.module.css';
import keyGen from '../../../../utils/keygen';

export default function AddBtn(props) {

    const { activeQRs, liquidQRLength, plan } = props;
    const navigate = useNavigate();
    
    const [ showAdd, setShowAdd ] = useState(false);
    const [ disableNewBtn, setDisableNewBtn ] = useState(false);

    useEffect(() => {
        setDisableNewBtn(plan.liquid_qrs <= liquidQRLength);

        return () => setDisableNewBtn(false);
    },[activeQRs, liquidQRLength, plan]);

    const handleShowAdd = () => {
        let shine1 = document.querySelector(`${styles.shine2}`);
        let shine2 = document.querySelector(`${styles.shine5}`);
        let shine3 = document.querySelector(`${styles.shine6}`);
        let btn = document.querySelector(`.${styles.addBtn}`);

        shine1?.classList.remove(styles.shine);
        shine2?.classList.remove(styles.shine);
        shine3?.classList.remove(styles.shine);
        setShowAdd(true);
        btn?.classList.add(styles.stretch);

        setTimeout(() => {
            let words = document.querySelector(`.${styles.addContent}`);
            words?.classList.add(styles.stretch);
        },250);
    };

    const handleHideAdd = () => {
        setShowAdd(false);
        let btn = document.querySelector(`.${styles.addBtn}`);
        btn.classList.remove(styles.stretch);

        setTimeout(() => {
            let shine1 = document.querySelector(`${styles.shine2}`);
            let shine2 = document.querySelector(`${styles.shine5}`);
            let shine3 = document.querySelector(`${styles.shine6}`);
            shine1?.classList.add(styles.shine);
            shine2?.classList.add(styles.shine);
            shine3?.classList.add(styles.shine);
        },250);
    };

    const handleAddQR = () => {
        navigate('/collection/new');
        setShowAdd(false);
    };

    return (
        <button className={styles.addBtn} onMouseEnter={window.innerWidth > 768 ? handleShowAdd : null} onMouseLeave={window.innerWidth > 768 ? handleHideAdd : null} onClick={handleAddQR} disabled={disableNewBtn}>
            {showAdd ?
                <div className={styles.addContent}>
                    <span className='me-1'>New</span>
                    <i className='fas fa-plus' style={{pointerEvents: 'none'}} />
                </div>
                :
                <i className='fas fa-plus' style={{pointerEvents: 'none'}} />
            }
        </button>
    );
};