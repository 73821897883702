import React, { useState, useEffect, Fragment } from 'react';
import AddLog from '../AddLog/index.js';
import API from '../../../API/index.js';
import SignatureCanvas from 'react-signature-canvas';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styles from './Table.module.css';

const Log = (props) => {
    const { selectedLog, setSelectedLog, selectedSignature, columns, editLogEntry, setEditLogEntry, id } = props;

    const [ sigCanvas, setSigCanvas ] = useState(null);
    const [ changeLog, setChangeLog ] = useState(selectedLog?.edits ?? {});
    const [ showChanges, setShowChanges ] = useState(false);
    
    useEffect(() => {
        sigCanvas && sigCanvas?.clear();
        if (sigCanvas && selectedSignature) {
            sigCanvas?.clear();
            sigCanvas?.fromData(selectedSignature);
            sigCanvas?.off();
        };
    },[sigCanvas, selectedSignature]);

    useEffect(() => {
        if (selectedLog?.edits) setChangeLog(() => selectedLog?.edits);
    },[selectedLog, setChangeLog]);

    if (selectedLog && !editLogEntry) {
        return (
            <div className={styles.selectedLogContainer}>
                {selectedLog?.deleted ? <div className={styles.deleted}><p>Deleted Log</p></div> : <></>}
                {columns?.map((col, index) => {
                    switch(col?.type) {
                        case 'signature':
                            return (
                                <div className={styles.selectedLog} key={col?.type + index}>
                                    <div title={col?.name}>{col?.name}</div>
                                    {selectedLog[col?.id] ? <SignatureCanvas ref={(ref) => {setSigCanvas(ref)}} penColor='black' canvasProps={{ width: 350, height: 100, className:`${styles.trackerCanvas}` }} /> : <p>No Signature</p>}
                                </div>
                            );
                        case 'date':
                            return (
                                <div className={styles.selectedLog} key={col?.type + index}>
                                    <div title={"Date"}>Date</div>
                                    <p>{new Date(selectedLog[col?.id]).toLocaleString()}</p>
                                </div>
                            );
                        case 'checkbox': 
                            return (
                                <div className={styles.selectedLog} key={col?.type + index}>
                                    <div title={col?.name}>{col?.name}</div>
                                    <p>{selectedLog[col?.id]?.join(', ')}</p>
                                </div>
                            );
                        case 'text-area':
                            return (
                                <div className={styles.selectedLog} key={col?.type + index}>
                                    <div title={col?.name}>{col?.name}</div>
                                    {(selectedLog[col?.id] && selectedLog[col?.id].split('\n').length > 0) ? selectedLog[col?.id].split('\n').map((text, index) => <p key={index}>{text}</p>) : <></>}
                                </div>
                            );
                        default:
                            return (
                                <div className={styles.selectedLog} key={col?.type + index}>
                                    <div title={col?.name}>{col?.name}</div>
                                    <p>{selectedLog[col?.id]}</p>
                                </div>
                            );
                    };
                })}
                {(changeLog && Object.keys(changeLog).length > 0) ?  
                    <div className={styles.changeLogWrapper}>
                        {showChanges ?
                            <>
                            <div className={styles.changeLogContainer} >
                                <h4>Edits:</h4>
                                {Object.keys(changeLog).sort((a, b) => Number(b) - Number(a)).map((date) => 
                                    <div className={styles.changeLogEntry} key={date}>
                                        <p className={styles.changeLogDate}>{new Date(Number(changeLog[date].date_edited))?.toLocaleString()}</p>
                                        {Object.keys(changeLog[date]).map((key, index) => {
                                            if (key === 'date_active' || key === 'date_edited') {
                                                return <Fragment key={key} ></Fragment>
                                            };

                                            return (
                                                <div className={styles.changeLogColumn} key={key + index}>
                                                    <p className={styles.changeLogColumnName}>{columns.find(col => col.id === key)?.name}:</p>
                                                    <p className={styles.changeLogValue}>{changeLog[date][key] ? ((columns.find(col => col.id === key)?.type === 'checkbox' && changeLog[date][key]?.length > 0) ? changeLog[date][key]?.join(', ') : changeLog[date][key]) :  'No Value'}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                            <div className={styles.btnContainer}>
                                <span className={styles.changeLog} onClick={() => setShowChanges(false)} >Hide Edits</span>
                            </div>
                            </>
                        :
                            <div className={styles.btnContainer}>
                                <span className={styles.changeLog} onClick={() => setShowChanges(true)} >Show Edits</span>
                            </div>
                        }
                    </div>
                :
                    <></>
                }
            </div>
        );
    } else if (selectedLog && editLogEntry) {
        return <AddLog id={id} columns={columns} setShowAddLog={setEditLogEntry} editLog={selectedLog} setEditLog={setSelectedLog} />;
    } else {
        return (<></>);
    };
};

const Table = (props) => {
    const { id, columns, logs, setShowAddLog, editLogEntry, setEditLogEntry, selectedLog, setSelectedLog, authenticatedUser, deletedToggle, setDeletedToggle } = props;
    const { getTrackerSignature } = API;

    const [ error, setError ] = useState('');
    const [ selectedSignature, setSelectedSignature ] = useState(null);
    const [ sortAsc, setSortAsc ] = useState(false);

    const handleGetSignature = async (log, show) => {
        setError('');
        
        const signatureId = columns?.find(col => col?.type === 'signature')?.id ?? null;
        
        if (selectedSignature && selectedSignature?.length > 0) {
            setSelectedSignature(() => null);
        };

        if (show && log[signatureId]) {
            await getTrackerSignature(id, log.date).then((res) => {
                if (res) {
                    setSelectedLog(log);
                    setSelectedSignature(res);
                } else {
                    setError('No Signature Found');
                    setSelectedSignature(null);
                };
            }).catch((err) => {
                console.log(err);
                setError('Something went wrong, please try again');
            });
        } else if (show && !log[signatureId]) {
            setSelectedLog(log);
        } else {
            setSelectedLog('');
        };
    };

    const handleSort = (logs) => {
        if (deletedToggle) {
            return logs.filter(l => l?.deleted).sort((a, b) => !sortAsc ? b.date - a.date : a.date - b.date)
        };

        return logs.filter(l => !l?.deleted).sort((a, b) => !sortAsc ? b.date - a.date : a.date - b.date)
    };

    if (error) return (<div className={styles.error}>{error}</div>);
    
    if (!logs) return (<></>);

    if (selectedLog) {
        return (<Log selectedLog={selectedLog} setSelectedLog={setSelectedLog} selectedSignature={selectedSignature} columns={columns} editLogEntry={editLogEntry} setEditLogEntry={setEditLogEntry} id={id} />)
    };

    return (
        <>
        <div className={styles.btnContainer}>
            <button className={styles.btn} onClick={() => setShowAddLog(true)} >Add Log</button>
        </div>
        <div className={styles.logContainer} >
            <div className={styles.logSection} >
                <div className={styles.table}>
                    <div className={styles.logHeaders}>
                        {columns?.map((column, index) => {
                            if (column?.type ==='date') return (
                                <div className={`${styles.logType} ${styles.dateHeader}`} style={{width: `calc(100% / ${columns?.length - 1})`}} key={index} onClick={() => setSortAsc(prev => !prev)} >
                                    Date
                                    <i className={sortAsc ? `far fa-caret-down ms-2 ${styles.ascend}` : `far fa-caret-down ms-2`} />
                                </div>
                            );

                            return (
                                <OverlayTrigger
                                    placement='top'
                                    delay={{show: 250, hide: 400}}
                                    trigger={['click','hover']}
                                    overlay={<Tooltip>{column?.name}</Tooltip>}
                                    key={index}
                                >
                                    <div className={styles.logType} key={index} style={{width: `calc(100% / ${columns?.length - 1})`}}>
                                        <span>{column?.name}</span>
                                    </div>
                                </OverlayTrigger>
                            );
                        })}
                    </div>
                    {logs?.length > 0 ?
                        handleSort(logs)?.map((log, index) => {
                            return (
                                <div className={deletedToggle ? `${styles.logRow} ${styles.deletedLog}` : `${styles.logRow}`} key={index} onClick={() => handleGetSignature(log, selectedLog?.date === log?.date ? false : true)}>
                                    {columns?.map((column, index) => {
                                        switch (column?.type) {
                                            case 'checkbox':
                                                return (
                                                    <OverlayTrigger
                                                        placement='top'
                                                        delay={{show: 250, hide: 400}}
                                                        trigger={['click','hover']}
                                                        key={index}
                                                        overlay={<Tooltip>{log[column?.id]?.length > 0 ? log[column?.id]?.join(', ') : ''}</Tooltip>}
                                                    >
                                                        <div className={styles.logType} key={index} style={{width: `calc(100% / ${columns?.length - 1})`}}>
                                                            <span>{(log?.hasOwnProperty('edits') && Object?.keys(log?.edits)?.length > 0 && index === 0) ? '*' : ''}{log[column?.id]?.length > 0 ? log[column?.id]?.join(', ') : ''}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                );
                                            case 'date':
                                                return (
                                                    <div className={styles.logType} key={index} style={{width: `calc(100% / ${columns?.length - 1})`}}>
                                                        <span>{(log?.hasOwnProperty('edits') && Object?.keys(log?.edits)?.length > 0 && index === 0) ? '*' : ''}{new Date(log[column?.id]).toLocaleString()}</span>
                                                    </div>
                                                );
                                            case 'signature':
                                                return (
                                                    <div className={styles.logType} key={index} style={{width: `calc(100% / ${columns?.length - 1})`}}>
                                                        <span>{(log?.hasOwnProperty('edits') && Object?.keys(log?.edits)?.length > 0 && index === 0) ? '*' : ''}{log[column?.id] ? 'Signed' : 'No Signature'}</span>
                                                    </div>
                                                );
                                            default:
                                                return (
                                                    <OverlayTrigger
                                                        placement='top'
                                                        delay={{show: 250, hide: 400}}
                                                        trigger={['click','hover']}
                                                        key={index}
                                                        overlay={<Tooltip>{log[column.id]}</Tooltip>}
                                                    >
                                                        <div className={styles.logType} key={index} style={{width: `calc(100% / ${columns?.length - 1})`}}>
                                                            <span>{(log?.hasOwnProperty('edits') && Object?.keys(log?.edits)?.length > 0 && index === 0) ? '*' : ''}{log[column.id]}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                );
                                        };
                                    }
                                    )}
                                </div>
                            );
                        })
                    :
                        <div className={styles.none}>No logs found</div>
                    }
                </div>
                <div className={styles.key}>
                    <span>* indicates log has been edited</span>
                    {(authenticatedUser && logs?.filter(l => l?.deleted)?.length) ? <span className={styles.deletedToggle} onClick={() => setDeletedToggle(prev => !prev) } >{deletedToggle ? 'Hide Deleted' : 'Show Deleted'}</span> : <></>}    
                </div>
            </div>
        </div>
        </>
    );
};

export default Table;