import React, { useState, useEffect } from 'react';
import QRCard from './QRCard';
import Style from './Style';
import API from '../../../API';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../utils/Auth';
import styles from './LinkQR.module.css';

export default function LinkQR(props) {

    const navigate = useNavigate();
    const { storeLogo, linkQR } = API;
    const { liquidQRs } = useAuth();

    const { 
        QR, 
        qr_options, 
        optionsDispatch, 
        qr_data, 
        dataDispatch, 
        categories,
        org_id,
        setLogo,
        formData,
        handleReset,
        setLinking
    } = props;

    const [ filters, setFilters ] = useState({
        searchValue: '',
        categoryValue: '',
        searchType: 'name',
        sortBy: '',
    });

    const { searchValue, categoryValue, searchType, sortBy } = filters;
    const [ searchReturnArr, setSearchReturnArr ] = useState(null);
    const [ categoryDisplay, setCategoryDisplay ] = useState('');
    const [ parent, setParent ] = useState({obj: {}, selected: false});
    const [ loading, setLoading ] = useState(false);

    const handleSearchType = (type) => {
        setFilters(prev => {
            return {
                ...prev,
                searchValue: '',
                searchType: type
            };
        });
    };


    const handleSearchValue = (e) => {
        e.preventDefault();

        setFilters(prev => {
            return {
                ...prev,
                searchValue: e.target.value
            };
        });
    };

    const handleCategoryFilter = (e) => {
        e.preventDefault();

        if (e.target.value === 'Clear Filter') {
            setFilters(prev => {
                return {
                    ...prev,
                    categoryValue: '',
                }
            });
        } else {
            setFilters(prev => {
                return {
                    ...prev,
                    categoryValue: e.target.value
                };
            });
        };
    };

    useEffect(() => {

        const handleSortByFilter = (arr) => {
            // Sort helper for newest/oldest
            // check out this StackOverflow for explanation of Space Ship Operator 
            // https://stackoverflow.com/a/9175783
            const sortHelper = (a,b) => (a < b) - (a > b);
            let result;
            switch(sortBy) {
                case 'newest':
                    result = arr.sort((a,b) => sortHelper((a.data?.created_at ?? '2024-01-01T00:00:00.000Z'), (b.data?.created_at ?? '2024-01-01T00:00:00.000Z')) || sortHelper(b?.data?.nickname?.toLowerCase(), a?.data?.nickname?.toLowerCase()));
                    break;
                case 'oldest':
                    result = arr.sort((a,b) => sortHelper((b.data?.created_at ?? '2024-01-01T00:00:00.000Z'), (a.data?.created_at ?? '2024-01-01T00:00:00.000Z')) || sortHelper(a?.data?.nickname?.toLowerCase(), b?.data?.nickname?.toLowerCase()));
                    break;
                case 'alpha-asc':
                    result = arr.sort((a,b) => a?.data?.nickname?.toLowerCase() > b?.data?.nickname?.toLowerCase() ? 1 : -1);
                    break;
                case 'alpha-des':
                    result = arr.sort((a,b) => a?.data?.nickname?.toLowerCase() < b?.data?.nickname?.toLowerCase() ? 1 : -1);
                    break;
                default:
                    if (!sortBy) {
                        result = arr.sort((a,b) => a?.data?.nickname?.toLowerCase() > b?.data?.nickname?.toLowerCase() ? 1 : -1);
                    } else {
                        result = arr.filter(qr => qr?.data?.qr_type === sortBy).sort((a,b) => b?.data?.nickname?.toLowerCase() > a?.data?.nickname?.toLowerCase() ? 1 : -1);
                    };
                    break;
            };
            return handleCategoryFilter(result);
        };

        const handleCategoryFilter = (arr) => {
            if (categoryValue) {
                const value = categories?.filter(cat => cat.id === categoryValue)[0]?.name;
                setCategoryDisplay(value);
                if (value === 'Uncategorized') {
                    return handleSearchFilter(arr.filter(obj => !obj.data.category));
                } else {
                    return handleSearchFilter(arr.filter(obj => obj.data.category && obj.data.category === categoryValue));
                };
            } else {
                setCategoryDisplay('');
                return handleSearchFilter(arr);
            };
        };

        const handleSearchFilter = (arr) => {
            if (searchValue?.length > 0) {
                if (searchType === 'name' && searchValue) {
                    return arr.filter(obj => obj.data.nickname.toLowerCase().includes(searchValue.toLowerCase()));
                } else if (searchType === 'tag' && searchValue) {
                    return arr.filter(obj => {
                        if (obj.data.tags?.length > 0) {
                            const tempArr = obj.data.tags.filter(tag => tag.toLowerCase().includes(searchValue.toLowerCase()));
                            if (tempArr.length > 0) {
                                return obj;
                            } else return null;
                        } else {
                            return null;
                        }
                    });
                };
            } else {
                return arr;
            };
        };

        setSearchReturnArr(handleSortByFilter([...liquidQRs]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchValue, categoryValue, searchType, liquidQRs, sortBy, QR.id]);

    const handleLink = async () => {
        if (qr_data?.nickname) {
            setLoading(true);
    
            const data = {};
            const date = new Date();
            const linkedObj = {
                id: QR.id,
                data: {
                    active: true,
                    category: null,
                    linked_qr: parent?.obj?.id, // parent id
                    nickname: qr_data.nickname,
                    qr_type: 'liquid_link',
                    qr_style: qr_options,
                    org_id: org_id,
                    redirect_url: qr_data.redirect_url,
                    schedule: null,
                    tags: qr_data.tags,
                    created_at: date
                }
            };

            const parentObj = {
                id: parent?.obj?.id,
                data: {
                    ...parent?.obj?.data,
                    children: parent?.obj?.data?.children ? [ ...parent?.obj?.data?.children, linkedObj.id ] : [linkedObj.id],
                    updated_at: date
                }
            };

            if (linkedObj.data.qr_style.image) {
                linkedObj.data.qr_style.image = linkedObj.data.qr_style.image.split('?')[0];
            };

            if (linkedObj.data.qr_style.height !== 300 || linkedObj.data.qr_style.width !== 300) {
                linkedObj.data.qr_style.height = 300;
                linkedObj.data.qr_style.width = 300;
            };

            if (formData) {
                await storeLogo(linkedObj?.id, formData)
                    .then(async () => {
                        data.linkData = linkedObj.data;
                        data.pid = parentObj.id;
                        data.parentData = parentObj.data;
                        data.linkData.qr_style.image = `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/qr_logos/${linkedObj?.id}/logo.png`;
    
                        await linkQR(linkedObj?.id, data)
                            .then(() => {
                                setLoading(false);
                                handleReset();
                                const qrID = linkedObj?.id;
                                navigate('/collection', { state: { qrID }});
                            }).catch(err => console.log('Failed to link QR.'));
                    }).catch(err => console.log('Failed to store logo.'));
            } else {
                data.linkData = linkedObj.data;
                data.pid = parentObj.id;
                data.parentData = parentObj.data;

                await linkQR(linkedObj?.id, data)
                    .then(() => {
                        setLoading(false);
                        handleReset();
                        const qrID = linkedObj?.id;
                        navigate('/collection', { state: { qrID }});
                    }).catch(err => console.log('Failed to link QR.'));
            };


        };
    };

    const resultReturn = () => {
        if (searchReturnArr?.length > 0) {
            return (
                <div className={styles.resultMessage}>
                    {searchValue?.length > 0 ? <div className={styles.resultMessageInfo}>Search: "<span style={{fontWeight: 'bold'}}>{searchValue}</span>"</div> : <></>}
                    {categoryValue?.length > 0 ? <div className={styles.resultMessageInfo}>Category: "<span style={{fontWeight: 'bold'}}>{categoryDisplay}</span>"</div> : <></>}
                </div>
            );
        } else {
            return <div className={styles.resultMessage}>No QRs were found. Try another search or category.</div>
        };
    };

    switch(parent.selected) {
        case true:
            return <Style qr_options={qr_options} optionsDispatch={optionsDispatch} qr_data={qr_data} dataDispatch={dataDispatch} setLogo={setLogo} setParent={setParent} handleLink={handleLink} loading={loading} />
        case false:
        default:
            return (
                <>
                    <button className={styles.backBtn} onClick={() => setLinking(false)}><i className='far fa-arrow-left me-1' style={{pointerEvents: 'none'}} />Back</button>
                    <h4 className={styles.title}>Liquid Link</h4>
                    <div className={styles.searchContainer}>
                        <div className={styles.searchInner}>
                            <label htmlFor='search-name'>Search by Name</label>
                            <input type='radio' id='search-name' checked={searchType === 'name'} onChange={() => handleSearchType('name')} />
                            <label htmlFor='search-tag'>Search by Tag</label>
                            <input type='radio' id='search-tag' checked={searchType === 'tag'} onChange={() => handleSearchType('tag')} />
                        </div>
                        <input type='text' placeholder='Search...' className={styles.input} value={searchValue} onChange={handleSearchValue} maxLength='30'></input>
                        {categories?.length > 0 ?
                            <div className={styles.selectGroup}>
                                <select className={styles.select} value={categoryValue === '' ? '0' : categoryValue} onChange={handleCategoryFilter} disabled={!categories}>
                                    <option value='0' disabled>Category Filter</option>
                                    {categories.map((cat, i) => (
                                        <option key={i} value={cat.id}>{cat.name}</option>
                                    ))}
                                    <option value='Clear Filter'>Clear Category Filter</option>
                                </select>
                            </div>
                        : <></>}
                    </div>
                    <div className={styles.resultContainer}>
                        {resultReturn()}
                    </div>
                    {searchReturnArr?.length > 0 ?
                        <div className={styles.qrContainer}>
                            {searchReturnArr.filter(qr => qr.data.qr_type !== 'liquid_link').map((qr, i) => (
                                <QRCard key={i} QR={qr} setParent={setParent} />
                            ))}
                        </div>
                        : <></>
                    }
                </>
            );
    };
};