import React, { useState, useEffect } from 'react';
import { useAuth } from '../../utils/Auth';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoDark from '../Images/LiquidQR-dark.png';
import Collapse from 'react-bootstrap/esm/Collapse';
import styles from './MobileNavigation.module.css';

export default function MobileNavigation(props) {
    
    const { currentUser, logout, orgObj } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [ linePos, setLinePos ] = useState(currentUser ? 0 : 80);
    const [ menu, setMenu ] = useState(false);
    const [ colMenu, setColMenu ] = useState(false);

    useEffect(() => {
        switch (location.pathname) {
            case '/':
            case '/home': 
                setLinePos(0);
                break;
            case '/collection':
            case '/collection/edit': 
                setLinePos(20);
                break;
            case '/collection/new': 
                setLinePos(40); 
                break;
            case '/login':
            case '/signup':
                setLinePos(80);
                break;
        };
    },[location.pathname])

    const handleNavigate = e => {
        const id = e.target.id;
        setColMenu(false);
        switch (id) {
            case 'home':
                setLinePos(0);
                navigate('/');
                break;
            case 'collection':
                setLinePos(20);
                navigate('/collection');
                break;
            case 'create':
                setLinePos(40);
                navigate('/collection/new');
                break;
            case 'store':
                window.open(`https://liquidqr.com/store`, '_blank');
                break;
            case 'settings':
                setLinePos(80);
                setMenu(false);
                navigate('/settings');
                break;
            case 'analytics':
                setLinePos(80);
                setMenu(false);
                navigate('/analytics');
                break;
            case 'login':
                setLinePos(80);
                setMenu(false);
                navigate('/login');
                break;
            case 'signup':
                setLinePos(80);
                setMenu(false);
                navigate('/signup');
                break;
            default:
                break;
        };
    };

    const handleLogout = e => {
        e.preventDefault();
        logout().then(() => {
            setLinePos(80);
            window.location.reload('/')
        });
    };

    useEffect(() => {
        const menu = document.querySelector(`.${styles.menu}`);
        const menuTrigger = document.querySelector('#menu');
        const listener = (e) => {
            if ((e.target !== menu && e.target !== menuTrigger) && !menu?.contains(e.target) && e.target.id !== 'reorder-close') {
                setMenu(false);
            };
        };

        const select = document.getElementById('cat-select');
        const selectTrigger = document.getElementById('select-qr-open');
        const listener2 = (e) => {
            if ((e.target !== select && e.target !== selectTrigger) && !select?.contains(e.target) && e.target.id !== 'show-menu') {
                setShowSelect(false);
            };
        };

        window.addEventListener('click', listener);
        window.addEventListener('click', listener2);
        return () => {
            window.removeEventListener('click', listener);
            window.removeEventListener('click', listener2);
        };
    },[]);

    const [ showSelect, setShowSelect ] = useState(false);
    const [ collapsed, setCollapsed ] = useState(false);
    const [ reorder, setReorder ] = useState(false);
    const handleQrMenu = (e) => {
        const id = e?.target.id;
        const sideMenuOpen = document.getElementById('show-menu');
        const sideMenuClose = document.getElementById('hide-menu');
        const collapseOpen = document.getElementById('collapse-open');
        const collapseClose = document.getElementById('collapse-close');
        const reorderOpen = document.getElementById('reorder-open');
        const reorderClose = document.getElementById('reorder-close');
        const toTop = document.getElementById('to-top');

        switch (id) {
            case 'select-qr-close':
                setShowSelect(false);
                sideMenuClose?.click();
                break;
            case 'select-qr-open':
                setShowSelect(true);
                sideMenuOpen?.click();
                break;
            case 'collapse-qr-close':
                setCollapsed(true);
                collapseClose?.click();
                break;
            case 'collapse-qr-open':
                setCollapsed(false);
                collapseOpen?.click();
                break;
            case 'reorder-qr-close':
                setReorder(false);
                reorderClose?.click();
                break;
            case 'reorder-qr-open':
                setReorder(true);
                reorderOpen?.click();
                break;
            case 'menu':
                setColMenu(false);
                setReorder(false);
                reorderClose?.click();
                break;
            case 'to-qr-top':
                toTop?.click();
                break;
            default:
                break;  
        };
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.underline} style={{left: `${linePos}%`}}></div>
                {currentUser ?
                    <>
                    <div className={styles.navItem} id='home' onClick={handleNavigate}>
                        <span className={styles.icon}><i className='fas fa-home' /></span>
                        <span className={styles.word}>Home</span>
                    </div>
                    <div className={styles.navItem} id='collection' onClick={handleNavigate}>
                        <span className={styles.icon}><i className='fak fa-liquidqr' /></span>
                        <span className={styles.word}>QR Codes</span>
                    </div>
                    <div className={styles.navItem} id='create' onClick={handleNavigate}>
                        <span className={styles.circle}><i className='fas fa-circle-plus' /></span>
                        <span className={styles.icon}></span>
                        <span className={styles.word}>Create</span>
                    </div>
                    </>
                    : 
                    <>
                    <div className={styles.navItem} />
                    <div className={styles.navItem} />
                    <div className={styles.navItem} />
                    </>
                }
                {currentUser && location?.pathname === '/collection' ?
                    <div className={styles.navItem} id='collection-menu' onClick={() => setColMenu(!colMenu)}>
                        <span className={styles.icon}><i className={colMenu ? 'far fa-arrow-down-to-line' : 'far fa-arrow-up-from-line'} /></span>
                        <span className={styles.word}>QR Menu</span>
                    </div>
                :
                    <div className={styles.navItem} id='store' onClick={handleNavigate}>
                        <span className={styles.icon}><i className='fas fa-store' /></span>
                        <span className={styles.word}>Shop QRs</span>
                    </div>
                }
                <div className={styles.navItem} id='menu' onClick={(e) => {setMenu(!menu); handleQrMenu(e)}}>
                    <span className={styles.icon}><i className='fas fa-bars' /></span>
                    <span className={styles.word}>More</span>
                </div>
            </div>
            <Collapse in={menu}>
                <div className={styles.menuPosition}>
                    <div className={styles.menu}>
                        <div className={styles.logo}><img src={LogoDark} /></div>
                        {currentUser ? 
                            <>
                            <div className={styles.menuTitle}><i className='fas fa-user me-2' />{currentUser?.email}</div>
                            {location?.pathname === '/collection' ? <div className={styles.menuItem} id='store' onClick={handleNavigate}><i className='fas fa-store me-2' />Shop QRs</div> : <></>}
                            <div className={location?.pathname === '/settings' ? `${styles.menuItem} ${styles.active}` : styles.menuItem} id='settings' onClick={handleNavigate}><i className='fas fa-gear me-2' />Account Settings</div>
                            {(orgObj?.plan?.analytics > 1 && orgObj?.plan?.plan_id > 2) ?
                                <div className={location?.pathname === '/analytics' ? `${styles.menuItem} ${styles.active}` : styles.menuItem} id='analytics' onClick={handleNavigate}><i className='fas fa-chart-mixed me-2' />Analytics</div>
                            : <></>}
                            <div className={styles.menuItem} onClick={handleLogout}><i className='fas fa-arrow-right-from-bracket me-2' />Logout</div>
                            </>
                            :
                            <>
                            <div className={location?.pathname === '/login' ? `${styles.menuItem} ${styles.active}` : styles.menuItem} id='login' onClick={handleNavigate}><i className='fas fa-arrow-right-to-bracket me-2' />Log In</div>
                            <div className={location?.pathname === '/signup' ? `${styles.menuItem} ${styles.active}` : styles.menuItem} id='signup' onClick={handleNavigate}><i className='fas fa-user-plus me-2' />Sign Up</div>
                            </>
                        }
                    </div>
                </div>
            </Collapse>
            <Collapse in={colMenu}>
                <div className={styles.btnsPosition}>
                    <div className={styles.btns}>
                        <button className={styles.btnItem} id={showSelect ? 'select-qr-close' : 'select-qr-open'} onClick={handleQrMenu} disabled={reorder}>
                            <span className={styles.icon}><i className={showSelect ? 'far fa-xmark' : 'fak fa-sidemenu'} /></span>
                            <span className={styles.word}>{showSelect ? 'Close' : 'Open'}</span>
                        </button>
                        <button className={styles.btnItem} id={collapsed ? 'collapse-qr-open' : 'collapse-qr-close'} onClick={handleQrMenu} disabled={reorder}>
                            <span className={styles.icon}><i className={collapsed ? 'far fa-arrows-from-line' : 'far fa-arrows-to-line'} /></span>
                            <span className={styles.word}>{collapsed ? 'Expand' : 'Collapse'}</span>
                        </button>
                        <button className={styles.btnItem} id={reorder ? 'reorder-qr-close' : 'reorder-qr-open'} onClick={handleQrMenu}>
                            <span className={styles.icon}><i className={reorder ? 'far fa-xmark' : 'fak fa-reorder'} /></span>
                            <span className={styles.word}>{reorder ? 'Close' : 'Reorder'}</span>
                        </button>
                        <button className={styles.btnItem} id='to-qr-top' onClick={handleQrMenu}>
                            <span className={styles.icon}><i className='far fa-arrow-up' /></span>
                            <span className={styles.word}>To Top</span>
                        </button>
                    </div>
                </div>
            </Collapse>
        </div>
    );
};