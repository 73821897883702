import React from 'react';
import styles from './Shape.module.css';

export default function Shape(props) {

    const { qr_options, optionsDispatch } = props;

    const handleShapeChange = (e) => {
        const id = e.target.id;
        if (id === 'square') {
            optionsDispatch({type: 'shape', options: 'square'});
        } else if (id === 'circle') {
            optionsDispatch({type: 'shape', options: 'circle'});
        } else {
            return;
        };
    };

    return (
        <div className={styles.container}>
            <input type='radio' id='square' className={styles.radio} checked={qr_options?.shape === 'square'} onChange={handleShapeChange} />
            <label htmlFor='square' className={styles.radioBtn}>Square</label>
            <input type='radio' id='circle' className={styles.radio} checked={qr_options?.shape === 'circle'} onChange={handleShapeChange} />
            <label htmlFor='circle' className={styles.radioBtn}>Circle</label>
        </div>
    );
};