import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useAuth } from '../../utils/Auth';
import { reauthenticateWithCredential, EmailAuthProvider, updateEmail, updatePassword } from 'firebase/auth';
import API from '../../API';
import { Navigate, useNavigate } from 'react-router-dom';
import PasswordStrength from '../../components/PasswordStrength';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import styles from './Settings.module.css';

export default function Settings() {
    const { updateUserObject } = API;
    
    const { userObj, currentUser } = useAuth();
    const [ newEmail, setNewEmail ] = useState(currentUser ? currentUser?.email : '');
    const [ newPassword, setNewPassword ] = useState('');
    const [ inputType, setInputType ] = useState('password');
    const [ confirmInputType, setConfirmInputType ] = useState('password');
    // used for email and password confirmation
    const [ currentPassword, setCurrentPassword ] = useState('');
    const [ emailConfirm, setEmailConfirm ] = useState(false);
    const [ passwordConfirm, setPasswordConfirm ] = useState(false);
    const [ disabled, setDisabled ] = useState(false);
    const [ message, setMessage ] = useState(null);
    const [ sonarLength, setSonarLength ] = useState('900000');
    const [ selectedTimezone, setSelectedTimezone ] = useState('');
    // const userCredits = userObj?.credit_balance ? userObj?.credit_balance : 0;
    const navigate = useNavigate();

    const credential = EmailAuthProvider.credential(
        currentUser?.email,
        currentPassword
    );

    // sets sonar length if userObj contains sonar_length
    useEffect(() => {
        if (userObj) {
            setSonarLength(userObj?.sonar_length ? userObj.sonar_length.toString() : '900000');
            setSelectedTimezone(userObj?.timezone?.value ? userObj.timezone.value : Intl.DateTimeFormat().resolvedOptions().timeZone)
        }
    }, [userObj]);

    const authenticate = (e) => {
        e.preventDefault();
        reauthenticateWithCredential(currentUser, credential)
            .then(() => {
                if (emailConfirm) { handleUpdateEmail(); }
                if (passwordConfirm) { handleUpdatePassword(); }
            }).catch(error => {
                setMessage(<p className={styles.successOrError} style={{color: 'red'}}>Authentication failed. Please try again.</p>);
            });
    }

    const handleUpdateEmail = () => {
        setDisabled(true);
        updateEmail(currentUser, newEmail)
            .then(() => {
                resetForm();
                setMessage(<p className={styles.successOrError} style={{color: 'green'}}>Email successfully updated!</p>);
                setDisabled(false);
            }).catch(error => {
                setMessage(<p className={styles.successOrError} style={{color: 'red'}}>Email could not be updated. Please try again.</p>);
            });
    };

    const handleUpdatePassword = () => {
        setDisabled(true);
        updatePassword(currentUser, newPassword)
            .then(() => {
                resetForm();
                resetInputFields();
                setMessage(<p className={styles.successOrError} style={{color: 'green'}}>Password successfully updated!</p>);
                setDisabled(false);
            }).catch(error => {
                console.log(error);
                setMessage(<p className={styles.successOrError} style={{color: 'red'}}>Password could not be updated. Please try again.</p>)
            });
    };

    const handleUpdateSonar = (e) => {
        const data = {
            sonar_length: parseInt(e.target.value)
        }
        updateUserObject(currentUser.uid, data)
            .then(() => {
                setMessage(<p className={styles.successOrError} style={{color: 'green'}}>Sonar length successfully updated!</p>);
                resetForm();
            }).catch(error => {
                setMessage(<p className={styles.successOrError} style={{color: 'red'}}>Sonar length could not be updated. Please try again.</p>)
            });
    };

    const { options, parseTimezone } = useTimezoneSelect('original', allTimezones);
    const handleUpdateTimezone = (e) => {
        const zone = parseTimezone(e.currentTarget.value);
        const data = {
            timezone: {
                value: zone.value,
                label: zone.label,
                offset: zone.offset
            }
        };
        updateUserObject(currentUser.uid, data)
            .then(() => {
                setMessage(<p className={styles.successOrError} style={{color: 'green'}}>Timezone successfully updated!</p>);
                resetForm();
            }).catch(error => (
                setMessage(<p className={styles.successOrError} style={{color: 'red'}}>Timezone could not be updated. Please try again.</p>)
            ));
    }

    const resetForm = () => {
        setNewEmail(currentUser ? currentUser.email : '');
        setNewPassword('');
        setCurrentPassword('');
        setEmailConfirm(false);
        setPasswordConfirm(false);
        setTimeout(() => {
            setMessage(null);
        }, 3000);
    };

    const resetInputFields = () => {
        const newP = document.getElementById('settings-password');
        setNewPassword('');
        newP.value = '';
    };

    const handleAccountLevelReturn = () => {
        switch(userObj?.plan?.plan_id) {
            case 0:
                return 'Free';
            case 1:
                return 'Basic';
            case 2:
                return 'Basic+';
            case 3:
                return 'Pro';
            case 4:
                return 'Pro+';
            default:
                return 'Enterprise';
        };
    };

    if (userObj && currentUser) {
        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <Accordion className={styles.card} defaultActiveKey='0'>
                        <div className={styles.title}>Account Settings</div>
                        <Accordion.Item className={styles.emailContainer} eventKey='0' onClick={resetInputFields}>
                            <Accordion.Header>Update Email</Accordion.Header>
                            <Accordion.Body>
                                <div className={styles.inputGroup}>
                                    <input type='text' id='settings-email' className={styles.input} onChange={(e) => setNewEmail(e.target.value)} defaultValue={newEmail} />
                                    <label htmlFor='settings-email' className={styles.label}>Email</label>
                                    <button className={styles.btn} onClick={() => {setPasswordConfirm(false); setEmailConfirm(true);}}>Update</button>
                                </div>
                                {message && message}
                                {emailConfirm && 
                                    <div className={styles.inputGroup} style={{marginTop: '1rem'}}>
                                        <input type='password' id='settings-password-confirm' className={styles.input} onChange={(e) => setCurrentPassword(e.target.value)} />
                                        <label htmlFor='settings-password-confirm' className={styles.label}>Current Password</label>
                                        <button className={styles.btn} onClick={authenticate} disabled={disabled}>Confirm</button>
                                    </div>
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className={styles.passwordContainer} eventKey='1'>
                            <Accordion.Header>Update Password</Accordion.Header>
                            <Accordion.Body>
                                <div className={styles.inputGroup}>
                                    <input type={inputType} id='settings-password' className={styles.input} onChange={(e) => setNewPassword(e.target.value.replace(' ',''))} value={newPassword} />
                                    <label htmlFor='settings-password' className={styles.label}>New Password</label>
                                    <button className={styles.btn} onClick={() => {setEmailConfirm(false); setPasswordConfirm(true);}} disabled={newPassword?.length < 1}>Update</button>
                                    <button className={styles.hidePassword} onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')} disabled={newPassword?.length < 1}>{inputType === 'password' ? <i className='fas fa-eye' /> : <i className='fas fa-eye-slash' />}</button>
                                </div>
                                <PasswordStrength password={newPassword} />
                                {message && message}
                                {passwordConfirm && 
                                    <div className={styles.inputGroup} style={{marginTop: '1rem'}}>
                                        <input type={confirmInputType} id='settings-password-confirm' className={styles.input} onChange={(e) => setCurrentPassword(e.target.value)} value={currentPassword} />
                                        <label htmlFor='settings-password-confirm' className={styles.label}>Current Password</label>
                                        <button className={styles.btn} onClick={authenticate} disabled={disabled}>Confirm</button>
                                        <button className={styles.hidePassword} onClick={() => setConfirmInputType(confirmInputType === 'password' ? 'text' : 'password')} disabled={currentPassword?.length < 1}>{confirmInputType === 'password' ? <i className='fas fa-eye' /> : <i className='fas fa-eye-slash' />}</button>
                                    </div>
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className={styles.timeContainer} eventKey='2'>
                            <Accordion.Header>Update Timezone</Accordion.Header>
                            <Accordion.Body>
                                <div className={styles.inputGroup}>
                                    <select value={selectedTimezone} onChange={handleUpdateTimezone} className={styles.timezone} id='settings-timezone'>
                                        {options.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                    <label htmlFor='settings-timezone' className={styles.filled}>Timezones</label>
                                </div>
                                {message && message}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className={styles.sonarContainer} eventKey='3' onClick={resetInputFields}>
                            <Accordion.Header>Update Sonar</Accordion.Header>
                            <Accordion.Body>
                                <div className={styles.inputGroup}>
                                    <select id='settings-sonar' className={styles.input} onChange={handleUpdateSonar} value={sonarLength}>
                                        <option value='300000'>5 Minutes</option>
                                        <option value='600000'>10 Minutes</option>
                                        <option value='900000'>15 Minutes</option>
                                        <option value='1800000'>30 Minutes</option>
                                        <option value='3600000'>1 Hour</option>
                                        <option value='7200000'>2 Hours</option>
                                    </select>
                                    <label htmlFor='settings-sonar' className={styles.filled}>Sonar Length</label>
                                </div>
                                {message && message}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className={styles.creditsContainer} eventKey='4' onClick={resetInputFields}>
                            <Accordion.Header>Account Plan: {handleAccountLevelReturn()}</Accordion.Header>
                            <Accordion.Body>
                                <div className={styles.inputGroup} style={{justifyContent: 'center'}}>
                                    <button onClick={() => {navigate('/upgrade')}} className={styles.upgradeBtn}>Upgrade</button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        );
    } else if (currentUser && !userObj) {
        return (<></>);
    } else {
        return (<Navigate to='/login' />);
    };
};