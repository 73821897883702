import React, { useState, useEffect } from 'react';
import styles from './Search.module.css';

export default function Search(props) {

    const { searchType, handleSearchType } = props;

    const [ showSearch, setShowSearch ] = useState(false);

    const handleSearchShow = () => {
        let btn = document.getElementById('search');
        setShowSearch(true);
        btn.classList.add(styles.stretch);
    };

    const handleSearchHide = () => {
        let btn = document.getElementById('search');
        setShowSearch(false);
        btn.classList.remove(styles.stretch);
    };

    // Handles search button animations
    useEffect(() => {
        if (showSearch) {
            let nicknameBtn = document.querySelector(`.${styles.nicknameBtn}`);
            let tagBtn = document.querySelector(`.${styles.tagBtn}`);
            if (searchType === 'name') {
                tagBtn?.classList.remove(styles.active);
                tagBtn?.classList.add(styles.stretch);
                nicknameBtn?.classList.add(styles.active, styles.stretch);
            } else if (searchType === 'tag') {
                nicknameBtn?.classList.remove(styles.active);
                nicknameBtn?.classList.add(styles.stretch);
                tagBtn?.classList.add(styles.active, styles.stretch);
            } else {
                nicknameBtn?.classList.add(styles.stretch);
                tagBtn?.classList.add(styles.stretch);
            };
        };
    },[searchType, showSearch]);

    return (
        <div className={styles.search} id='search' onMouseOver={handleSearchShow} onMouseLeave={handleSearchHide} >
            {showSearch ?
                <div className={styles.inputGroup} style={{borderRadius: '2rem', flexWrap: 'nowrap'}}>
                    <button className={styles.nicknameBtn} onClick={() => handleSearchType('name')}>Name</button>
                    <button className={styles.tagBtn} onClick={() => handleSearchType('tag')}>Tag</button>
                </div>
            :
                <i className='fa-solid fa-magnifying-glass' style={{pointerEvents: 'none'}}></i>
            }
        </div>
    );
};