export function scheduleUTCFormat(obj, timezone) {
    // Create an object to return
    const returnObj = {
        '0': [],
        '1': [],
        '2': [],
        '3': [],
        '4': [],
        '5': [],
        '6': []
    };

    // Apply timezone offset to UTC time
    const offset = (timezone && timezone?.offset) ? new Date().getTimezoneOffset() / 60 + timezone.offset : 0;

    // Function to format time as 'HH:mm'
    const formatTime = (date) => {
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    // Function to split time intervals if end time goes into the next day after converting to UTC
    const splitTimeIntervals = (dayObj, dayOfWeek) => {
        // Loop through each time breakdown for scheduled responses
        dayObj.forEach(interval => {
            const startTimeParts = interval.start.split(':');
            const endTimeParts = interval.end.split(':');
            
            const startHour = parseInt(startTimeParts[0]) + (offset * -1);
            const startMinute = parseInt(startTimeParts[1]);
            
            const endHour = parseInt(endTimeParts[0]) + (offset * -1);
            const endMinute = parseInt(endTimeParts[1]);
            
            // Convert start and end times to UTC
            const startUTC = new Date(0);
            startUTC.setHours(startHour, startMinute, 0, 0);
            startUTC.toUTCString();
            
            const endUTC = new Date(0);
            endUTC.setHours(endHour, endMinute, 0, 0);
            endUTC.toUTCString();

            // Check if end time goes into the next day (end time is before start time)
            if (startHour > startUTC.getUTCHours()) {
                // End time is on the next day, split the interval into two parts
                if (endUTC.getUTCHours() < startUTC.getUTCHours() && endUTC.getUTCHours() !== 0) {
                    // Split the interval into two parts: from start to midnight and from midnight to end
                    const midnightUTC = new Date(0);
                    midnightUTC.setUTCHours(0);
                    midnightUTC.setUTCMinutes(0);
                    
                    const startToEndOfDay = {
                        start: formatTime(startUTC),
                        end: formatTime(midnightUTC),
                        link: interval.link,
                    };

                    const midnightToEndTime = {
                        start: formatTime(midnightUTC),
                        end: formatTime(endUTC),
                        link: interval.link,
                    };

                    returnObj[dayOfWeek].push(startToEndOfDay);
                    returnObj[`${+dayOfWeek === 6 ? 0 : +dayOfWeek + 1}`].push(midnightToEndTime);
                } else {
                    const scheduleObj = {
                        start: formatTime(startUTC),
                        end: formatTime(endUTC),
                        link: interval.link,
                    };

                    returnObj[`${+dayOfWeek === 6 ? 0 : +dayOfWeek + 1}`].push(scheduleObj);
                };
            } else if (endUTC.getUTCHours() < startUTC.getUTCHours() && endUTC.getUTCHours() !== 0) {
                // Split the interval into two parts: from start to midnight and from midnight to end
                const midnightUTC = new Date(0);
                midnightUTC.setUTCHours(0);
                midnightUTC.setUTCMinutes(0);
                
                const startToEndOfDay = {
                    start: formatTime(startUTC),
                    end: formatTime(midnightUTC),
                    link: interval.link,
                };

                const midnightToEndTime = {
                    start: formatTime(midnightUTC),
                    end: formatTime(endUTC),
                    link: interval.link,
                };

                returnObj[dayOfWeek].push(startToEndOfDay);
                returnObj[`${+dayOfWeek === 6 ? 0 : +dayOfWeek + 1}`].push(midnightToEndTime);
            } else {
                // End time is on the same day, no need to split
                const scheduleObj = {
                    start: formatTime(startUTC),
                    end: formatTime(endUTC),
                    link: interval.link,
                };
                returnObj[dayOfWeek].push(scheduleObj);
            };
        });
    };

    const processAllDays = () => {
        // Loop through each day and adjust the time intervals
        for (const dayOfWeek in obj) {
            splitTimeIntervals(obj[dayOfWeek], dayOfWeek);
        };
    };

    const removeEmptyDays = () => {
        for (const dayOfWeek in returnObj) {
            if (returnObj[dayOfWeek].length < 1) {
                delete returnObj[dayOfWeek];
            };
        };
    };

    // Execute functions
    processAllDays();
    removeEmptyDays();

    // Return the object to save to DB
    return returnObj;
};

export function scheduleLocalFormat(obj, timezone) {
    // Object to return
    const returnObj = {
        '0': [],
        '1': [],
        '2': [],
        '3': [],
        '4': [],
        '5': [],
        '6': []
    };

    // Apply timezone offset to UTC time
    const offset = (timezone && timezone?.offset) ? new Date().getTimezoneOffset() / 60 + timezone.offset : 0;

    // Function to format time as 'HH:mm'
    const formatTime = (date) => {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    // Function to convert UTC time intervals to local time for a single day
    const convertUTCToLocalForDay = (dayArr, dayOfWeek) => {
        dayArr.forEach(interval => {
            const startTimeParts = interval.start.split(':');
            const endTimeParts = interval.end.split(':');
            
            const startHour = parseInt(startTimeParts[0]) - (offset * -1);
            const startMinute = parseInt(startTimeParts[1]);
            
            const endHour = parseInt(endTimeParts[0]) - (offset * -1);
            const endMinute = parseInt(endTimeParts[1]);
            
            // Set start and end times to UTC
            const startLocal = new Date(0);
            startLocal.setUTCHours(startHour, startMinute, 0, 0);
            startLocal.toUTCString();
            
            const endLocal = new Date(0);
            endLocal.setUTCHours(endHour, endMinute, 0, 0);
            endLocal.toUTCString();
        
            // Check if start time goes into the previous day
            if (startLocal.getHours() > endLocal.getHours() && (endLocal.getHours() !== 0 || (endLocal.getHours() === 0 && endLocal.getMinutes() > 0))) {
                    const midnightUTC = new Date(0);
                    midnightUTC.setHours(0);
                    midnightUTC.setMinutes(0);
                    
                    const startToEndOfDay = {
                        start: formatTime(startLocal),
                        end: formatTime(midnightUTC),
                        link: interval.link,
                    };

                    const midnightToEndTime = {
                        start: formatTime(midnightUTC),
                        end: formatTime(endLocal),
                        link: interval.link,
                    };

                    returnObj[`${+dayOfWeek === 0 ? 6 : +dayOfWeek - 1}`].push(startToEndOfDay);
                    returnObj[dayOfWeek].push(midnightToEndTime);
            } else if (startHour < startLocal.getHours()) {
                const intervalObj = {
                    start: formatTime(startLocal),
                    end: formatTime(endLocal),
                    link: interval.link,
                };

                returnObj[`${+dayOfWeek === 0 ? 6 : +dayOfWeek - 1}`].push(intervalObj);
            } else {
                // Start time is on the same day
                const scheduleObj = {
                    start: formatTime(startLocal),
                    end: formatTime(endLocal),
                    link: interval.link,
                };

                returnObj[dayOfWeek].push(scheduleObj);
            };

        });

        // Sort the time intervals for each day, needed for reduceTimeIntervals()
        returnObj[dayOfWeek].sort((a, b) => +a.start.split(':')[0] > +b.start.split(':')[0] ? 1 : -1);
    };

    // Reduce the time intervals for each day to remove overlaps
    const reduceTimeIntervals = () => {
        for (const dayOfWeek in returnObj) {
            returnObj[dayOfWeek] = returnObj[dayOfWeek].reduce((acc, cur) => {
                if (acc.length > 0 && acc.find(obj => obj.end === cur.start && obj.link === cur.link)) {
                    acc.find(obj => obj.end === cur.start).end = cur.end;
                } else {
                    acc.push(cur);
                };
                return acc;
            },[]);
        };

        return returnObj;
    };

    // Function to convert UTC time intervals to local time for all days
    const convertUTCToLocalForAllDays = () => {
        for (const dayOfWeek in obj) {
            convertUTCToLocalForDay (obj[dayOfWeek], dayOfWeek);
        };

        return reduceTimeIntervals();
    };

    // Convert UTC time intervals to local time for all days
    return convertUTCToLocalForAllDays();
};