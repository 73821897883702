import React, { useEffect, useState } from 'react';
import API from '../../API';
import { auth } from '../../firebase';
import LoadingSpinner from '../LoadingSpinner';
import styles from './Stripe.module.css';

const { createStripeCustomerAPI, createStripeCheckout, upgradeStripeSubscription, updateUserObject } = API;

const createCheckoutSession = async (stripeID, subID, setLoading, setConfirm, setShowSuccess, planID) => {
    // check if there is a current subscription, if not create new, if there is move to 
    // process to edit subscription
    if (subID) {
        await upgradeStripeSubscription({ customer_id: stripeID, sub_id: subID, plan_id: planID }).then(res => {
            setLoading(false);
            setConfirm(false);
            setShowSuccess(true);
        }).catch(err => console.log(err));
    } else {
        await createStripeCheckout({ customer_id: stripeID, price_id: planID }).then((response) => {
            if (response.data.result === 'redirect') {
                setLoading(false);
                setConfirm(false);
                setShowSuccess(true);
                window.location.assign(response.data.url);
            };
        }).catch(err => console.log(err));
    };
};

const createStripeCustomer = async (data) => {
    const { uid } = data;

    const customer = await createStripeCustomerAPI(data)
        .then(async (res) => {
            await updateUserObject(uid, { stripe_id: res?.data?.customer?.id })
            return res?.data?.customer;
        }).catch(error => {
            return error;
        });

    if (customer.id) {
        return customer.id;
    };
};

export function Stripe(props) {
    const { basePlan, userObj, email, confirm, setConfirm, disabled, setShowSuccess } = props;
    const [sessionId, setSessionId] = useState('');
    const [planId, setPlanId] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        switch (basePlan) {
            case 1:
                setPlanId(process.env.REACT_APP_STRIPE_BASIC);
                break;
            case 2:
                setPlanId(process.env.REACT_APP_STRIPE_BASIC_PLUS);
                break;
            case 3:
                setPlanId(process.env.REACT_APP_STRIPE_PRO);
                break;
            case 4:
                setPlanId(process.env.REACT_APP_STRIPE_PRO_PLUS);
                break;
            case 5:
                // Handle Enterprise
                break;
            case 0:
            default:
                setPlanId(0);
                break;
        };
    }, [basePlan]);

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setSessionId(query.get('session_id'));
        };
    }, [sessionId, userObj]);

    const checkoutClick = async () => {
        if (!disabled) {
            const customer = (auth.currentUser?.uid === userObj?.org_id) && (userObj?.stripe_id ? userObj?.stripe_id : await createStripeCustomer({ email: email, uid: auth.currentUser.uid }));
            setLoading(true);
            createCheckoutSession(customer, userObj?.sub_id, setLoading, setConfirm, setShowSuccess, planId);
        };
    };

    return (loading ?
            <button className={styles.btn} disabled>
                One Moment...
                <LoadingSpinner />
            </button>
        : confirm ?
            <button className={styles.confirmBtn} onClick={() => checkoutClick()} >Confirm?</button>
        :
            <button className={styles.btn} onClick={() => { userObj?.sub_id ? setConfirm(true) : checkoutClick() }} disabled={disabled}>Checkout</button>
    );
};