import React, { useEffect, useState } from 'react';
import styles from './AssetEnlarged.module.css';

export default function AssetEnlarged(props) {

    const { imgRef } = props;

    const [ enlarge, setEnlarge ] = useState(false);
    const [ src, setSrc ] = useState('');

    useEffect(() => {
        const img = imgRef.current;
        const watcher = (e) => { 
            if (`${e.target}` === `${img}`) {
                setEnlarge(true);
                setSrc(e.target.src);
            } else return;
        };
        window.addEventListener('click', watcher);
        return () => window.removeEventListener('click', watcher);
    },[imgRef]);


    return (
        <div className={enlarge ? `${styles.enlarged} ${styles.active}` : styles.enlarged} onClick={() => setEnlarge(false)}>
            <div className={styles.enlargedInner}>
                <div className={styles.enlargedClose}><i className='far fa-xmark' style={{pointerEvents: 'none'}} /></div>
                <div className={styles.imgWrap}>
                    <img src={src} width='auto' height='auto' className={enlarge ? `${styles.enlargedImg} ${styles.active}` : styles.enlargedImg} alt='enlarged' onClick={e => e.stopPropagation()} />
                </div>
            </div>
        </div>
    );
};