const qrData = (dataObj, state) => {
    const { type, data, index, newBool } = dataObj
    switch (type) {
        case 'redirect':
            return { ...state, redirect_url: data };
        case 'alt-redirect':
            let arr = [...state.alternate_redirect_url];

            if (newBool) {
                arr.push(data);
            };
            
            if (index === 0) {
                arr[0] = data;
            } else {
                if (!data && !newBool) {
                    arr.splice(index, 1);
                } else {
                    arr[index] = data;
                };
            };

            return { ...state, alternate_redirect_url: arr ?? [] };
        case 'category':
            return { ...state, category: data };
        case 'add-tag':
            return { ...state, tags: [...state.tags, data] };
        case 'delete-tag':
            return { ...state, tags: state.tags.filter((item, index) => index !== data) };
        case 'nickname':
            return { ...state, nickname: data };
        case 'pdf':
            return { ...state, pdf: [ ...state.pdf, data ]};
        case 'pdf-reset':
            return { ...state, pdf: state.pdf.filter((obj, index) => index !== data )};
        case 'menu':
            return { ...state, menu: [...state.menu, data]};
        case 'menu-edit':
            state.menu[index] = data;
            return { ...state };
        case 'remove-menu':
            return { ...state, menu: state.menu.filter((obj, index) => index !== data )};
        case 'reset':
            return { redirect_url: '', category: '', tags: [], nickname: '', menu: '', microsite: '', pdf: [], asset: '', tracker: { description:'', columns: [{ archived: false, id: 'date', name: 'date', required: true, options: [], type: 'date' }] }, survey: { description:'', post_survey_options: { title:'', message:'', url:''}, questions: [{ archived: false, id: 'date', name: 'date', required: true, options: [], type: 'date' }] }, alternate_redirect_url: [] };
        case 'microsite':
            return { ...state, microsite: data };
        case 'location':
            return { ...state, location: data };
        case 'remove-location':
            return { ...state, location: null };
        case 'edit':
            return data;
        case 'asset-description':
            return { ...state, asset: { ...state.asset, description: data }};
        case 'asset-route':
            return { ...state, asset: { ...state.asset, route: state?.asset?.hasOwnProperty('route') ? [ ...state.asset.route, data ] : [ data ] } };
        case 'tracker-new-column':
            return { ...state, tracker: { ...state.tracker, columns: state.tracker.columns.concat(data) }};
        case 'survey-new-question':
            return { ...state, survey: { ...state.survey, questions: state.survey.questions.concat(data)}};
        case 'tracker-columns':
            return { ...state, tracker: { ...state.tracker, columns: data }};
        case 'survey-questions':
            return { ...state, survey: { ...state.survey, questions: data }};
        case 'survey-options-title':
            return { ...state, survey: { ...state.survey, post_survey_options: { ...state.survey.post_survey_options, title: data }}};
        case 'survey-options-message':
            return { ...state, survey: { ...state.survey, post_survey_options: { ...state.survey.post_survey_options, message: data }}};
        case 'survey-options-url':
            return { ...state, survey: { ...state.survey, post_survey_options: { ...state.survey.post_survey_options, url: data }}};
        case 'tracker-archive-state':
            if (state.tracker.columns.find(column => column.id === data.id)) {
                const arr = state?.tracker?.columns?.filter(column => column.id !== data.id);
                arr.push({ ...data, archived: data.archived ? false : true, required: false });
                return { ...state, tracker: { ...state.tracker, columns: arr }};
            };
            
            return { ...state, tracker: { ...state?.tracker }};
        case 'survey-archive-state':
            if (state.survey.questions.find(question => question.id === data.id)) {
                const arr = state?.survey?.questions?.filter(question => question.id !== data.id);
                arr.push({ ...data, archived: data.archived ? false : true, required: false });
                return { ...state, survey: { ...state.survey, questions: arr }};
            };
            return { ...state, survey: { ...state?.survey }};
        case 'tracker-description':
            return { ...state, tracker: { ...state.tracker, description: data }};
        case 'survey-description':
            return { ...state, survey: { ...state.survey, description: data }};
        case 'tracker-update-column':
            if (state.tracker?.columns?.find(column => column.id === data.id)) {
                const arr = state?.tracker?.columns?.map(column => column.id === data.id ? data : column);

                return { ...state, tracker: { ...state.tracker, columns: arr }};
            };
            
            return state;
        case 'survey-update-question':
            if (state.survey?.questions?.find(question => question.id === data.id)) {
                const arr = state?.survey?.questions?.map(question => question.id === data.id ? data : question);

                return { ...state, survey: { ...state.survey, questions: arr }};
            };

            return state;
        case 'tracker-delete-column':
            return { ...state, tracker: { ...state.tracker, columns: state.tracker.columns.filter(column => column.id !== data)}};
        case 'survey-delete-question':
            return { ...state, survey: { ...state.survey, questions: state.survey.questions.filter(question => question.id !== data)}};
        case 'tracker-required-state':
            return { ...state, tracker: { ...state.tracker, columns: state.tracker.columns.map(column => column.id === data.id ? { ...column, required: !column.required } : column)}};
        case 'survey-required-state':
            return { ...state, survey: { ...state.survey, questions: state.survey.questions.map(question => question.id === data.id ? { ...question, required: !question.required } : question)}};
        case 'schedule':
            return { ...state, schedule: data };
        default:
            return data;
    };
};

export default qrData;