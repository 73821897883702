import React from 'react';
import { HexColorPicker, HexColorInput } from "react-colorful";
import styles from './ColorPicker.module.css';

export default function ColorPicker({ onChange, name, color }) {

    return (
        <div className={styles.card} >
            <div className={styles.bottom}>
                <div className={styles.saturationHue}>
                    <HexColorPicker color={color} onChange={e => onChange(e, name)} />
                </div>
                <div className={styles.inputWrap}>
                    <span className={styles.label}>Hex</span>
                    <HexColorInput color={color} prefixed={true} onChange={e => onChange(e, name)} />
                </div>
            </div>
        </div>
    );
};