const getCombinedChartData = (liquidQRs, analytics, timeFrame, categoryFilter, startDate, endDate) => {
    const data = [];
    const qrs = categoryFilter.length > 0 && liquidQRs.filter(obj => categoryFilter.includes(obj.data.category)).map(obj => obj.id);
    const qrsToCheck = categoryFilter.length > 0 ? Object.keys(analytics).filter(key => qrs.includes(key)).map(key => analytics[key]) : Object.keys(analytics).map(key => analytics[key]);

    const date = new Date(Date.now());
    
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    
    switch (timeFrame) {
        case 0:
            const start = new Date(startDate);
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);

            const end = new Date(endDate);
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            end.setMilliseconds(999);

            const dateRange = Math.ceil(((end.getTime() - start.getTime()) / 86400000) - 1);
            for (let i = dateRange; i >= 0; i--) {
                let num = 0;
                const startCheck = new Date(start);
                startCheck.setDate(end.getDate() - i);
                
                const endCheck = new Date(end);
                endCheck.setDate(end.getDate() - i);
    
                qrsToCheck.forEach(arr => {
                    arr.forEach(obj => {
                        if (startCheck.getTime() < obj.date && obj.date < endCheck.getTime()) {
                            num++;
                        };
                    });
                });
                data.push(num);
            };
            
            return(data);
        case 1:
        case 2:
            const startCheck = new Date(date);
            startCheck.setDate(date.getDate() - (timeFrame - 1));

            const endCheck = new Date(date);
            endCheck.setDate(date.getDate() - (timeFrame - 2))
            endCheck.setMilliseconds(date.getMilliseconds() - 1);

            for (let i = 0; i < 24; i++) {
                let num = 0;
                startCheck.setHours(date.getHours() + i);
                endCheck.setHours(date.getHours() + i);
    
                qrsToCheck.forEach(arr => {
                    arr.forEach(obj => {
                        if (startCheck.getTime() < obj.date && obj.date < endCheck.getTime()) {
                            num++;
                        };
                    });
                });
                data.push(num);
            };

            return(data);
        case 7:
        case 30:
        case 90:
            for (let i = timeFrame - 1; i >= 0; i--) {
                let num = 0;
    
                const startCheck = new Date(date);
                startCheck.setDate(date.getDate() - i);
                
                const endCheck = new Date(date);
                endCheck.setDate(date.getDate() - (i - 1));
                endCheck.setMilliseconds(date.getMilliseconds() - 1);

                qrsToCheck.forEach(arr => {
                    arr.forEach(obj => {
                        if (startCheck.getTime() < obj.date && obj.date < endCheck.getTime()) {
                            num++;
                        };
                    });
                });
                data.push(num);
            };

            return(data);
        default:
            return;
    };
}

export default getCombinedChartData