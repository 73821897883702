import { firestore } from '../../firebase/index';
import { collection, doc, getDoc } from 'firebase/firestore';
import { badCodes } from '../BadCodes';

export default async function keyGen() {
    let arr = 'M1NqBaVz2CwXsZxA3eSdDcF4GrfHvJ5KtLgbP6OyIhUn7YuTjRmE8iWk9Qol0p';
    let randCode = '';
    let idBool = true;

    while(idBool === true){
        for (let i = 0; i < 6; i++) {
            let randNum = Math.floor(Math.random() * 62);
            randCode += arr[randNum];
    
            if(i === 5){
                const snapshot = await getDoc(doc(collection(firestore, 'codes'), randCode));
                if (snapshot.exists() || badCodes.includes(randCode.toLowerCase())) {
                    randCode = '';
                } else {
                    idBool = false;
                    return randCode;
                };
            };
        };
    };
};