import React, { useState, useEffect, useRef } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import styles from './QRSortMenu.module.css';

export default function QRSortMenu(props) {
    
    const { filters, setFilters } = props;
    const { sortBy } = filters;
    const [ showSort, setShowSort ] = useState(false);
    
    const sortRef = useRef();

    useEffect(() => {
        const watcher = (e) => {
            if (!sortRef.current || sortRef.current.contains(e.target)) {
                return;            
            } else {
                setShowSort(false);
            };
        };
        window.addEventListener('click', watcher);
        return () => window.removeEventListener('click', watcher);
    },[]);

    const handleSort = (e) => {
        e.stopPropagation();
        const id = e.target.id.replace('type-','');
    
        setFilters(prev => ({ ...prev, page: 1, sortBy: id === prev.sortBy ? '' : id }));
    };

    const handleSortIconReturn = () => {
        switch (sortBy) {
            case 'newest':
                return <i className='fas fa-arrow-down-small-big' />;
            case 'oldest':
                return <i className='fas fa-arrow-down-big-small' />;
            case 'alpha-asc':
                return <i className='fas fa-arrow-down-a-z' />;
            case 'alpha-des':
                return <i className='fas fa-arrow-down-z-a' />;
            case 'link':
                return <i className='fas fa-link-simple' />;
            case 'pdf':
                return <i className='fas fa-file-pdf' />;
            case 'menu':
                return <i className='fas fa-fork-knife' />;
            case 'location':
                return <i className='fas fa-map-location' />;
            case 'microsite':
                return <i className='fas fa-square-list' />;
            case 'appointment':
                return <i className='fas fa-calendar-days' />;
            case 'tracker':
                return <i className='fas fa-clipboard-list-check' />;
            case 'asset':
                return <i className='fas fa-shelves' />;
            case 'survey':
                return <i className='far fa-square-poll-vertical' />;
            default:
                return <i className='fas fa-arrow-down-wide-short' />;
        };
    };

    // Submenu states
    const [ showSortSub, setShowSortSub ] = useState(true);
    const [ showFilterSub, setShowFilterSub ] = useState(true);

    const handleSubMenus = (e) => {
        e.stopPropagation();
        const id = e.target.id;

        if (id === 'sort') {
            setShowSortSub(prev => !prev);
        } else if (id === 'filter') {
            setShowFilterSub(prev => !prev);
        };

        return;
    };

    useEffect(() => {
        const sortMenu = document.querySelector('#sort');
        const filterMenu = document.querySelector('#filter');
        const sortOptions = document.querySelector('#sort-options');
        const filterOptions = document.querySelector('#filter-options');

        if (!showSortSub) {
            sortMenu?.classList.add(styles.collapsed);
            sortOptions?.classList.add(styles.collapsed);
        } else {
            sortMenu?.classList.remove(styles.collapsed);
            sortOptions?.classList.remove(styles.collapsed);
        };
        
        if (!showFilterSub) {
            filterMenu?.classList.add(styles.collapsed);
            filterOptions?.classList.add(styles.collapsed);
        } else {
            filterMenu?.classList.remove(styles.collapsed);
            filterOptions?.classList.remove(styles.collapsed);
        };
    }, [showSortSub, showFilterSub]);

    return (
        <div className={styles.sortBtn} ref={sortRef} onClick={() => setShowSort(prev => !prev)}>
            {handleSortIconReturn()}
            <Collapse in={showSort} mountOnEnter>
                <div className={styles.sortMenuWrap}>
                    <div className={styles.sortMenu}>
                        <div id='sort' className={styles.sortMenuSub} onClick={(e) => handleSubMenus(e)}><i className='far fa-caret-down me-1' /> Sort <i className='far fa-caret-down ms-1' /></div>
                        <div id='sort-options' className={styles.sortMenuOptionWrap}>
                            <div id='newest' className={styles.sortMenuOption} style={sortBy === 'newest' ? {backgroundColor: '#161d2d', color: '#fff'} : null} onClick={e => handleSort(e)}>Newest</div>
                            <div id='oldest' className={styles.sortMenuOption} style={sortBy === 'oldest' ? {backgroundColor: '#161d2d', color: '#fff'} : null} onClick={e => handleSort(e)}>Oldest</div>
                            <div id='alpha-asc' className={styles.sortMenuOption} style={sortBy === 'alpha-asc' ? {backgroundColor: '#161d2d', color: '#fff'} : null} onClick={e => handleSort(e)}>Alphabetical Ascend</div>
                            <div id='alpha-des' className={styles.sortMenuOption} style={sortBy === 'alpha-des' ? {backgroundColor: '#161d2d', color: '#fff'} : null} onClick={e => handleSort(e)}>Alphabetical Descend</div>
                        </div>
                        <div id='filter' className={styles.filterMenuSub} onClick={e => handleSubMenus(e)}><i className='far fa-caret-down me-1' /> Filter (Type) <i className='far fa-caret-down ms-1' /></div>
                        <div id='filter-options' className={styles.filterMenuOptionWrap}>
                            <div id='type-link' className={styles.filterMenuOption} style={sortBy === 'link' ? {backgroundColor: '#161d2d', color: '#fff'} : null} onClick={e => handleSort(e)}>Link</div>
                            <div id='type-pdf' className={styles.filterMenuOption} style={sortBy === 'pdf' ? {backgroundColor: '#161d2d', color: '#fff'} : null} onClick={e => handleSort(e)}>PDF</div>
                            <div id='type-menu' className={styles.filterMenuOption} style={sortBy === 'menu' ? {backgroundColor: '#161d2d', color: '#fff'} : null} onClick={e => handleSort(e)}>Menu</div>
                            <div id='type-location' className={styles.filterMenuOption} style={sortBy === 'location' ? {backgroundColor: '#161d2d', color: '#fff'} : null} onClick={e => handleSort(e)}>Location</div>
                            <div id='type-microsite' className={styles.filterMenuOption} style={sortBy === 'microsite' ? {backgroundColor: '#161d2d', color: '#fff'} : null} onClick={e => handleSort(e)}>Microsite</div>
                            <div id='type-appointment' className={styles.filterMenuOption} style={sortBy === 'appointment' ? {backgroundColor: '#161d2d', color: '#fff'} : null} onClick={e => handleSort(e)}>Appointment</div>
                            <div id='type-tracker' className={styles.filterMenuOption} style={sortBy === 'tracker' ? {backgroundColor: '#161d2d', color: '#fff'} : null} onClick={e => handleSort(e)}>Tracker</div>
                            <div id='type-asset' className={styles.filterMenuOption} style={sortBy === 'asset' ? {backgroundColor: '#161d2d', color: '#fff'} : null} onClick={e => handleSort(e)}>Liquid Label</div>
                            <div id='type-survey' className={styles.filterMenuOption} style={sortBy === 'survey' ? {backgroundColor: '#161d2d', color: '#fff'} : null} onClick={e => handleSort(e)}>Liquid Feedback</div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    );
};