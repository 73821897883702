import React, { useEffect, useState, useRef } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
);

export const options = {
    responsive: true,
    plugins: {
        legend: true,
        title: {
            display: true,
            text: 'Top 5 Performing LiquidQRs',
        }
    },
    scales: {
        y: {
          beginAtZero: true,
        },
    },
};

export const data = {
    labels: ["a", "b", "c", "d", "e", "f", "g"],
    datasets: [
        {
            label: 'LiquidQR Scans',
            data: [0, 0, 0, 0, 0, 0, 0],
            borderColor: '#607aa4',
            backgroundColor: '#607aa4',
        }
    ],
};

export const updateChart = (chart, chartLabels, chartData) => {
    chart.data.datasets = chartData; 
    chart.data.labels = chartLabels;

    chart.update();
};

export function TopFive(props) {
    const { chartData, chartLabels, chartHeaders } = props;
    const chartRef = useRef(null);
    const [ chartDataArr, setChartDataArr ] = useState();
    const [ headers, setHeaders ] = useState([]);

    useEffect(() => {
        if (chartHeaders && chartHeaders.length > 0 && (chartHeaders.some(name => !headers.includes(name)))) {
            setHeaders(chartHeaders);
        };
    },[chartHeaders, headers]);

    useEffect(() => {
        if (chartData && chartData.length > 0 && headers && headers.length > 0) {
            let defaultColors = [
                "#607aa4", "#DC3912", "#FF9900", "#109618", "#990099", "#3B3EAC", "#0099C6",
                "#DD4477", "#66AA00", "#B82E2E", "#316395", "#994499", "#22AA99", "#AAAA11",
                "#6633CC", "#E67300", "#8B0707", "#329262", "#5574A6", "#651067"
            ];

            if (chartDataArr && headers && chartDataArr.some(obj => !headers.includes(obj.name))) {
                const dataArr = [];
                chartData.sort((a,b) => headers.indexOf(a.name) - headers.indexOf(b.name));
                chartDataArr.forEach((obj, i) => {
                    obj.data = chartData[i]?.data;
                    obj.label = `${chartData[i]?.name} Scans`
                    dataArr.push(obj); 
                });
            } else {
                const dataArr = [];
                chartData.forEach((arr, i) => {
                    const dataObj = {
                        label: `${arr.name} Scans`,
                        data: arr.data,
                        borderColor: defaultColors[i],
                        backgroundColor: defaultColors[i],
                    };
                    dataArr.push(dataObj);
                });
                setChartDataArr(dataArr);
            };
        };
        
    },[chartData, headers]);

    useEffect(() => {
        const chart = chartRef.current;

        if (chartDataArr && chartHeaders) {
            updateChart(chart, chartLabels, chartDataArr, chartHeaders);
        };
    },[chartLabels, chartDataArr]);

    return <Line options={options} data={data} ref={chartRef} />;
};