import React, { useState, useEffect } from 'react';
import styles from './ColumnTypes.module.css';

const Options = ({ options, setColumn }) => {
    if (options?.length > 0) {
        return (
            options?.map((opt, index) => (
                <div key={opt+index} className={styles.addOptContainer}>
                    <div className={styles.inputGroup}>
                        <input type='text' id='column-options-label' className={styles.input} maxLength='100' value={opt} disabled/>
                        <label htmlFor='column-options-label' className={styles.label}>Option</label>
                        <button className={styles.removeOptionBtn} onClick={() => setColumn(prev => ({...prev, options: prev.options.filter((option, i) => i !== index)}))} ><i className='fa-solid fa-xmark' /></button>
                    </div>
                </div>
            ))
        );
    } else {
        return <></>;
    };
};

const ColumnTypes = (props) => {
    const { column, setColumn, option, setOption } = props;

    switch (column?.type) {
        case 'dropdown':
        case 'radio':
        case 'checkbox':
            return (
                <div className={styles.optionsContainer}>
                    <Options options={column?.options} setColumn={setColumn} />
                    <div className={styles.addOptContainer}>
                        <div className={styles.inputGroup}>
                            <input type='text' id='column-options-label' className={styles.input} maxLength='100' value={option} onChange={e => setOption(e.target.value)} />
                            <label htmlFor='column-options-label' className={styles.label}>Options</label>
                        </div>
                        <button className={styles.addOptionBtn} onClick={() => { setColumn(prev => ({ ...prev, options: prev.options.concat(option) })); setOption(''); }} ><i className='fa-solid fa-plus' /></button>
                    </div>
                </div>
            );
        default:
            return <></>;
    };
};

export default ColumnTypes;