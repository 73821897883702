import React from 'react';
import Nickname from '../../Nickname';
import Tags from '../../Tags';
import Categories from '../../Categories';
import styles from './Appointment.module.css';

export default function Appointment(props) {
    // ----- STATE INITIALIZATION -----
    const { qr_data, dataDispatch, categoryList, setCategoryList, plan, plan_id } = props;
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    return(
        <>
        <h5 className={styles.header}>Schedule Appointment</h5>
        <Nickname qr_data={qr_data} dataDispatch={dataDispatch} />
        <Categories categoryList={categoryList.filter(cat => cat.id !== 'Uncategorized')} setCategoryList={setCategoryList} qr_data={qr_data} dataDispatch={dataDispatch} plan_id={plan_id} plan={plan} />
        <Tags qr_data={qr_data} dataDispatch={dataDispatch} />
        </>
    );
};