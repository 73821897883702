import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useShowNav } from '../../utils/NavigationContext';
import API from '../../API';
import LoadingSpinner from '../../components/LoadingSpinner';
import styles from './MicrositeDisplay.module.css';

const MicrositeDisplay = () => {
    const { setShowNavigation } = useShowNav();
    const { id: qrId } = useParams();
    
    const [ qrObj, setQrObj ] = useState(null);
    const [ html, setHtml ] = useState(null);
    
    
    useEffect(() => {
        const { getQR } = API;
        setShowNavigation(false);

        const handleGetQR = async () => {
            try {
                const qr = await getQR(qrId);
                if (qr) setQrObj(qr);
            } catch (err) {
                console.error(err);
            };
        };

        handleGetQR();

        return () => {
            setQrObj(null);
            setHtml(null);
            setShowNavigation(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[qrId]);

    useEffect(() => {
        if (qrObj?.microsite) {
            setHtml(qrObj?.microsite);
        };
    }, [qrObj]);

    if (!qrObj) {
        return <LoadingSpinner />;
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container} dangerouslySetInnerHTML={{__html: html && html}} />
        </div>
    );
};

export default MicrositeDisplay;