import React, { useState, createContext, useContext } from 'react';
import Navigation from '../../components/Navigation';
import MobileNavigation from '../../components/MobileNavigation';

const NavigationContext = createContext();

export const useShowNav = () => useContext(NavigationContext);

export const NavigationContextProvider = ({ children }) => {
    const [ showNavigation, setShowNavigation ] = useState(true);
    
    return (
        <NavigationContext.Provider value={{ showNavigation, setShowNavigation }}>
            {children}
        </NavigationContext.Provider>
    );
};


export const NavigationConditional = () => {
    const { showNavigation } = useShowNav();

    if (!showNavigation) return <></>;

    return (
        <>
        <Navigation />
        <MobileNavigation />
        </>
    );
};