import React from 'react';
import Grid from './Grid';
import List from './List';

export default function QRCard(props) { 
    // ----- STATE INITIALIZATION -----
    const { QR, orgId, setQRtoEdit, setDisplay, dataDispatch, optionsDispatch, analytics, layout, childQRs } = props;

    switch (layout) {
        case 'list':
            return (
                <List
                    QR={QR}
                    orgId={orgId}
                    analytics={analytics}
                    setQRtoEdit={setQRtoEdit}
                    setDisplay={setDisplay}
                    dataDispatch={dataDispatch}
                    optionsDispatch={optionsDispatch}
                    childQRs={childQRs}
                />
            );
        case 'grid':
        default:
            return (
                <Grid
                    QR={QR}
                    orgId={orgId}
                    analytics={analytics}
                    setQRtoEdit={setQRtoEdit}
                    setDisplay={setDisplay}
                    dataDispatch={dataDispatch}
                    optionsDispatch={optionsDispatch}
                    childQRs={childQRs}
                />
            );
    }
}