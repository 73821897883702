import React, { useEffect } from 'react';
import styles from './Tabs.module.css';

export default function Tabs(props) {
    const { qrType, step, setStep } = props;

    useEffect(() => {
        const tabs = document.querySelectorAll(`.${styles.tab}`);
        tabs.forEach(tab => {
            const tabNum = tab.id.split('-');
            const num = parseInt(tabNum[1]);
            if (num === step) {
                tab.classList.add(styles.tabActive);
                tab.classList.remove(styles.tabShadow);
            } else {
                tab.classList.remove(styles.tabActive);
                tab.classList.add(styles.tabShadow);
            };
        });
    },[step]);

    return (
        <div className={styles.container}>
            <div className={styles.tab} id='tabs-2' onClick={() => setStep(2)} style={(qrType === 'link' || qrType === 'pdf' || qrType === 'menu') ? {width: 'calc(100% / 4'} : {width: 'calc(100% / 3'}} >
                Details
            </div>
            <div className={styles.tab} id='tabs-5' onClick={() => setStep(5)} style={(qrType === 'link' || qrType === 'pdf' || qrType === 'menu') ? {width: 'calc(100% / 4)'} : {width: 'calc(100% / 3'}} >
                Options
            </div>
            <div className={styles.tab} id='tabs-3' onClick={() => setStep(3)} style={(qrType === 'link' || qrType === 'pdf' || qrType === 'menu') ? {width: 'calc(100% / 4'} : {width: 'calc(100% / 3'}} >
                Styling
            </div>
            {(qrType === 'link' || qrType === 'pdf' || qrType === 'menu') ?
                <div className={styles.tab} id='tabs-4' onClick={() => setStep(4)} style={{width: 'calc(100% / 4'}} >
                    Schedule
                </div>
            :
                <></>
            }
        </div>
    );
};