const getSeparateChartData = (liquidQRs, analytics, timeFrame, categoryFilter, startDate, endDate) => {
    const data = [];
    const qrs = categoryFilter?.length > 0 ? liquidQRs.filter(obj => categoryFilter?.includes(obj.data.category))?.map(obj => obj.id) : liquidQRs.map(obj => obj.id);
    const categoryFilterBool = categoryFilter.length > 0 && qrs.length < 1;
    const qrsToCheck = Object.keys(analytics).filter(key => qrs?.includes(key))?.map(key => ({ name: liquidQRs.find(obj => obj?.id === key)?.data?.nickname, data: analytics[key]}));
    const date = new Date(Date.now());
    
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    
    switch (timeFrame) {
        case 0:
            const start = new Date(startDate);
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);

            const end = new Date(endDate);
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            end.setMilliseconds(999);

            const dateRange = Math.ceil(((end.getTime() - start.getTime()) / 86400000) - 1);
            qrsToCheck.forEach(arr => {
                const dataArr = [];

                for (let i = dateRange; i >= 0; i--) {
                    let num = 0;
                    const startCheck = new Date(start);
                    startCheck.setDate(end.getDate() - i);
                    
                    const endCheck = new Date(end);
                    endCheck.setDate(end.getDate() - i);
                    arr.data.forEach(obj => {
                        if (startCheck.getTime() < obj.date && obj.date < endCheck.getTime()) {
                            num++;
                        };
                    });
                    dataArr.push(num);
                };
                data.push({data: dataArr, name: arr.name});
            });
            const filteredDataA = data.sort((a, b) => b.total - a.total).map(obj => {return {data: obj.data, name: obj.name}});
            const orderA = filteredDataA.map(obj => obj.name);

            return({data: filteredDataA, order: orderA, bool: categoryFilterBool});
        case 1:
        case 2:
            const startCheck = new Date(date);
            startCheck.setDate(date.getDate() - (timeFrame - 1));

            const endCheck = new Date(date);
            endCheck.setDate(date.getDate() - (timeFrame - 2))
            endCheck.setMilliseconds(date.getMilliseconds() - 1);

            qrsToCheck.forEach(arr => {
                const dataArr = [];

                for (let i = 0; i < 24; i++) {
                    let num = 0;
                    startCheck.setHours(date.getHours() + i);
                    endCheck.setHours(date.getHours() + i);

                    arr.data.forEach(obj => {
                        if (startCheck.getTime() < obj.date && obj.date < endCheck.getTime()) {
                            num++;
                        };
                    });
                    dataArr.push(num);
                };
                data.push({data: dataArr, name: arr.name});
            });
            const filteredDataB = data.sort((a, b) => b.total - a.total).map(obj => {return {data: obj?.data, name: obj.name}});
            const orderB = filteredDataB.map(obj => obj.name);

            return({data: filteredDataB, order: orderB, bool: categoryFilterBool});
        case 7:
        case 30:
        case 90:
            qrsToCheck.forEach(arr => {
                const dataArr = [];
                let total = 0;
                for (let i = timeFrame - 1; i >= 0; i--) {
                    let num = 0;
        
                    const startCheck = new Date(date);
                    startCheck.setDate(date.getDate() - i);
                    
                    const endCheck = new Date(date);
                    endCheck.setDate(date.getDate() - (i - 1));
                    endCheck.setMilliseconds(date.getMilliseconds() - 1);
                
                    arr.data.forEach(obj => {
                        if (startCheck.getTime() < obj.date && obj.date < endCheck.getTime()) {
                            num++;
                        };
                    });
                    total += num;
                   dataArr.push(num);
                };
                data.push({data: dataArr, name: arr.name, total: total});
            });
            const filteredDataC = data.sort((a, b) => b.total - a.total).map(obj => {return {data: obj.data, name: obj.name}});
            const orderC = filteredDataC.map(obj => obj.name);
            return({data: filteredDataC, order: orderC, bool: categoryFilterBool});
        default:
            return;
    };
};

export default getSeparateChartData;