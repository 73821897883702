import React, { useState, useEffect, useRef, useMemo } from 'react';
import QRCodeStyling from 'pp-qr-code';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../utils/Auth/index.js';
import API from '../../../API';
import Categories from '../../QRSwitch/Categories/index.js';
import Nickname from '../../QRSwitch/Nickname/index.js';
import QRList from '../../QRSwitch/QRList/index.js';
import styles from './GenExistingUser.module.css';

const QR = ({ options }) => {
    const qrRef = useRef();
    const code = new QRCodeStyling();

    useEffect(() => {
        if (qrRef.current.children[0]) {
            return;
        };
        if (options) {
            code.update(options);
            code.append(qrRef.current);
        };

        return () => { code.update(options); }
    },[options, code]);

    return (
        <div className={styles.qrContainer} >
            <div ref={qrRef} className={styles.qrRef} />
        </div>
    );
};

const GenExistingUser = (props) => {
    const { gen_id, state, setState, createQrTemplate } = props;
    const { orgObj, liquidQRs } = useAuth();
    const [ parent, setParent ] = useState({ obj: {}, selected: false });
    const qrLen = liquidQRs?.length;
    const navigate = useNavigate();
    
    const { addQrFromGenerator, deactivateQR } = API;

    const planQRs = useMemo(() => {
        if (orgObj?.hasOwnProperty('plan')) {
            return Number(orgObj.plan.liquid_qrs);
        } else {
            return 0;
        };
    },[orgObj?.plan]);

    const handleAddQr = async () => {
        try {
            const temp = await createQrTemplate({ ...state.qrGenObj, org_id: orgObj?.org_id })
            
            await addQrFromGenerator({ id: gen_id, data: temp});
            navigate('/collection');
        } catch (err) {
            console.log(err);
        };
    };

    const handleDeactivateQr = async () => {
        try {
            if (!parent.obj?.id) return;
            await deactivateQR(parent.obj?.id);
            setParent({ obj: {}, selected: false });
        } catch (err) {
            console.log(err);
        };
    };
    
    const handleDataDispatch = ({ type, data }) => {
        if (type === 'category') {
            setState(prev => ({ ...prev, qrGenObj: { ...prev.qrGenObj, category: data }}));
        } else if (type === 'nickname') {
            setState(prev => ({ ...prev, qrGenObj: { ...prev.qrGenObj, nickname: data }}));
        } else return;
    };

    if (planQRs && qrLen) {
        return (
            <div className={styles.container}>
                <h2>Add to Account</h2>
                <div className={styles.body}>
                    {planQRs > qrLen ?
                        <>
                        <QR options={state?.qrGenObj?.qr_style} />
                        <div className={styles.addQrContainer}>
                            <div className={styles.nameCatContainer} >
                                <Nickname qr_data={{ nickname: state.qrGenObj.nickname }} dataDispatch={handleDataDispatch} setUp={false} />
                                <Categories qrData={{ category: state.qrGenObj.category }} dataDispatch={handleDataDispatch} noNewCategoriesBool={true} categoryList={orgObj.categories} plan={orgObj.plan} />
                            </div>
                            <button className={styles.addQrBtn} onClick={handleAddQr} disabled={planQRs <= qrLen}>Add QR</button>
                            <Link to='/collection' className={styles.cancelBtn}>Cancel</Link>
                        </div>
                        </>
                    :
                        <QRList categories={orgObj?.categories} setParent={setParent} liquidQRs={liquidQRs} title={'QR Limit Reached'} message={'Delete a QR from your collection below to add this to your account.'} generatedQR={state?.qrGenObj?.qr_style} handleDeactivateQr={handleDeactivateQr} />
                    }
                </div>
            </div>
        );
    } else {
        return <></>;
    };
};

export default GenExistingUser;