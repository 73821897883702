import React, { useState, useEffect } from 'react';
import { customAlphabet } from 'nanoid';
import ColumnTypes from '../ColumnTypes';
import styles from './Column.module.css';

const Column = (props) => {
    const { columns, dataDispatch, selected, setSelected, originalQRTracker } = props;
    const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzQWERTYUIOPASDFGHJKLZXCVBNM', 6);

    const handleNewId = () => {
        const newId = nanoid();
        
        if (columns.find(column => column.id === newId)) {
            return handleNewId();
        };

        return newId;
    };

    const [ show, setShow ] = useState(false);
    const [ column, setColumn ] = useState(selected ?? { id: handleNewId(), name: '', type: '', archived: false, options: [], required: false });
    const [ option, setOption ] = useState('');
    const required = ['checkbox','radio','dropdown'];

    const columnOptions = [{name: 'Text Box', id: 'text-box'},{name: 'Text Area', id: 'text-area'},{name: 'Count', id: 'number'},{name: 'Dropdown', id: 'dropdown'},{name: 'Signature', id: 'signature'},{name: 'Radio', id: 'radio'},{name: 'Checkbox(es)', id: 'checkbox'}];
    
    const handleAddColumn = () => {
        const tempOptions = [ ...column.options ];
        
        if (option) {
            tempOptions.push(option);
        };

        if (selected) {
            dataDispatch({ type: 'tracker-update-column', data: { ...column, options: tempOptions } });
        } else {
            dataDispatch({ type: 'tracker-new-column', data: { ...column, options: tempOptions } });
        };

        handleCancel();
    };

    const handleCancel = () => {
        setOption('');
        setColumn({ id: handleNewId(), name: '', type: '', archived: false, options: [], required: false });
        setShow(false);
        setSelected(null);
    };

    useEffect(() => {
        if (selected) {
            setColumn(selected);
            setShow(true);
        };
    },[selected, setColumn]);
    
    if (show) {
        return (
            <>
            <div className={styles.column}>
                <div className={styles.inputGroup}>
                    <input type='text' id='column-name-label' className={styles.input} maxLength='50' onChange={e => setColumn(prev => ({...prev, name: e.target.value}))} value={column?.name} />
                    <label htmlFor='column-name-label' className={styles.label}>Column Name</label>
                </div>
                <div className={styles.inputGroupSelect}>
                    <select className={styles.select} defaultValue={column?.type ?? ''} onChange={e => setColumn(prev => ({ ...prev, type: e.target.value }))} disabled={(originalQRTracker?.data?.tracker?.columns?.find( col => col?.id === column?.id))} >
                        <option value='' disabled default >Select Column Type</option>
                        {columnOptions.map((opt, index) => <option key={opt.id+index} value={opt.id} disabled={(opt.id === 'signature' && columns?.find(col => col.type === 'signature')) ? true : false}>{opt.name}</option>)}
                    </select>
                </div>
            </div>
            <ColumnTypes column={column} setColumn={setColumn} option={option} setOption={setOption} />
            {(required.includes(column?.type) && column.options.length < 1) ? <span className={styles.optionWarn} ><i className='fa-solid fa-asterisk fa-xs me-1' /> One option required.</span> : <></>}
            <div className={styles.saveCancelContainer}>
                <button className={styles.addColumnBtn} onClick={handleAddColumn} disabled={!column?.type || !column?.name || (required.includes(column.type) && column.options.length < 1)}>Confirm</button>
                <button className={styles.addColumnBtn} onClick={handleCancel}>Cancel</button>
            </div>
            </>
        );
    } else {
        return <button className={styles.addColumnBtn} onClick={() => setShow(true)}>Add +</button>
    };
};

export default Column;