import React, { useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import styles from './QuestionTypes.module.css';

const TextBox = ({ state, setState, question }) => {
    const { id, name, required } = question;
    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <label htmlFor={id} className={styles.label}>{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                <input id={id} type='text' className={styles.input} maxLength='100' value={state[id]} placeholder='Your answer' onChange={e => setState(prev => ({ ...prev, [id]: e.target.value}))} ></input>
            </div>
        </div>
    );
};

const TextArea = ({ state, setState, question }) => {
    const { id, name, required } = question;
    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <label htmlFor={id} className={styles.label} >{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                <textarea id={id} value={state[id]} className={styles.textarea} placeholder='Your answer' maxLength={256} onChange={e => setState(prev => ({ ...prev, [id]: e.target.value}))} ></textarea>
            </div>
        </div>
    );
};

const Number = ({ state, setState, question }) => {
    const { id, name, required } = question;
    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <label htmlFor={id} className={styles.label} >{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                <input id={id} type='number' placeholder={0} className={styles.input} value={state[id]} onFocus={(e) => e.target.addEventListener("wheel", (e) => { e.preventDefault() }, { passive: false })} onChange={e => setState(prev => ({ ...prev, [id]: e.target.value}))}></input>
            </div>
        </div>
    );
};

const Dropdown = ({ state, setState, question }) => {
    const { id, options, name, required } = question;
    return (
        <div className={styles.section}>
            <div className={styles.inputGroupSelect}>
                <label htmlFor={id} className={styles.label} >{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                <select id={id} value={state[id] ?? ''} className={styles.dropdown} onChange={e => setState(prev => ({ ...prev, [id]: e.target.value}))} >
                    <option value='' disabled>Select an option</option>
                    {options?.map((name, index) => (<option key={name + index} className={styles.option} value={name}>{name}</option>))}
                </select>
            </div>
        </div>
    );
};

const Signature = ({ question, sigCanvas, setSigCanvas, setState, edit }) => {
    const { id, name, required } = question;

    useEffect(() => {
        if (edit && sigCanvas) {
            sigCanvas.off();
        };
    },[edit, sigCanvas]);

    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <div className={styles.signatureLabelContainer}>
                    <label className={styles.label}>{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                    {edit ? <p style={{paddingLeft: '.5rem', marginBottom: '0.25rem', fontSize: '.85rem'}}>Signatures cannot be edited.</p> : <button className={styles.btn} onClick={() => { sigCanvas.clear(); setState(prev => ({ ...prev, [id]: false } )) }} disabled={edit}>Clear</button>}
                </div>
                <SignatureCanvas ref={(ref) => {setSigCanvas(ref)}} onEnd={() => setState(prev => ({...prev, [id]: sigCanvas?.isEmpty() ? true : false}))} penColor='black' canvasProps={{ width: 350, height: 100, className:`${styles.trackerCanvas}` }} />
            </div>
        </div>
    );
};

const Radio = ({ state, setState, question }) => {
    const { id, name, options, required } = question;
    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <label className={styles.label} >{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                {options.map((opt, index) => (
                    <div key={opt + index} className={styles.checkGroup} >
                        <input type='radio' name={`${question}_options`} id={id+index} className={styles.checkbox} value={opt} checked={state[id] === opt} onChange={(e) => setState(prev => ({ ...prev, [id]: e.target.value}))} ></input>
                        <label htmlFor={id+index} className={styles.checkboxOption} >{opt}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

const Checkbox = ({ state, setState, question }) => {
    const { id, name, options, required } = question;
    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <label className={styles.label} >{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                {options.map((opt, index) => (
                    <div key={opt + index} className={styles.checkGroup}>
                        <input type='checkbox' id={id+index} className={styles.checkbox} defaultChecked={state[id]?.includes(opt) ?? false} onChange={e => setState(prev => e.target.checked ? ({ ...prev, [id]: prev[id].concat(opt) }) : ({...prev, [id]: prev[id].filter(x => x !== opt) }))} ></input>
                        <label htmlFor={id+index} className={styles.checkboxOption}>{opt}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

const IconRating = ({ state, setState, question }) => {
    const { id, name, required, icon } = question;

    const [ iconObj ] = useState(() => {
        const obj = {};
        switch (icon) {
            case 'hearts':
                obj.unselected = 'far fa-heart';
                obj.selected = 'fas fa-heart';
                obj.checked = 'red';
                break;
            case 'thumbs-up':
                obj.unselected = 'far fa-thumbs-up';
                obj.selected = 'fas fa-thumbs-up';
                obj.checked = '#161d2d';
                break;
            case 'flames':
                obj.unselected = 'far fa-fire';
                obj.selected = 'fa-duotone fa-fire';
                obj.checked = '#e14714';
                obj.checked2 = '#ff9b29';
                break;
            case 'emotions':
                obj.unselected = 'far fa-face-meh';
                obj.selected = 'far fa-face-angry';
                obj.selected2 = 'far fa-face-frown';
                obj.selected3 = 'far fa-face-grin';
                obj.selected4 = 'far fa-face-grin-wide';
                obj.selected5 = 'far fa-face-laugh-beam';
                obj.checked = 'red';
                obj.checked2 = 'orangered';
                obj.checked3 = 'purple';
                obj.checked4 = 'blue';
                obj.checked5 = 'green';
                break;
            case 'stars':
            default:
                obj.unselected = 'far fa-star';
                obj.selected = 'fas fa-star';
                obj.checked = 'gold';
                break;
        };

        return obj;
    });

    const handleRating = (e) => {
        const selectedRating = parseInt(e.target.id.split('-')[1]);
        setState(prev => ({ ...prev, [id]: selectedRating}));
    };

    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <label className={styles.label} >{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                <div className={styles.innerGroup}>
                    <div id='radio-1' className={state[id] === 1 ? `${styles.radioRating} ${styles.selected}` : styles.radioRating} onClick={handleRating}>
                        <i className={state[id] > 0 ? iconObj.selected : iconObj.unselected} style={state[id] > 0 ? icon === 'flames' ? {'--fa-primary-color': iconObj.checked, '--fa-secondary-color': iconObj.checked2, '--fa-secondary-opacity': '1'} : {color: iconObj.checked} : null} />
                    </div>
                    <div id='radio-2' className={state[id] === 2 ? `${styles.radioRating} ${styles.selected}` : styles.radioRating} onClick={handleRating}>
                        <i className={state[id] > 1 ? icon === 'emotions' ? iconObj.selected2 : iconObj.selected : iconObj.unselected} style={state[id] > 1 ? icon === 'flames' ? {'--fa-primary-color': iconObj.checked, '--fa-secondary-color': iconObj.checked2, '--fa-secondary-opacity': '1'} : icon === 'emotions' ? {color: iconObj.checked2} : {color: iconObj.checked} : null} />
                    </div>
                    <div id='radio-3' className={state[id] === 3 ? `${styles.radioRating} ${styles.selected}` : styles.radioRating} onClick={handleRating}>
                        <i className={state[id] > 2 ? icon === 'emotions' ? iconObj.selected3 : iconObj.selected : iconObj.unselected} style={state[id] > 2 ? icon === 'flames' ? {'--fa-primary-color': iconObj.checked, '--fa-secondary-color': iconObj.checked2, '--fa-secondary-opacity': '1'} : icon === 'emotions' ? {color: iconObj.checked3} : {color: iconObj.checked} : null} />
                    </div>
                    <div id='radio-4' className={state[id] === 4 ? `${styles.radioRating} ${styles.selected}` : styles.radioRating} onClick={handleRating}>
                        <i className={state[id] > 3 ? icon === 'emotions' ? iconObj.selected4 : iconObj.selected : iconObj.unselected} style={state[id] > 3 ? icon === 'flames' ? {'--fa-primary-color': iconObj.checked, '--fa-secondary-color': iconObj.checked2, '--fa-secondary-opacity': '1'} : icon === 'emotions' ? {color: iconObj.checked4} : {color: iconObj.checked} : null} />
                    </div>
                    <div id='radio-5' className={state[id] === 5 ? `${styles.radioRating} ${styles.selected}` : styles.radioRating} onClick={handleRating}>
                        <i className={state[id] > 4 ? icon === 'emotions' ? iconObj.selected5 : iconObj.selected : iconObj.unselected} style={state[id] > 4 ? icon === 'flames' ? {'--fa-primary-color': iconObj.checked, '--fa-secondary-color': iconObj.checked2, '--fa-secondary-opacity': '1'} : icon === 'emotions' ? {color: iconObj.checked5} : {color: iconObj.checked} : null} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const NumberRating = ({ state, setState, question }) => {
    const { id, name, required, scale } = question;

    const handleRating = (e) => {
        const selectedRating = parseInt(e.target.id.split('-')[1]);
        setState(prev => ({ ...prev, [id]: selectedRating}));
    };

    const numbers = () => {
        let numbs = [];
        let spread = scale ? scale : 5;
        for (let i = 1; i <= spread; i++) {
            numbs.push(i);
        };

        return (
            <>
            {numbs.filter(n => n !== 10).map((n,i) => (
                <div id={`radio-${n}`} key={i} className={state[id] === n ?  `${styles.radioRating} ${styles.selected}` : styles.radioRating} onClick={handleRating}>
                    <i className={state[id] > i ? `fas fa-${n}` : `far fa-${n}`} style={{pointerEvents: 'none'}} />
                </div>
            ))}
            {numbs.filter(n => n === 10).map((n,i) => (
                <div id={`radio-${n}`} key={n} className={state[id] === n ? ` ${styles.radioRating} ${styles.selected}` : styles.radioRating} onClick={handleRating}>
                    <i className={state[id] > 9 ? `fas fa-1` : `far fa-1`} style={{pointerEvents: 'none'}} />
                    <i className={state[id] > 9 ? `fas fa-0` : `far fa-0`} style={{pointerEvents: 'none'}} />
                </div>
            ))}
            </>
        );
    };

    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <label className={styles.label} >{required ? <>{name}<sup><i className='fa-regular fa-asterisk' /></sup></> : name}</label>
                <div className={styles.innerGroup}>
                    {numbers()}
                </div>
            </div>
        </div>
    );
}; 

const QuestionTypes = ({ question, state, setState, sigCanvas, setSigCanvas, edit }) => {
    switch (question?.type) {
        case 'text-box':
            return <TextBox state={state} setState={setState} question={question} />;
        case 'text-area':
            return <TextArea state={state} setState={setState} question={question} />;
        case 'number':
            return <Number state={state} setState={setState} question={question} />;
        case 'dropdown':
            return <Dropdown state={state} setState={setState} question={question} />;
        case 'signature':
            return <Signature question={question} sigCanvas={sigCanvas} setSigCanvas={setSigCanvas} setState={setState} edit={edit} />;
        case 'radio':
            return <Radio state={state} setState={setState} question={question} />;
        case 'checkbox':
            return <Checkbox state={state} setState={setState} question={question} />;
        case 'icon-rating':
            return <IconRating state={state} setState={setState} question={question} />;
        case 'number-rating':
            return <NumberRating state={state} setState={setState} question={question} />;
        default:
            return <></>;
    };
};

export default QuestionTypes;