import React from 'react';
import Nickname from '../../Nickname';
import Tags from '../../Tags';
import Categories from '../../Categories';
import styles from './Asset.module.css';

const Asset = (props) => {
    // ----- STATE INITIALIZATION -----
    const { qr_data, dataDispatch, categoryList, setCategoryList, plan, plan_id } = props;

    return (
        <>
        <h5 className={styles.header}>Liquid Label</h5>
        <Nickname qr_data={qr_data} dataDispatch={dataDispatch} />
        <div className={styles.inputGroup} >
            <div className={styles.fade} />
            <textarea id='asset-textarea-label' className={styles.textArea} value={qr_data?.asset?.description} onChange={e => dataDispatch({type: 'asset-description', data: e.target.value})} ></textarea>
            <label htmlFor='asset-textarea-label' className={qr_data?.asset?.description ? styles.labelFilled : styles.label}>Description</label>
        </div>
        <Categories categoryList={categoryList.filter(cat => cat.id !== 'Uncategorized')} setCategoryList={setCategoryList} qr_data={qr_data} dataDispatch={dataDispatch} plan_id={plan_id} plan={plan} />
        <Tags qr_data={qr_data} dataDispatch={dataDispatch} />
        </>
    );
};

export default Asset;