import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Loader } from '@googlemaps/js-api-loader';
import styles from './LocationOptions.module.css';

const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS,
    version: "weekly"
});

const LocationOptions = (props) => {
    const { qr_data, dataDispatch } = props;

    const [ autocomplete, setAutocomplete] = useState(null);
    const [ place, setPlace ] = useState(qr_data.location ? `${qr_data.location.lat}, ${qr_data.location.lng}` : '');
    const [ input, setInput ] = useState(null);

    useEffect(() => {
        const center = { lat: 50.064192, lng: -130.605469 };
        const defaultBounds = {
            north: center.lat + 0.1,
            south: center.lat - 0.1,
            east: center.lng + 0.1,
            west: center.lng - 0.1,
        };
        const options = {
            bounds: defaultBounds,
            componentRestrictions: { country: "us" },
            fields: ["geometry", "name"],
            strictBounds: false,
            types: ["establishment"],
        };

        if (input && !autocomplete) {
            loader.importLibrary('places')
                .then(places => {
                    setAutocomplete(new places.Autocomplete(input, options));
                }).catch(err => console.log(err));
        };
    },[input, autocomplete]);

    useEffect(() => {
        if (!input) {
            setInput(document.getElementById("location"));
        };
    },[input]);

    useEffect(() => {
        if (autocomplete) {
            autocomplete.addListener("place_changed", () => {
                const details = autocomplete.getPlace();
                if (details?.geometry?.location) {
                    setPlace(`${details.geometry.location.lat()}, ${details.geometry.location.lng()}`);
                    dataDispatch({ type: 'location', data: { lat: details.geometry.location.lat(), lng: details.geometry.location.lng()}});
                };
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[autocomplete]);

    const handleGeoLocation = (e) => {
        if (e.coords.latitude && e.coords.longitude) {
            setPlace(`${e.coords.latitude}, ${e.coords.longitude}`);
            dataDispatch({ type: 'location', data: { lat: e.coords.latitude, lng: e.coords.longitude} });
        };
    };

    return (
        <div className={styles.container}>
            <OverlayTrigger 
                placement='top'
                delay={{show: 250, hide: 400}}
                trigger={["click", "hover"]}
                overlay={<Tooltip><p>Location is captured using Latitude and Longitude coordinates, displayed as 'Latitude, Longitude'.</p></Tooltip>}
            >
                <h5 className='mb-2'>Coordinates <i className="fa-regular fa-circle-info"></i></h5>
            </OverlayTrigger>
            <div className={styles.inputGroup} style={{marginBottom: '1rem'}}>
                <input id='location' className={styles.input} onChange={(e) => setPlace(e.target.value)} value={place}></input>
                <label htmlFor='location' placeholder='' className={styles.label}>Search Location</label>
                <button onClick={() => navigator.geolocation.getCurrentPosition(handleGeoLocation, null, {enableHighAccuracy: true})} className={styles.crosshairs}><i className="fa-solid fa-location-crosshairs"></i></button>
            </div>
        </div>
    );
};

export default LocationOptions;