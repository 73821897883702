import React, { useState, useEffect } from 'react';
import { useAuth } from '../../utils/Auth';
import Menu from '../Menu';
import QRbg from '../Images/qr-background3.png';
import { useNavigate } from 'react-router-dom';
import LogoDark from '../Images/LiquidQR-dark.png';
import styles from './Navigation.module.css';

export default function Navigation() {
    // ----- STATE INITIALIZATION -----
    const { currentUser, logout, orgObj } = useAuth();

    const [ navState, setNavState ] = useState(null);
    const [ showMenu, setShowMenu ] = useState(true);

    const navigate = useNavigate();
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- LOGOUT FUNCTION -----
    const handleLogout = (e) => {
        e.preventDefault();
        logout().then(() => window.location.reload('/'));
    };
    // ----- END LOGOUT FUNCTION -----
    // ----------------------------------------------

    // ----- HELPER FUNCTIONS -----
    const handleShowUser = () => {
        const el = document.querySelector(`.${styles.usernameSm}`);
        if (el?.classList.contains(styles.show)) {
            el?.classList.remove(styles.show);
        } else {
            el?.classList.add(styles.show);
        };
    };

    window.addEventListener('click', (e) => {
        const el = document.querySelector(`.${styles.usernameSm}`);
        if (e.target.id !== 'user-icon') {
            el?.classList.remove(styles.show);
        } else {
            el?.classList.add(styles.show);
        };
    });

    useEffect(() => {
        if (currentUser && !navState) {
            setNavState(
                <>
                    <div className={styles.userContainer}>
                        <div className={styles.username}>{currentUser.email}</div>
                        <div className={styles.icon} id='user-icon' onClick={handleShowUser}>
                            <i className='fas fa-user' />
                            <div className={styles.usernameSm}>{currentUser.email}</div>
                        </div>
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.iconContainer} style={{marginRight: '.5rem'}} onClick={() => window.open(`https://liquidqr.com/store`, '_blank')}>
                        <i className='fa-solid fa-store' />
                    </div>
                    <div className={styles.iconContainer} style={{marginRight: '.5rem'}} onClick={() => navigate('/settings')}>
                        <i className='fas fa-gear' />
                    </div>
                    <div className={styles.iconContainer} onClick={handleLogout}>
                        <i className='fas fa-arrow-right-from-bracket' />
                    </div>
                </>
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[navState, currentUser]);

    const toggleNav = () => {
        let navBar = document.getElementById('nav-toggle');
        let bar1 = document.getElementById('bar-1');
        let bar2 = document.getElementById('bar-2');
        let bar3 = document.getElementById('bar-3');
        let menu = document.getElementById('menu-container');
        let elementsArr = [navBar,bar1,bar2,bar3,menu];

        setShowMenu(prev => !prev);
        if(showMenu) {
            elementsArr.forEach(el => {
                el.classList.add(styles.open);
            });
        } else {
            elementsArr.forEach(el => {
                el.classList.remove(styles.open);
            });
        };
    };
    // ----- END HELPER FUNCTIONS -----
    // ----------------------------------------------

    // ----- RETURN -----
    return (
        <div className={styles.container}>
            <div className={styles.background} style={{backgroundImage: `url(${QRbg})`}} />
            <div className={styles.inner}>
                <div id='nav-toggle' className={styles.toggle} onClick={() => toggleNav()}>
                    <div id='bar-1' className={styles.bar1} />
                    <div id='bar-2' className={styles.bar2} />
                    <div id='bar-3' className={styles.bar3} />
                </div>
                <div className={styles.logo} onClick={() => navigate('/')}>
                    <img src={LogoDark} width='100%' height='auto' alt='Dark Logo' />
                </div>
                <div 
                    id='menu-container'
                    className={styles.menuContainer} 
                    style={{
                        backgroundImage: `url(${QRbg})`, 
                        backgroundRepeat: 'no-repeat', 
                        backgroundPosition: 'bottom', 
                        backgroundSize: '450px 250px',
                    }}
                >
                    <Menu handleLogout={handleLogout} currentUser={currentUser} plan={orgObj?.plan} toggleNav={toggleNav} />
                </div>
                {navState}
            </div>
        </div>
    );
};