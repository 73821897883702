import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/Auth';
import LoadingSpinner from '../../components/LoadingSpinner';

export default function Sonar() {
    // ----- STATE INITIALIZATION -----
    const { currentUser, userObj } = useAuth();
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();

    const qrID = searchParams.get('qrid');
    const qrURL = searchParams.get('qrurl');
    const qrOrg = searchParams.get('qrorg');
    const qrType = searchParams.get('qrtype');
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------
    
    useEffect(() => {
        if (qrID) {
            if (currentUser) {
                // If it hits here there is a logged in user
                if(userObj) {

                    if (userObj.org_id === qrOrg) {
                    // If it hits here the logged in user owns the QR
    
                        navigate(`../collection?qrid=${qrID}`, { replace: true})
    
                    } else {
                        if (qrType === 'menu' || qrType === 'pdf') {
                            navigate(`/${qrID}`);
                        } else {
                            window.location.replace(qrURL);
                        };
                    };
                }

            } else {
                if (qrType && (qrType === 'menu' || qrType === 'pdf')) {
                    navigate(`/${qrID}`);
                } else {
                    // If it hits here, there is no logged in user
                    // this will need to be reconsidered once we have more modes than redirect
                    window.location.replace(qrURL);
                };
            };
        };
                // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentUser, userObj, qrID, qrType, qrURL, qrOrg]);
    
    return (
    <>
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
            <LoadingSpinner />  
        </div>
    </>
    );
}
