import React, { useState, useRef, useEffect, memo } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import API from '../../../../API/index';
import styles from './QRDragNDrop.module.css';

const Category = ({ categories, setCategoryOrder, category }) => {
    const { updateCategories } = API;

    const { listeners, transform, transition, isDragging, setNodeRef, setActivatorNodeRef } = useSortable({
        id: category.id
    });

    const style = transform ? {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : undefined,
    } : undefined;

    const inputRef = useRef(null);
    const [show, setShow] = useState('');
    const [categoryName, setCategoryName] = useState(category.name);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleEdit = async () => {
        setLoading(true);
        const update = {};
        let arr;
        if (show === 'delete') {
            arr = categories.filter(cat => cat.id !== category.id);
        } else {
            arr = categories.map(cat => {
                if (cat.id === category.id) {
                    cat.name = categoryName;
                };
                return cat;
            });
        };
        
        setCategoryOrder(arr);
        update.categories = arr.filter(cat => cat.id !== 'Uncategorized');

        try {
            await updateCategories(update);
            setLoading(false);
            setShow('');
        } catch (err) {
            console.error(err);
            setError(show === 'delete' ? 'Error deleting category' : 'Error updating category');
            setLoading(false);
        };
    };

    useEffect(() => {
        const handleMouseDown = (e) => {
            if (inputRef.current && !inputRef.current.contains(e.target) && (!e.target?.id?.includes('icon') || !e.target?.id?.includes(category.id))) {
                setCategoryName(category.name);
                setShow('');
            };
        };

        document.addEventListener('mousedown', handleMouseDown);
        return () => document.removeEventListener('mousedown', handleMouseDown);
    }, [inputRef]);

    return (
        <div className={styles.catContainer} style={style} ref={setNodeRef} >
            <i ref={setActivatorNodeRef} {...listeners} className={`fas fa-grip ${styles.icon}`} />
            <input type='text' id={`input_${category.id}`} ref={inputRef} className={category?.id === 'Uncategorized' ? `${styles.input} ${styles.disabled}` : styles.input} value={show === 'delete' ? `Delete ${categoryName}?` : categoryName} onChange={e => setCategoryName(e.target.value)} onClick={() => category.id !== 'Uncategorized' && setShow('edit')}></input>
            {show === 'delete' ?
                <div className={styles.iconContainer}>
                    <i className={`fas fa-xmark ${styles.icon}`} id={`cancel_icon_${category.id}`} onClick={() => setShow('')} />
                    <i className={loading ? `fa-duotone fa-spinner fa-spin ${styles.icon}` : `fas fa-check ${styles.icon}`} id={`delete_icon_${category.id}`} onClick={handleEdit} disabled={loading} />
                </div>
                : show === 'edit' ?
                    <i className={loading ? `fa-duotone fa-spinner fa-spin ${styles.icon}` : `fas fa-save ${styles.icon}`} id={`save_icon_${category.id}`} onClick={handleEdit} disabled={loading} />
                    :
                    <i className={category?.id === 'Uncategorized' ? `fas fa-xmark ${styles.icon} ${styles.disabled} ${styles.delUncategorized}` : `fas fa-xmark ${styles.icon}`} id={`show_del_icon_${category.id}`} onClick={() => setShow('delete')} disabled={category.id === 'Uncategorized'} />
            }
            {error ? <p className={styles.error}>{error}</p> : <></>}
        </div>
    );
};

const CategoryList = memo(({ categories, setCategoryOrder }) => {
    return categories.map((category, index) => (
        <Category categories={categories} setCategoryOrder={setCategoryOrder} category={category} index={index} key={category.id} />
    ));
});

export default function QRDragNDrop(props) {

    const { categoryOrder, setCategoryOrder } = props;

    const [ categories, setCategories ] = useState(categoryOrder?.length ? categoryOrder.map(cat => cat.id) : []);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setCategories((items) => {
                const activeIndex = items.indexOf(active.id);
                const overIndex = items.indexOf(over.id);
                
                setCategoryOrder(reorder(categoryOrder, activeIndex, overIndex));
                return arrayMove(items, activeIndex, overIndex);
            });
        };
    };

    return (
        <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd} >
            <SortableContext items={categories} strategy={verticalListSortingStrategy} >
                <CategoryList categories={categoryOrder} setCategoryOrder={setCategoryOrder} />
            </SortableContext>
        </DndContext>
    );
};