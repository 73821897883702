import React from 'react';
import { useAuth } from '../../utils/Auth';
import { Navigate } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import styles from './FAQ.module.css';

export default function FAQ() {
    const { userObj, currentUser } = useAuth();

    if (userObj && currentUser) {
        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.header}>FAQ</div>
                    <Accordion className={styles.accordion} defaultActiveKey='0'>
                        <Accordion.Item className={styles.accordionItem} eventKey='0'>
                            <Accordion.Header>Question 1</Accordion.Header>
                            <Accordion.Body>Answer 1</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className={styles.accordionItem} eventKey='1'>
                            <Accordion.Header>Question 2</Accordion.Header>
                            <Accordion.Body>Answer 2</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className={styles.accordionItem} eventKey='2'>
                            <Accordion.Header>Question 3</Accordion.Header>
                            <Accordion.Body>Answer 3</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        );
    } else if (currentUser && !userObj) {
        return (<></>);
    } else {
        return (<Navigate to='/login' />);
    };
};