const getWeekLabels = () => {
  const today = Date.now()
  const todayDate = new Date(today)
  const todayDay = todayDate.getDay()
  const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  const weekDaysShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]


  let dayNumPattern = [todayDay]

  for (let i = 1; i < 7; i++) {
    todayDay - i >= 0 ? dayNumPattern.unshift(todayDay - i) : dayNumPattern.unshift(todayDay - i + 7)
  }

  let chartLabels = []

  dayNumPattern.forEach(day => {
    if(window.innerWidth <= 768){
      chartLabels.push(weekDaysShort[day])
    }else{
      chartLabels.push(weekDays[day])
    }
  })

  return chartLabels
}

const chartLabels = getWeekLabels()

export default chartLabels