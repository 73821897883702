export function handleTempObj(qrObj) {
    const temp = {};
    for (const key in qrObj) {
        temp[key] = qrObj[key];
    };
    return temp;
};

export function handleQRReset(qrObj, tempObj) {
    for (const key in tempObj) {
        if (key === 'qrOptions') {
            if (tempObj.image) {
                qrObj[key].errorCorrectionLevel = "H";
            } else {
                qrObj[key].errorCorrectionLevel = "M";
            };
        };
        qrObj[key] = tempObj[key];
    };
    return qrObj;
};