import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import API from '../../../../API';
import { useAuth } from '../../../../utils/Auth';
import styles from './SonarSwitch.module.css';

export default function SonarSwitch(props) {
    // ----- STATE INITIALIZATION -----
    const { QR } = props;
    const { updateSonar } = API;
    const { userObj } = useAuth();

    const [ checked ] = useState((QR?.data?.sonar && (QR?.data?.sonar - Date.now()) > 0) ? true : false);
    const [ disabled, setDisabled ] = useState(false);
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------


    // ----- HELPER FUNCTIONS -----
    // Handles click to start animation, Calls update function
    const handleToggleSonarMode = (e) => {
        const circles = document.querySelectorAll(`.circle.qr-${QR.id}`);
        if(e.target.checked === true) {
            // setChecked(true);
            circles.forEach(circle => {
                circle.style.display = 'block';
            });
        } else {
            // setChecked(false);
            circles.forEach(circle => {
                circle.style.display = 'none';
            });
        };
        handleUpdateQR();
    };

    // Handles Sonar Enabled animation
    useEffect(() => {
        const checker = document.getElementById(`sonar-check-${QR.id}`);
        const circles = document.querySelectorAll(`.circle.qr-${QR.id}`);
        if(checker && checker.checked === true) {
            circles.forEach(circle => {
                circle.style.display = 'block';
            });
        } else {
            circles.forEach(circle => {
                circle.style.display = 'none';
            });
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[handleToggleSonarMode]);

    // ----- HANDLE UPDATE -----
    // Updates Sonar time update
    const handleUpdateQR = () => {
        setDisabled(true);
        const date = Date.now();
        const time = userObj?.sonar_length ? userObj.sonar_length : 900000;
        let timeDif;
        if (QR?.data?.sonar && (QR?.data?.sonar - date) > 0) {
            timeDif = date;
        } else {
            timeDif = date + time;
        }

        const data = {
            time: timeDif
        };

        updateSonar(QR.id, data)
            .then(() => {
                setDisabled(false);
            })
            .catch(err => console.log(err));
    };
    // ----- HANDLE UPDATE -----
    // -------------------------

    return (
        <div id='sonar-container' className={styles.container}>
            <OverlayTrigger placement='top'
                delay={{show: 250, hide: 400}}
                trigger={["click", "hover"]}
                overlay={<Tooltip><p>Enable Sonar and scan to edit this QR. Scanning device must be signed in to edit.</p></Tooltip>}
            >
            <div className={styles.wordsWrap}>
                <p className={styles.words}>Sonar Mode</p>
                <i className='fa-regular fa-circle-info'></i>
            </div>
            </OverlayTrigger>
            <div className={styles.btnWrap}>
                <input type='checkbox' className={styles.checkbox} id={`sonar-check-${QR.id}`} onChange={handleToggleSonarMode} defaultChecked={checked} disabled={disabled} />
                <label htmlFor={`sonar-check-${QR.id}`} style={{cursor: 'pointer'}}>
                    <i className='fa-light fa-radar' />
                </label>
                <div className={`circle qr-${QR.id}`} />
                <div className={`circle qr-${QR.id}`} />
                <div className={`circle qr-${QR.id}`} />
                <div className={`circle qr-${QR.id}`} />
            </div>
        </div>
    );
};