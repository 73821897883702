import React, { useState, useEffect } from 'react';
import styles from './AddAsset.module.css';

export default function AddAsset(props) {
    const { type, id, qr_data, setQrFiles, dataDispatch, index, setIndex, link, setLink } = props;

    const [ show, setShow ] = useState(false);
    const [ name, setName ] = useState('');
    const [ menuType, setMenuType ] = useState('');
    const [ nameArr, setNameArr ] = useState([]);
    const [ file, setFile ] = useState();
    const [ url, setUrl ] = useState('');
    const [ acceptableFileTypes, setAcceptableFileTypes ] = useState([]);

    useEffect(() => {
        switch(type) {
            case 'link':
                setNameArr(qr_data?.alternate_redirect_url?.length > 0 ? qr_data?.alternate_redirect_url : []);
                break;
            case 'pdf':
                setNameArr(qr_data?.pdf?.length > 0 ? qr_data?.pdf?.map(x => x.name) : []);
                setAcceptableFileTypes(['application/pdf']);
                break;
            case 'menu':
                setNameArr(qr_data?.menu?.length > 0 ? qr_data?.menu?.map(x => x.name) : []);
                setAcceptableFileTypes(['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']);
                break;
            default:
                break;
        };
    },[qr_data]);

    const handleFormReset = () => {
        setShow(false);
        setName('');
        setMenuType('');
        setFile('');
        setUrl('');
    };

    const handleMenuUpload = () => {
        if (qr_data?.menu?.length < 1) {
            dataDispatch({type: 'redirect', data: url ? url : `${id}/${name}.${file.type.split('/')[1]}`});
        };

        if (file && acceptableFileTypes.includes(file?.type) && file?.size <= 5000000) {
            setQrFiles(prev => prev.concat({data: file, name: name}));
            dataDispatch({type: 'menu', data: {name: name, redirect: `${id}/${name}.${file.type.split('/')[1]}`}});
        } else {
            dataDispatch({type: 'menu', data: {name: name, redirect: url}});
        };

        setLink(name);
        setNameArr([...nameArr, name]);
        handleFormReset();
    };

    const handlePdfUpload = () => {
        if (qr_data?.pdf?.length < 1) {
            dataDispatch({type: 'redirect', data: `${id}/${name}.pdf`});
        };

        if (file && file?.type === 'application/pdf' && file?.size <= 5000000) {
            setQrFiles(prev => prev.concat({data: file, name: name}));
            dataDispatch({type: 'pdf', data: {name: name, redirect: `${id}/${name}.pdf`}});
            setNameArr(prev => prev.concat(name));
            setLink(name);
            handleFormReset();
        };
    };

    const handleSetLink = (value) => {
        if (value === 'new') {
            const label = document.querySelector(`.${styles.redirectLabel}`);
            const redirect = document.querySelector('#redirect-wrap');

            label.style.display = 'flex';
            redirect.style.display = 'flex';
        } else {
            const label = document.querySelector(`.${styles.redirectLabel}`);
            const redirect = document.querySelector('#redirect-wrap');
            
            label.style.display = label.style.display === 'flex' && 'none';
            redirect.style.display = redirect.style.display === 'flex' && 'none';
            setLink(value);
        };
    };

    switch (type) {
        case 'link':
            return (
                <div className={styles.linkContainer}>
                    <div className={styles.inputGroupSelect} style={{marginBottom: '.5rem'}}>
                        <select className={styles.select} value={link} onChange={e => handleSetLink(e.target.value)}>
                            <option value='' disabled>Select</option>
                            <option value='new'>Add New</option>
                            {qr_data?.alternate_redirect_url?.map((url,i) => (
                                <option key={i} value={url}>{url}</option>
                            ))}
                        </select>
                    </div>
                    <label htmlFor='add-redirect' className={styles.redirectLabel}>Add Redirect</label>
                    <div className={styles.inputGroupRedirect} id='redirect-wrap'>
                        <input id='add-redirect' className={styles.redirectInput} type='url' value={url} onChange={e => setUrl(e.target.value)} placeholder='URL'></input>
                        <button
                            className={styles.addBtn}
                            onClick={() => {
                                qr_data?.alternate_redirect_url?.length < 1 && dataDispatch({type: 'redirect', data: url });
                                dataDispatch({type: 'alt-redirect', data: url, index: index });
                                setIndex(qr_data?.alternate_redirect_url?.length);
                                handleSetLink(url);
                                setUrl('');
                            }}
                            disabled={!url}
                        >
                            <i className='fa-regular fa-plus' style={{pointerEvents: 'none'}} /> Add
                        </button>
                    </div>
                </div>
            );
        case 'pdf':
            if (show) {
                return (
                    <div className={styles.pdfContainer} style={{paddingBottom: '0'}}>
                        <div className={styles.inputGroup}>
                            <input type='text' maxLength={15} id='pdf-label' className={styles.pdfInput} onChange={e => setName(e.target.value.replaceAll(' ', '_'))} value={name} />
                            <label htmlFor='pdf-label' className={styles.pdfLabel}>PDF Name</label>
                        </div>
                        {nameArr.includes(name) ? <p>PDf name cannot match an existing menu name.</p> : <></>}
                        <div className={styles.inputGroup} style={{marginTop: '.5rem', justifyContent: 'center'}}>
                            <input type='file' id='pdf-file-label' className={styles.fileInput} accept='.pdf' onChange={(e) => setFile(e.target.files[0])} ></input>
                            <label htmlFor='pdf-file-label' className={styles.fileLabel}>Add File <i className='far fa-plus ms-2' /></label>
                        </div>
                        {(file && file?.type === 'application/pdf' && file?.size <= 5000000) ? 
                            <p className='mb-0'>File Selected: <b>{file?.name}</b></p>
                        : file?.type !== 'application/pdf' || file?.size > 5000000 ?
                            <p>{file?.type !== 'application/pdf' ? 'File must be type PDF.' : 'File size exceeds 5MB. File must be under 5MB.'}</p>
                        :
                            <p className='mb-0'>Please select a PDF file for upload.</p>
                        }
                        <div className={styles.inputGroup} style={{justifyContent: 'center'}}>
                            <button className={styles.addPdfBtn} style={{marginRight: '.25rem'}} onClick={handleFormReset}>Cancel</button>
                            <button className={styles.addPdfBtn} style={{marginLeft: '.25rem'}} onClick={handlePdfUpload} disabled={(!name && !file) || nameArr.includes(name) || (!acceptableFileTypes?.includes(file?.type) || file?.size > 5000000)} >Add PDF</button>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={styles.pdfContainer}>
                        <div className={styles.inputGroupSelect} style={{marginBottom: '.5rem'}}>
                            <select className={styles.select} value={link} onChange={e => setLink(e.target.value)}>
                                <option value='' disabled>-- Select --</option>
                                {nameArr?.length > 0 ?
                                    nameArr?.map((name,i) => (
                                        <option key={i} value={name}>{name}</option>
                                    ))
                                    :
                                    <></>
                                }
                            </select>
                        </div>
                        <div className={styles.addPdfWrap}>
                            <button className={styles?.addPdfBtn} onClick={() => setShow(true)} disabled={nameArr?.length >= 5} >Add PDF</button>
                        </div>
                    </div>
                );
            };
        case 'menu':
            if (show) {
                return (
                    <div className={styles.menuContainer} style={{paddingBottom: '0'}}>
                        <div className={styles.inputGroup} style={{marginBottom: '.5rem'}}>
                            <input type='text' maxLength={15} id='menu-label' className={styles.input} onChange={e => setName(e.target.value.replaceAll(' ', '_'))} value={name} />
                            <label htmlFor='menu-label' className={styles.label} style={{marginRight:'0.5rem'}}>Menu Name</label>
                        </div>
                        {nameArr.includes(name) ? <p>Menu name cannot match an existing menu name.</p> : <></>}
                        {menuType === 'file' ? 
                            <>
                            <div className={styles.inputGroup} style={{marginTop: '.5rem', justifyContent: 'center'}}>
                                <input type='file' id='menu-file' className={styles.fileInput} accept='.pdf,.jpg,.jpeg,.png' onChange={(e) => setFile(e.target.files[0])} ></input>
                                <label htmlFor='menu-file' className={styles.fileLabel} >Add File <i className='far fa-plus ms-2' /></label>
                            </div>
                            {file ? 
                                ((!acceptableFileTypes?.includes(file?.type) || file?.size > 5000000)
                                    ?
                                        <p>{!acceptableFileTypes?.includes(file?.type) ? 'File must be of type JPG, PNG, or PDF.' : 'File size exceeds 5MB. File must be under 5MB.' }</p>
                                    :
                                        <p className='mb-0'>File Selected: <b>{file?.name}</b></p>
                                )
                            :
                                <p className='mb-0'>Please select a PDF, PNG, or JPEG type file for upload.</p>
                            }
                            <div className={styles.inputGroup} style={{justifyContent: 'center'}}>
                                <button className={styles.addMenuBtn} style={{marginRight: '.25rem'}} onClick={handleFormReset}>Cancel</button>
                                <button className={styles.addMenuBtn} style={{marginLeft: '.25rem'}} onClick={handleMenuUpload} disabled={!name || (menuType === 'link' && !url) || (menuType === 'file' && (!file || !acceptableFileTypes?.includes(file?.type) || file?.size > 5000000))} >Add Menu</button>
                            </div>
                            </>
                        : menuType === 'link' ?
                            <>
                            <div className={styles.inputGroup} style={{marginTop: '.75rem'}}>
                                <input type='url' id='redirect_url-label' className={styles.input} onChange={e => setUrl(e.target.value.includes('http') ? e.target.value : `https://${e.target.value}`)} value={url} />
                                <label htmlFor='redirect_url-label' className={styles.label} style={{marginRight:'0.5rem'}}>Default Menu Link</label>
                            </div>
                            <div className={styles.menuBtnContainer} style={{marginTop: '.5rem'}}>
                                <button className={styles.addMenuBtn} style={{marginRight: '.25rem'}} onClick={handleFormReset}>Cancel</button>
                                <button className={styles.addMenuBtn} style={{marginLeft: '.25rem'}} onClick={handleMenuUpload} disabled={(!name || !url) && (!name || !file)} >Add Menu</button>
                            </div>
                            </>
                        :
                            <div className={styles.menuBtnWrap}>
                                <h5>Type</h5>
                                <div className={styles.menuBtnContainer}>
                                    <button className={styles.menuBtn} onClick={() => setMenuType('file')}>File</button>
                                    <p style={{marginBottom: '0'}}>OR</p>
                                    <button className={styles.menuBtn} onClick={() => setMenuType('link')}>Link (URL)</button>
                                </div>
                            </div>
                        }
                    </div>
                );
            } else {
                return (
                    <div className={styles.menuContainer}>
                        <div className={styles.inputGroupSelect} style={{marginBottom: '.5rem'}}>
                            <select className={styles.select} value={link} onChange={e => setLink(e.target.value)}>
                                <option value='' disabled>Select</option>
                                {nameArr?.length > 0 ?
                                    nameArr?.map((name,i) => (
                                        <option key={i} value={name}>{name}</option>
                                    ))
                                    :
                                    <></>
                                }
                            </select>
                        </div>
                        <div className={styles.addMenuWrap}>
                            <button className={styles.addMenuBtn} onClick={() => setShow(true)} >Add Menu</button>
                        </div>
                    </div>
                );
            };
        default:
            return <></>;
    };
};