import React, { useState, useEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { useParams, useSearchParams } from 'react-router-dom';
import { useShowNav } from '../../utils/NavigationContext';
import LoadingSpinner from '../../components/LoadingSpinner';
import API from '../../API';

import styles from './FileDisplay.module.css';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

export default function FileDisplay() {
    const { id } = useParams();
    const { setShowNavigation } = useShowNav();

    const [searchParams] = useSearchParams();

    const [mimetype, setMimetype] = useState('');
    const [pages, setPages] = useState(null);
    const [fileToServe, setFileToServe] = useState(null);
    const [qrObj, setQrObj] = useState(null);
    const [index] = useState(searchParams.get('i') ? Number(searchParams.get('i')) : 0);

    useEffect(() => {
        const { getQR } = API;
        setShowNavigation(false);

        const handleGetQR = async () => {
            try {
                const qr = await getQR(id);
                if (qr) setQrObj(qr);
            } catch (err) {
                console.error(err);
            };
        };

        handleGetQR();

        return () => {
            setFileToServe(null);
            setQrObj(null);
            setPages(null);
            setShowNavigation(true)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (qrObj?.qr_type === 'menu') {
            if (qrObj?.menu[index] && qrObj?.menu[index]?.redirect) {
                setFileToServe(qrObj.menu[index].redirect);
                const fileExt = qrObj.menu[index].redirect?.split('.')[1];
                setMimetype(fileExt?.split('.')[1] === 'pdf' ? 'application/pdf' : `image/${fileExt}`);
            } else {
                setFileToServe(qrObj.menu[0].redirect);
                const fileExt = qrObj.menu[0].redirect?.split('.')[1];
                setMimetype(fileExt?.split('.')[1] === 'pdf' ? 'application/pdf' : `image/${fileExt}`);
            };
        } else if (qrObj?.qr_type === 'pdf') {
            if (qrObj?.pdf[index] && qrObj?.pdf[index]?.redirect) {
                setFileToServe(qrObj.pdf[index].redirect);
                setMimetype('application/pdf');
            } else {
                setFileToServe(qrObj.pdf[0].redirect);
                setMimetype('application/pdf');
            };
        };
    }, [qrObj, index]);

    useEffect(() => {
        if (fileToServe) {
            if (fileToServe.split('.')[1] === 'pdf') {
                setMimetype('application/pdf');
            } else if (fileToServe.split('.')[1] === 'png') {
                setMimetype('image/png');
            } else {
                setMimetype('image/jpeg');
            };
        };
    }, [fileToServe]);

    const onLoad = ({ numPages }) => {
        setPages(numPages);
    };

    if (fileToServe && mimetype) {
        if (mimetype.includes('pdf')) {
            return (
                <div className={styles.display}>
                    <Document file={`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/qr_files/${fileToServe}?${Date.now()}`} onLoadSuccess={onLoad} loading={<LoadingSpinner />} >
                        {Array.from(new Array(pages), (el, index) => (<Page key={`page_${index + 1}`} pageNumber={index + 1} />))}
                    </Document>
                </div>
            );
        } else {
            return (
                <div className='d-flex justify-content-center mt-4'>
                    <img alt={fileToServe.split(`${id}/`)[1]} title={fileToServe.split(`${id}/`)[1]} src={`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/qr_files/${fileToServe}?${Date.now()}`}></img>
                </div>
            );
        };
    } else {
        return <div className={styles.display}><LoadingSpinner /></div>;
    };
};