import React from 'react';
import ColumnDisplay from '../ColumnDisplay';
import styles from './TrackerOptions.module.css';

const TrackerOptions = (props) => {
    const { qr_data, dataDispatch } = props;

    return (
        <div className={styles.container}>
            <h2>Tracker Columns</h2>
            <div className={styles.columnList}>
                <ColumnDisplay columns={qr_data?.tracker?.columns?.sort((a,b) => a.archived - b.archived)} qr_data={qr_data} dataDispatch={dataDispatch}  />
            </div>
        </div>
    );
};

export default TrackerOptions;