import React, { useState } from 'react';
import styles from './Tags.module.css';

export default function Tags(props) {
    // ----- STATE INITIALIZATION -----
    const { qr_data, dataDispatch } = props;

    const [ tag, setTag ] = useState('');

    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- TAGS -----
    const handleAddTag = () => {
        if (tag.length > 0 && !qr_data.tags.includes(tag) && tag !== '') {
            dataDispatch({type:'add-tag', data: tag});
            setTag('');
        } else {
            setTag('');
        };
    };

    const handleRemoveTag = (tag) => {
        dataDispatch({type: 'delete-tag', data: qr_data.tags.indexOf(tag)});
    };
    // ----- END TAGS -----
    // ----------------------------------------------

    return (
        <>
        {/* <h5>Tags</h5> */}
        <div className={styles.inputGroup}>
            <input type='text' id='tag-name' className={styles.input} value={tag} onChange={e => setTag(e.target.value)} />
            <label htmlFor='tag-name' className={styles.label}>Enter Tags</label>
            <button className={styles.btn} onMouseDown={e => e.preventDefault()} onClick={handleAddTag}><i className='fas fa-plus'></i></button>
            <div className={styles.tags}>
                {qr_data.tags.length > 0 &&
                    qr_data.tags.map((tag, index) => <div key={index} className={styles.tag}>{tag} <i className='fa-solid fa-xmark ms-2' onClick={() => handleRemoveTag(tag)}></i></div>)
                }
            </div>
        </div>
        </>
    );
};