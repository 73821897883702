import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../utils/Auth';
import API from '../../API';
import Table from './Table';
import AddResponse from './AddResponse';
import { useNavigate } from 'react-router-dom';
import { handleTextWithLinks } from '../../utils/QRFunctions/handleTextWithLinks';
import LoadingSpinner from '../../components/LoadingSpinner';
import styles from './SurveyPage.module.css';

const Display = (props) => {
    const { id, survey, responses, editResponse, setEditResponse, selectedResponse, setSelectedResponse, authenticatedUser, deletedToggle, setDeletedToggle } = props;

    const [ questions ] = useState(survey?.questions?.filter(q => !q?.archived) ?? []);

    if (!survey) return (<div>Survey not found</div>);

    if (authenticatedUser) {
        return (
            <Table
                id={id}
                questions={questions}
                responses={responses}
                editResponse={editResponse}
                setEditResponse={setEditResponse}
                selectedResponse={selectedResponse}
                setSelectedResponse={setSelectedResponse}
                authenticatedUser={authenticatedUser}
                deletedToggle={deletedToggle}
                setDeletedToggle={setDeletedToggle}
            />
        );
    } else {
        return(
            <AddResponse
                id={id}
                survey={survey}
                questions={questions}
                authenticatedUser={authenticatedUser}
            />
        );
    };
};

export default function SurveyPage() {
    const { getQRListener, updateSurveyResponseToDeleted } = API;
    const { userObj } = useAuth();
    const navigate = useNavigate();
    
    // ----- STATE INITIALIZATION -----
    const [ qrObj, setQrObj ] = useState(null);
    const [ editResponse, setEditResponse ] = useState(false);
    const [ deleteResponse, setDeleteResponse ] = useState(false);
    const [ selectedResponse, setSelectedResponse ] = useState(null);
    const [ deletedToggle, setDeletedToggle ] = useState(false);

    const { id } = useParams();
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    useEffect(() => {
        let listener;

        if (id) {
            listener = getQRListener(id, setQrObj);
        };

        return () => {
            listener();
            setQrObj(null);
        };
    }, []);

    const handleDeleteResponse = async () => {
        try {
            const res = await updateSurveyResponseToDeleted(id, selectedResponse.date);
            if (res === true) {
                setSelectedResponse(null);
                setEditResponse(false);
                setDeleteResponse(false);
            };
        } catch (err) {
            console.log(err);
        };
    };

    const handleNavButtons = () => {
        if (!selectedResponse) {
            return (
                <div className={styles.navBtnCont}>
                    <button className={styles.backBtn} onClick={() => navigate(`/collection`, { state: { qrID: id } }) } >Collection</button>
                </div>
            );
        };

        if (deletedToggle) {
            return (
                <div className={styles.navBtnCont}>
                    <button className={styles.backBtn} onClick={() => setSelectedResponse(null)} >Back</button>
                </div>
            );
        } else if (editResponse) {
            return (
                <div className={styles.navBtnCont}>
                    <button className={styles.backBtn} onClick={() => setEditResponse(false)} >Back</button>
                </div>
            );
        } else if (deleteResponse) {
            return (
                <>
                    <div className={styles.navBtnCont}>
                        <button className={styles.cancelDeleteBtn} onClick={() => setDeleteResponse(false)} >Cancel</button>
                        <button className={styles.deleteBtn} onClick={handleDeleteResponse} >Confirm</button>
                    </div>
                    <p className={styles.deleteText}>Deleting responses is permanent. Delete response?</p>
                </>
            );
        } else {
            return (
                <div className={styles.navBtnCont}>
                    <button className={styles.backBtn} onClick={() => setSelectedResponse(null)}>Back</button>
                    <button className={styles.deleteBtn} onClick={() => setDeleteResponse(true)} >Delete</button>
                </div>
            );
        };
    };

    if (!qrObj || !qrObj?.survey) {
        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <LoadingSpinner />
                </div>
            </div>
        );
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.display} >
                    <div className={styles.header}>
                        {(userObj?.org_id === qrObj?.org_id) ?
                            handleNavButtons()
                        : selectedResponse ?
                            <div className={styles.navBtnCont}>
                                <button className={styles.backBtn} onClick={() => setSelectedResponse(null)} >Back</button>
                            </div>
                        :
                            <></>
                        }
                        <h2 className='mt-3'>{qrObj?.nickname}</h2>
                        {(userObj?.org_id === qrObj?.org_id) ? ((selectedResponse) ? <button className={styles.editBtn} onClick={() => setEditResponse(true)} disabled={selectedResponse?.deleted} >Edit</button> : <button className={styles.editBtn} onClick={() => qrObj?.type === 'tracker' ? navigate(`/collection?qrid=${id}&edit=true&ref=t`) : navigate(`/collection?qrid=${id}&edit=true&ref=s`)}>Edit</button>) : <></>}
                        <div className={styles.description}>
                            <div className={styles.logSectionText} style={{marginTop: '.5rem', lineHeight: '1.25rem', textAlign: 'center'}}>{qrObj?.survey?.description ? <p>{handleTextWithLinks(qrObj?.survey?.description)}</p> : <></>}</div>
                        </div>
                    </div>
                    <Display id={id} survey={qrObj?.survey} responses={qrObj?.survey_responses ?? []} editResponse={editResponse} setEditResponse={setEditResponse} selectedResponse={selectedResponse} setSelectedResponse={setSelectedResponse} authenticatedUser={userObj?.org_id === qrObj?.org_id} deletedToggle={deletedToggle} setDeletedToggle={setDeletedToggle} />
                </div>
            </div>
        </div>
    );
};