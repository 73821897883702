import React from 'react';
import styles from './ScheduleDisplay.module.css';

export default function ScheduleDisplay(props) {

    const { schedule, colorIndex, handleEditSchedule } = props;
    const colorArr = ['#9e1303','#ed6801','#00800a','#004c71','#582aa7','#627ea8','#3a3a3a','#3d0e61'];

    const handleTop = (day) => {
        switch (day) {
            case '0':
                return 'calc((100% / 7) * 6)';
            case '1':
                return 'calc((100% / 7) * 0)';
            case '2':
                return 'calc((100% / 7) * 1)';
            case '3':
                return 'calc((100% / 7) * 2)';
            case '4':
                return 'calc((100% / 7) * 3)';
            case '5':
                return 'calc((100% / 7) * 4)';
            case '6':
                return 'calc((100% / 7) * 5)';
            default:
                return '0rem'
        }
    };
 
    const handleLeft = () => {
        const time = schedule.start.split(':');
        const hours = time[0];
        const minutes = time[1];
        return `calc((100% / 24) * (${hours} + (${minutes} / 60))`
    };

    const handleWidth = () => {
        const endTime = schedule.end.split(':');
        const endHours = endTime[0] === '00' && endTime[1] === '00' ? 23 : endTime[0] / 1;
        const endMinutes = endTime[0] === '00' && endTime[1] === '00' ? 59 : endTime[1] / 60;
        const startTime = schedule.start.split(':');
        const startHours = startTime[0] / 1;
        const startMinutes = startTime[1] / 60;
        const total = (endHours + endMinutes) - (startHours + startMinutes);
        return `calc((100% / 24) * ${total})`;
    };

    const handleBackground = () => {
        return colorArr[colorIndex];
    };

    const handleTimeDisplay = () => {
        let startTime = schedule.start.split(':');
        let startHours = startTime[0] / 1;
        let startMinutes = startTime[1];
        let endTime = schedule.end.split(':');
        let endHours = endTime[0] / 1;
        let endMinutes = endTime[1];
        let sAMorPM, eAMorPM;

        if (startHours > 12) {
            startHours = startHours - 12;
            sAMorPM = 'pm';
        } else if (startHours === 12) {
            sAMorPM = 'pm';
        } else if (startHours === 0) {
            startHours = 12;
            sAMorPM = 'am';
        } else {
            sAMorPM = 'am';
        };

        if (endHours > 12) {
            endHours = endHours - 12;
            eAMorPM = 'pm';
        } else if (endHours === 12) {
            eAMorPM = 'pm';
        } else {
            eAMorPM = 'am';
        };

        return `${startHours}:${startMinutes}${sAMorPM} - ${endHours}:${endMinutes}${eAMorPM}`;
    };

    return (
        <>
        {schedule.days.map((day, i) => (
            <div key={i} className={styles.container} onClick={() => handleEditSchedule(schedule)} style={{top: `${handleTop(day)}`, left: `${handleLeft()}`, width: `${handleWidth()}`, background: `${handleBackground()}`}}>
                <div className={styles.words}>
                    <span>{schedule.link}</span>
                    <span>{handleTimeDisplay()}</span>
                </div>
            </div>
        ))}
        </>
    );
};