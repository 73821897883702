import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../utils/Auth';
import { scheduleUTCFormat, scheduleLocalFormat } from '../../../utils/ScheduleFunctions';
import ScheduleDisplay from './ScheduleDisplay';
import AddAsset from './AddAsset';
import API from '../../../API';
import styles from './Schedule.module.css';

export default function Schedule(props) {
    const { qr_data, dataDispatch, qrType, id, setQrFiles, edit } = props;
    const { updateQRSchedule } = API;
    const { userObj } = useAuth();

    const times = ['12a','1','2','3','4','5','6','7','8','9','10','11','12p','1','2','3','4','5','6','7','8','9','10','11'];
    const days = [{name: 'Mon', value: '1'},{name: 'Tues', value: '2'},{name: 'Wed', value: '3'},{name: 'Thur', value: '4'},{name: 'Fri', value: '5'},{name: 'Sat', value: '6'},{name: 'Sun', value: '0'}];

    const handleUpdateScheduleDisplay = (schedule) => {
        const scheduleObj = Object.keys(schedule).reduce((acc, cur) => {
            schedule[cur].forEach(day => {
                if (day && day?.hasOwnProperty('link')) {
                    if (acc?.hasOwnProperty(day.link)) {
                        if (acc.hasOwnProperty(day.link) && acc[day.link].some(x => x.start === day.start && x.end === day.end)) {
                            acc[day.link].find(x => x.start === day.start && x.end === day.end).days.push(cur);
                        } else {
                            acc[day.link].push({start: day.start, end: day.end, link: day.link, days: [cur]});
                        };
                    } else {
                        acc[day.link] = [{start: day.start, end: day.end, link: day.link, days: [cur]}];
                    };
                };
            });
            return acc;
        }, {});

        const arr = [];
        Object.values(scheduleObj).forEach(x => arr.push(...x));

        setSchedDisplay(() => arr);
    };

    const [ show, setShow ] = useState(false);
    const [ link, setLink ] = useState('');
    const [ startDate, setStartDate ] = useState('00:00');
    const [ endDate, setEndDate ] = useState('00:00');
    const [ editObj, setEditObj ] = useState({});
    const [ schedule, setSchedule ] = useState({ '0': [], '1': [], '2': [], '3': [], '4': [], '5': [], '6': [] });
    const [ schedDisplay, setSchedDisplay ] = useState([]);
    const [ checkedDays, setCheckedDays ] = useState([]);
    const [ index, setIndex ] = useState(qr_data?.alternate_redirect_url?.length ? qr_data?.alternate_redirect_url?.length : 0);
    const [ error, setError ] = useState('');

    useEffect(() => {
        if (qr_data?.hasOwnProperty('schedule') && Object.keys(qr_data.schedule).length > 0) {
            setSchedule(scheduleLocalFormat(qr_data.schedule, userObj?.hasOwnProperty('timezone') ? userObj.timezone : {}));
        };
    },[qr_data, userObj]);

    useEffect(() => {
        if (schedule) {
            handleUpdateScheduleDisplay(schedule);
        };
    },[schedule]);

    const handleAddToSchedule = async (addAnotherSchedule) => {
        setError(() => (''));
        let err = false;

        const obj = {};
        const tempSchedule = {...JSON.parse(JSON.stringify(schedule))};

        if (editObj.hasOwnProperty('link')) {
            editObj.days.forEach(day => {
                tempSchedule[day] = tempSchedule[day].filter(x => x.start !== editObj.start && x.end !== editObj.end && x.link !== editObj.link);
            });
        };

        checkedDays.forEach(day => {
            const [startHour, startMinute] = startDate.split(':');
            const [endHour,endMinute] = endDate.split(':');

            if (Number(startHour) > Number(endHour) && (Number(startHour) >= Number(endHour) && Number(endHour) !== 0 || (Number(endHour) === 0 && Number(endMinute) > 0))){
                setError(() => 'Your end time exceeds 24 hours');
                err = true;
                return;
            };
        
            if (endDate === '00:00') {
                obj[day] = {start: startDate, end: '23:59', link: link};
            } else {
                obj[day] = {start: startDate, end: endDate, link: link};
            };
        });

        // Checks for overlapping schedules
        // Disables Approve button if there is an overlap
        for (const day in tempSchedule) {
            if (tempSchedule[day].length > 0 && obj.hasOwnProperty(day)) {
                for (let i = 0; i < tempSchedule[day].length; i++) {
                    const [startHour, startMin] = tempSchedule[day][i].start.split(':');
                    const [endHour, endMin] = tempSchedule[day][i].end.split(':');
                    const start = new Date(0, 0, 0, startHour, startMin, 0);
                    const end = new Date(0, 0, 0, endHour, endMin, 0);
                    const start2 = new Date(0, 0, 0, obj[day].start.split(':')[0], obj[day].start.split(':')[1], 0);
                    const end2 = new Date(0, 0, 0, obj[day].end.split(':')[0], obj[day].end.split(':')[1], 0);

                    if (start < end2 && end > start2) {
                        setError(() => ('Schedule overlaps with existing schedule'));
                        err = true;
                        return;
                    };
                };
            };

            if (obj.hasOwnProperty(day)) {
                tempSchedule[day].push(obj[day]);
            };
        };

        if (err) return;
        
        const finalSchedule = scheduleUTCFormat(tempSchedule, userObj?.hasOwnProperty('timezone') ? userObj.timezone : {});
        dataDispatch({ type: 'schedule', data: finalSchedule });

        if (edit) {
            await updateQRSchedule(id, { schedule: finalSchedule })
                .then(() => {
                    dataDispatch({ type: 'schedule', data: finalSchedule });
                    handleUpdateScheduleDisplay(finalSchedule);
                    handleCancel(addAnotherSchedule);
                })
                .catch(err => console.log(err));
        } else {
            dataDispatch({ type: 'schedule', data: finalSchedule });
            handleUpdateScheduleDisplay(finalSchedule);
            handleCancel(addAnotherSchedule);
        };
    };

    const handleEditSchedule = (time) => {
        setShow(true);
        setCheckedDays(time.days);
        setStartDate(time.start);
        setEndDate(time.end);
        setLink(time.link);
        setEditObj(time);
    };

    const handleCancel = (addAnotherSchedule) => {
        setLink('');
        setStartDate('00:00');
        setEndDate('00:00');
        setCheckedDays([]);
        setIndex(qr_data?.alternate_redirect_url?.length ? qr_data?.alternate_redirect_url?.length : 0);
        setError('');

        if (addAnotherSchedule) {
            return;
        };

        setShow(false);
    };

    useEffect(() => {
        const wrap = document.querySelector(`.${styles.scheduleWrap}`);
        if (show) {
            wrap.scrollLeft = 0;
            wrap.style.overflowX = 'hidden';
        } else {
            wrap.style.overflowX = 'scroll';
        };
    },[show])

    return (
        <div className={styles.container}>
            <div className={styles.addWrap}>
                <button className={styles.addBtn} onClick={() => setShow(true)}>Edit <i className='far fa-pencil' /></button>
            </div>
            <div className={styles.scheduleWrap}>
                <div className={styles.schedule}>
                    <div className={styles.time}>
                        {times.map((time,i) => (
                            <span key={i}>{time}</span>
                        ))}
                    </div>
                    <div className={styles.days}>
                        {days.map((day,i) => (
                            <span key={i}>{day.name}</span>
                        ))}
                    </div>
                    <div className={styles.layout}>
                        {times.map((col,i) => (
                            <div key={i} className={styles.columns} style={{left: `calc((100% / 24) * ${i})`}} />
                        ))}
                        {days.map((row,i) => (
                            <div key={i} className={styles.rows} style={{top: `calc((100% / 7) * ${i})`}}></div>
                        ))}
                        {schedDisplay.length > 0 ?
                            schedDisplay.map((sched,i) => (
                                <ScheduleDisplay key={i} schedule={sched} colorIndex={i} handleEditSchedule={handleEditSchedule} />
                            ))
                        : <></>
                        }
                    </div>
                </div>
                {show ?
                    <div className={styles.modalWrap}>
                        <div className={styles.modal}>
                            <div className={styles.modalHeader}>Update Schedule</div>
                            <div className={styles.modalBody}>
                                <AddAsset type={qrType} id={id} setQrFiles={setQrFiles} qr_data={qr_data} dataDispatch={dataDispatch} index={index} setIndex={setIndex} link={link} setLink={setLink} />
                                <div className={styles.scheduler}>
                                    <div className='ms-2'>Days</div>
                                    <div className={styles.daysOfWeek}>
                                        {days.map((day,i) =>(
                                                <div className={styles.checkboxWrap} key={i}>
                                                    <input type='checkbox' className={styles.checkbox} id={`scheduler-${day.name}`} checked={checkedDays.includes(day.value)} value={day.value} onChange={(e) => setCheckedDays(e.target.checked ? [...checkedDays, e.target.value] : checkedDays.filter((a) => a !== e.target.value))} />
                                                    <label htmlFor={`scheduler-${day.name}`}>{day.name}</label>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div className={styles.timeWrap}>
                                        <div className={styles.timeWrapInner}>
                                            <div className={styles.timeContainer}>
                                                <label htmlFor='scheduler-start' style={{marginLeft: '.5rem'}}>Start Date</label>
                                                <input type='time' id='scheduler-start' className={styles.timeInput} value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                            </div>
                                            <div className={styles.timeContainer} style={{marginRight: '0'}}>
                                                <label htmlFor='scheduler-end' style={{marginLeft: '.5rem'}}>End Date</label>
                                                <input type='time' id='scheduler-end' className={styles.timeInput} value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                            </div>
                                        </div>
                                        <button className={styles.timeBtn} onClick={() => handleAddToSchedule(true)} disabled={!link}>Add to Schedule<i className='far fa-plus ms-1' /></button>
                                    </div>
                                    <div className={styles.table} >
                                        <div className={styles.tableHeader}>
                                            <div className={styles.tableHeaderTitle}>URL</div>
                                            <div className={styles.tableHeaderTitle}>M</div>
                                            <div className={styles.tableHeaderTitle}>T</div>
                                            <div className={styles.tableHeaderTitle}>W</div>
                                            <div className={styles.tableHeaderTitle}>Th</div>
                                            <div className={styles.tableHeaderTitle}>F</div>
                                            <div className={styles.tableHeaderTitle}>Sa</div>
                                            <div className={styles.tableHeaderTitle}>Su</div>
                                            <div className={styles.tableHeaderTitle}>Start</div>
                                            <div className={styles.tableHeaderTitle}>End</div>
                                        </div>
                                        {schedDisplay.length > 0 ? 
                                            schedDisplay.map((time,i) => (
                                                <div className={styles.tableRow} key={i} onClick={() => handleEditSchedule(time)}>
                                                    <div className={styles.tableHeaderTitle} title={time.link} >{time.link}</div>
                                                    <div className={styles.tableHeaderTitle}>{time.days.includes('1') && 'X'}</div>
                                                    <div className={styles.tableHeaderTitle}>{time.days.includes('2') && 'X'}</div>
                                                    <div className={styles.tableHeaderTitle}>{time.days.includes('3') && 'X'}</div>
                                                    <div className={styles.tableHeaderTitle}>{time.days.includes('4') && 'X'}</div>
                                                    <div className={styles.tableHeaderTitle}>{time.days.includes('5') && 'X'}</div>
                                                    <div className={styles.tableHeaderTitle}>{time.days.includes('6') && 'X'}</div>
                                                    <div className={styles.tableHeaderTitle}>{time.days.includes('0') && 'X'}</div>
                                                    <div className={styles.tableHeaderTitle}>{time.start}</div>
                                                    <div className={styles.tableHeaderTitle}>{time.end}</div>
                                                </div>
                                            ))
                                            :
                                            <div className={styles.tableRow} style={{justifyContent: 'center', cursor: 'default'}}>No Data</div>
                                        }
                                    </div>
                                    {error ? <div>{error}</div> : <></>}
                                    <div className={styles.modalBottom}>
                                        <button className={styles.cancelBtn} onClick={() => handleCancel(false)}>Cancel</button>
                                        <button className={styles.approveBtn} onClick={() => handleAddToSchedule(false)} disabled={error} >Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <></>
                }
            </div>
        </div>
    );
};