import React, { useState, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import Modal from 'react-bootstrap/Modal';
import iPhone from '../../../../Images/iPhone.png';
import styles from './MicrositeOptions.module.css';

const MicrositeOptions = (props) => {
    const { qr_data, dataDispatch } = props;

    const editorRef = useRef(null);
    const [ show, setShow ] = useState(false);
    const [ previewHTML, setPreviewHTML ] = useState(null);
    const [ textInit ] = useState(qr_data?.microsite ? qr_data.microsite : "<p>This is the initial content of the editor.</p>");

    const handleClose = () => setShow(false);

    const previewContent = () => {
        setShow(true);
        if (editorRef.current) {
            const content = editorRef.current.getContent();
            setPreviewHTML(content);
        };
    };

    const handleInitialImageSize = (content) => {
        const beginning = content.split("width=\"")[0];
        const width = content.split("width=\"")[1];
        const height = content.split("height=\"")[1];
        const replacedWidth = width.replace(/^.+?(\")/, `width="100%"`);
        const replacedHeight = height.replace(/^.+?(\")/, `%" height="auto"`);
        const final = beginning + replacedWidth.substring(0, replacedWidth.indexOf("%")) + replacedHeight;
        return final;
    };

    return (
        <div className={styles.container}>
            <Editor
                apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                onInit={(evt, editor) => editorRef.current = editor }
                onBeforeSetContent={(e) => {
                    if (e && e.content.includes('img')) {
                        e.content = handleInitialImageSize(e.content);
                    }
                }}
                onEditorChange={() => dataDispatch({type: 'microsite', data: editorRef.current.getContent()})}
                initialValue={textInit}
                init={{
                    selector: 'textarea',
                    plugins: [
                        'importcss', 'searchreplace', 'autolink', 'directionality', 'code', 'visualblocks', 'visualchars', 
                        'image', 'link', 'media', 'codesample', 'table', 'charmap', 'pagebreak', 'nonbreaking', 
                        'anchor', 'insertdatetime', 'advlist', 'lists', 'wordcount', 'help', 'charmap', 'quickbars', 'emoticons'
                    ],
                    menubar: 'file edit view insert format tools table help',
                    toolbar: 'undo redo | bold italic underline strikethrough | ' +
                    'fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | ' +
                    'outdent indent | numlist bullist | forecolor backcolor removeformat | ' +
                    'pagebreak | charmap emoticons | ' +
                    'image media template link anchor codesample | ltr rtl',
                    toolbar_sticky: true,
                    toolbar_mode: window.innerWidth <= 768 ? 'scrolling' : 'floating',
                    importcss_append: true,
                    height: 500,
                    branding: false,
                    invalid_elements: 'script',
                    link_assume_external_targets: 'http',
                    image_caption: true,
                    quickbars_insert_toolbar: false,
                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quicktable',
                    noneditable_class: 'mceNonEditable',
                    contextmenu: 'link image table',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
            <button className={styles.previewBtn} onClick={previewContent}>Preview</button>
            <Modal className={styles.modal} show={show} onHide={handleClose}>
                <Modal.Header className={styles.modalHeader} closeButton>
                    <Modal.Title>Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.bodyWrap} style={window.innerWidth > 575 ? {backgroundImage: `url(${iPhone})`} : null}>
                        {window.innerWidth > 575 &&
                        <>
                            <div className={styles.time}>3:45</div>
                            <div className={styles.barsBattery}>
                                <i className='fas fa-signal me-1' />
                                <i className='fas fa-battery' style={{fontSize: '1rem'}} />
                            </div>
                        </>
                        }
                        <div className={styles.bodyContainer} dangerouslySetInnerHTML={{__html: previewHTML && previewHTML}}>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default MicrositeOptions;