import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { CombinedChart } from './CombinedChart';
import { SeparatedChart } from './SeparatedChart';
import { UniqueUsers } from './UniqueUsers';
import { TopFive } from './SeparatedChart/topfive';
import getCombinedChartData from './ChartFunctions/getCombinedChartData';
import getCombinedChartLabels from './ChartFunctions/getCombinedChartLabels';
import getUniqueUsers from './ChartFunctions/getUniqueUsers';
import getSeparateChartData from './ChartFunctions/getSeparateChartData';
import getTopFiveSeparateData from'./ChartFunctions/getTopFiveSeparateData';
import styles from './AnalyticsView.module.css';

export default function AnalyticsView(props) {
    // ----- STATE INITIALIZATION -----
    const { analytics, orgObj, liquidQRs } = props;

    const [ timeFrame, setTimeFrame ] = useState(7);
    const [ categoryFilter, setCategoryFilter ] = useState([]);
    const [ selectedStartDate, setSelectedStartDate ] = useState(new Date(Date.now()));
    const [ selectedEndDate, setSelectedEndDate ] = useState(new Date(Date.now()));
    const [ startDate, setStartDate ] = useState(new Date(Date.now()));
    const [ endDate, setEndDate ] = useState(new Date(Date.now()));
    const [ chartLabels, setChartLabels ] = useState();
    const [ chartData, setChartData ] = useState();
    const [ usersData, setUsersData ] = useState();
    const [ topFiveData, setTopFiveData ] = useState();
    const [ separateChartData, setSeparateChartData ] = useState();
    const [ showMultipleSelect, setShowMultipleSelect ] = useState(false);
    const [ tempCategoryFilter, setTempCategoryFilter ] = useState([]);
    const [ tempTimeFrame, setTempTimeFrame ] = useState(7);
    const [ categoryList, setCategoryList ] = useState(['All QRs']);
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- HANDLE EDITS -----
    const onDateChange = (dates) => {
        const [start, end] = dates;
        setSelectedStartDate(start);
        setSelectedEndDate(end);
    };

    const onSave = () => {
        setTimeFrame(tempTimeFrame);
        setCategoryFilter(tempCategoryFilter);
        setStartDate(selectedStartDate);
        setEndDate(selectedEndDate);
    };
    
    useEffect(() => {
        if (liquidQRs && analytics) {
            setChartData(getCombinedChartData(liquidQRs, analytics, timeFrame, categoryFilter, startDate, endDate));
            setChartLabels(getCombinedChartLabels(timeFrame, startDate, endDate));

            setUsersData(getUniqueUsers(liquidQRs, analytics, timeFrame, categoryFilter, startDate, endDate));
            setSeparateChartData(getSeparateChartData(liquidQRs, analytics, timeFrame, categoryFilter, startDate, endDate));
            setTopFiveData(getTopFiveSeparateData(liquidQRs, analytics, timeFrame, categoryFilter, startDate, endDate));
        };
    },[liquidQRs, analytics, categoryFilter, timeFrame, startDate, endDate]);

    const handleTimeFrame = (e) => {
        setTempTimeFrame(parseInt(e.target.value));
    };

    const handleCategories = (e) => {
        if (e.target.id === 'all-qrs' && e.target.checked) {
            setTempCategoryFilter([]);
            setCategoryList(['All QRs']);
            orgObj.categories.forEach(category => {
                const elem = document.querySelector(`#${category.name.replace(/\s+/g, '')}`);
                elem.checked = false;
            });
        } else {
            orgObj.categories.forEach(category => {
                const elem = document.querySelector(`#${category.name.replace(/\s+/g, '')}`);
                if (elem.checked && !tempCategoryFilter.includes(category.name.replace(/\s+/g, ''))) {
                    setTempCategoryFilter(prev => ([...prev, category.name.replace(/\s+/g, '')]));
                    setCategoryList(prev => ([...prev.filter(cat => cat.name !== 'All QRs'), category]));
                } else if (!elem.checked && tempCategoryFilter.includes(category.name.replace(/\s+/g, ''))) {
                    setTempCategoryFilter(prev => prev.filter(name => name !== category.name.replace(/\s+/g, '')));
                    if (categoryList.length === 1) {
                        setCategoryList(['All QRs']);
                    } else {
                        setCategoryList(prev => prev.filter(cat => cat.name !== category.name.replace(/\s+/g, '')));
                    };
                };
            });
        };
    };

    useEffect(() => {
        window.addEventListener('click', (e) => {
            const element = document.getElementById('analytics-categories');
            if (e.target.id !== element && !element?.contains(e.target)) {
                setShowMultipleSelect(false);
            };
        });
        return () => {
            window.removeEventListener('click', () => {});
        };
    },[]);
    // ----- END HANDLE EDITS -----
    // ----------------------------------------------

    return (
        <>
            <div className={styles.filterWrap}>
                <div className={styles.title}>Filters</div>
                <div className={styles.inputGroup}>
                    <select id='analytics-time-frame' className={styles.dropdown} defaultValue={tempTimeFrame.toString()} onChange={handleTimeFrame}>
                        <option value='1'>Today</option>
                        <option value='2'>Yesterday</option>
                        <option value='7'>7 Days</option>
                        <option value='30'>30 Days</option>
                        <option value='90'>90 Days</option>
                        <option value='0'>Custom Range</option>
                    </select>
                    <label htmlFor='analytics-time-frame' className={styles.label}>Time Frame</label>
                </div>
                {tempTimeFrame === 0 &&
                <div className={styles.inputGroup}>
                    <DatePicker
                        selected={selectedStartDate}
                        onChange={onDateChange}
                        startDate={selectedStartDate}
                        endDate={selectedEndDate}
                        maxDate={new Date()}
                        dateFormat="MM/dd/yy"
                        selectsRange
                        id="picker"
                        className={styles.picker}
                    />
                </div>
                }
                <div className={styles.inputGroup}>
                    <div id='analytics-categories' className={styles.dropdown} onClick={() => setShowMultipleSelect(true)}>
                    {categoryList.length < 2 ?
                        categoryList.map((item, index) => (
                            <span key={item.id + index}>{item.name}</span>
                        ))
                        :
                        categoryList.map((item, index) => {
                            if (index + 1 === categoryList.length) {
                                return <span key={item.id + index}>{item.name}</span>
                            } else {
                                return <span key={item.id + index}>{item.name},</span>
                            }
                        })
                    }
                    {showMultipleSelect &&
                        <div className={styles.list}>
                            <div className={styles.listTitle}>QRs w/o Categories</div>
                            <input type='checkbox' checked={tempCategoryFilter.length === 0} onChange={handleCategories} id='all-qrs' />
                            <label htmlFor='all-qrs' className='ms-1'>All QRs</label>
                            <div className={styles.listTitle}>Categories</div>
                            {orgObj?.categories?.length > 0 ?
                                orgObj.categories.map((category, index) => (
                                    <div key={category.name.replace(/\s+/g, '') + index} style={{width: 'fit-content', height: 'fit-content'}}>
                                        <input type='checkbox' checked={tempCategoryFilter.includes(category.name.replace(/\s+/g, ''))} onChange={handleCategories} id={category.name.replace(/\s+/g, '')} />
                                        <label htmlFor={category.name.replace(/\s+/g, '')} className='ms-1'>{category.name}</label>
                                    </div>
                                ))
                                :
                                <div>No categories</div>
                            }
                        </div>
                    }
                    </div>
                    <label className={styles.label}>Categories</label>
                </div>
                <button className={styles.btn} onClick={() => onSave()}>SAVE</button>
            </div>
            {!separateChartData?.bool && <h4 className='mt-4 mb-0'>No QRs with that category exist</h4>}
            <div className={styles.mainChart}>
                <CombinedChart chartLabels={chartLabels} chartData={chartData} />
            </div>
            <div className={styles.chartWrap}>
                <div className={styles.subChart1}>
                    <UniqueUsers chartLabels={chartLabels} chartData={usersData} />
                </div>
                <div className={styles.subChart2}>
                    <TopFive chartLabels={chartLabels} chartData={topFiveData?.data} chartHeaders={topFiveData?.order} />
                </div>
            </div>
            <div className={styles.mainChart}>
                <SeparatedChart chartLabels={chartLabels} chartData={separateChartData?.data} chartHeaders={separateChartData?.order} />
            </div>
        </>
    );
};