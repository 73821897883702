import React from 'react';
import styles from './Nickname.module.css';

export default function Nickname(props) {
    const { qr_data, dataDispatch, setUp } = props;
    
    switch (setUp) {
        case true:
            return (
                <div className={styles.container}>
                    <div className={styles.inputGroup}>
                        <input type='text' id='nickame-label' className={styles.input} maxLength='100' onChange={e => dataDispatch({type: 'nickname', data: e.target.value})} value={qr_data?.nickname} />
                        <label htmlFor='nickame-label' className={styles.label}>QR Name</label>
                        <div className={styles.required}>* Required</div>
                    </div>
                </div>
            );
        case false:
        default:
            return(
                <div className={styles.inputGroup}>
                    <input type='text' id='nickame-label' className={styles.input} maxLength='100' onChange={e => dataDispatch({type: 'nickname', data: e.target.value})} value={qr_data?.nickname} />
                    <label htmlFor='nickame-label' className={styles.label}>QR Name</label>
                </div>
            );
    };
};