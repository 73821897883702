import { firestore } from '../../firebase';
import { collection, where, onSnapshot, query, documentId } from 'firebase/firestore';

export function getLiquidListener(org_id, setLiquidQRs) {
    if (org_id) {
        const userQuery = query(collection(firestore, 'codes'), where('org_id', '==', org_id));
        const liquidListener = onSnapshot(userQuery, snapshot => {
            const arr = [];
            snapshot.forEach(code => {
                const obj = code.data();
                if (obj?.active) {
                    obj.qr_style.type = 'svg';
                    obj.qr_style.width = 128;
                    obj.qr_style.height = 128;
                    obj.qr_style.image = obj.qr_style.image ? `${obj.qr_style.image}?${Date.now()}` : '';
                    obj.qr_style.imageOptions.imageSize = 0.4;
                    arr.push({id: code.id, data: obj});
                };
            });
            setLiquidQRs(arr.sort((a,b) => a.data.nickname.toLowerCase() > b.data.nickname.toLowerCase() ? 1 : -1));
        });

        return liquidListener;
    };
};

export function getAnalytics(org_id, setAnalytics) {
    if (org_id) {
        const userQuery = query(collection(firestore, 'analytics'), where(documentId(), '==', org_id));
        const analyticsListener = onSnapshot(userQuery, snapshot => {
            snapshot.forEach(obj => {
                if (obj.exists()) {
                    setAnalytics(obj.data());
                } else {
                    setAnalytics(null)
                };
            });
        });

        return analyticsListener;
    };
};

export function getOrganization(org_id, setOrgObj) {
    if (org_id) {
        const userQuery = query(collection(firestore, 'users'), where(documentId(), '==', org_id));
        const orgListener = onSnapshot(userQuery, snapshot => {
            snapshot.forEach(obj => {
                if (obj.exists()) {
                    setOrgObj(obj.data());
                } else {
                    setOrgObj(null);
                };
            });
        });
        return orgListener;
    };
};