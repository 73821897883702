export default function handleLabels(e) {
    const labelName = e.target.id.split('-label')[0];
    const label = document.querySelector(`[for="${e.target.id}"]`);
    
    if (e.target.value.length > 0) {
        if (e.target.id !== `${labelName}-label`) {
            label.classList.add('tag-filled');
        } else {
            label.classList.add('filled');
        };
    } else {
        if (e.target.id !== `${labelName}-label`) {
            label.classList.remove('tag-filled');
        } else {
            label.classList.remove('filled');
        };
    };
};