import React, { useState } from 'react';
import ColorPicker from '../ColorPicker';
import cloneDeep from 'lodash.clonedeep';
import styles from './Background.module.css';

export default function Background(props) {

    const { qr_options, optionsDispatch } = props;

    const [ colorType, setColorType ] = useState(qr_options?.backgroundOptions?.gradient ? 'gradient' : 'single');
    const [ gradientType, setGradientType ] = useState(qr_options?.backgroundOptions?.gradient?.type ? qr_options.backgroundOptions.gradient.type : 'linear');
    const [ displayColorPicker, setDisplayColorPicker ] = useState(null);
    const [ firstColor, setFirstColor ] = useState(qr_options?.backgroundOptions?.color ? qr_options.backgroundOptions.color : qr_options?.backgroundOptions?.gradient?.colorStops ? qr_options.backgroundOptions.gradient.colorStops[0].color : '#ffffff');
    const [ secondColor, setSecondColor ] = useState(qr_options?.backgroundOptions?.gradient?.colorStops ? qr_options.backgroundOptions.gradient.colorStops[1].color : '#ffffff');
    const [ transparent, setTransparent ] = useState(qr_options?.backgroundOptions?.color === 'TRANSPARENT');

    const handleColorType = (e) => {
        setColorType(e.target.id);
        if(e.target.id === 'single') {
            setGradientType('linear');
            optionsDispatch({type: 'background', options: { color: firstColor }});
            optionsDispatch({type: 'background', options: { gradient: null }});
        } else if (e.target.id === 'gradient') {
            optionsDispatch({type: 'background', options: { gradient: {...qr_options.backgroundOptions.gradient, colorStops: [{offset: 0, color: firstColor}, {offset: 1, color: secondColor}]}}});
        };
    };

    const [ previous, setPrevious ] = useState(cloneDeep(qr_options?.backgroundOptions));
    const handleTransparent = (checked) => {
        setPrevious(cloneDeep(qr_options?.backgroundOptions));
        if (checked) {
            setColorType('single');
            optionsDispatch({type: 'background', options: {color: 'TRANSPARENT'}});
            optionsDispatch({type: 'background', options: {gradient: null}});
        } else {
            switch (colorType) {
                case 'single':
                    if (previous.color !== 'TRANSPARENT') {
                        setFirstColor(previous.color);
                        optionsDispatch({type: 'background', options: { color: previous.color }});
                        optionsDispatch({type: 'background', options: { gradient: null }});
                    } else {
                        setFirstColor('#ffffff');
                        optionsDispatch({type: 'background', options: { color: '#ffffff' }});
                        optionsDispatch({type: 'background', options: { gradient: null}});
                    };
                    break;
                case 'gradient':
                    if (previous.color !== 'TRANSPARENT') {
                        setFirstColor(previous.gradient.colorStops[0].color);
                        setSecondColor(previous.gradient.colorStops[1].color);
                        optionsDispatch({type: 'background', options: { gradient: {...qr_options.backgroundOptions.gradient, colorStops: [{offset: 0, color: previous.gradient.colorStops[0].color}, {offset: 1, color: previous.gradient.colorStops[1].color}]}}});
                    } else {
                        setFirstColor('#ffffff');
                        setSecondColor('#ffffff');
                        optionsDispatch({type: 'background', options: { gradient: {...qr_options.backgroundOptions.gradient, colorStops: [{offset: 0, color: '#ffffff'}, {offset: 1, color: '#ffffff'}]}}});
                    }
                    break;
                default:
                    return;
            };
        };
    };

    const handleGradientType = (e) => {
        setGradientType(e.target.id);
        optionsDispatch({type: 'background', options: { gradient: {...qr_options.backgroundOptions.gradient, type: e.target.id}}});
    };

    const handleColorChange = (e, name) => {
        if (colorType === 'single') {
            setFirstColor(e);
            optionsDispatch({type: 'background', options: {color: e, gradient: null}});
        } else if (colorType === 'gradient') {
            if (name === 'gradient-color1') {
                setFirstColor(e);
                optionsDispatch({type: 'background', options: {gradient: {...qr_options.backgroundOptions.gradient, colorStops: [{offset: 0, color: e}, {offset: 1, color: secondColor}]}}});
            } else if (name === 'gradient-color2') {
                setSecondColor(e);
                optionsDispatch({type: 'background', options:{gradient: {...qr_options.backgroundOptions.gradient, colorStops: [{offset: 0, color: firstColor}, {offset: 1, color: e}]}}});
            };
        };
    };

    const handleRotation = (e) => {
        optionsDispatch({type: 'background', options:{gradient:{...qr_options.backgroundOptions.gradient, rotation: e.target.value}}});
    };

    return (
        <div className={styles.container}>
            <div className={styles.inputGroup}>
                <span className='me-3'>Transparent</span>
                <label htmlFor='transparent-check' className={styles.checkboxToggle}>
                    <span className={!transparent ? styles.toggler : `${styles.toggler} ${styles.active}`} />
                    {transparent ? <span className={styles.togglerOn}>On</span> : <span className={styles.togglerOff}>Off</span>}
                    <input type='checkbox' id='transparent-check' checked={transparent} className={styles.checkbox} onChange={e => {setTransparent(e.target.checked); handleTransparent(e.target.checked)}} />
                </label>
            </div>
            {!transparent ?
                <>
                <div className={styles.inputGroup}>
                    <label className={styles.label}>Color Type</label>
                    <input type='radio' className={styles.radio} onChange={handleColorType} id='single' checked={colorType === 'single'} />
                    <label htmlFor='single' className={styles.radioBtn}>Single Color</label>
                    <input type='radio' className={styles.radio} onChange={handleColorType} id='gradient' checked={colorType === 'gradient'} />
                    <label htmlFor='gradient' className={styles.radioBtn}>Color Gradient</label>
                </div>
                {colorType === 'single' ?
                    <div className={styles.inputGroup}>
                        <label className={styles.label}>Background Color</label>
                        <div className={styles.swatch} onClick={() => setDisplayColorPicker('bg-color')}>
                            <div className={styles.color}  style={{background: firstColor}} />
                        </div>
                        { displayColorPicker === 'bg-color' ? 
                            <div className={styles.popover}>
                                <div className={styles.cover} onClick={() => setDisplayColorPicker(null)} />
                                <ColorPicker
                                    name='single-color'
                                    color={ firstColor }
                                    onChange={handleColorChange}
                                />
                            </div> : null }
                    </div>
                    :
                    <>
                    <div className={styles.inputGroup}>
                        <label className={styles.label}>Gradient Type</label>
                        <input type='radio' className={styles.radio} onChange={handleGradientType} id='linear' checked={gradientType === 'linear'} />
                        <label htmlFor='linear' className={styles.radioBtn}>Linear</label>
                        <input type='radio' className={styles.radio} onChange={handleGradientType} id='radial' checked={gradientType === 'radial'} />
                        <label htmlFor='radial' className={styles.radioBtn}>Radial</label>
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.label}>Background Gradient</label>
                        <div className='me-2'>
                            <div className={styles.swatch} onClick={() => setDisplayColorPicker('bg-color1')}>
                                <div className={styles.color} style={{background: firstColor}} />
                            </div>
                            { displayColorPicker === 'bg-color1' ? 
                                <div className={styles.popover}>
                                    <div className={styles.cover} onClick={() => setDisplayColorPicker(null)} />
                                    <ColorPicker
                                        name='gradient-color1'
                                        color={ firstColor }
                                        onChange={handleColorChange}
                                    />
                                </div> : null }
                        </div>
                        <div>
                            <div className={styles.swatch}  onClick={() => setDisplayColorPicker('bg-color2')}>
                                <div className={styles.color} style={{background: secondColor}} />
                            </div>
                            { displayColorPicker === 'bg-color2' ? 
                                <div className={styles.popover}>
                                    <div className={styles.cover} onClick={() => setDisplayColorPicker(null)} />
                                    <ColorPicker
                                        name='gradient-color2'
                                        color={ secondColor }
                                        onChange={handleColorChange}
                                    />
                                </div> : null }
                        </div>
                    </div>
                    <div className={styles.inputGroup} style={{marginBottom: '0'}}>
                        <label htmlFor='rotation' className={styles.label}>Rotation</label>
                        <input id='rotation' type="number" min='0' max='7' className={styles.rotation} value={qr_options?.backgroundOptions?.gradient?.rotation ? qr_options.backgroundOptions.gradient.rotation : '0'} onChange={handleRotation} />
                    </div>
                    </>
                }
                </>
            : <></>
            }
        </div>
    );
};