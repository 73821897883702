import React from 'react';
import Nickname from '../../Nickname';
import Tags from '../../Tags';
import Categories from '../../Categories';
import styles from './Survey.module.css';

export default function Survey(props) {
    // ----- STATE INITIALIZATION -----
    const { qr_data, dataDispatch, categoryList, setCategoryList, plan, plan_id } = props;
    // ----- END STATE INITIALIZATION -----

    return (
        <>
            <h5 className={styles.header}>Liquid Feedback</h5>
            <Nickname qr_data={qr_data} dataDispatch={dataDispatch} />
            <div className={styles.inputGroup}>
                <div className={styles.fade} />
                <textarea id='survey-textarea-label' className={styles.textArea} value={qr_data?.survey?.description} onChange={e => dataDispatch({type: 'survey-description', data: e.target.value})} ></textarea>
                <label htmlFor='survey-textarea-label' className={qr_data?.survey?.description ? styles.labelFilled : styles.label} style={{marginRight:'0.5rem'}}>Description</label>
            </div>
            <Categories categoryList={categoryList.filter(cat => cat.id !== 'Uncategorized')} setCategoryList={setCategoryList} qr_data={qr_data} dataDispatch={dataDispatch} plan_id={plan_id} plan={plan} />
            <Tags qr_data={qr_data} dataDispatch={dataDispatch} />
        </>
    );
};