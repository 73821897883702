import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LoginComponent.module.css';

const LoginComponent = (props) => {
    // ----- STATE INITIALIZATION -----
    const { setUp, handleLogin, email, setEmail, password, setPassword, error, setError } = props;
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(false);
    const [ inputType, setInputType ] = useState('password');

    useEffect(() => {
        if (!error) {
            setLoading(false);
        };
    }, [error]);

    const query = window.location.search;
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- HANDLE INPUTS -----
    const handleEmail = (e) => {
        if (error) setError(false);

        setEmail(e.target.value);
    };

    const handlePassword = (e) => {
        if (error) setError(false);

        setPassword(e.target.value);
    };
    // ----- END HANDLE INPUTS -----
    // ----------------------------------------------

    // ----- LOGIN REDIRECT -----
    const signUpRedirect = () => {
        if (query) {
            window.location.assign(window.location.origin + `/signup${query}`);
        };

        navigate('/signup');
    };
    // ----- END LOGIN REDIRECT -----
    // ----------------------------------------------

    useEffect(() => {
        const submitBtn = document.getElementById('submit');
        window.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                submitBtn?.click();
            };
        });
        return () => {window.removeEventListener('keypress', () => {})}
    },[]);

    return (
        <div className={styles.form}>
            {setUp !== true && 
                <div className={styles.needAccount}>
                    Need an account? <span onClick={signUpRedirect}>SIGN UP</span>
                </div>
            }
            <div className={styles.inputGroup}>
                <input
                    type='email'
                    id='login-email-label'
                    style={{marginBottom: '1rem'}}
                    value={email}
                    className={styles.input}
                    onChange={handleEmail}
                    required
                />
                <label htmlFor='login-email-label' className={styles.label}>Email</label>
            </div>
            <div className={styles.inputGroup} style={{marginBottom: '.5rem'}}>
                <input
                    type={inputType}
                    id='login-password-label'
                    value={password}
                    onChange={handlePassword}
                    className={styles.input}
                    style={{paddingRight: '4rem'}}
                    required
                />
                <label htmlFor='login-password-label' className={styles.label}>Password</label>
                <button className={styles.hidePassword} onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')} disabled={password?.length < 1}>{inputType === 'password' ? <i className='fas fa-eye' /> : <i className='fas fa-eye-slash' />}</button>
                <a className={styles.forgotPassword} href={`${window.location.href.split('/login')[0]}/reset`}>Forgot password?</a>
            </div>
            <div className={styles.inputGroup} style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <button className={styles.btn} id='submit' onClick={(e) => {setLoading(true); handleLogin(e);}} disabled={email?.length < 1 || password?.length < 1 || loading}>Submit</button>
                {error ? <p className='text-center' style={{color: 'red'}}>Email or password is incorrect.</p> : <></>}
            </div>
        </div>
    );
};

export default LoginComponent;