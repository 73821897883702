import React, { useState, useEffect } from 'react';
import styles from './TypeSelect.module.css';

const TypeSelect = (props) => {
    const { qrType, setQrType, setStep, plan_id, setLinking } = props;
    const [ disabledQRs, setDisabledQRs ] = useState(true);

    const handleSelect = (type) => {
        if (qrType !== type) {
            setQrType(type);
        };
        setStep(2);
    };

    useEffect(() => {
        if (plan_id) {
            setDisabledQRs(plan_id < 1);
        };
    },[plan_id]);

    return (
        <>
            <h4 className='mb-3 text-center'>Select LiquidQR Type</h4>
            <button className={styles.linkBtn} onClick={() => setLinking(true)}><i className='far fa-object-exclude' /> Link to an existing QR</button>
            <div className={styles.separator}>or</div>
            <div className={styles.checkboxWrap}>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='link-type-label' className={styles.checkbox} value='link' onChange={(e) => e.target.checked && handleSelect('link')} ></input>
                    <label htmlFor='link-type-label' className={styles.label}><i className='fa-solid fa-link-simple'></i>Link</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='pdf-type-label' className={styles.checkbox} value='pdf' onChange={(e) => e.target.checked && handleSelect('pdf')} ></input>
                    <label htmlFor='pdf-type-label' className={styles.label}><i className='fa-solid fa-file-pdf'></i>PDF</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='menu-type-label' className={styles.checkbox} value='menu' onChange={(e) => e.target.checked && handleSelect('menu')} disabled={disabledQRs}></input>
                    <label htmlFor='menu-type-label' className={styles.label}><i className='fa-solid fa-fork-knife'></i>Menu</label>
                </div>
            </div>
            <div className={styles.checkboxWrap} style={{marginTop: '1rem'}}>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='location-type-label' className={styles.checkbox} value='location' onChange={(e) => e.target.checked && handleSelect(e.target.value)} disabled={disabledQRs}></input>
                    <label htmlFor='location-type-label' className={styles.label}><i className='fa-solid fa-map-location'></i>Location</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='microsite-type-label' className={styles.checkbox} value='microsite' onChange={(e) => e.target.checked && handleSelect(e.target.value)} disabled={disabledQRs}></input>
                    <label htmlFor='microsite-type-label' className={styles.label}><i className='fa-solid fa-square-list'></i>Microsite</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='appointment-type-label' className={styles.checkbox} value='appointment' onChange={(e) => e.target.checked && handleSelect(e.target.value)} disabled={disabledQRs}></input>
                    <label htmlFor='appointment-type-label' className={styles.label}><i className='fa-solid fa-calendar-days'></i>{window.innerWidth > 480 ? 'Appointment' : 'Appt.'}</label>
                </div>
            </div>
            <div className={styles.checkboxWrap} style={{marginTop: '1rem', marginBottom: '1rem'}}>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='tracker-type-label' className={styles.checkbox} value='tracker' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='tracker-type-label' className={styles.label}><i className='fa-solid fa-clipboard-list-check'></i>Tracker</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='asset-type-label' className={styles.checkbox} value='asset' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='asset-type-label' className={styles.label}><i className='fa-solid fa-shelves'></i>Liquid Label</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='survey-type-label' className={styles.checkbox} value='survey' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='survey-type-label' className={styles.label}><i className='fa-regular fa-square-poll-vertical'></i>Liquid Feedback</label>
                </div>
            </div>
        </>
    );
};

export default TypeSelect;