import React, { useState, useEffect, useRef } from 'react';
import QRCodeStyling from 'pp-qr-code';

const QRRef = (props) => {
    // ----- STATE INITIALIZATION -----
    const { qr_options } = props;

    const [ qrObj, setQrObj ] = useState();

    const qrRef = useRef();
    // ----- END STATE INITILIZATION -----
    // ----------------------------------------------

    useEffect(() => {
        let ref = qrRef.current;
        if (qrObj) {
            if (qr_options) {
                if (qr_options.data) {
                    qrObj.update(qr_options);
                } else {
                    if (ref.children[0]) {
                        ref.children[0].style.display = 'none';
                    };
                    qrObj.update(qr_options);
                };
                qrObj.append(ref);
            };
        } else {
            if (qr_options) {
                setQrObj(new QRCodeStyling(qr_options));
            };
        };

        return () => {
            if (qrObj) {
                qrObj.update(qr_options);
                qrObj.append(ref);
            };
        };
    },[qr_options, qrObj]);

    return (<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} ref={qrRef} />);
};

export default QRRef;