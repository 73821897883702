import React, { useRef, useEffect } from 'react';
import AssetEnlarged from '../AssetEnlarged';
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';
import styles from './AssetCarousel.module.css';
import './AssetCarousel.css';

export default function AssetCarousel(props) {
    const { asset, pdfLinks } = props;

    const swiperRef = useRef(null);
    const imgRef = useRef(null);
    
    
    useEffect(() => {
        const params = {
            modules: [Navigation, Pagination, EffectCoverflow],
            slidesPerView: 1,
            loop: asset?.route?.length > 2 ? true : false,
            navigation: window.innerWidth <= 768 ? false : true,
            pagination: true,
            injectStyles: [
                `
                :host .swiper-wrapper {
                    align-items: center;
                }
                `,
                `
                :host .swiper-pagination-bullet-active {
                    background: #161d2d;
                }
                `,
                `
                :host .swiper-button-next,
                :host .swiper-button-prev {
                    color: #bbb;
                    transition: color .2s ease;
                }
                `,
                `
                :host .swiper-button-next:hover,
                :host .swiper-button-prev:hover {
                    color: #161d2d;
                    transition: color .2s ease;
                }
                `
            ],
        };
        const swiperEl = swiperRef.current;
        Object.assign(swiperEl, params);
        
        swiperEl.initialize();
    },[swiperRef?.current])

    return (
        <>
        <swiper-container
            init={false}
            ref={swiperRef}
            key={Date.now()}
        >
            {asset?.route?.map(assRoute => (
                <swiper-slide key={assRoute}>
                    {(assRoute?.includes('.mp4') || assRoute?.includes('.webm') || assRoute?.includes('.mov')) ?
                        <div className={styles.videoWrap}>
                            <video src={`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/qr_files/${assRoute}?${Date.now()}`} controls autoPlay className={styles.video} />
                        </div>
                    : (assRoute?.includes('.jpeg') || assRoute?.includes('.jpg') || assRoute?.includes('.png') || assRoute?.includes('.gif')) ?
                        <div className={styles.imgWrap}>
                            <img src={`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/qr_files/${assRoute}?${Date.now()}`} ref={imgRef} className={styles.image} alt={`Asset file for qr`} />
                        </div>
                    : (assRoute?.includes('.pdf')) ?
                        <div className={styles.pdfWrap}>
                        PDF
                        {pdfLinks[assRoute] ? <a href={`${pdfLinks[assRoute]}`} className={styles.pdfBtn} download={`${assRoute.split('/')[1]}`}>Download</a> : <button className={styles.pdfBtn} disabled>Loading</button>}
                        </div>
                    : <></>
                    }
                </swiper-slide>
            ))}
        </swiper-container>
        <AssetEnlarged imgRef={imgRef} />
        </>
    );
};