import React, { useState, useEffect } from 'react';
import QuestionTypes from '../QuestionTypes';
import API from '../../../API';
import Modal from 'react-bootstrap/Modal';
import styles from './AddResponse.module.css';

const Timer = (props) => {
    const { url } = props;
    const [ count, setCount ] = useState(5);

    useEffect(() => {
        let interval = setInterval(() => {
            if (count > 0) {
                setCount(count - 1);
            } else {
                window.location.assign(url ? url : '/');
            };
        },1000);

        return () => clearInterval(interval);
    });

    return (
        <div className={styles.countdown}>Redirect in <span>{count}</span> seconds...</div>
    );
}

const AddResponse = (props) => {
    const { id, survey, questions, editResponse, setEditResponse, authenticatedUser } = props;
    const { updateSurveyResponse, editSurveyResponse, getSurveySignature } = API;
    
    const [ error, setError ] = useState('');
    const [ disabled, setDisabled ] = useState(false);
    const [ sigCanvas, setSigCanvas ] = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const [ newResponse, setNewResponse ] = useState(questions.reduce((acc, question) => {
        if (question?.archived) return acc;
        if (editResponse && editResponse?.hasOwnProperty(question.id)) {
            acc[question.id] = editResponse[question.id];
            return acc;
        };

        acc[question.id] = question?.type === 'checkbox' ? [] : '';
        return acc;
    }, {}));

    // useEffect(() => {
    //     if (editResponse && questions?.find(q => q?.type === 'signature') && sigCanvas) {
    //         const handleLogSig = async () => {
    //             await getSurveySignature(id, editResponse.date).then(res => {
    //                 if (res) {
    //                     sigCanvas.fromData(res);
    //                 };
    //             }).catch(err => console.log(err));
    //         };

    //         handleLogSig();
    //     };
    // },[editResponse, questions, sigCanvas, id]);

    const handleAddResponse = async () => {
        const responseValid = questions?.reduce((acc, cur) => {
            if (!acc) return acc;

            if (cur?.required) {
                switch (cur?.type) {
                    case 'date':
                        break;
                    // case 'signature':
                    //     if (editResponse && editResponse?.hasOwnProperty(cur.id)) {
                    //         break;
                    //     };

                    //     acc = (!sigCanvas?.isEmpty() ? true : false);
                    //     break;
                    case 'icon-rating':
                    case 'number-rating':
                        acc = newResponse[cur.id] > 0 ? true : false;
                        break;
                    case 'checkbox':
                    default:
                        acc = (newResponse[cur.id]?.length > 0 ? true : false);
                        break;
                };
            };

            return acc;
        }, true);

        if (!responseValid) {
            setError('Please fill out all required fields');
            return;
        };

        const newDate = Date.now();
        newResponse.date = (editResponse && editResponse?.hasOwnProperty('date')) ? editResponse.date : newDate;
        newResponse.date_edited = newDate;
        let sigArr = [];

        if (questions?.find(q => q?.type === 'signature')) {
            const que = questions?.find(q => q?.type === 'signature');
            if (que?.required && sigCanvas?.isEmpty() && !editResponse) {
                setError('Please sign before submitting');
                return;
            } else if (sigCanvas?.isEmpty()) {
                sigArr = [];
                newResponse[que.id] = false;
            } else {
                sigArr = sigCanvas.toData();
                newResponse[que.id] = true;
            };
        };

        try {
            if (editResponse && Object?.keys(editResponse)?.length > 0) {
                const res = await editSurveyResponse(id, newResponse);
                setEditResponse(res);
            } else {
                await updateSurveyResponse(id, newResponse, sigArr);
            };
        } catch (e) {
            console.log(e);
        } finally {
            setShowModal(true);
            handleReset();
        };
    };

    const handleReset = () => {
        (sigCanvas && !editResponse) && sigCanvas.clear();
        setNewResponse(questions.reduce((acc, question) => {
            if (question?.archived) return acc;
            
            acc[question.id] = question?.type === 'checkbox' ? [] : '';
            return acc;
        }, {}));
        setDisabled(false);
    };

    return (
        <div className={styles.addLogContainer}>
            <div className={styles.typesContainer}>
                {questions?.filter(question => !question?.archived)?.map((question, index) => (
                    <QuestionTypes
                        key={index}
                        question={question}
                        state={newResponse}
                        setState={setNewResponse}
                        sigCanvas={sigCanvas}
                        setSigCanvas={setSigCanvas}
                        edit={(editResponse && Object?.keys(editResponse)?.length) ? true : false}
                    />
                ))}
            </div>
            <div className={styles.btnContainer}>
                <div style={{display: 'flex', justifyContent: 'space-evenly',  alignItems: 'center'}}>
                    {authenticatedUser ? <button className={`${styles.btn} ${styles.cancel} me-2`} onClick={handleReset}>Cancel</button> : <></>}
                    <button
                        className={authenticatedUser ? `${styles.save} ${styles.btn} ms-2` : `${styles.save} ${styles.btn}`}
                        onClick={() => { setDisabled(() => true); handleAddResponse(); }}
                        disabled={!newResponse || disabled || questions?.reduce((acc, cur) => {
                            if (acc) return acc;

                            if (cur?.required) {
                                switch (cur?.type) {
                                    case 'date':
                                        break;
                                    // case 'signature':
                                    //     if (editResponse && editResponse?.hasOwnProperty(cur.id)) {
                                    //         break;
                                    //     };
                                    //     acc = (sigCanvas?.isEmpty() ? true : false);
                                    //     break;
                                    case 'icon-rating':
                                    case 'number-rating':
                                        acc = newResponse[cur.id] > 0 ? false : true;
                                        break;
                                    case 'checkbox':
                                    default:
                                        acc = (newResponse[cur.id]?.length > 0 ? false : true);
                                        break;
                                };
                            };

                            return acc;
                        }, false)}
                    >{disabled ? <i className='fa-duotone fa-spinner fa-spin' /> : 'Save'}</button>
                </div>
                <div style={{color: 'red'}}><i className='far fa-asterisk me-1' style={{fontSize: '.9rem'}} />- Required</div>
                {error ? <p className={styles.error}>{error}</p> : <></>}
            </div>
            {!authenticatedUser ?
                <Modal show={showModal} className={styles.modal}>
                    <Modal.Header>{survey?.post_survey_options?.title ? survey.post_survey_options.title : 'Thanks!'}</Modal.Header>
                    <Modal.Body>
                        {survey?.post_survey_options?.message ? survey.post_survey_options.message : 'Submission received!'}
                        {showModal ? <Timer url={survey?.post_survey_options?.url} /> : <></>}
                    </Modal.Body>
                </Modal>
                : <></>
            }
        </div>
    );
};

export default AddResponse;