import { useState, useEffect, useRef } from 'react';
import QRCodeStyling from 'pp-qr-code';
import styles from './GeneratorQRDisplay.module.css';

export default function GeneratorQRDisplay({ state }) {
    const qrRef = useRef();
    const [ code, setCode ] = useState(new QRCodeStyling());
    const [ options, setOptions ] = useState({});

    useEffect(() => {
        if (options && options?.hasOwnProperty('data')) {
            code.update(options);
            code.append(qrRef.current);
        };

        return () => setCode(new QRCodeStyling());
    },[options, setCode]);

    useEffect(() => {
        if (state && state?.qrGenObj?.hasOwnProperty('qr_style') && state?.qrGenObj?.qr_style?.hasOwnProperty('data')) {
            setOptions(state.qrGenObj.qr_style);
        };
    },[state, state?.qrGenObj, state?.qrGenObj?.qr_style]);

    return (
        <div className={styles.wrapper} >
            <h3 className='text-center mb-2'>Generator QR</h3>
            <span className={styles.text} >Complete the sign up process to unlock your QR's full potential.</span>
            {state.error ? 
                <div className={styles.errorContainer} ><span className={styles.error}>{state.error}</span></div>
            :
            <>
                <div className={styles.container}>
                    <div ref={qrRef} className={styles.qrRef} />
                </div>
            </>
            }
        </div>
    );
};