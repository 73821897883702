import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/Auth';
import AnalyticsView from '../../components/AnalyticsView';
import styles from './Analytics.module.css';

export default function Analytics() {
    // ----- STATE INITIALIZATION -----
    const { userObj, currentUser, liquidQRs, analytics, orgObj } = useAuth();
    const navigate = useNavigate();
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    if (!currentUser) return (<Navigate to='/login' />);

    if (currentUser && (orgObj?.hasOwnProperty('plan') && orgObj?.plan?.plan_id > 2 && orgObj?.plan?.analytics > 1)) {
        return (
            <>
              <div className={styles.wrapper}>
                  <div className={styles.container}>
                      <AnalyticsView 
                        orgObj={orgObj}
                        liquidQRs={liquidQRs}
                        analytics={analytics}
                        plan={userObj.plan}
                      />
                  </div>
              </div>
            </>
        );
    } else {
        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <h1>Analytics</h1>
                    <div className={styles.content}>
                        <p>Upgrade to a plan with Advanced Analytics to view this content.</p>
                    </div>
                    <button className={styles.upgradeBtn} onClick={() => navigate('/upgrade')} >Upgrade</button>
                </div>
            </div>
        );
    };
};