import React from 'react';
import { Link } from 'react-router-dom';
import LogoDark from '../Images/LiquidQR-dark.png';
import styles from './Menu.module.css';

export default function Menu(props) {
    const { handleLogout, currentUser, plan, toggleNav } = props;

    return (
        <>
            {currentUser ?
                <>
                    <div className={styles.menuHeader}>
                        <img src={LogoDark} alt='liquid logo' width='100%' />
                        <h4>LiquidQR</h4>
                    </div>
                    <Link to='/'><button  id='home' className={styles.btn} onClick={toggleNav}><i className='fas fa-home me-1' />Home</button></Link>
                    <Link to='/collection'><button id='qr-codes' className={styles.btn} onClick={toggleNav}>QR Codes</button></Link>
                    {(plan?.analytics > 1 && plan?.plan_id > 2) ?
                        <Link to='/analytics'><button  id='analytics' className={styles.btn} onClick={toggleNav} >Analytics</button></Link>
                    :
                        <></>
                    }
                    <div className={styles.extra}>
                        <Link to='/settings'><button  id='settings' className={styles.extraLinks}><i className='fas fa-gear me-1' style={{pointerEvents: 'none'}} onClick={toggleNav} />Settings</button></Link>
                        |
                        <button className={styles.extraLinks} onClick={(e) => { handleLogout(e); toggleNav();}} >Logout</button>
                    </div>
                </>
            :
                <>
                    <div className='menu-header'>
                        <img src={LogoDark} alt='liquid logo' width='100%' />
                        <h4>LiquidQR</h4>
                    </div>
                    <Link to='/login'><button  id='login' className={styles.btn} onClick={toggleNav}>Log In</button></Link>
                    <Link to='/signup'><button id='sign-up' className={styles.btn} onClick={toggleNav}>Sign Up</button></Link>
                </>
            }
        </>
    );
};