const getCombinedChartLabels = (timeFrame, startDate, endDate) => {
    const chartLabels = []

    if (timeFrame === 0 && startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const length = ((end.getTime() - start.getTime()) / (86400000)) + 1;

        for (let i = 0; i < length; i++) {
            start.setDate(start.getDate() + (i > 0));
            chartLabels.push(start.getDate() + ' ' + start.toLocaleString('en-us', { month: "short" }) + ' ' + start.getFullYear());
        };
    } else if (timeFrame === 1 || timeFrame === 2) {
        chartLabels.push(...['00:00', '01:00', '02:00', '03:00', '04:00',
            '05:00', '06:00', '07:00', '08:00', '09:00',
            '10:00', '11:00', '12:00', '13:00', '14:00',
            '15:00', '16:00', '17:00', '18:00', '19:00',
            '20:00', '21:00', '22:00', '23:00']);
    } else {
        const today = new Date(Date.now());

        for (let i = 0; i < timeFrame; i++) {
            today.setDate(today.getDate() - (i > 0));

            chartLabels.unshift(today.getDate() + ' ' + today.toLocaleString('en-us', { month: "short" }) + ' ' + today.getFullYear());
        };
    };

    return chartLabels;
};

export default getCombinedChartLabels;
