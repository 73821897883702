import React from 'react';
import QuestionDisplay from '../QuestionDisplay';
import styles from './SurveyOptions.module.css';

const SurveyOptions = (props) => {
    const { qr_data, dataDispatch } = props;

    const handleTitle = e => {
        dataDispatch({type: 'survey-options-title', data: e.target.value});
    };

    const handleMessage = e => {
        dataDispatch({type: 'survey-options-message', data: e.target.value});
    };

    const handleUrl = e => {
        dataDispatch({type: 'survey-options-url', data: e.target.value});
    };

    return (
        <div className={styles.container}>
            <h3>Liquid Feedback Option(s)</h3>
            <div className={styles.inputGroup}>
                <input id='post-survey-title' type='text' className={styles.input} maxLength='50' defaultValue={qr_data?.survey?.post_survey_options?.title} onChange={handleTitle} />
                <label htmlFor='post-survey-title' className={styles.label}>Post Feedback Title</label>
            </div>
            <div className={styles.inputGroup}>
                <input id='post-survey-message' type='text' className={styles.input} maxLength='100' defaultValue={qr_data?.survey?.post_survey_options?.message} onChange={handleMessage} />
                <label htmlFor='post-survey-message' className={styles.label}>Post Feedback Message</label>
            </div>
            <div className={styles.inputGroup}>
                <input id='post-survey-url' type='url' className={styles.input} maxLength='100' defaultValue={qr_data?.survey?.post_survey_options?.url} onChange={handleUrl} />
                <label htmlFor='post-survey-url' className={styles.label}>Post Feedback URL</label>
            </div>
            <h3>Liquid Feedback Question(s)</h3>
            <div className={styles.columnList}>
                <QuestionDisplay questions={qr_data?.survey?.questions?.sort((a,b) => a.archived - b.archived)} qr_data={qr_data} dataDispatch={dataDispatch}  />
            </div>
        </div>
    );
};

export default SurveyOptions;