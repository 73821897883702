import React from 'react';
import { useAuth } from '../../utils/Auth';
import { Navigate } from 'react-router-dom';
import Dashboard from '../../components/Dashboard';
import LoadingSpinner from '../../components/LoadingSpinner';
import styles from './Home.module.css';

export default function Home() {
    const { orgObj, currentUser, liquidQRs, analytics } = useAuth();

    if (!currentUser) return <Navigate to='/login'/>;

    if (!orgObj || !analytics) {
        return <></>
    };
    // console.log('orgObj', orgObj);
    // console.log('liquidQRs', liquidQRs);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {(orgObj && analytics) ?
                    <Dashboard 
                        categories={orgObj?.categories}
                        liquidQRs={liquidQRs}
                        analytics={analytics}
                        plan={orgObj.plan}
                    />
                :
                    <LoadingSpinner />
                }
            </div>
        </div>
    );
};