import React from 'react';
import styles from './QuestionTypes.module.css';

const Options = ({ options, setQuestion }) => {
    if (options?.length > 0) {
        return (
            options?.map((opt, index) => (
                <div key={opt+index} className={styles.addOptContainer}>
                    <div className={styles.inputGroup}>
                        <input type='text' id='question-options-label' className={styles.input} maxLength='100' value={opt} disabled/>
                        <label htmlFor='question-options-label' className={styles.label}>Option</label>
                        <button className={styles.removeOptionBtn} onClick={() => setQuestion(prev => ({...prev, options: prev.options.filter((option, i) => i !== index)}))} ><i className='fa-solid fa-xmark' /></button>
                    </div>
                </div>
            ))
        );
    } else {
        return <></>;
    };
};

const QuestionTypes = (props) => {
    const { question, setQuestion, option, setOption } = props;

    switch (question?.type) {
        case 'dropdown':
        case 'radio':
        case 'checkbox':
            return (
                <div className={styles.optionsContainer}>
                    <Options options={question?.options} setQuestion={setQuestion} />
                    <div className={styles.addOptContainer}>
                        <div className={styles.inputGroup}>
                            <input type='text' id='question-options-label' className={styles.input} maxLength='100' value={option} onChange={e => setOption(e.target.value)} />
                            <label htmlFor='question-options-label' className={styles.label}>Options</label>
                        </div>
                        <button className={styles.addOptionBtn} onClick={() => { setQuestion(prev => ({ ...prev, options: prev.options.concat(option) })); setOption(''); }} ><i className='fa-solid fa-plus' /></button>
                    </div>
                </div>
            );
        case 'number-rating':
            return (
                <div className={styles.optionsContainer}>
                    <div className={styles.addOptContainer}>
                        <div className={styles.inputGroup}>
                            <input type='number' min='3' max='10' id='question-options-label' className={styles.input} defaultValue={question?.scale ? question.scale : '5'} onChange={e => setQuestion(prev => ({...prev, scale: parseInt(e.target.value)}))} />
                            <label htmlFor='question-options-label' className={styles.label}>Scale</label>
                        </div>
                    </div>
                </div>
            );
        case 'icon-rating':
            return (
                <div className={styles.optionsContainer}>
                    <div className={styles.addOptContainer}>
                        <div className={styles.inputGroupSelect}>
                            <select className={styles.input} id='question-options-label' defaultValue={question?.icon ? question.icon : 'stars'} onChange={e => setQuestion(prev => ({...prev, icon: e.target.value}))}>
                                <option value='stars'>Stars</option>
                                <option value='hearts'>Hearts</option>
                                <option value='thumbs-up'>Thumbs Up</option>
                                <option value='flames'>Flames</option>
                                <option value='emotions'>Emotions</option>
                            </select>
                            <label htmlFor='question-options-label' className={styles.label}>Icon</label>
                        </div>
                    </div>
                </div>
            );
        default:
            return <></>;
    };
};

export default QuestionTypes;