import React, { useState } from 'react';
import handleLabels from '../../utils/QRFunctions/handleLabels';
import API from '../../API';
import styles from './PasswordResetRequest.module.css';

const PasswordResetRequest = () => {
    const { resetPassword } = API;

    // ----- STATE INITIALIZATION -----
    const [ email, setEmail ] = useState('');
    const [ showSent, setShowSent ] = useState(false);
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- AUTH FUNCTIONS -----
    const handlePasswordReset = async () => {
        await resetPassword(email).then(res => {
            setShowSent(true);
        }).catch(err => console.log(err));
    };
    // ----- END AUTH FUNCTIONS -----
    // ----------------------------------------------

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <h2>Request Password Reset</h2>
                {showSent ?
                    <div>Please check your email inbox to reset your password.</div>
                :
                <>
                    <div className={styles.inputGroup}>
                        <input
                            type='email'
                            id='password-reset-email-label'
                            style={{marginBottom: '1rem'}}
                            value={email}
                            onChange={(e) => {setEmail(e.target.value)}}
                            onBlur={handleLabels}
                            required
                        />
                        <label htmlFor='password-reset-email-label' className={styles.label}>Email</label>
                    </div>
                    <div className={styles.inputGroup} style={{justifyContent: 'center'}}>
                        <button className={styles.btn} onClick={handlePasswordReset}>Submit</button>
                    </div>
                </>
            }
            </div>
        </div>
    );
};

export default PasswordResetRequest;