import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../utils/Auth';
import API from '../../API';
import Table from './Table';
import AddLog from './AddLog';
import { useNavigate } from 'react-router-dom';
import { handleTextWithLinks } from '../../utils/QRFunctions/handleTextWithLinks';
import LoadingSpinner from '../../components/LoadingSpinner';
import styles from './TrackerPage.module.css';

const Display = (props) => {
    const { id, tracker, logs, editLogEntry, setEditLogEntry, selectedLog, setSelectedLog, authenticatedUser, deletedToggle, setDeletedToggle } = props;

    const [ showAddLog, setShowAddLog ] = useState(false);
    const [ columns ] = useState(tracker?.columns?.filter(col => !col?.archived) ?? []);

    if (!tracker) return (<div>Tracker not found</div>);

    if (showAddLog) {
        return (
            <AddLog
                id={id}
                columns={columns}
                setShowAddLog={setShowAddLog}
                authenticatedUser={authenticatedUser}
            />
        );
    } else {
        return (
            <Table
                id={id}
                columns={columns}
                logs={logs}
                setShowAddLog={setShowAddLog}
                editLogEntry={editLogEntry}
                setEditLogEntry={setEditLogEntry}
                selectedLog={selectedLog}
                setSelectedLog={setSelectedLog}
                authenticatedUser={authenticatedUser}
                deletedToggle={deletedToggle}
                setDeletedToggle={setDeletedToggle}
            />
        );
    };
};

export default function TrackerPage() {
    const { getQRListener, updateTrackerLogToDeleted } = API;
    const { userObj } = useAuth();
    const navigate = useNavigate();
    
    // ----- STATE INITIALIZATION -----
    const [ qrObj, setQrObj ] = useState(null);
    const [ editLogEntry, setEditLogEntry ] = useState(false);
    const [ deleteLogEntry, setDeleteLogEntry ] = useState(false);
    const [ selectedLog, setSelectedLog ] = useState(null);
    const [ deletedToggle, setDeletedToggle ] = useState(false);

    const { id } = useParams();
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    useEffect(() => {
        let listener;

        if (id) {
            listener = getQRListener(id, setQrObj);
        };

        return () => {
            listener();
            setQrObj(null);
        };
    }, []);

    const handleDeleteLog = async () => {
        try {
            const res = await updateTrackerLogToDeleted(id, selectedLog.date);
            if (res === true) {
                setSelectedLog(null);
                setEditLogEntry(false);
                setDeleteLogEntry(false);
            };
        } catch (err) {
            console.log(err);
        };
    };

    const handleNavButtons = () => {
        if (!selectedLog) {
            return (
                <div className={styles.navBtnCont}>
                    <button className={styles.backBtn} onClick={() => navigate(`/collection`, { state: { qrID: id } }) } >Collection</button>
                </div>
            );
        };

        if (deletedToggle) {
            return (
                <div className={styles.navBtnCont}>
                    <button className={styles.backBtn} onClick={() => setSelectedLog(null)} >Back</button>
                </div>
            );
        } else if (editLogEntry) {
            return (
                <div className={styles.navBtnCont}>
                    <button className={styles.backBtn} onClick={() => setEditLogEntry(false)} >Back</button>
                </div>
            );
        } else if (deleteLogEntry) {
            return (
                <>
                    <div className={styles.navBtnCont}>
                        <button className={styles.cancelDeleteBtn} onClick={() => setDeleteLogEntry(false)} >Cancel</button>
                        <button className={styles.deleteBtn} onClick={handleDeleteLog} >Confirm</button>
                    </div>
                    <p className={styles.deleteText}>Deleting logs is permanent. Delete log?</p>
                </>
            );
        } else {
            return (
                <div className={styles.navBtnCont}>
                    <button className={styles.backBtn} onClick={() => setSelectedLog(null)}>Back</button>
                    <button className={styles.deleteBtn} onClick={() => setDeleteLogEntry(true)} >Delete</button>
                </div>
            );
        };
    };

    if (!qrObj || !qrObj?.tracker) return (<div className={styles.wrapper}>
            <div className={styles.container}>
                <LoadingSpinner />
            </div>
        </div>);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.display} >
                    <div className={styles.header}>
                        {(userObj?.org_id === qrObj?.org_id) ?
                            handleNavButtons()
                        : selectedLog ?
                            <div className={styles.navBtnCont}>
                                <button className={styles.backBtn} onClick={() => setSelectedLog(null)} >Back</button>
                            </div>
                        :
                            <></>
                        }
                        <h2 className='mt-3'>{qrObj?.nickname}</h2>
                        {(userObj?.org_id === qrObj?.org_id) ? ((selectedLog) ? <button className={styles.editBtn} onClick={() => setEditLogEntry(true)} disabled={selectedLog?.deleted} >Edit</button> : <button className={styles.editBtn} onClick={() => navigate(`/collection?qrid=${id}&edit=true&ref=t`) }>Edit</button>) : <></>}
                        <div className={styles.description}>
                            <div className={styles.logSectionText} style={{marginTop: '.5rem', lineHeight: '1.25rem', textAlign: 'center'}}>{qrObj?.tracker?.description ? <p>{handleTextWithLinks(qrObj?.tracker?.description)}</p> : <></>}</div>
                        </div>
                    </div>
                    <Display id={id} tracker={qrObj?.tracker} logs={qrObj?.tracker_logs ?? []} editLogEntry={editLogEntry} setEditLogEntry={setEditLogEntry} selectedLog={selectedLog} setSelectedLog={setSelectedLog} authenticatedUser={userObj?.org_id === qrObj?.org_id} deletedToggle={deletedToggle} setDeletedToggle={setDeletedToggle} />
                </div>
            </div>
        </div>
    );
}