import React, { useEffect, useState } from 'react';
import styles from './PasswordStrength.module.css';

export default function PasswordStrength({password}) {

    const [ word, setWord ] = useState("No Password");
    const [ barWidth, setBarWidth ] = useState('.75rem');
    const [ color, setColor ] = useState('#e44242');

    const containerStyle = {
        padding: window.location.pathname === '/signup' ? '.5rem .75rem' : '.5rem 5rem .5rem .75rem'
    }
    const barStyle = {
        width: barWidth,
        backgroundColor: color
    };
    const wordStyle = {
        color: color
    };

    useEffect(() => {
        //             Strong -                      Medium -                      Weak -
        // at least    1 lowercase letter,           same                          same
        // at least    1 uppercase letter,           same                          none
        // at least    1 digit,                      both or                       none
        // at least    1 special character,          one                           none
        // at least    8 characters in length        6 if both ^ or 8 if 1 ^       6
        const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
        const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}))')
        
        if (strongPassword.test(password)) {
            setBarWidth('100%');
            setColor('#36c565')
            setWord('Strong')
        } else if (mediumPassword.test(password)) {
            setBarWidth('66.6%');
            setColor('#1493ff');
            setWord('Medium');
        } else if (password?.length >= 6) {
            setBarWidth('33.3%');
            setColor('#f99f4a');
            setWord('Weak');
        } else if (password?.length !== 0) {
            setBarWidth('.75rem');
            setColor('#e44242');
            setWord('Minimum 6 Characters')
        } else {
            setBarWidth('.75rem');
            setColor('#e44242');
            setWord('No Password');
        }
    },[password])

    return (
        <div className={styles.container} style={containerStyle}>
            <div className={styles.backgroundBar}>
                <div className={styles.bar} style={barStyle} />
            </div>
            <p className={styles.word} style={wordStyle}>{word}</p>
        </div>
    )
}